import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'
import {
    INationalOutreachBudgetAttachment,
    NationalOutreachBudgetAttachmentFormValues
} from "../../models/NationalOutreach/NationalOutreachBudgetAttachment";


export default class NationalOutreachBudgetAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.nationalOutreachBudgetAttachmentRegistry.clear()
                this.loadNationalOutreachBudgetAttachment()
            }
        )

        reaction(
            () => this.currentNationalOutreachBudgetId,
            () => {
                this.page = 0
                this.nationalOutreachBudgetAttachmentRegistry.clear()
                !!this.currentNationalOutreachBudgetId ? this.getNationalOutreachBudgetAttachmentsByNationalOutreachBudgetId() : this.loadNationalOutreachBudgetAttachment()
            }
        )
    }

    nationalOutreachBudgetAttachmentRegistry = new Map()
    nationalOutreachBudgetAttachment: INationalOutreachBudgetAttachment | null = null
    editMode = false


    currentNationalOutreachBudgetId = '';
    nationalOutreachBudgetAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentNationalOutreachBudgetId = (nationalOutreachBudgetId: string) => {
        this.currentNationalOutreachBudgetId = nationalOutreachBudgetId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.nationalOutreachBudgetAttachmentCount / this.limit)
    }

    get nationalOutreachBudgetAttachmentsById() {
        return Array.from(this.nationalOutreachBudgetAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get nationalOutreachBudgetAttachmentsByDate() {
        return Array.from(this.nationalOutreachBudgetAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getNationalOutreachBudgetAttachment = (id: string) => {
        return this.nationalOutreachBudgetAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.nationalOutreachBudgetAttachmentRegistry.clear()
        this.loadNationalOutreachBudgetAttachment()
    }

    clearNationalOutreachBudgetAttachment = () => {
        this.nationalOutreachBudgetAttachmentRegistry.clear()
    }

    loadNationalOutreachBudgetAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const nationalOutreachBudgetAttachmentsEnvelope = await Agent.NationalOutreachBudgetAttachments.list(this.axiosParams);
            const {modelsDto, modelCount} = nationalOutreachBudgetAttachmentsEnvelope;

            runInAction(() => {
                this.clearNationalOutreachBudgetAttachment()
                modelsDto.forEach((nationalOutreachBudgetAttachment: INationalOutreachBudgetAttachment) => {
                    this.nationalOutreachBudgetAttachmentRegistry.set(nationalOutreachBudgetAttachment.id, nationalOutreachBudgetAttachment)
                })
                this.nationalOutreachBudgetAttachmentCount = modelCount
            })
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getNationalOutreachBudgetAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let nationalOutreachBudgetAttachment = await Agent.NationalOutreachBudgetAttachments.byId(id)
            runInAction(() => {
                this.nationalOutreachBudgetAttachment = nationalOutreachBudgetAttachment
                this.nationalOutreachBudgetAttachmentRegistry.set(nationalOutreachBudgetAttachment.id, nationalOutreachBudgetAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return nationalOutreachBudgetAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getNationalOutreachBudgetAttachmentsByNationalOutreachBudgetId = async (nationalOutreachBudgetId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let nationalOutreachBudgetAttachmentEnvelope = await Agent.NationalOutreachBudgetAttachments.byNationalOutreachBudgetId(nationalOutreachBudgetId || this.currentNationalOutreachBudgetId, this.axiosParams)
            const {modelsDto, modelCount} = nationalOutreachBudgetAttachmentEnvelope
            runInAction(() => {
                modelsDto.forEach(nationalOutreachBudgetAttachment => {
                    this.nationalOutreachBudgetAttachmentRegistry.set(nationalOutreachBudgetAttachment.id, nationalOutreachBudgetAttachment)
                })

                this.nationalOutreachBudgetAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createNationalOutreachBudgetAttachment = async (nationalOutreachBudgetAttachment: NationalOutreachBudgetAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newNationalOutreachBudgetAttachment = await Agent.NationalOutreachBudgetAttachments.create(nationalOutreachBudgetAttachment)
            await (!!this.currentNationalOutreachBudgetId ? this.getNationalOutreachBudgetAttachmentsByNationalOutreachBudgetId() : this.loadNationalOutreachBudgetAttachment())

            runInAction(() => {
                this.nationalOutreachBudgetAttachmentRegistry.set(newNationalOutreachBudgetAttachment.id, newNationalOutreachBudgetAttachment);
                this.rootStore.modalStore.closeModal('AttachmentModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteNationalOutreachBudgetAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.NationalOutreachBudgetAttachments.delete(id)
            runInAction(() => {
                this.nationalOutreachBudgetAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('NationalOutreachBudgetAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

}