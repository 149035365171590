import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {
    IReimbursementClaim,
    IReimbursementClaimApproval,
    IReimbursementClaimEnvelope,
    IReimbursementClaimPayment,
    IReimbursementClaimSubmitAttachment,
    ReimbursementClaimFormValues
} from "../../models/Reimbursement/ReimbursementClaim";
import {ReimbursementClaimDTORevert} from "../../models/Reimbursement/ReimbursementClaimDTORevert";

const ReimbursementClaim = {
    list: (
        params: URLSearchParams
    ): Promise<IReimbursementClaimEnvelope> =>
        axios.get('/ReimbursementClaim', {params: params})
            .then(responseBody),

    byId: (reimbursementClaimId: string) =>
        api.get(`/ReimbursementClaim/${reimbursementClaimId}`),

    create: (ReimbursementClaim: ReimbursementClaimFormValues) =>
        api.post(`/ReimbursementClaim`, ReimbursementClaim),

    update: (ReimbursementClaim: IReimbursementClaim) =>
        api.put(`/ReimbursementClaim/${ReimbursementClaim.id}`, ReimbursementClaim),

    revert: (id:  string, payload: ReimbursementClaimDTORevert) =>
        api.put(`/ReimbursementClaim/${id}/revert`, payload),

    approve: (ReimbursementClaimId: string, Approval: IReimbursementClaimApproval) =>
        api.put(`/ReimbursementClaim/${ReimbursementClaimId}/Approve`, Approval),

    payment: (ReimbursementClaimId: string, Payment: IReimbursementClaimPayment) =>
        api.put(`/ReimbursementClaim/${ReimbursementClaimId}/Payment`, Payment),

    calculate: (ReimbursementClaimId: string) =>
        api.put(`/ReimbursementClaim/${ReimbursementClaimId}/Calculate`, {}),

    submit: (ReimbursementClaimId: string, Submit: IReimbursementClaimSubmitAttachment) => {
        let formData = new FormData();
        for (const key in Submit) {
            formData.append(key, Submit[key])
        }

        return api.put(`/ReimbursementClaim/${ReimbursementClaimId}/Submit`, formData)
    },
};

export default ReimbursementClaim