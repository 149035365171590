export interface IEntityEnvelope {
    modelsDto: IEntity[]
    modelCount: number
}

export interface IEntity {
    id: string
    name: string
    address1: string
    address2?: string
    city: string
    state: string
    zipCode: string
    phone: string
}


//interface extention for custom built items like time in a datetime
export interface IEntityFormValues extends Partial<IEntity> {
    //time?:Date
}

//empty form model
export class EntityFormValues implements IEntityFormValues {
    id: string = ''
    name: string = ''
    address1: string = ''
    address2?: string
    city: string = ''
    state: string = ''
    zipCode: string = ''
    phone: string = ''

    constructor(entity?: IEntity) {
        if (entity) {
            Object.assign(this, entity);
        }
    }
}