import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {ITravel, TravelFormValues} from "../../../../models/Travel/Travel";
import {RootStoreContext} from "../../../../stores/RootStore";
import {displayDate, formNavigationHandler, objectDateConverter, today} from "../../../../lib/utilities";
import InstallationMultiselect from "../../../../components/form/Multiselects/InstallationMultiselect";
import Input from "../../../../components/Inputs/Input";
import EntityStateAutoCompleteInput from "../../../../components/form/EntityStatesAutoCompleteInput";
import TechnicianAutoCompleteInput from "../../../../components/form/TechnicianAutoCompleteInput";
import MaintenanceRepairMultiselect from "../../../../components/form/Multiselects/MaintenanceRepairMultiselect";
import AssessmentMultiselect from "../../../../components/form/Multiselects/AssessmentMultiselect";
import Button from "../../../../components/generic/Button";
import dateInputOptions from "../../../../data/cleave/dateInputOptions";
import Toggle from "../../../../components/generic/Toggle";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import TrainingMultiselect from "../../../../components/form/Multiselects/TrainingMultiselect";
import {CheckIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface TravelFormProps {
    travel?: ITravel;
}

type Props = TravelFormProps

const TravelForm: React.FC<Props> = ({travel}) => {
    const maxStep = 4

    // store
    const rootStore = useContext(RootStoreContext)
    const {closeModal, openModal} = rootStore.modalStore
    const {createTravel, editTravel} = rootStore.travelStore

    // state
    const [currentTravel, setCurrentTravel] = useState(new TravelFormValues(travel))
    const [step, setStep] = useState(0)
    const [animation, setAnimation] = useState('from-right')
    const [addCost, setAddCost] = useState(true);

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);

    // Use Effects
    useEffect(() => {
        if (!!travel)
            objectDateConverter(travel, setCurrentTravel)
    }, [])

    useEffect(() => {
        if (!currentTravel.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentTravel((prev: TravelFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})`
                }
            })
        }
    }, [localEntityStates])


    const handleChange = (e) => {
        const {name, value} = e.currentTarget
        setCurrentTravel(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newTravel = {...currentTravel}
        if (!newTravel.id) {
            delete newTravel.id
            return createTravel(newTravel).then(data => addCostAfterSaving(data))
        } else {
            return editTravel(newTravel)
        }
    }

    const addCostAfterSaving = async (data) => {
        if (!!data && addCost) await openModal('SubItemModal', {
            header: 'New Travel Cost',
            lineItemId: data.id,
            lineItemType: 'Travel',
            entityStateId: data.entityStateId,
        })
    }

    const handleNavChange = (e, step) => {
        formNavigationHandler(step, setAnimation, setStep,)
    }

    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-1 flex-col h-full'}>
            <div className={'overflow-y-auto overflow-x-hidden flex-1'}>
                <div className={`${step === 0 ? animation : 'hide'}`}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1 mb-3">
                        <EntityStateAutoCompleteInput
                            required
                            isLocked={(!currentTravel.id && !!localEntityStates && localEntityStates.length === 1)}
                            onChange={({id}) => handleAutoComplete(id, 'entityStateId')}
                            name={'entityStateId'}
                            placeholder={'California'}
                            displayValue={(currentTravel.entityStateId) ? (currentTravel?.entityState || 'State Program already selected') : ''}
                        />

                        <TechnicianAutoCompleteInput
                            onChange={({id}) => handleAutoComplete(id, 'technicianId')}
                            name={'technicianId'}
                            displayValue={(currentTravel.technicianId) ? (currentTravel?.technicianName || 'Technician already selected') : ''}
                        />
                    </div>

                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1 mb-3'}>
                        <Input
                            label={'Start Date'}
                            value={`${currentTravel.travelStartDate ?? ''}`}
                            name={'travelStartDate'}
                            onChange={handleChange}
                            options={{
                                ...dateInputOptions,
                                dateMax: currentTravel.travelEndDate ? displayDate(currentTravel.travelEndDate, 'YYYY-MM-DD').toString() : today()
                            }}

                            pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                            placeholder={'10/26/1985'}
                            required
                        />

                        <Input
                            label={'End Date'}
                            value={`${currentTravel.travelEndDate ?? ''}`}
                            name={'travelEndDate'}
                            onChange={handleChange}
                            options={{
                                ...dateInputOptions,
                                dateMin: displayDate(currentTravel.travelStartDate, 'YYYY-MM-DD').toString() ?? '',
                            }}

                            pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                            placeholder={'10/26/1985'}
                            required
                        />
                    </div>
                    {!currentTravel.id ?
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                            <Toggle label={'add cost after saving'} onChange={() => setAddCost(prev => !prev)}
                                    checked={addCost} name={'addCostToggle'}/>
                        </div>
                        : ''}
                </div>

                <div className={`${step === 1 ? animation : 'hide'}`}>
                    <AssessmentMultiselect name={'assessmentIds'} onChange={handleChange}
                                           selected={currentTravel.assessmentIds}
                                           entityStateId={currentTravel.entityStateId}/>
                </div>

                <div className={`${step === 2 ? animation : 'hide'}`}>
                    <InstallationMultiselect name={'installationIds'} onChange={handleChange}
                                             selected={currentTravel.installationIds}
                                             entityStateId={currentTravel.entityStateId}/>
                </div>

                <div className={`${step === 3 ? animation : 'hide'}`}>
                    {/*@ts-ignore*/}
                    <TrainingMultiselect name={'trainingIds'} onChange={handleChange}
                                         selected={currentTravel.trainingIds}
                                         entityStateId={currentTravel.entityStateId}/>
                </div>

                <div className={`${step === 4 ? animation : 'hide'}`}>
                    <MaintenanceRepairMultiselect name={'maintenanceRepairIds'} onChange={handleChange}
                                                  selected={currentTravel.maintenanceRepairIds}
                                                  entityStateId={currentTravel.entityStateId}/>
                </div>
            </div>

            <div className={'flex justify-between'}>
                <div className={'flex gap-4'}>
                    <Button color='dark'
                            onClick={(e) => handleNavChange(e, step - 1)}
                            disabled={step === 0}
                            iconLeft={<ChevronLeftIcon className={'h-5 w-5'}/>} className={"mr-3"}
                    >Back</Button>


                    <Button color='dark'
                            onClick={(e) => handleNavChange(e, step + 1)}
                            id={'travelNextBtn'}
                            disabled={!currentTravel.entityStateId || step === maxStep}
                            iconRight={<ChevronRightIcon className={'h-5 w-5'}/>}
                    >Next</Button>
                </div>

                <div className={'w-full'}>{/* DO NOT DELETE */}</div>

                <div className={'flex gap-4'}>
                    <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} disabled={step !== maxStep}
                            color={'green'}
                            type={'submit'}>Save</Button>
                    <Button
                        onClick={() => {
                            closeModal('TravelModal')
                        }}
                        color="red"
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}
                        name={'cancel'}
                    >Cancel</Button>
                </div>
            </div>
        </form>
    );
};
export default observer(TravelForm)