import axios from "axios";
import {
    AdministrativeFormValues,
    IAdministrative,
    IAdministrativeEnvelope
} from "../../models/Administrative/Administrative";
import {api, responseBody} from "./apiBase.service";

const Administratives = {
    list: (
        params: URLSearchParams
    ): Promise<IAdministrativeEnvelope> =>
        axios.get("/Administrative", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/Administrative/${id}`),
    create: (Administrative: AdministrativeFormValues) =>
        api.post(`/Administrative/`, Administrative),
    update: (Administrative: IAdministrative) =>
        api.put(`/Administrative/${Administrative.id}`, Administrative),
    delete: (id: string) =>
        api.del(`/Administrative/${id}`),
};

export default Administratives