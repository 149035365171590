import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RootStoreContext} from "../../stores/RootStore";
import Input from "../Inputs/Input";
import numberInputOptions from "../../data/cleave/numberInputOptions";
import TextArea from "../form/TextArea";
import {inputOnChange, splitCamelCase} from "../../lib/utilities";
import {ISubItem} from "../../models/SubItems/SubItem";
import {observer} from "mobx-react-lite";
import Select from "../generic/Select";
import {ISubItemCategory} from "../../models/SubItems/SubItemCategory";
import Button from "../generic/Button";
import SubItemQueueTable from "./SubItemQueueTable";
import {CheckIcon, PaperClipIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {CheckCircleIcon} from "@heroicons/react/24/outline";

export interface SubItemFormProps {
    entityStateId: string
    lineItemId: string
    lineItemType?: "Administrative" | "Assessment" | "Equipment" | "Installation" | "Installation or Training" | "Maintenance/Repair" | "Maintenance/Repair or Equipment" | "Outreach" | "ShippingAndTax" | "Training" | "Travel" | "Travel (Installation or Training)" | "Travel (Training or Installation)" | "Travel (Upgrade or M/R)" | "Travel (installation or Training)" | "Travel (outreach)" | "MaintenanceRepair" | "ShippingTax"
    subItem?: ISubItem

    onCancel?(e: any): void
}

type Props = SubItemFormProps;

interface ISelect {
    key: any
    text: string
    value: any
}

const SubItemForm: FunctionComponent<Props> = ({subItem, lineItemId, lineItemType, entityStateId, onCancel}) => {
    // Mobx State
    const rootStore = useContext(RootStoreContext);
    const {openModal} = rootStore.modalStore
    const {
        loadSubItemCategory,
        subItemCategoriesByLineItem,
        createSubItem,
        updateSubItem,
        newSubItemQueue,
        clearNewSubItemQueue,
        markSubItemAsAttachedById,
    } = rootStore.subItemStore
    const {createLineItemAttachment} = rootStore.lineItemAttachmentStore
    const {isLoading} = rootStore.commonStore


    // Local State
    const [lineItemTypes, setLineItemTypes] = useState([] as Array<ISelect>);
    const [subItemCategory, setSubItemCategory] = useState([] as ISelect[]);
    const [currentSubItem, setCurrentSubItem] = useState({
        lineItemId,
        activityType: lineItemType,
        entityStateId
    } as ISubItem);


    // Use Effects
    useEffect(() => {
        loadSubItemCategory()
    }, [loadSubItemCategory]);

    useEffect(() => {
        setLineItemTypes(subItemCategoriesByLineItem.map((category: ISubItemCategory[]) => {
            return {
                key: category[0].lineItem,
                value: category[0].lineItem,
                text: splitCamelCase(category[0].lineItem),
            }
        }))

        filterCategories()
    }, [subItemCategoriesByLineItem]);

    useEffect(() => {
        setCurrentSubItem(prev => {
            return {...prev, activityType: lineItemType}
        })
    }, []);

    useEffect(() => {
        if (!!subItem) setCurrentSubItem(subItem)
    }, [subItem]);

    useEffect(() => {
        return () => {
            handleCancel({})
        }
    }, []);

    // Function
    const filterCategories = () => {
        let tmpArr: Array<Array<ISubItemCategory>> = subItemCategoriesByLineItem
            .filter((category: ISubItemCategory[]) => {
                return !!currentSubItem.activityType ? category[0].lineItem === currentSubItem.activityType : category[0].lineItem === lineItemType
            })

        if (tmpArr.length > 0 && tmpArr[0].length > 0) {
            setSubItemCategory(
                tmpArr[0].map((category: ISubItemCategory) => {
                    return {
                        text: category.description,
                        // text: `${category.categoryNumber} - ${category.description}`,
                        key: category.categoryNumber,
                        value: category.categoryNumber
                    }
                }).sort((a, b) => a.value - b.value)
            )
        }
    }

    const handleInput = (e) => {
        const {name} = e.currentTarget;
        inputOnChange(e, setCurrentSubItem)

        if (name === 'activityType') {
            filterCategories()
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let tmpSubItem = {...currentSubItem}
        // @ts-ignore
        if (tmpSubItem.pageNumber === '') delete tmpSubItem.pageNumber

        if (!tmpSubItem.id || tmpSubItem.id === '') {
            try {
                let newSubItem = await createSubItem(tmpSubItem)
                if (!!newSubItem.id) {
                    clearSubItemForm()
                }
            } catch (err) {
                console.error(err)
            }
        } else {
            await updateSubItem(tmpSubItem)
        }
    }

    const handleCancel = (e?) => {
        clearSubItemForm()
        clearNewSubItemQueue()
        !!onCancel && onCancel(e)

    }

    const attachToAllSubItems = () => {
        const createFunction = async (attachment) => {
            try {
                let newAttachment = await createLineItemAttachment(attachment)
                if (!!newAttachment.id) {
                    for (let a of newAttachment.subItemIds) {
                        markSubItemAsAttachedById(a)
                    }
                }
            } catch (e) {
                console.error(e)
            }
        }

        let subItemIds = newSubItemQueue.filter(si => !si.hasAttachment).map(si => si.id)
        openModal('LineItemAttachmentModal', {subItemIds, header: 'Cost Attachment', createFunction})
    }


    const clearSubItemForm = () => {
        setCurrentSubItem(prev => {
            let tmp = {...prev}
            for (let key in tmp) {
                if (key !== 'activityType' && key !== 'lineItemId' && key !== 'entityStateId') tmp[key] = ''
                if (key === 'time') tmp[key] = 0
            }
            return tmp
        })
    }

    return (<>
        <form onSubmit={handleSubmit} className={'flex flex-col flex-1'} autoComplete={'off'}>
            <div>
                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    {lineItemType !== 'Equipment' && <div className='grow'>
                        <Input
                            label={'Time (Hours)'}
                            name={'time'}
                            options={{
                                numeral: true,
                                numeralPositiveOnly: true
                            }}
                            onChange={handleInput}
                            value={currentSubItem.time}
                            placeholder={'2.56'}
                        />
                    </div>}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                    <Select
                        className={'mb-3'}
                        options={lineItemTypes}
                        name={'activityType'}
                        value={currentSubItem.activityType}
                        label={'Activity Type'}
                        onChange={handleInput}
                        disabled
                    />
                    <Select
                        className={'mb-3'}
                        options={subItemCategory}
                        name={'categoryNumber'}
                        value={currentSubItem.categoryNumber}
                        label={'Category'}
                        onChange={handleInput}
                        required
                    />
                </div>
                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                    <Input
                        label={'Documentation Page Number'}
                        options={{
                            numeral: true,
                            numeralPositiveOnly: true,
                            numeralDecimalScale: 0,
                        }}
                        onChange={handleInput}
                        placeholder={'1959'}
                        value={currentSubItem.pageNumber || ''}
                        name={'pageNumber'}
                    />
                    <Input
                        label={'Cost'}
                        options={numberInputOptions}
                        onChange={handleInput}
                        placeholder={'$88.00'}
                        pattern={'^\\$(\\d{1,3}[,]?)+(\\.\\d{1,2})?$'}
                        value={currentSubItem.amount}
                        name={'amount'}
                        required
                    />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <TextArea label={'Description'} name={'description'} onChange={handleInput}
                              placeholder={'Plutonium fuel for time machine'}
                              value={currentSubItem.description}/>
                </div>
            </div>


            <div className={'flex justify-end gap-4'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'} type={'submit'}
                        disabled={isLoading}>Save</Button>
                <Button color={newSubItemQueue.length > 0 ? 'indigo' : 'red'}
                        disabled={isLoading}
                        iconLeft={newSubItemQueue.length > 0 ? <CheckCircleIcon className={'h-5 w-5'}/> : <XMarkIcon className={'h-5 w-5'}/>}
                        onClick={handleCancel}>{newSubItemQueue.length > 0 ? 'Done' : 'Cancel'}</Button>
            </div>
        </form>

        <SubItemQueueTable/>

        {newSubItemQueue.length > 0 && <div className="flex justify-end mt-4">
            <Button disabled={isLoading} onClick={attachToAllSubItems}
                    iconLeft={<PaperClipIcon className={'h-5 w-5'}/>}>Attach to All</Button>
        </div>}

    </>)
}

export default observer(SubItemForm);
