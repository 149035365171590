import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {AssessmentAttachmentFormValues, IAssessmentAttachment} from '../../models/Assessment/AssessmentAttachment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class AssessmentAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.assessmentAttachmentRegistry.clear()
                this.loadAssessmentAttachment()
            }
        )

        reaction(
            () => this.currentAssessmentId,
            () => {
                this.page = 0
                this.assessmentAttachmentRegistry.clear()
                !!this.currentAssessmentId ? this.getAssessmentAttachmentsByAssessmentId() : this.loadAssessmentAttachment()
            }
        )
    }

    assessmentAttachmentRegistry = new Map()
    assessmentAttachment: IAssessmentAttachment | null = null
    editMode = false


    currentAssessmentId = '';
    assessmentAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentAssessmentId = (assessmentId: string) => {
        this.currentAssessmentId = assessmentId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.assessmentAttachmentCount / this.limit)
    }

    get assessmentAttachmentsById() {
        return Array.from(this.assessmentAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get assessmentAttachmentsByDate() {
        return Array.from(this.assessmentAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getAssessmentAttachment = (id: string) => {
        return this.assessmentAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.assessmentAttachmentRegistry.clear()
        this.loadAssessmentAttachment()
    }

    clearAssessmentAttachment = () => {
        this.assessmentAttachmentRegistry.clear()
    }

    loadAssessmentAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const assessmentAttachmentsEnvelope = await Agent.AssessmentAttachments.list(this.axiosParams);
            const {modelsDto, modelCount} = assessmentAttachmentsEnvelope;

            runInAction(() => {
                this.clearAssessmentAttachment();
                modelsDto.forEach((assessmentAttachment: IAssessmentAttachment) => {
                    this.assessmentAttachmentRegistry.set(assessmentAttachment.id, assessmentAttachment)
                })
                this.assessmentAttachmentCount = modelCount
            })
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getAssessmentAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let assessmentAttachment = await Agent.AssessmentAttachments.byId(id)
            runInAction(() => {
                this.assessmentAttachment = assessmentAttachment
                this.assessmentAttachmentRegistry.set(assessmentAttachment.id, assessmentAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return assessmentAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getAssessmentAttachmentsByAssessmentId = async (assessmentId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let assessmentAttachmentEnvelope = await Agent.AssessmentAttachments.byAssessmentId(assessmentId || this.currentAssessmentId, this.axiosParams)
            const {modelsDto, modelCount} = assessmentAttachmentEnvelope
            runInAction(() => {
                modelsDto.forEach(assessmentAttachment => {
                    this.assessmentAttachmentRegistry.set(assessmentAttachment.id, assessmentAttachment)
                })

                this.assessmentAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createAssessmentAttachment = async (assessmentAttachment: AssessmentAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newAssessmentAttachment = await Agent.AssessmentAttachments.create(assessmentAttachment)
            await (!!this.currentAssessmentId ? this.getAssessmentAttachmentsByAssessmentId() : this.loadAssessmentAttachment())

            runInAction(() => {
                this.assessmentAttachmentRegistry.set(newAssessmentAttachment.id, newAssessmentAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('AttachmentModal')
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteAssessmentAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.AssessmentAttachments.delete(id)
            runInAction(() => {
                this.assessmentAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('AssessmentAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }
    }

}