import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import ConsumerTransferForm, {
    ConsumerFormProps
} from "../../../pages/consumers/consumers/components/Consumers/ConsumerTransferForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, ConsumerFormProps {
}

type Props = OwnProps;

// @ts-ignore
const ConsumerModal = NiceModal.create<Props>(({consumer, onClose, header}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <ConsumerTransferForm consumer={consumer}/>
        </SlideOver>
    )
})

export default ConsumerModal;
