import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {
    INationalOutreachBudgetNote,
    INationalOutreachBudgetNoteEnvelope,
    NationalOutreachBudgetNoteFormValues
} from "../../models/NationalOutreach/NationalOutreachBudgetNote";

const NationalOutreachBudgetNotes = {
    list: (params: URLSearchParams): Promise<INationalOutreachBudgetNoteEnvelope> =>
        axios
            .get("/NationalOutreachBudget/Note", {params: params})
            .then(responseBody),
    create: (NationalOutreachBudget: NationalOutreachBudgetNoteFormValues) =>
        api.post(`/NationalOutreachBudget/Note`, NationalOutreachBudget),
    update: (NationalOutreachBudget: INationalOutreachBudgetNote) =>
        api.put(`/NationalOutreachBudget/Note/${NationalOutreachBudget.id}`, NationalOutreachBudget),
    delete: (id: string) =>
        api.del(`/NationalOutreachBudget/Note/${id}`),
    byId: (id: string) => api.get(`/NationalOutreachBudget/Note/${id}`),
    byNationalOutreachBudgetId: (nationalOutreachId: string, params: URLSearchParams): Promise<INationalOutreachBudgetNoteEnvelope> =>
        axios
            .get(`/NationalOutreachBudget/${nationalOutreachId}/Note`, {params: params})
            .then(responseBody)
    ,
};

export default NationalOutreachBudgetNotes