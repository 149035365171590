import {ContactFormValues, IContact, IContactEnvelope} from "../../models/Contact";
import axios from "axios";
import {api, responseBody} from './apiBase.service'

const Contacts = {
    list: (
        params: URLSearchParams
    ): Promise<IContactEnvelope> => {
        return axios
            .get("/Contact", {params: params})
            .then(responseBody)
    },
    create: (contact: ContactFormValues) =>
        api.post(`/Contact/`, contact),
    update: (contact: IContact) =>
        api.put(`/Contact/${contact.id}`, contact),
    delete: (id: string) =>
        api.del(`/Contact/${id}`),
    byId: (contactId: string) =>
        api.get(`/Contact/${contactId}`),
};


export default Contacts