import React, {FunctionComponent} from 'react';
import History from "../../AppHistory";
import {ArrowLeftIcon} from "@heroicons/react/24/solid";


interface OwnProps {
}

type Props = OwnProps;

const BackArrow: FunctionComponent<Props> = () => {
    const goBack = (e) => {
        e.preventDefault()
        e.stopPropagation()
        History.length > 2 ? History.goBack() : History.push('/home')
    }

    return (
        <button className={'select-none hover:-translate-x-1 hover:opacity-75 transition-all duration-300'}>
            <span className="sr-only">navigate back</span>
            <ArrowLeftIcon className={'mr-2 h-6 w-6'} onClick={goBack}/>
        </button>
    );
};

export default BackArrow;
