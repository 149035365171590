import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {IEquipment, IEquipmentReturn} from "../../../../../models/Equipment/Equipment";
import Input from "../../../../../components/Inputs/Input";
import Button from "../../../../../components/generic/Button";
import {inputOnChange} from "../../../../../lib/utilities";
import TextArea from "../../../../../components/form/TextArea";
import numberInputOptions from "../../../../../data/cleave/numberInputOptions";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface EquipmentReturnFormProps {
    equipment?: IEquipment

}

type Props = EquipmentReturnFormProps

const EquipmentForm: React.FC<Props> = ({equipment}) => {
    // store
    const rootStore = useContext(RootStoreContext)
    const {closeModal} = rootStore.modalStore
    const {returnEquipment} = rootStore.equipmentStore

    // state
    const [currentEquipmentReturn, setCurrentEquipmentReturn] = useState({id: equipment?.id ?? ''} as IEquipmentReturn);


    // Functions
    const handleChange = (e) => inputOnChange(e, setCurrentEquipmentReturn)

    const handleSubmit = (e) => {
        e.preventDefault()
        returnEquipment(currentEquipmentReturn)
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full'}>
            <div className={'overflow-y-auto overflow-x-hidden flex-1'}>
                <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                    <Input label={'Return Amount'}
                           placeholder={'88.00'}
                           value={currentEquipmentReturn.returnAmount}
                           name={'returnAmount'}
                           onChange={handleChange}
                           options={numberInputOptions}
                           required
                    />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <TextArea label={'Description'} onChange={handleChange} name={'description'}
                              placeholder={'Defective flux capacitor'}/>
                </div>
            </div>

            <div className={'w-full inline-flex gap-4 justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'}
                        type={'submit'}>Save</Button>
                <Button name={'cancel'} onClick={() => closeModal('EquipmentModal')} color="red"
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>
    )
}

export default observer(EquipmentForm)