const isUUID4 = (uuid: string) => {
    let isValid = uuid.match(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/)
    return isValid ? undefined : 'Please enter a valid UUID'
}

const isUSPhoneNumber = (phone: string) => {
    let isValid = phone.match(/^\(?([2-9][0-8][0-9])\)?[-.●\s]?([2-9][0-9]{2})[-.●\s]?([0-9]{4})$/)
    return isValid ? undefined : 'Please provide a valid phone number'
}

const isDate = (shortDate: string) => {
    let isValid = shortDate.match(/^(\d{2})\/(\d{2})\/(\d{4})$/)
    return isValid ? undefined : 'Please provide a valid date "MM/DD/YYYY"'
}

export {
    isUUID4,
    isUSPhoneNumber,
    isDate,
}