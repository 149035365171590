import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../stores/RootStore";
import {IAttachment} from "../../models/generic/Attachment";
import AttachmentTable from "../attachment/AttachmentTable";
import {SubItemAttachmentFormValues} from "../../models/SubItems/SubItemAttachment";


export interface SubItemAttachmentTableProps {
    subItemId: string;
}

type Props = SubItemAttachmentTableProps;

const SubItemAttachmentTable: FunctionComponent<Props> = ({subItemId}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentSubItemId,
        subItemAttachmentsById,
        subItemAttachmentCount,
        handleChangePage,
        getSubItemAttachmentsBySubItemId,
        getSubItemAttachmentById,
        createSubItemAttachment,
        deleteSubItemAttachment,
        subItemAttachmentRegistry
    } = rootStore.subItemAttacmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment


    // USE EFFECTS
    useEffect(() => {
        if (!!subItemId) {
            setCurrentSubItemId(subItemId);
            getSubItemAttachmentsBySubItemId(subItemId)
        }
    }, [subItemId, getSubItemAttachmentsBySubItemId, subItemAttachmentRegistry]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {header: 'Create Sub-item Attachment', createAttachment});
    }

    const createAttachment = (attachment: IAttachment) => {
        const newAttachment: SubItemAttachmentFormValues = {...attachment, subItemId}
        createSubItemAttachment(newAttachment);
    }


    return (<AttachmentTable
        attachments={subItemAttachmentsById}
        attachmentCount={subItemAttachmentCount}
        handleChangePage={handleChangePage}
        openCreateModal={openCreateModal}
        filterPredicate={setPredicate}
        getAttachmentById={getSubItemAttachmentById}
        getDirectAttachmentById={getGenericDirectAttachmentById}
        deleteAttachment={deleteSubItemAttachment}
    />)
};

export default observer(SubItemAttachmentTable)
