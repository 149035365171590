import {Envelope} from "../generic/Envelope";

export interface INotificationEnvelope extends Envelope<INotification> {
}

export interface INotification {
    id?: string
    notificationTypeId: string
    notificationType?: string
    entityId: string
    entityName?: string
    emailTo: string
}

export class NotificationFormValues implements INotification {
    id?: string
    notificationTypeId: string = ''
    notificationType?: string
    entityId: string = ''
    entityName?: string
    emailTo: string = ''

    constructor(notification?: INotification) {
        if (!!notification) {
            Object.assign(this, notification)
        }
    }
}