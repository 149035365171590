import axios from "axios";
import {ITechnician, ITechnicianEnvelope, TechnicianFormValues} from "../../models/Technician";
import {api, responseBody} from "./apiBase.service";

const Technician = {
    list: (params: URLSearchParams): Promise<ITechnicianEnvelope> =>
        axios
            .get("/Technician", {params: params})
            .then(responseBody),
    create: (Technician: TechnicianFormValues) =>
        api.post(`/Technician`, Technician),
    update: (Technician: ITechnician) =>
        api.put(`/Technician/${Technician.id}`, Technician),
    delete: (id: string) =>
        api.del(`/Technician/${id}`),
    byId: (id: string) => api.get(`/Technician/${id}`),
};

export default Technician