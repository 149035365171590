import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import AuditReportForm, {AuditReportFormProps} from "../../../pages/reports/auditReport/components/AuditReportForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, AuditReportFormProps {
}

type Props = OwnProps;

// @ts-ignore
const AuditReportFormModal = NiceModal.create<Props>(({header, onClose}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <AuditReportForm/>
        </SlideOver>
    )
})

export default AuditReportFormModal;
