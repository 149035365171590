import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite";
import Agent from "../../api/Agent";
import {ComboBoxProps, OptionType} from "./ComboBox";
import Skeleton from "../generic/Skeleton";
import Input from "../Inputs/Input";
import Autocomplete from "../Inputs/Autocomplete";

interface EntityStateAutoCompleteProps extends Omit<ComboBoxProps, 'options'> {
    // filter?(item: any): any

    isLocked?: boolean // used to create a disabled version of the input
    idKey?: string // The field that should be used as the id value in the autocomplete options
}

const EntityStateAutoCompleteInput: React.FC<EntityStateAutoCompleteProps> = ({
                                                                                  idKey = 'id',
                                                                                  isLocked = false,
                                                                                  ...rest
                                                                              }) => {

    // Local State
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([] as OptionType[]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (autoCompleteOptions.length === 1 && !rest.displayValue) {
            rest?.onChange(autoCompleteOptions[0])
        }
    }, [autoCompleteOptions, rest]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const params = new URLSearchParams()
                params.append('limit', '10000000')
                params.append('offset', `0`)
                const {modelsDto} = await Agent.EntityStates.list(params)

                setAutoCompleteOptions(modelsDto.map(opt => {
                    return {
                        id: opt[idKey],
                        name: `${opt.stateName} (${opt.entityName})`.trim()
                    }
                }).sort((optA, optB) => optA.name > optB.name ? 1 : -1))
            } catch (e) {
                console.error(e)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, []);


    if (isLoading)
        return <>
            {!rest.prepend && <Skeleton width={'8rem'} height={'1rem'}/>}
            <Skeleton height={'2.25rem'}/>
        </>

    if (!isLocked && autoCompleteOptions.length > 1) {
        return <Autocomplete
            placeholder={'California'}
            label={'State Program'}
            options={autoCompleteOptions}
            {...rest}
        />
    } else if (!isLocked && autoCompleteOptions.length === 1) {
        return <>
            <Input
                value={autoCompleteOptions[0].name}
                label={'State Program'}
                disabled={true}
                {...rest}
            />
        </>
    } else {
        return <Input
            value={rest.displayValue}
            // className={'mb-3'}
            label={'State Program'}
            disabled={true}
        />
    }


};
export default observer(EntityStateAutoCompleteInput);

