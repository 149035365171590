import {Envelope} from "../generic/Envelope";
import {ISubItem} from "../SubItems/SubItem";

export interface IInstallationEnvelope extends Envelope<IInstallation> {
}

export interface IInstallation {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string
    entityState?: string
    consumerId: string
    consumerName?: string
    equipmentIds: string[]
    equipmentName?: string
    technicianId?: string
    technicianName?: string
    dateRequested?: Date
    datePerformed: Date
    installationTime?: number
    installationLaborCosts?: number
    instructionalMaterialCosts?: number
    auxiliaryAidCosts?: number
    otherExpenses?: number
    currentStatus?: string
    isFlagged?: boolean
    travelIds?: string[]
    subItems?: ISubItem[]
    updateEquipmentDate: boolean
    attachmentCount?: number
}

export class InstallationFormValues implements IInstallation {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string = ''
    entityState?: string
    consumerId: string = ''
    consumerName?: string
    equipmentIds: string[] = []
    equipmentName?: string
    technicianId?: string
    technicianName?: string
    dateRequested?: Date
    datePerformed: Date = new Date()
    installationTime?: number
    installationLaborCosts?: number
    instructionalMaterialCosts?: number
    auxiliaryAidCosts?: number
    otherExpenses?: number
    currentStatus?: string
    isFlagged?: boolean
    travelIds?: string[]
    subItems?: ISubItem[]
    updateEquipmentDate: boolean = false
    attachmentCount?: number

    constructor(installation?: IInstallation) {
        if (!!installation) {
            Object.assign(this, installation)
        }
    }
}