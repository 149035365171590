import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import AttachmentTable from '../../../../../components/attachment/AttachmentTable';
import {InstallationAttachmentFormValues} from '../../../../../models/Installation/InstallationAttachment';
import {IAttachment} from '../../../../../models/generic/Attachment';
import {RootStoreContext} from '../../../../../stores/RootStore';


export interface InstallationAttachmentTableProps {
    installationId: string;
    disableCreateNew?: boolean;
}

type Props = InstallationAttachmentTableProps;

const InstallationAttachmentTable: FunctionComponent<Props> = ({installationId, disableCreateNew = false}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentInstallationId,
        installationAttachmentsByDate,
        installationAttachmentCount,
        handleChangePage,
        getInstallationAttachmentsByInstallationId,
        getInstallationAttachmentById,
        createInstallationAttachment,
        deleteInstallationAttachment
    } = rootStore.installationAttachmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment

    // USE EFFECTS
    useEffect(() => {
        if (!!installationId) {
            setCurrentInstallationId(installationId);
            getInstallationAttachmentsByInstallationId()
        }
    }, [installationId, getInstallationAttachmentsByInstallationId]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {createAttachment, header: 'Create Installation Attachment'});
    }

    const createAttachment = (attachment: IAttachment) => {
        const newInstallationAttachment: InstallationAttachmentFormValues = {...attachment, installationId}
        createInstallationAttachment(newInstallationAttachment);
    }

    const deleteAttachment = (attachmentId) => {
        deleteInstallationAttachment(attachmentId);
    }


    return (<AttachmentTable
        attachments={installationAttachmentsByDate}
        attachmentCount={installationAttachmentCount}
        handleChangePage={handleChangePage}
        openCreateModal={openCreateModal}
        filterPredicate={setPredicate}
        getAttachmentById={getInstallationAttachmentById}
        getDirectAttachmentById={getGenericDirectAttachmentById}
        deleteAttachment={deleteAttachment}
        disableCreateNew={disableCreateNew}
    />)
};

export default observer(InstallationAttachmentTable)
