import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {RootStore} from './RootStore'
import agent from "../api/Agent"
import {toast} from "react-toastify"
import {ITechnician, TechnicianFormValues} from '../models/Technician'

export default class TechnicianStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.technicianRegistry.clear()
                this.loadTechnicians()
            }
        )
    }

    limit = 5
    loadingInitial = false
    page = 0
    predicate = new Map()
    submitting = false
    target = ""
    technician: ITechnician | null = null
    technicianCount = 0
    technicianRegistry = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get techniciansList() {
        return Array.from(this.technicianRegistry.values())
    }

    get pageCount() {
        return Math.ceil(this.technicianCount / this.limit)
    }

    loadTechnicians = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const technicianEnvelope = await agent.Technician.list(this.axiosParams)
            let {modelsDto, modelCount} = technicianEnvelope

            runInAction(() => {
                modelsDto.forEach((technician) => {
                    this.technicianRegistry.set(technician.id, technician)
                })
                this.technicianCount = modelCount
            })

        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    clearTechnicians = async () => {
        this.technicianRegistry.clear()
    }
    setPage = (page: number) => {
        this.page = page
    }
    setLimit = (limit: number) => {
        this.limit = limit
    }
    handleChangePage = (newPage: number, newRow?: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        if (newRow) this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.technicianRegistry.clear()
        this.loadTechnicians()
    }

    createTechnician = async (technician: TechnicianFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.Technician.create(technician)
            await this.loadTechnicians()
            toast.success('Saved')
            runInAction(() => {
                this.rootStore.modalStore.closeModal('TechnicianModal')
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    editTechnician = async (technician: ITechnician) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.Technician.update(technician)
            runInAction(() => {
                this.clearTechnicians()
                this.loadTechnicians()
                this.rootStore.modalStore.closeModal('TechnicianModal')
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    getTechnicianOptions = () => {
        var Technicians: {
            key: string
            text: string
            value: string
        }[] = []

        this.techniciansList.map((technician) => (
            Technicians.push({
                key: technician.id,
                text: technician.name + " - " + technician.state,
                value: technician.id
            })
        ))

        return Technicians
    }

    getTechnicianById = async (technicianId) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let currentTechnician
        try {
            currentTechnician = await agent.Technician.byId(technicianId)
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return currentTechnician
    }
}
