import axios from "axios";
import {ConsumerNoteFormValues, IConsumerNote, IConsumerNoteEnvelope} from "../../models/Consumer/ConsumerNote";
import {api, responseBody} from "./apiBase.service";

const ConsumerNotes = {
    list: (params: URLSearchParams): Promise<IConsumerNoteEnvelope> =>
        axios
            .get("/Consumer/Note", {params: params})
            .then(responseBody),
    create: (Consumer: ConsumerNoteFormValues) =>
        api.post(`/Consumer/Note`, Consumer),
    update: (Consumer: IConsumerNote) =>
        api.put(`/Consumer/Note/${Consumer.id}`, Consumer),
    delete: (id: string) =>
        api.del(`/Consumer/Note/${id}`),
    byId: (id: string) => api.get(`/Consumer/Note/${id}`),
    byConsumerId: (equipmentId: string, params: URLSearchParams): Promise<IConsumerNoteEnvelope> =>
        axios.get(`/Consumer/${equipmentId}/Note`, {params: params})
            .then(responseBody),
};


export default ConsumerNotes