import React, {useContext, useEffect, useState} from 'react'
import {RootStoreContext} from "../../stores/RootStore";
import {observer} from "mobx-react-lite";
import {ComboBoxProps, OptionType} from "./ComboBox";
import Agent from "../../api/Agent";
import {displayDate} from "../../lib/utilities";
import {Combobox} from '@headlessui/react'
import {PlusIcon} from "@heroicons/react/24/solid";
import Input from "./Input";
import Skeleton from "../generic/Skeleton";
import Autocomplete from "../Inputs/Autocomplete";


interface OwnProps extends Omit<ComboBoxProps, 'options'> {
    defaultDemo?: boolean
    filterIds?: string[]
    consumerId?: string
    isLocked?: boolean
    canAddNew?: boolean
}

type Props = OwnProps

const EquipmentAutoCompleteInput: React.FC<Props> = ({
                                                         defaultDemo = true,
                                                         filterIds,
                                                         consumerId,
                                                         isLocked = false,
                                                         canAddNew = true,
                                                         ...rest
                                                     }) => {

    // Mobx
    const rootStore = useContext(RootStoreContext)
    const {equipmentsList} = rootStore.equipmentStore
    const {openModal} = rootStore.modalStore

    // local state
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([] as OptionType[]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams()
                params.append('limit', '10000000')
                params.append('offset', `0`)

                if (defaultDemo && !consumerId) {
                    params.append('demo', 'true')
                }

                if (!!consumerId) {
                    params.append('consumerId', consumerId)
                }

                const {modelsDto} = await Agent.Equipments.list(params)
                setAutoCompleteOptions(() => {
                    const opts = modelsDto.map(opt => {
                        return {
                            id: opt.id,
                            name: `${opt.equipmentName ?? ''} - ${opt.consumerName ?? ''} (${displayDate(opt.dateReceived) ?? ''})`.trim()
                        }
                    })
                        .filter(equipment => optionsFilter(equipment))
                        .sort((optA, optB) => optA.name > optB.name ? 1 : -1)

                    // @ts-ignore
                    if (canAddNew) opts.unshift({id: 0, name: '+ New Equipment'})
                    return opts
                })
            } catch (e) {

            }
        }

        fetchData()
    }, [equipmentsList, consumerId, defaultDemo, filterIds]);


    const optionsFilter = (option) => {
        if (filterIds) {
            return !filterIds.includes(option.id)
        } else {
            return true;
        }
    }

    const createNew = () => {
        openModal('EquipmentModal', {header: 'New Equipment', consumerId})
    }

    const handleChange = (e) => {
        return e.id === 0 ? createNew() : !!rest.onChange && rest?.onChange(e)
    }


    if (!autoCompleteOptions)
        return <Skeleton/>

    return (!isLocked ?
            <Autocomplete placeholder={'Flux Capacitor'} {...rest} options={autoCompleteOptions} label={'Equipment'}
                      onChange={handleChange}></Autocomplete> :
            <Input
                value={rest.displayValue}
                className={'mb-3'}
                label={'Equipment'}
                disabled={true}
            />
    );
};
export default observer(EquipmentAutoCompleteInput);