import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {AssessmentNoteFormValues, IAssessmentNote} from '../../models/Assessment/AssessmentNote'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class AssessmentNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.assessmentNoteRegistry.clear()
                this.loadAssessmentNote()
            }
        )

        reaction(
            () => this.currentAssessmentId,
            () => {

                this.page = 0
                this.assessmentNoteRegistry.clear()
                this.loadAssessmentNote()
            }
        )
    }

    assessmentNoteRegistry = new Map()
    assessmentNote: IAssessmentNote | null = null
    editMode = false


    currentAssessmentId = '';
    assessmentNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentAssessmentId = (assessmentId: string) => {
        this.currentAssessmentId = assessmentId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.assessmentNoteCount / this.limit)
    }

    get assessmentNotesById() {
        return Array.from(this.assessmentNoteRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get assessmentNotesByDate() {
        return Array.from(this.assessmentNoteRegistry.values()).sort(
            (a, b) => dayjs(b.updatedOn).isBefore(a.updatedOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getAssessmentNote = (id: string) => {
        return this.assessmentNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.assessmentNoteRegistry.clear()
        this.loadAssessmentNote()
    }

    clearAssessmentNote = () => {
        this.assessmentNoteRegistry.clear()
    }

    loadAssessmentNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentAssessmentId) {
                const assessmentNotesEnvelope = await Agent.AssessmentNotes.byAssessmentId(this.currentAssessmentId, this.axiosParams);
                const {modelsDto, modelCount} = assessmentNotesEnvelope;
                runInAction(() => {
                    modelsDto.forEach((assessmentNote: IAssessmentNote) => {
                        this.assessmentNoteRegistry.set(assessmentNote.id, assessmentNote)
                    })
                    this.assessmentNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getAssessmentNoteById = async (id: string) => {
        let assessmentNote = this.getAssessmentNote(id)
        if (assessmentNote) {
            this.assessmentNote = assessmentNote
            return assessmentNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                assessmentNote = await Agent.AssessmentNotes.byId(id)
                runInAction(() => {
                    this.assessmentNote = assessmentNote
                    this.assessmentNoteRegistry.set(assessmentNote.id, assessmentNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return assessmentNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createAssessmentNote = async (assessmentNote: IAssessmentNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newAssessmentNote = await Agent.AssessmentNotes.create(assessmentNote)
            await this.loadAssessmentNote()
            runInAction(() => {
                this.assessmentNoteRegistry.set(newAssessmentNote.id, newAssessmentNote)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editAssessmentNote = (assessmentNote: AssessmentNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.AssessmentNotes.update(assessmentNote).then((updatedAssessmentNote) => {
            runInAction(() => {
                this.assessmentNoteRegistry.set(updatedAssessmentNote.id, updatedAssessmentNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteAssessmentNote = async (assessmentNote: IAssessmentNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.AssessmentNotes.delete(assessmentNote.id)
            runInAction(() => {
                this.assessmentNoteRegistry.delete(assessmentNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }

    }


}