import React, {FunctionComponent, useEffect, useState} from 'react';

interface OwnProps {
    step: number
    stepList: string[]
    numbered?: boolean

    handleClick?(step: number): void
}

type Props = OwnProps;

const ProgressStepper: FunctionComponent<Props> = ({step = 0, stepList, handleClick, numbered= false}) => {
    const [internalStep, setInternalStep] = useState(step);

    useEffect(() => {
        !!handleClick && handleClick(internalStep)
    }, [internalStep]);

    useEffect(() => {
        setInternalStep(step)
    }, [step]);

    return (<>
        <div>
            <div className="mt-6" aria-hidden="true">
                <div className="bg-gray-200 rounded-full overflow-hidden">
                    <div className="h-2 bg-indigo-600 rounded-full transition-all ease-in-out duration-700"
                         style={{width: `${(internalStep + 1) / stepList.length * 100}%`}}/>
                </div>
                <div className=" text-sm font-medium text-gray-600 mt-6 flex">
                    {stepList.map((item, idx) =>
                        <p
                            className={`w-full capitalize  transition-all ease-in-out duration-700 text-center first-of-type:text-left last-of-type:text-right ${idx <= internalStep ? 'text-indigo-600' : ''} ${internalStep === idx ? 'underline' : ''}`}
                            onClick={() => setInternalStep(idx)}
                        >{numbered ? `${idx+1}. ` : '' }{item}</p>
                    )}
                </div>
            </div>
        </div>

    </>);
};

export default ProgressStepper;
