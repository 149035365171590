import React from "react";
import './Spinner.css'


interface ISpinner2Props {
    srText?: string
    role?: string
}

const Spinner: React.FC<ISpinner2Props> = ({srText = 'Loading', role, ...rest}) => {
    return (
        <div className={`loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32`} {...rest}
             role={role}>
            <span className="sr-only" aria-live='assertive'>{srText}</span>
        </div>

    );
}

export default Spinner;