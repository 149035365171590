import {IConsumerMergeEnvelope} from "../../models/Consumer/ConsumerMerge";
import axios from "axios";
import {api, responseBody} from './apiBase.service'
import {IConsumerTransfer} from "../../models/Consumer/ConsumerTransfer";

const ConsumerTransfers = {
    list: (params: URLSearchParams): Promise<IConsumerMergeEnvelope> =>
        axios.get(`/ConsumerTransfer`, {params: params}).then(responseBody),
    create: (consumerId: string, consumerTransfer: IConsumerTransfer) =>
        api.post(`/ConsumerTransfer/${consumerId}`, consumerTransfer),
    update: (id: string, data: object) =>
        api.put(`/ConsumerTransfer/${id}`, data)
};


export default ConsumerTransfers