import axios from "axios";
import {EquipmentAttachmentFormValues, IEquipmentAttachmentEnvelope} from "../../models/Equipment/EquipmentAttachment";
import {api, responseBody} from "./apiBase.service";

const EquipmentAttachments = {
    list: (params: URLSearchParams): Promise<IEquipmentAttachmentEnvelope> =>
        axios
            .get("/Equipment/Attachment", {params: params})
            .then(responseBody),
    create: (equipmentAttachment: EquipmentAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in equipmentAttachment) {
            formData.append(key, equipmentAttachment[key])
        }
        return api.post(`/Equipment/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/Equipment/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/Equipment/Attachment/${id}`),
    byEquipmentId: (equipmentId: string, params: URLSearchParams): Promise<IEquipmentAttachmentEnvelope> =>
        axios.get(`/Equipment/${equipmentId}/Attachment`, {params}).then(responseBody)
};

export default EquipmentAttachments