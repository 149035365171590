import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../api/Agent'
import {IProgramYear, ProgramYearFormValues} from '../models/ProgramYear'
import {toast} from 'react-toastify'
import {RootStore} from './RootStore'


export default class ProgramYearStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.programYearRegistry.clear()
                this.loadProgramYear()
            }
        )
    }

    programYearRegistry = new Map()
    programYear: IProgramYear | null = null

    programYearCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.programYearCount / this.limit)
    }

    get programYearsById() {
        return Array.from(this.programYearRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get programYearsByDate() {
        return Array.from(this.programYearRegistry.values()).sort(
            (a, b) => b.dateCreated - a.dateCreated//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getProgramYear = (id: string) => {
        return this.programYearRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.programYearRegistry.clear()
        this.loadProgramYear()
    }

    clearProgramYear = () => {
        this.programYearRegistry.clear()
    }

    loadProgramYear = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const programYearsEnvelope = await Agent.ProgramYear.list(this.axiosParams)
            const {modelsDto, modelCount} = programYearsEnvelope

            runInAction(() => {
                modelsDto.forEach((programYear: IProgramYear) => {
                    this.programYearRegistry.set(programYear.id, programYear)
                })
                this.programYearCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getProgramYearById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let programYear = await Agent.ProgramYear.byId(id)
            runInAction(() => {
                this.programYear = programYear
                this.programYearRegistry.set(programYear.id, programYear)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return programYear
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    createProgramYear = async (programYear: IProgramYear) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newProgramYear = await Agent.ProgramYear.create(programYear)
            this.loadProgramYear()
            runInAction(() => {
                this.programYearRegistry.set(newProgramYear.id, newProgramYear)
                this.rootStore.modalStore.closeModal('ProgramYearModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newProgramYear
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editProgramYear = async (programYear: ProgramYearFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newProgramYear = await Agent.ProgramYear.update(programYear)
            runInAction(() => {
                this.programYearRegistry.set(newProgramYear.id, newProgramYear)
                this.rootStore.modalStore.closeModal('ProgramYearModal')
            })
            toast.success('Saved')
            return newProgramYear
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

}