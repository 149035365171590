import React, {FunctionComponent} from 'react';

interface OwnProps extends React.HTMLProps<HTMLDivElement>{
    children?: React.ReactNode
}

type Props = OwnProps;

const TR: FunctionComponent<Props> = ({className, children, ...rest}) => {

  return (
          <div {...rest} role={"flex gap-4"} className="md:table-row hover:bg-gray-100 last:border-b-0 md:border-b-0 border-b py-2 md:py-0 ">
              {children}
          </div>
  );
};

export default TR;
