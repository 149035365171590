import React, {FunctionComponent} from 'react';

interface OwnProps extends Omit<React.HTMLProps<HTMLInputElement>, 'label'> {
    label?: React.ReactNode | string;
    direction?: 'left' | 'right';
    forwardedRef?: (input) => void;
}

type Props = OwnProps;

const Checkbox: FunctionComponent<Props> = ({label, direction = 'right', forwardedRef, ...rest}) => {

    return (
        <>
            <label
                htmlFor={rest.id ?? rest.name}
                className={
                    'inline-flex gap-x-2 items-center align-middle hover:bg-gray-100 p-2 rounded-md'
                }
            >
                {direction === 'left' && (
                    <span className={'inline-block'}>{label}</span>
                )}
                <input
                    type="checkbox"
                    className={`focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded p-2`}
                    ref={forwardedRef}
                    {...rest}
                    id={rest.id ?? rest.name}
                />
                {direction === 'right' && (
                    <span className={'inline-block'}>{label}</span>
                )}
            </label>
        </>
    );
};

export default Checkbox;
