import {Envelope} from "../generic/Envelope";
import {ISubItem} from "../SubItems/SubItem";

export interface ITravelEnvelope extends Envelope<ITravel> {
}

export interface ITravel {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string
    entityState?: string
    technicianId?: string
    technicianName?: string
    trainingIds?: string[]
    installationIds?: string[]
    assessmentIds?: string[]
    maintenanceRepairIds?: string[]
    travelStartDate: Date
    travelEndDate: Date
    travelTime?: number
    travelTimeCosts?: number
    travelCosts?: number
    intraStateCosts?: number
    interStateCosts?: number
    auxiliaryAidCosts?: number
    currentStatus?: string
    isFlagged?: boolean
    subItems?: ISubItem[]
    attachmentCount?: number
    consumerNames?: string
}

export class TravelFormValues implements ITravel {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string = ''
    entityState?: string
    technicianId?: string
    technicianName?: string
    trainingIds?: string[]
    installationIds?: string[]
    assessmentIds?: string[]
    maintenanceRepairIds?: string[]
    travelStartDate: Date = new Date()
    travelEndDate: Date = new Date()
    travelTime?: number
    travelTimeCosts?: number
    travelCosts?: number
    intraStateCosts?: number
    interStateCosts?: number
    auxiliaryAidCosts?: number
    currentStatus?: string
    isFlagged?: boolean
    subItems?: ISubItem[]
    attachmentCount?: number
    consumerNames?: string

    constructor(travel?: ITravel) {
        Object.assign(this, travel);
    }
}