import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from '../../../../stores/RootStore';
import AttachmentTable from '../../../../components/attachment/AttachmentTable';
import {IAttachment} from '../../../../models/generic/Attachment';
import {AdministrativeAttachmentFormValues} from '../../../../models/Administrative/AdministrativeAttachment';


export interface AdministrativeAttachmentTableProps {
    administrativeId: string;
    disableCreateNew?: boolean;
}

type Props = AdministrativeAttachmentTableProps;

const AdministrativeNoteTable: FunctionComponent<Props> = ({administrativeId, disableCreateNew= false}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentAdministrativeId,
        administrativeAttachmentsByDate,
        administrativeAttachmentCount,
        handleChangePage,
        getAdministrativeAttachmentsByAdministrativeId,
        getAdministrativeAttachmentById,
        createAdministrativeAttachment,
        deleteAdministrativeAttachment
    } = rootStore.administrativeAttachmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment

    // USE EFFECTS
    useEffect(() => {
        if (!!administrativeId) {
            setCurrentAdministrativeId(administrativeId)
            getAdministrativeAttachmentsByAdministrativeId()
        }
    }, [administrativeId]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {createAttachment, header: 'Create Administrative Attachment'});
    }

    const createAttachment = (attachment: IAttachment) => {
        const newAdministrativeAttachment: AdministrativeAttachmentFormValues = {...attachment, administrativeId}
        createAdministrativeAttachment(newAdministrativeAttachment);
    }

    const deleteAttachment = (attachmentId) => {
        deleteAdministrativeAttachment(attachmentId);
    }


    return (<>
        <AttachmentTable
            attachments={administrativeAttachmentsByDate}
            attachmentCount={administrativeAttachmentCount}
            handleChangePage={handleChangePage}
            openCreateModal={openCreateModal}
            filterPredicate={setPredicate}
            getAttachmentById={getAdministrativeAttachmentById}
            getDirectAttachmentById={getGenericDirectAttachmentById}
            deleteAttachment={deleteAttachment}
            disableCreateNew={disableCreateNew}
        />
    </>)
};

export default observer(AdministrativeNoteTable)
