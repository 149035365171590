import axios, {AxiosResponse} from "axios";

const {REACT_APP_API_URL} = process.env;

axios.defaults.baseURL = REACT_APP_API_URL;
const authlessAxiosInstance = axios.create();

const responseBody = (response: AxiosResponse) => response.data;

const sleep = (ms: number) => (response: AxiosResponse) =>
    new Promise<AxiosResponse>((resolve) =>
        setTimeout(() => resolve(response), ms)
    );

const api = {
    get: (url: string, params?: {}) =>
        authlessAxiosInstance.get(url, params).then().then(responseBody),
    post: (url: string, body?: {}) =>
        authlessAxiosInstance.post(url, body).then().then(responseBody),
    put: (url: string, body: {}, params?: {}) =>
        authlessAxiosInstance.put(url, body, params).then().then(responseBody),
    del: (url: string, body?: {}) =>
        authlessAxiosInstance.delete(url, !!body ? {
            data: body
        } : undefined).then().then(responseBody),
};

export {
    responseBody,
    api,
    sleep,
}