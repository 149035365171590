import axios from "axios";
import {
    AssessmentAttachmentFormValues,
    IAssessmentAttachmentEnvelope
} from "../../models/Assessment/AssessmentAttachment";
import {api, responseBody} from "./apiBase.service";

const AssessmentAttachments = {
    list: (params: URLSearchParams): Promise<IAssessmentAttachmentEnvelope> =>
        axios
            .get("/Assessment/Attachment", {params: params})
            .then(responseBody),
    create: (assessmentAttachment: AssessmentAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in assessmentAttachment) {
            formData.append(key, assessmentAttachment[key])
        }
        return api.post(`/Assessment/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/Assessment/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/Assessment/Attachment/${id}`),
    byAssessmentId: (assessmentId: string, params: URLSearchParams): Promise<IAssessmentAttachmentEnvelope> =>
        axios.get(`/Assessment/${assessmentId}/Attachment`, {params}).then(responseBody)
};

export default AssessmentAttachments