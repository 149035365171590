import {makeAutoObservable, reaction, runInAction} from 'mobx';
import {RootStore} from './RootStore';
import agent from "../api/Agent";
import {IEmbeddedReport} from "../models/EmbeddedReport/EmbeddedReport";

export default class EmbeddedReportStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.embeddedReportsRegistry.clear()
                this.loadEmbeddedReports()
            }
        )
    }

    embeddedReportsRegistry = new Map()
    embeddedReport: IEmbeddedReport | null = null
    limit = 5
    page = 0
    predicate = new Map()
    embeddedReportsCount = 0;

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get pageCount() {
        return Math.ceil(this.embeddedReportsCount / this.limit)
    }

    get embeddedReportsList() {
        // @ts-ignore
        return Array.from(this.embeddedReportsRegistry.values()).sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }


    clearEmbeddedReports = async () => {
        this.embeddedReportsRegistry.clear()
    }
    setPage = (page: number) => {
        this.page = page
    }
    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.embeddedReportsRegistry.clear()
        this.loadEmbeddedReports()
    }

    getEmbeddedReportFromRegistry = (id) => {
        return this.embeddedReportsRegistry.get(id);
    }

    setCurrentEmbeddedReport = (embeddedReport?) => {
        this.embeddedReport = embeddedReport
    }

    loadEmbeddedReports = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let EmbeddedReportEnvelope;

        try {
            EmbeddedReportEnvelope = await agent.EmbeddedReport.list(this.axiosParams)
            let {modelsDto, modelCount} = EmbeddedReportEnvelope

            runInAction(() => {
                modelsDto.forEach((embeddedReport) => {
                    this.embeddedReportsRegistry.set(embeddedReport.id, embeddedReport)
                })
                this.embeddedReportsCount = modelCount
            })

        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return EmbeddedReportEnvelope;
    }

    downloadEmbeddedReport = async (id: string, paramId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let downloadable;
        try {
            downloadable = await agent.EmbeddedReport.downloadById(id, paramId);
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return downloadable;
    }
    //function to get the new sets of reports created by the API
    downloadReport = async (id: string, paramId?: string,type?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let downloadable;
        try {
            if(type === 'excel'){
                downloadable = await agent.Report.byIdExcel(id, paramId);
            }
            else if (type === 'pdf'){
                downloadable = await agent.Report.byIdPdf(id, paramId);
            }

        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return downloadable;
    }
    getEmbeddedReportById = async (id: string, paramId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let embeddedReport;
        try {
            embeddedReport = await agent.EmbeddedReport.byId(id, paramId);
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return embeddedReport
    };

}