import React, {FunctionComponent, useEffect, useState} from 'react';

import {Link, useLocation} from "react-router-dom";
import {isUUID4} from "../lib/validators";
import {HomeIcon} from "@heroicons/react/24/solid";

interface OwnProps extends React.HTMLProps<HTMLDivElement>{
}

type PathArr = Array<{ name: string, path: string }>

type Props = OwnProps;

const BreadCrumb: FunctionComponent<Props> = ({className ,...rest}) => {
    const {pathname} = useLocation()

    const [currentPaths, setCurrentPaths] = useState([] as PathArr);

    useEffect(() => {
        let paths: PathArr = []
        let tmpPath = ''
        for (let part of pathname.split('/')) {
            tmpPath += !!part ? `/${part}` : ''
            paths.push({
                // this check seems insane but it makes sense i swear
                name: !!isUUID4(part) ? part : 'details',
                path: tmpPath
            })
        }

        setCurrentPaths(paths)
    }, [pathname]);

    return (<div
            className={`flex items-center bg-white text-gray-900 px-6 py-2 ${className}`} {...rest}>
            <nav className={''} aria-label="breadcrumb">
                <ol className={'bg-transparent m-0 p-0 align-middle flex items-center'} role={'list'}>
                    {currentPaths.map((path, idx) => <li className={'focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 rounded flex'} key={path.path}>
                        <Link className={'text-gray-900 lowercase m-0 hover:text-gray-800 focus:outline-none'} to={path.path}>{path.name.replaceAll('-', ' ') ||
                        <><HomeIcon className={'h-5 w-5'}/> <span className="sr-only">Home</span></>}</Link>
                        {(currentPaths.length - 1) !== idx && <svg
                            className="flex-shrink-0 h-5 w-5 text-gray-300"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                        >
                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"/>
                        </svg>}
                    </li>)}
                </ol>
            </nav>
        </div>
    );
};

export default BreadCrumb;
