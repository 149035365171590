import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import History from "../AppHistory";

dayjs.extend(utc)

export const displayDateTime = (date: any, format: string = 'MM/DD/YYYY hh:mm:ss A', utc: boolean = false): string => {
    let nd = utc ? dayjs(date).utc().format(format) : dayjs(date).format(format)
    return nd === 'Invalid Date' ? '' : nd
}

export const displayDate = (date: any, format?: string | null, utc: boolean = true): string => {
    if (!!date) {
        format = format || 'MM/DD/YYYY'
        let nd = utc ? dayjs(date).utc().format(format) : dayjs(date).format(format)
        return nd === 'Invalid Date' ? '' : nd
    } else {
        return ''
    }
}

export const displayPhone = (phone?: string | number): string | null => {
    if (!phone) return null
    phone = typeof phone === "string" ? phone : phone.toString().replace(/[^0-9]/g, '')
    let newPhone = phone
    if (phone.length === 10) {
        newPhone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
    }
    if (phone.length === 11) {
        newPhone = `${phone[0]} (${phone.slice(1, 4)}) ${phone.slice(4, 7)}-${phone.slice(7)}`
    }

    return newPhone || null
}

export const displayDollarAmount = (data?: string | bigint | number): string => {
    if (!data) return '$0.00'
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })
    return formatter.format(typeof data === 'string' ? parseFloat(data) : data)
}

export const summarizeText = (content: string = '', totalLength = 20) => {
    if (!!content && content.length > totalLength) {
        return `${content.slice(0, totalLength - 3)}...`
    }
    return content
}

export const arraySum = (values: Array<any>) => {
    if (values.length === 0) return 0
    return values.reduce((value, sum) => {
        value = parseFloat(value) || 0
        sum = parseFloat(sum) || 0
        return (value + sum).toFixed(2)
    })
}

export const today = () => {
    let today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy: any = today.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    return yyyy + '-' + mm + '-' + dd;
}

export const uuid = (): string => {
    if ('randomUUID' in window.crypto) {
        // @ts-ignore
        return window.crypto.randomUUID()
    } else {
        // This is a less secure, non-compliant implementation of uuidV4
        // @ts-ignore // eslint-disable-next-line
        return (() => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, a =>
            /* eslint-disable-next-line*/
            (a ^ Math.random() * 16 >> a / 4).toString(16)))()
    }
}

export const restrictDecimalPlaces = (e, setCurrent) => {
    const {name, value} = e.currentTarget
    let newValue = value
    let dec = value.split('.')

    if (value.includes('.') && dec[1].length > 2) {
        newValue = `${dec[0]}.${dec[1].slice(0, 2)}`
    }
    setCurrent(prev => {
        return {...prev, [name]: parseFloat(newValue)}
    })
    return
}

export const roundToDecimal = (value: number, precision: number): number => {
    const multiplier: number = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export const formatDollarAmount = (e, setCurrent) => {
    const {name, value} = e.currentTarget
    setCurrent(prev => {
        return {...prev, [name]: displayDollarAmount(value)}
    })
}

export const formNavigationHandler = (step, setAnimation, setStep) => {
    setAnimation('to-left')
    setTimeout(() => {
        setStep(step)
        setAnimation('from-right')
    }, 600)
}

export const inputOnChange = (e, setCurrent) => {
    const {name, value, rawValue, checked, type, title} = e.currentTarget
    const pattern = e.target?.getAttribute("data-pattern")
    if (!!pattern) {
        const dataValidator = new RegExp(pattern, 'gi');

        if (dataValidator.test(value)) {
            e.target.setCustomValidity('')
        } else {
            e.target.setCustomValidity(title ?? 'Invalid input')
        }

        e.target.reportValidity()
    }

    let valueToSet = !!rawValue ? rawValue : value

    if (type === 'checkbox') {
        valueToSet = checked
    }

    // if value is a date in the form of 'mm/dd/yyyy'
    if (typeof value === 'string' && value?.match(/^(\d{2}\/){2}\d{4}$/)) {
        valueToSet = value
    }


    setCurrent(prev => {
        return {
            ...prev,
            [name]: valueToSet
        }
    })
}

export const suppressOnTabThrough = (e, setCurrent) => {
    const {rawValue, name} = e.currentTarget
    if (!rawValue || rawValue === '') {
        setCurrent(prev => {
            let newVal = {...prev}
            delete prev[name]

            return newVal
        })
    }
}

export const splitCamelCase = (str?: string) => {
    if (!str) return ''
    return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
}

export const objectDateConverter = (obj, setState) => {
    if (!!obj) {
        Object.keys(obj).forEach((key) => {
            let value = obj[key]

            if (typeof value === 'string') {
                if (value.match(/\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z?\b/)) {
                    obj[key] = displayDate(value)
                }
            }
        })
        setState(obj)
    }
}

export const cleaveFormatOptionsDateToArray = (datePattern: string[], optionDate: string) => {
    const optionPattern = ['y', 'd', 'm']; // Cleave bug has standard date as y,d,m instead of following usual option pattern.
    const tmpOptionDateArray = optionDate.split('-');
    return datePattern?.map((value) => {
        const insertIntoIdx = optionPattern.indexOf(value.toLowerCase());
        return Number(tmpOptionDateArray[insertIntoIdx]);
    });
}

export const classNames = (...classes) => classes.filter(Boolean).join(' ')


export const range = (size: number = 0) => {
    size = Math.ceil(size)
    if (size < 0) return []
    return [...Array(size).keys()]
}

export const isEmptyObject = (obj: object = {}) => Object.keys(obj).length === 0


export const goBack = () => History.length > 2 ? History.goBack() : History.push('/home')

export const formatPercent = (i) => `${(+i / 100).toFixed(2)}%`

export const parseFloatElseZero = (num: any) => parseFloat(num) ?? 0

export const isNumber = (value) => typeof value === 'number' && isFinite(value);

export const toSentenceCase = (s: string) => (s[0].toUpperCase() + s.slice(1))

export default {
    arraySum,
    classNames,
    cleaveFormatOptionsDateToArray,
    displayDate,
    displayDateTime,
    displayDollarAmount,
    displayPhone,
    formNavigationHandler,
    formatDollarAmount,
    inputOnChange,
    objectDateConverter,
    restrictDecimalPlaces,
    roundToDecimal,
    splitCamelCase,
    summarizeText,
    suppressOnTabThrough,
    titleCase,
    today,
    uuid,
    range,
    goBack,
    isEmptyObject,
    isNumber,
    parseFloatElseZero,
    toSentenceCase
}