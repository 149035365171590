import {AttachmentFormValues, IAttachment} from "../generic/Attachment";
import {Envelope} from "../generic/Envelope";

export interface IReimbursementClaimSpreadsheetEnvelope extends Envelope<IReimbursementClaimSpreadsheetUpload> {
}

export interface IReimbursementClaimSpreadsheetUpload extends IAttachment {
    reimbursementClaimId: string
}

export class ReimbursementClaimSpreadsheetUploadFormValues extends AttachmentFormValues implements IReimbursementClaimSpreadsheetUpload {
    reimbursementClaimId: string = ''

    constructor(private attachment?: any) {
        super(attachment)
    }
}

export interface IReimbursementClaimSpreadsheet {
    createdByName?: string
    createdOn?: string
    updatedByName?: string
    updatedOn?: string
    id?: string
    reimbursementClaimId?: string
    processedOn?: Date
    result?: string
}

export class ReimbursementClaimSpreadsheetFormValues implements IReimbursementClaimSpreadsheet {
    reimbursementClaimId: string = ''

    constructor(reimbursementClaimSheet: IReimbursementClaimSpreadsheet) {
        if (!!reimbursementClaimSheet) {

        }
    }
}
