import {Envelope} from "./generic/Envelope";

export interface ITechnicianEnvelope extends Envelope<ITechnician> {
}

export interface ITechnician {
    id?: string
    firstName: string
    middleName?: string
    lastName: string
    company?: string
    entityId: string
    entityName?: string
}

export interface ITechnicianFormValues extends Partial<ITechnician> {
}

export class TechnicianFormValues implements ITechnicianFormValues {
    id?: string
    firstName: string = ''
    middleName?: string
    lastName: string = ''
    company?: string
    entityId: string = ''
    entityName?: string

    constructor(technician?: ITechnician) {
        if (technician) {
            Object.assign(this, technician)
        }
        Object.assign(this);
    }
}