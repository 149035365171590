import axios from "axios";
import {responseBody} from "./apiBase.service";
import {IMassCost} from "../../models/MassCost/MassCost";

const MassCost = {
    list: (
        params: URLSearchParams
    ): Promise<IMassCost> =>
        axios.get("/MassCost", {params: params})
            .then(responseBody),
};

export default MassCost