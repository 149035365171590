import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Note, NoteFormValues} from '../../models/generic/Note';
import {RootStoreContext} from '../../stores/RootStore';
import TextArea from "../form/TextArea";
import {inputOnChange} from "../../lib/utilities";
import Button from "../generic/Button";
import Input from "../Inputs/Input";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface NoteFormProps {
    note?: Note;
    createNote: (note: Note) => void;
    editNote: (note: Note) => void;
}

type Props = NoteFormProps

const NoteForm: React.FC<Props> = ({note, createNote, editNote}) => {
    const categories = ['Important']
    const reservedCategory = ['line item flagged', 'claim approved', 'claim rejected']

    // store
    const rootStore = useContext(RootStoreContext);
    const {closeModal} = rootStore.modalStore;

    // state
    const [currentNote, setCurrentNote] = useState(note ?? new NoteFormValues());

    useEffect(() => {
        if (!!note) {
            setCurrentNote(note)
        }
    }, [note])

    const handleChange = (e) => {
        inputOnChange(e, setCurrentNote)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newNote = {...currentNote};
        if (!!currentNote.comment?.length && currentNote.comment.length <= 8000) {
            if (!newNote.id && newNote.id === '') {
                // @ts-ignore
                delete newNote.id;
                createNote(currentNote)
            } else {
                editNote(currentNote)
            }
        }

    }


    return (
        <form autoComplete={'off'} onSubmit={handleSubmit}
              className={'overflow-y-auto overflow-x-hidden grow flex flex-col h-full'}>
            <div className={'grow h-full'}>
                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <Input label={'Label'} name={'category'} value={currentNote.category} onChange={handleChange}
                           placeholder={'Important'} list={'categoriesNoteForm'}

                           data-pattern={'^(?!line item flagged|claim approved|claim rejected$).*$'}

                           title={'Label can be anything except line item flagged, claim approved, claim rejected'}
                           disabled={!!note?.category ? reservedCategory.includes(note?.category.toLowerCase()) : false}/>
                    <datalist id="categoriesNoteForm">
                        {categories.map(cat => <option key={cat} value={cat}/>)}
                    </datalist>
                </div>

                <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                    <TextArea
                        label={'Note Content'}
                        value={currentNote.comment}
                        name={'comment'}
                        onChange={handleChange}
                        maxLength={8000}
                        rows={10}
                        required
                        placeholder={'Radiation suit? Of course, because of all the fallout from the atomic wars.'}
                    />
                    <div className={'text-red-600'}>
                        {!!currentNote.comment && <span
                            className={`shake ${currentNote?.comment.length < 7900 ? 'hide' : ''}`}>{8000 - currentNote.comment.length} remaining characters</span>}
                    </div>
                </div>
            </div>

            <div className={'flex justify-end gap-4'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}
                        disabled={!!currentNote.comment?.length && currentNote.comment?.length > 8000}>Save</Button>
                <Button name={'cancel'} onClick={() => closeModal('NoteModal')} color="red"
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>

    );
};
export default observer(NoteForm)