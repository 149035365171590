import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {ITraining, TrainingFormValues} from "../../../../../models/Training/Training";
import {formNavigationHandler, inputOnChange, objectDateConverter, today} from "../../../../../lib/utilities";
import ConsumerAutoCompleteInput from '../../../../../components/form/ConsumerAutoCompleteInput';
import TechnicianAutoCompleteInput from '../../../../../components/form/TechnicianAutoCompleteInput';
import EquipmentMultiselect from './EquipmentMultiselect';
import Input from "../../../../../components/Inputs/Input";
import dateInputOptions from "../../../../../data/cleave/dateInputOptions";
import Button from "../../../../../components/generic/Button";
import {IEntityState} from "../../../../../models/Entity/EntityState";
import EntityStateAutoCompleteInput from "../../../../../components/form/EntityStatesAutoCompleteInput";
import Toggle from "../../../../../components/generic/Toggle";
import {useLocalStorage} from "../../../../../hooks/useLocalStorage";
import {CheckIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";
import Checkbox from "../../../../../components/Inputs/Checkbox";

export interface TrainingFormProps {
    training?: ITraining;
}

type Props = TrainingFormProps

const TrainingForm: React.FC<Props> = ({training}) => {
    // store
    const rootStore = useContext(RootStoreContext)
    const {entityStatesByName} = rootStore.entityStateStore
    const {currentEntityState} = rootStore.commonStore
    const {closeModal, openModal} = rootStore.modalStore
    const {createTraining, editTraining} = rootStore.trainingStore
    const {getEntityStateByConsumerId} = rootStore.entityStateStore


    // state
    const [currentTraining, setCurrentTraining] = useState(new TrainingFormValues(training));
    const [animation, setAnimation] = useState('from-right');
    const [step, setStep] = useState(0);
    const [maxSteps, setMaxSteps] = useState(0);
    // const [stateProgram, setStateProgram] = useState({} as IEntityState);
    const [addCost, setAddCost] = useState(true);


    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);


    // Use Effects
    useEffect(() => {
        objectDateConverter(training, setCurrentTraining)
        if (!!currentTraining.consumerId)
            getEntityStateByConsumerId(currentTraining.consumerId)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    setCurrentTraining((prev: TrainingFormValues) => {
                        return {
                            ...prev,
                            entityStateId: entityState?.id || '',
                            entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : ''
                        }
                    })
                })
    }, []);

    // Use Effects
    useEffect(() => {
        setMaxSteps(!!currentTraining.consumerId ? 1 : 0);
    }, [currentTraining]);

    useEffect(() => {
        if (!currentTraining.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentTraining((prev: TrainingFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates, currentTraining.isTrainTheTrainer])

    useEffect(() => {
        if (!currentTraining.entityStateId && (!!entityStatesByName && entityStatesByName.length === 1)) {
            setCurrentTraining((prev: TrainingFormValues) => {
                return {
                    ...prev,
                    entityStateId: currentEntityState.id || '',
                    entityState: currentEntityState.id ? `${currentEntityState.stateName} (${currentEntityState.entityName})` : ''
                }
            })
        }
    }, [currentEntityState])


    // Functions
    const handleEquipmentIdsChange = (equipmentIds) => {
        let tmpCurrentTraining = {...currentTraining};
        tmpCurrentTraining.equipmentIds = equipmentIds;
        setCurrentTraining(tmpCurrentTraining);
    }

    // This function is used to set the state when a consumer is selected
    const handleConsumerAndState = (value) => {
        if (!!value) {
            getEntityStateByConsumerId(value)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    // setStateProgram(entityState)
                    setCurrentTraining((prev: ITraining) => {
                        return {
                            ...prev,
                            consumerId: value,
                            entityStateId: entityState?.id || '',
                            entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : ''
                        }
                    })
                })
        } else {
            setCurrentTraining((prev: ITraining) => {
                return {
                    ...prev,
                    consumerId: '',
                    entityStateId: '',
                    entityState: ''
                }
            })
        }
    }

    // Clears consumer related data when Train-the-trainer
    const handleTrainTheTrainer = (checked) => {
        setCurrentTraining(prev => {
            return {
                ...prev,
                consumerId: '',
                consumerName: '',
                isTrainTheTrainer: checked
            }
        })
    }

    const handleChange = (e) => {
        const {name, value, checked} = e.currentTarget
        if (name === 'isTrainTheTrainer') {
            handleTrainTheTrainer(checked)
        } else if (name === 'consumerId') {
            handleConsumerAndState(value)
        } else {
            inputOnChange(e, setCurrentTraining)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newTraining = {...currentTraining}
        if (!newTraining.id || newTraining.id === '' || newTraining.id === 'undefined') {
            delete newTraining.id
            return createTraining(newTraining).then(data => addCostAfterSaving(data))
        } else {
            return editTraining(newTraining)
        }
    }

    const addCostAfterSaving = async (data) => {
        if (!!data && addCost) await openModal('SubItemModal', {
            header: 'New Training Cost',
            lineItemId: data.id,
            lineItemType: 'Training',
            entityStateId: data.entityStateId,
        })
    }

    const handleNavigation = (step) => formNavigationHandler(step, setAnimation, setStep)

    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    return <>
        <form autoComplete={'off'} onSubmit={handleSubmit}
              className={'flex flex-col flex-1 overflow-y-auto overflow-x-hidden h-full'}>
            <div className={'flex-1'}>
                <div className={`${step === 0 ? animation : 'hide'}`}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3 mb-3">
                        {!currentTraining.isTrainTheTrainer ?
                            <ConsumerAutoCompleteInput
                                onChange={({id}) => handleAutoComplete(id, 'consumerId')}
                                displayValue={(currentTraining.consumerId) ? (currentTraining.consumerName ? currentTraining.consumerName : 'Consumer already selected') : ''}
                                placeholder={'Marty McFly'}
                                required={true}
                            /> :
                            <Input label={'Consumer'} disabled/>
                        }

                        <EntityStateAutoCompleteInput
                            required
                            isLocked={!currentTraining.isTrainTheTrainer || (!!localEntityStates && localEntityStates.length === 1)}
                            onChange={({id}) => handleAutoComplete(id, 'entityStateId')}
                            name={'entityStateId'}
                            placeholder={'California'}
                            displayValue={currentTraining?.entityState ?? ''}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <TechnicianAutoCompleteInput
                            onChange={({id}) => handleAutoComplete(id, 'technicianId')}
                            displayValue={(currentTraining.technicianId) ? (currentTraining.technicianName ? currentTraining.technicianName : 'Technician already selected') : ''}
                            placeholder={'Doc Brown'}
                            name='technicianId'
                        />
                    </div>

                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3'}>

                        <Input label={'Date Requested'}
                               value={`${currentTraining.dateRequested ?? ''}`}
                               name={'dateRequested'}
                               onChange={handleChange}
                               placeholder={'10/26/1985'}
                               options={{
                                   ...dateInputOptions,
                                   dateMax: currentTraining.datePerformed ? currentTraining.datePerformed.toString() : today(),
                               }}

                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                        />

                        <Input label={'Date Performed '}
                               value={`${currentTraining.datePerformed ?? ''}`}
                               name={'datePerformed'}
                               onChange={handleChange}
                               placeholder={'10/26/1985'}
                               options={dateInputOptions}
                               errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               required
                        />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-3 mb-3">
                        <Checkbox label={'Train-The-Trainer'}
                                  checked={currentTraining.isTrainTheTrainer}
                                  name={'isTrainTheTrainer'}
                                  onChange={handleChange}
                                  disabled={!!Object.keys(training ?? {}).length}
                        />
                    </div>
                    {!currentTraining.id ?
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-3 mb-3">
                            <Toggle label={'add cost after saving'} onChange={() => setAddCost(prev => !prev)}
                                    checked={addCost} name={'addCostToggle'}/>
                        </div>
                        : ''}
                </div>

                <div className={`${step === 1 ? animation : 'hide'}`}>
                    <EquipmentMultiselect consumerId={currentTraining.consumerId}
                                          value={currentTraining?.equipmentIds}
                                          onChange={handleEquipmentIdsChange}/>
                </div>
            </div>

            <div className={'flex justify-between mt-3 gap-4'}>
                <div className={currentTraining.isTrainTheTrainer ? 'hide' : 'flex gap-4'}>
                    <Button color='dark' onClick={() => handleNavigation(step - 1)}
                            disabled={step <= 0}
                            iconLeft={<ChevronLeftIcon className={'h-5 w-5'}/>}>Back</Button>
                    <span className={'m-2'}/>
                    <Button color='dark' onClick={() => handleNavigation(step + 1)}
                            id={'trainingNextBtn'}
                            disabled={step >= maxSteps}
                            iconRight={<ChevronRightIcon className={'h-5 w-5'}/>}>Next</Button>
                </div>

                <div className={'w-full'}>{/* DO NOT DELETE */}</div>

                <div className={'flex gap-4'}>
                    <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} disabled={step !== maxSteps}
                            color={'green'}
                            type={'submit'}>Save</Button>
                    <Button onClick={() => {
                        closeModal('TrainingModal')
                    }} color="red" iconLeft={<XMarkIcon className={'h-5 w-5'}/>} name={'cancel'}>Cancel</Button>
                </div>
            </div>
        </form>
    </>;
};
export default observer(TrainingForm)