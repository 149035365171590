import React, {useContext, useEffect, useState} from 'react'
import {RootStoreContext} from "../../stores/RootStore";
import {observer} from "mobx-react-lite";
import Agent from "../../api/Agent";
import {ComboBoxProps, OptionType} from "./ComboBox";
import {Combobox} from "@headlessui/react";
import {PlusIcon} from "@heroicons/react/24/solid";
import Input from "./Input";
import Skeleton from "../generic/Skeleton";
import Autocomplete from "../Inputs/Autocomplete";

interface OwnProps extends Omit<ComboBoxProps, 'options'>{
    isLocked?: boolean
    canAddNew?: boolean
}

type Props = OwnProps

const TechnicianStateAutoCompleteInput: React.FC<Props> = ({isLocked = false, canAddNew = true, ...rest}) => {
    // Mobx Store
    const rootStore = useContext(RootStoreContext);
    const {openModal} = rootStore.modalStore
    const {techniciansList} = rootStore.techniciansStore

    const [autoCompleteOptions, setAutoCompleteOptions] = useState([] as OptionType[]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams()
                params.append('limit', '10000000')
                params.append('offset', `0`)
                const {modelsDto} = await Agent.Technician.list(params)
                setAutoCompleteOptions(() => {
                    const opts = modelsDto.map(opt => {
                        return {
                            id: opt.id,
                            name: `${opt.firstName ?? ''} ${opt.middleName ?? ''} ${opt.lastName ?? ''}`.trim()
                        }
                    }).sort((optA, optB) => optA.name > optB.name ? 1 : -1)
                    // @ts-ignore
                    if (canAddNew) opts.unshift({id: 0, name: '+ New Technician'})

                    return opts
                })
            }catch (e){

            }
        }

        fetchData()
    }, [techniciansList]);


    const onCreateNew = (e) => {
        e?.preventDefault()
        openModal('TechnicianModal', {header: 'New Technician'})
    }

    const handleChange = (e) => {
        return e.id=== 0 ? onCreateNew(null) : !!rest.onChange && rest?.onChange(e)
    }


    if (!autoCompleteOptions)
        return <Skeleton/>

    return (!isLocked ?
        <Autocomplete options={autoCompleteOptions} label={'Technician'} {...rest} onChange={handleChange} placeholder={'Doc Brown'}></Autocomplete> :
        <Input
            value={rest.displayValue}
            className={'mb-3'}
            label={'Technician'}
            disabled={true}
        />

    );
};
export default observer(TechnicianStateAutoCompleteInput);
