import React, {FunctionComponent} from 'react';

interface OwnProps {
    className?: string
    children?: React.ReactNode;
}

type Props = OwnProps;

const Paper: FunctionComponent<Props> = ({className, children}) => {

    return (<div className="text-gray-900 bg-white shadow-md sm:rounded-lg mb-4 print:shadow-none print:rounded-none w-full">
            <div className={`p-4 sm:px-6 sm:py-4  ${className}`}>
                {children}
            </div>
        </div>
    );
};

export default Paper;
