import React, {FunctionComponent} from 'react';
import './Backdrop.sass'
import ReactDOM from "react-dom";

interface OwnProps extends React.HTMLProps<any> {
    open?: boolean
    transparent?: boolean
}

type Props = OwnProps;

let body: any = document.querySelector('body');


const Backdrop: FunctionComponent<Props> = ({open = false, transparent = false, children, className, ...rest}) => {
    return ReactDOM.createPortal(
        <div className={`backdrop ${className} ${!!open ? 'open' : ''} ${!!transparent ? 'clear' : ''}`} {...rest}>
            {children}
        </div>, body);
};

export default Backdrop;