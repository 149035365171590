import React, {FunctionComponent} from 'react';
import {Link} from "react-router-dom";
import {HomeModernIcon} from "@heroicons/react/24/solid";

interface OwnProps {}

type Props = OwnProps;

const HomeLink: FunctionComponent<Props> = (props) => {

  return (<>
  <Link to={'/'} className={'mb-2 text-blue-600 flex items-center gap-2'}><HomeModernIcon className={'inline h-5 w-5'}/>Go to home</Link>
  </>);
};

export default HomeLink;
