import {makeAutoObservable} from 'mobx';
import {toast} from 'react-toastify';
import History from '../AppHistory';
import {axiosError} from '../components/types/AxiosError';
import {RootStore} from './RootStore';
import {AxiosError} from "axios";


export default class ErrorStore {

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
    }

    errors: any[] = [];
    serverSideValidation: any;

    LowerFirstLetter = (string: string) => {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    addError(error: AxiosError | any, customMessage?: string) {
        this.errors.push(error)
        if (error instanceof axiosError || error.isAxiosError) {
            if (error.message === 'user doesnt exists') {
            } else if (error.message.match("Network Error") || !error.response) {
                toast.error("Network Error- could not connect to server");
            } else if (error.response.status === 400) {
                if (!!error.response.data.errors?.Message) {
                    toast.error(customMessage ?? error.response.data.errors?.Message);
                } else {
                    for (let i in error.response.data.errors)
                        for (let j of error.response.data.errors[i])
                            toast.error(j)
                }
            } else if (error.response.status === 418) {
                toast.error(customMessage ?? "Teapots just can't even.");
                History.push("/teapot");
            } else if (error.response.status === 500) {
                toast.error(customMessage ?? error.response.data.errors?.Message);
            } else {
                toast.error(customMessage ?? error.response.data.errors?.Message ?? 'An Unknown Error Occurred');
            }
        } else {
            toast.error(customMessage ?? 'An unknown error occurred. Please try again later.');
        }

    }


}