import axios from "axios";
import {AssessmentFormValues, IAssessment, IAssessmentEnvelope} from "../../models/Assessment/Assessment";
import {api, responseBody} from "./apiBase.service";

const Assessments = {
    list: (
        params?: URLSearchParams
    ): Promise<IAssessmentEnvelope> =>
        axios.get("/Assessment", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/Assessment/${id}`),
    create: (Assessment: AssessmentFormValues) =>
        api.post(`/Assessment/`, Assessment),
    update: (Assessment: IAssessment) =>
        api.put(`/Assessment/${Assessment.id}`, Assessment),
    delete: (id: string) =>
        api.del(`/Assessment/${id}`),
};

export default Assessments