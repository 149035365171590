import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {ITraining, TrainingFormValues} from '../../models/Training/Training'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class TrainingStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.trainingRegistry.clear()
                this.loadTraining()
            }
        )
    }

    trainingRegistry = new Map()
    training: ITraining | null = null
    editMode = false

    trainingCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.trainingRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.trainingCount / this.limit)
    }

    get trainingsList() {
        // @ts-ignore
        return Array.from(this.trainingRegistry.values())//.sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get trainingsByDate() {
        return Array.from(this.trainingRegistry.values())
        // .sort(
        //     (a, b) => b.dateCreated - a.dateCreated//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        // )
    }

    getTraining = (id: string) => {
        return this.trainingRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.trainingRegistry.clear()
        this.loadTraining()
    }

    clearTraining = () => {
        this.trainingRegistry.clear()
    }

    loadTraining = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const trainingsEnvelope = await Agent.Training.list(this.axiosParams)
            const {modelsDto, modelCount} = trainingsEnvelope

            runInAction(() => {
                this.clearTraining()
                modelsDto.forEach((training: ITraining) => {
                    this.trainingRegistry.set(training.id, training)
                })
                this.trainingCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    loadNakedTraining = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)
            const trainingsEnvelope = await Agent.Training.list(params)
            const {modelsDto} = trainingsEnvelope

            return modelsDto
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getTrainingById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let training = await Agent.Training.byId(id)
            runInAction(() => {
                this.training = training
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return training
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getTrainingByIds = async (ids: string[]) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '1000000')
            params.append('offset', `0`)

            for (let id of ids) {
                params.append('ids', id)
            }

            let {modelsDto} = await Agent.Training.list(params)

            return modelsDto
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


    createTraining = async (training: ITraining) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newTraining = await Agent.Training.create(training)
            runInAction(() => {
                this.trainingRegistry.set(newTraining.id, newTraining)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('TrainingModal')
            })
            toast.success('Saved')
            return newTraining
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editTraining = async (training: TrainingFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedTraining = await Agent.Training.update(training)
            runInAction(() => {
                this.trainingRegistry.set(updatedTraining.id, updatedTraining)
                this.rootStore.modalStore.closeModal('TrainingModal')
            })

            toast.success('Saved')
            return updatedTraining
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

    }


    deleteTraining = async (training: ITraining) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            if (!training.id) throw new Error('Training Id is required to delete record')
            await Agent.Training.delete(training.id)
            runInAction(() => {
                this.trainingRegistry.delete(training.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('TrainingModal')
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


}