import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import BudgetForm, {
    AllocationFormProps
} from "../../../pages/administration/allocation/components/allocation/AllocationForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, AllocationFormProps {
}

type Props = OwnProps;

// @ts-ignore
const AllocationModal = NiceModal.create<Props>(({allocation, onClose, header}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <BudgetForm allocation={allocation}/>
        </SlideOver>
    )
})

export default AllocationModal;
