import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from '../../../../stores/RootStore';
import AttachmentTable from '../../../../components/attachment/AttachmentTable';
import {IAttachment} from '../../../../models/generic/Attachment';
import {OutreachAttachmentFormValues} from '../../../../models/Outreach/OutreachAttachment';


export interface OutreachAttachmentTableProps {
    outreachId: string;
    disableCreateNew?: boolean;
}

type Props = OutreachAttachmentTableProps;

const OutreachNoteTable: FunctionComponent<Props> = ({outreachId, disableCreateNew= false}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentOutreachId,
        outreachAttachmentsByDate,
        outreachAttachmentCount,
        handleChangePage,
        getOutreachAttachmentsByOutreachId,
        getOutreachAttachmentById,
        createOutreachAttachment,
        deleteOutreachAttachment
    } = rootStore.outreachAttachmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment

    // USE EFFECTS
    useEffect(() => {
        if (!!outreachId) {
            setCurrentOutreachId(outreachId);
            getOutreachAttachmentsByOutreachId()
        }
    }, [outreachId, getOutreachAttachmentsByOutreachId]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {header: 'Create Outreach Attachment', createAttachment});
    }

    const createAttachment = (attachment: IAttachment) => {
        const newOutreachAttachment: OutreachAttachmentFormValues = {...attachment, outreachId}
        createOutreachAttachment(newOutreachAttachment);
    }

    const deleteAttachment = (attachmentId) => {
        deleteOutreachAttachment(attachmentId);
    }


    return (<>
        <AttachmentTable
            attachments={outreachAttachmentsByDate}
            attachmentCount={outreachAttachmentCount}
            handleChangePage={handleChangePage}
            openCreateModal={openCreateModal}
            filterPredicate={setPredicate}
            getAttachmentById={getOutreachAttachmentById}
            getDirectAttachmentById={getGenericDirectAttachmentById}
            deleteAttachment={deleteOutreachAttachment}
            disableCreateNew={disableCreateNew}
        />
    </>)
};

export default observer(OutreachNoteTable)
