import {api, responseBody} from "./apiBase.service";
import {
    IReimbursementClaimSpreadsheet,
    IReimbursementClaimSpreadsheetEnvelope,
    ReimbursementClaimSpreadsheetUploadFormValues
} from "../../models/Reimbursement/ReimbursementClaimSpreadsheet";
import axios from "axios";

const ReimbursementClaimSpreadsheet = {
    list: (params: URLSearchParams): Promise<IReimbursementClaimSpreadsheetEnvelope> =>
        axios.get('/ReimbursementClaimSpreadsheet', {params: params})
            .then(responseBody),
    create: (ReimbursementClaimSpreadsheet: ReimbursementClaimSpreadsheetUploadFormValues) => {
        let formData = new FormData();
        for (const key in ReimbursementClaimSpreadsheet) {
            formData.append(key, ReimbursementClaimSpreadsheet[key])
        }
        return api.post(`/ReimbursementClaimSpreadsheet`, formData)
    },
    byId: (ReimbursementClaimSpreadsheet: IReimbursementClaimSpreadsheet) =>
        api.get(`/ReimbursementClaimSpreadsheet/${ReimbursementClaimSpreadsheet.reimbursementClaimId}`),
    fail: (id: string) => api.post(`/ReimbursementClaimSpreadsheet/${id}/Fail`),
};

export default ReimbursementClaimSpreadsheet