import React from 'react'
import Backdrop from "../components/drawer/Backdrop";
import Spinner from "../components/generic/Spinner2/Spinner";

const spinnerStyle: React.CSSProperties = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    color: 'white',
}
//loading component that should hover over the entire screen
const LoadingComponent: React.FC<{ content?: string, active?: boolean }> = ({
                                                                                content,
                                                                                active
                                                                            }) => {
    return (
        // @ts-ignore
        <Backdrop open={active} style={{zIndex: '10000'}} className={'flex items-center justify-center'}>
            <Spinner srText={content}/>
        </Backdrop>
    )
}

export default LoadingComponent