import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {
    IReallocationRequestApproval,
    IReallocationRequestEnvelope,
    ReallocationRequestFormValues
} from "../../models/Allocation/ReallocationRequest";

const ReallocationRequests = {
    list: (
        params: URLSearchParams
    ): Promise<IReallocationRequestEnvelope> =>
        axios.get("/ReallocationRequest", {params})
            .then(responseBody),
    byId: (id: string) => api.get(`/ReallocationRequest/${id}`),
    create: (ReallocationRequest: ReallocationRequestFormValues) =>
        api.post(`/ReallocationRequest/`, ReallocationRequest),
    update: (ReallocationRequestApproval: IReallocationRequestApproval) =>
        api.put(`/ReallocationRequest/Approve`, ReallocationRequestApproval)

};

export default ReallocationRequests