import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import ReimbursementClaimSubmitForm, {
    ReimbursementClaimSubmitFormProps
} from "../../../pages/reimbursements/components/claims/ReimbursementClaimSubmitForm";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, ReimbursementClaimSubmitFormProps {
}

type Props = OwnProps;

// @ts-ignore
const ReimbursementClaimSubmitModal = NiceModal.create<Props>(({reimbursementClaim, onClose, header, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <ReimbursementClaimSubmitForm reimbursementClaim={reimbursementClaim} {...rest}/>
        </SlideOver>
    )
})

export default ReimbursementClaimSubmitModal;
