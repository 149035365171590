import React, {FunctionComponent} from 'react';

import ToolTip from "../generic/ToolTip";
import {Link} from 'react-router-dom'
import {getClassName} from "../../lib/getClassName";

interface OwnProps extends React.HTMLProps<HTMLHtmlElement> {
    color?: 'red' | 'orange' | 'amber' | 'yellow' | 'lime' | 'green' | 'emerald' | 'sky' | 'blue' | 'indigo' | 'violet' | 'purple' | 'pink' | 'fuchsia' | 'rose' | 'gray'
    to?: string
    tooltip: string,
    tooltipDirection?: 'top' | 'bottom' | 'left' | 'right',
    children: React.ReactNode
}

type Props = OwnProps;

const ClickableIcon: FunctionComponent<Props> = ({
                                                     onClick,
                                                     to,
                                                     color = 'primary',
                                                     tooltip,
                                                     tooltipDirection = 'top',
                                                     className,
                                                     children,
                                                     ...rest
                                                 }) => {

    const handleClick = (e) => {
        if (!to) e.preventDefault()
        e.stopPropagation()
        !!onClick && onClick(e)
    }


    let classes = getClassName({
        '!text-red-700': color === 'red',
        '!text-orange-700': color === 'orange',
        '!text-amber-700': color === 'amber',
        '!text-yellow-500': color === 'yellow',
        '!text-lime-500': color === 'lime',
        '!text-green-700': color === 'green',
        '!text-emerald-700': color === 'emerald',
        '!text-sky-700': color === 'sky',
        '!text-blue-700': color === 'blue',
        '!text-indigo-700': color === 'indigo',
        '!text-violet-700': color === 'violet',
        '!text-purple-700': color === 'purple',
        '!text-pink-700': color === 'pink',
        '!text-fuchsia-700': color === 'fuchsia',
        '!text-rose-700': color === 'rose',
        '!text-gray-700': color === 'gray',
    })

    return (<>
        <ToolTip content={tooltip} direction={tooltipDirection}>
            {/*// @ts-ignore*/}
            <Link to={to ?? 'JavaScript:void(0)'} className={`${classes}`} {...rest} onClick={handleClick}>
                {children}
            </Link>
        </ToolTip>
    </>);
};

export default ClickableIcon;
