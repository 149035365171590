import axios from "axios";
import {ITraining, ITrainingEnvelope, TrainingFormValues} from "../../models/Training/Training";
import {api, responseBody} from "./apiBase.service";


const Training = {
    list: (
        params: URLSearchParams
    ): Promise<ITrainingEnvelope> =>
        axios
            .get("/Training", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/Training/${id}`),
    create: (Training: TrainingFormValues) =>
        api.post(`/Training/`, Training),
    update: (Training: ITraining) =>
        api.put(`/Training/${Training.id}`, Training),
    delete: (id: string) =>
        api.del(`/Training/${id}`),
};

export default Training