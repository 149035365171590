import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {
    IMaintenanceRepair,
    MaintenanceRepairFormValues
} from "../../../../../models/MaintenanceRepair/MaintenanceRepair";
import {inputOnChange, objectDateConverter, today} from "../../../../../lib/utilities";
import ConsumerAutoCompleteInput from '../../../../../components/form/ConsumerAutoCompleteInput';
import EquipmentAutoCompleteInput from '../../../../../components/form/EquipmentAutoCompleteInput';
import TechnicianAutoCompleteInput from '../../../../../components/form/TechnicianAutoCompleteInput';
import Input from "../../../../../components/Inputs/Input";
import dateInputOptions from "../../../../../data/cleave/dateInputOptions";
import Button from "../../../../../components/generic/Button";
import {IEntityState} from "../../../../../models/Entity/EntityState";
import {Combobox} from "@headlessui/react";
import Toggle from "../../../../../components/generic/Toggle";
import {useLocalStorage} from "../../../../../hooks/useLocalStorage";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import JSONView from "../../../../../components/generic/JSONView";

export interface MaintenanceRepairFormProps {
    maintenanceRepair?: IMaintenanceRepair;
}

type Props = MaintenanceRepairFormProps

const MaintenanceRepairForm: React.FC<Props> = ({maintenanceRepair}) => {
    // store
    const rootStore = useContext(RootStoreContext);
    const {closeModal, openModal} = rootStore.modalStore;
    const {createMaintenanceRepair, editMaintenanceRepair} = rootStore.maintenanceRepairStore
    const {setPredicate: setEquipmentPredicate, setPredicates: setEquipmentPredicates} = rootStore.equipmentStore
    const {getEntityStateByConsumerId, getEntityStateByEquipmentId} = rootStore.entityStateStore

    // state
    const [currentMaintenanceRepair, setCurrentMaintenanceRepair] = useState(new MaintenanceRepairFormValues(maintenanceRepair));
    const [currentConsumer, setCurrentConsumer] = useState(maintenanceRepair?.consumerId ?? '');
    const [addCost, setAddCost] = useState(true);
    const [isRedrawAutoComplete, setIsRedrawAutoComplete] = useState(false);

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);

    // Use Effects
    useEffect(() => {
        objectDateConverter(maintenanceRepair, setCurrentMaintenanceRepair)
        if (maintenanceRepair) {
            setCurrentMaintenanceRepair(maintenanceRepair);
        }

        if (!!maintenanceRepair?.consumerId) {
            assignStateProgram(maintenanceRepair?.consumerId || '', 'consumer')
            setEquipmentPredicates({consumerId: maintenanceRepair?.consumerId, demo: !maintenanceRepair?.consumerId})
        }

    }, [maintenanceRepair])

    useEffect(() => {
        if (currentMaintenanceRepair.consumerId !== currentConsumer && !!currentMaintenanceRepair.consumerId) {
            setEquipmentPredicates({
                'consumerId': currentMaintenanceRepair.consumerId ?? '',
                demo: !currentMaintenanceRepair.consumerId
            })
            setCurrentConsumer(currentMaintenanceRepair?.consumerId ?? '')
        }
    }, [currentMaintenanceRepair]);


    useEffect(() => {
        return () => {
            setEquipmentPredicate()
        }
    }, []);

    useEffect(() => {
        // used to wipe then redraw autocomplete inputs. Currently autocompletes can't be externally wiped...
        // @Todo find a better way to handle this. This is... bad.
        isRedrawAutoComplete && setIsRedrawAutoComplete(false);
    }, [isRedrawAutoComplete])

    useEffect(() => {
        if (!currentMaintenanceRepair.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentMaintenanceRepair((prev: MaintenanceRepairFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates])

    // Functions
    const handleChange = (e) => {
        const {name, value} = e.currentTarget
        inputOnChange(e, setCurrentMaintenanceRepair)

        if (name === 'consumerId') {
            setIsRedrawAutoComplete(true);
            setCurrentMaintenanceRepair((prev: IMaintenanceRepair) => {
                return {
                    ...prev,
                    equipmentId: '',
                    technicianId: '',
                    entityStateId: '',
                    entityState: ''
                }
            });
        }
        if (name === 'equipmentId') {
            assignStateProgram(value, 'equipment')
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newMaintenanceRepair = {...currentMaintenanceRepair}
        if (!newMaintenanceRepair.id || newMaintenanceRepair.id === '' || newMaintenanceRepair.id === 'undefined') {
            delete newMaintenanceRepair.id
            return createMaintenanceRepair(newMaintenanceRepair).then(data => addCostAfterSaving(data))
        } else {
            return editMaintenanceRepair(newMaintenanceRepair)
        }
    }

    const addCostAfterSaving = async (data) => {
        if (!!data && addCost) await openModal('SubItemModal', {
            header: 'New Maintenance/Repair Cost',
            lineItemId: data.id,
            lineItemType: 'MaintenanceRepair',
            entityStateId: data.entityStateId,
        })
    }


    const handleAutoComplete = (value, name) => handleChange({currentTarget: {name, value,}})

    const assignStateProgram = (id: string, type: 'consumer' | 'equipment') => {
        // if (!id) return setStateProgram({} as IEntityState)

        if (type === 'consumer')
            getEntityStateByConsumerId(id)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    // setStateProgram(entityState)
                    setCurrentMaintenanceRepair((prev: IMaintenanceRepair) => {
                        return {
                            ...prev,
                            entityStateId: entityState.id || '',
                            entityState: entityState.id ? `${entityState.stateName} (${entityState.entityName})` : ''
                        }
                    })
                })

        if (type === 'equipment')
            getEntityStateByEquipmentId(id)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    // setStateProgram(entityState)
                    setCurrentMaintenanceRepair((prev: IMaintenanceRepair) => {
                        return {
                            ...prev,
                            entityStateId: entityState?.id || '',
                            entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : ''
                        }
                    })
                })
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full'}>
            <div className={'flex-1'}>
                <div className="grid grid-cols-1 gap-x-4 gap-y-1 mb-3">
                    <div className='w-full'>
                        <ConsumerAutoCompleteInput
                            name='consumerId'
                            onChange={({id}) => handleAutoComplete(id, 'consumerId')}
                            displayValue={(currentMaintenanceRepair.consumerId) ? (currentMaintenanceRepair.consumerName ? currentMaintenanceRepair.consumerName : 'Consumer Already Assigned') : 'Inventory Equipment (No Consumer)'}
                            disabled={!!maintenanceRepair?.consumerId}
                            additionalOptions={[{id: '', name: 'Inventory Equipment (No Consumer)'}]}
                        >
                        </ConsumerAutoCompleteInput>
                    </div>
                </div>

                {!isRedrawAutoComplete && <>
                    <div className="grid grid-cols-1 gap-x-4 gap-y-1 mb-3">
                        <div className='w-full'>
                            <EquipmentAutoCompleteInput
                                name='equipmentId'
                                required
                                consumerId={currentMaintenanceRepair.consumerId}
                                onChange={({id}) => handleAutoComplete(id, 'equipmentId')}
                                displayValue={(currentMaintenanceRepair.equipmentId) ? (currentMaintenanceRepair.equipmentName ? currentMaintenanceRepair.equipmentName : 'Equipment Already Assigned') : ''}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <Input value={currentMaintenanceRepair?.entityState ?? ''} label={'State Program'}
                               disabled/>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <TechnicianAutoCompleteInput
                            name='technicianId'
                            onChange={({id}) => handleAutoComplete(id, 'technicianId')}
                            displayValue={(currentMaintenanceRepair.technicianId) ? (currentMaintenanceRepair.technicianName ? currentMaintenanceRepair.technicianName : 'Technician already selected') : ''}
                        />
                    </div>

                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <Input label={'Date Requested'}
                               value={`${currentMaintenanceRepair.dateRequested ?? ''}`}
                               name={'dateRequested'}
                               onChange={handleChange}
                               options={{
                                   ...dateInputOptions,
                                   dateMax: currentMaintenanceRepair.datePerformed ? currentMaintenanceRepair.datePerformed.toString() : today(),
                               }}

                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                        />
                        <Input label={'Date Performed'}
                               value={`${currentMaintenanceRepair.datePerformed ?? ''}`}
                               name={'datePerformed'}
                               onChange={handleChange}
                               options={dateInputOptions}
                               errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                               required
                        />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                        <Input label={'Date Sent'}
                               value={`${currentMaintenanceRepair.dateSent ?? ''}`}
                               name={'dateSent'}
                               onChange={handleChange}
                               options={{
                                   ...dateInputOptions,
                                   dateMax: currentMaintenanceRepair.dateReturned ? currentMaintenanceRepair.dateReturned.toString() : today(),
                               }}

                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                        />
                        <Input label={'Date Returned'}
                               value={`${currentMaintenanceRepair.dateReturned ?? ''}`}
                               name={'dateReturned'}
                               onChange={handleChange}
                               options={dateInputOptions}
                               errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                        />
                    </div>

                    {!currentMaintenanceRepair.id ?
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                            <Toggle label={'add cost after saving'} onChange={() => setAddCost(prev => !prev)}
                                    checked={addCost} name={'addCostToggle'}/>

                        </div> : ''}
                </>}

                <JSONView data={currentMaintenanceRepair}/>
            </div>

            <div className={'flex justify-end gap-4'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'}
                        type={'submit'}>Save</Button>
                <span className={'m-2'}/>
                <Button onClick={() => closeModal('MaintenanceRepairModal')} color="red" name={'cancel'}
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>
    );
};
export default observer(MaintenanceRepairForm)