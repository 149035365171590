import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IReallocationRequest, IReallocationRequestApproval} from '../../models/Allocation/ReallocationRequest'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class ReallocationRequestStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.reallocationRequestRegistry.clear()
                this.loadReallocationRequest()
            }
        )
    }

    reallocationRequestRegistry = new Map()
    reallocationRequest: IReallocationRequest | null = null

    reallocationRequestCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.reallocationRequestCount / this.limit)
    }

    get reallocationRequestsById() {
        return Array.from(this.reallocationRequestRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get reallocationRequestsByDate() {
        return Array.from(this.reallocationRequestRegistry.values()).sort(
            (a, b) => b.dateCreated - a.dateCreated//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getReallocationRequest = (id: string) => {
        return this.reallocationRequestRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.reallocationRequestRegistry.clear()
        this.loadReallocationRequest()
    }

    clearReallocationRequest = () => {
        this.reallocationRequestRegistry.clear()
    }

    loadReallocationRequest = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const reallocationRequestsEnvelope = await Agent.ReallocationRequests.list(this.axiosParams)
            const {modelsDto, modelCount} = reallocationRequestsEnvelope

            runInAction(() => {
                this.clearReallocationRequest();
                modelsDto.forEach((reallocationRequest: IReallocationRequest) => {
                    this.reallocationRequestRegistry.set(reallocationRequest.id, reallocationRequest)
                })
                this.reallocationRequestCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getReallocationRequestById = async (id: string) => {

        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let reallocationRequest = await Agent.ReallocationRequests.byId(id)
            runInAction(() => {
                this.reallocationRequest = reallocationRequest
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return reallocationRequest
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    createReallocationRequest = async (reallocationRequest: IReallocationRequest) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newReallocationRequest = await Agent.ReallocationRequests.create(reallocationRequest)
            this.loadReallocationRequest()
            runInAction(() => {
                this.reallocationRequestRegistry.set(newReallocationRequest.id, newReallocationRequest)
                this.rootStore.modalStore.closeModal('BudgetReallocationRequestModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newReallocationRequest
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    approveReallocationRequest = async (reallocationRequestApproval: IReallocationRequestApproval) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newReallocationRequest = await Agent.ReallocationRequests.update(reallocationRequestApproval)
            runInAction(() => {
                this.loadReallocationRequest()
                this.reallocationRequestRegistry.set(newReallocationRequest.id, newReallocationRequest)
                this.rootStore.modalStore.closeModal('BudgetReallocationRequestModal')
            })
            toast.success('Saved')
            return newReallocationRequest
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

}