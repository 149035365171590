import ContactForm, {ContactFormProps} from "../../../pages/administration/contacts/components/Contacts/ContactsForm";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import React from "react";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";

interface OwnProps extends SlideOverProps, ContactFormProps {}

type Props = OwnProps;

// @ts-ignore
const ContactsModal = NiceModal.create<Props>(({contact, entity, onClose, header}) => {
    const modal = useModal();

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <ContactForm contact={contact} entity={entity}/>
        </SlideOver>
    )
})
export default ContactsModal;