import axios from "axios";
import {AttachmentFormValues, IAttachment, IAttachmentEnvelope} from "../../models/generic/Attachment";
import {api, responseBody} from "./apiBase.service";

const genericAttachments = {
    list: (params: URLSearchParams): Promise<IAttachmentEnvelope> =>
        axios
            .get("/Attachment", {params: params})
            .then(responseBody),
    create: (administrativeAttachment: AttachmentFormValues) => {
        let formData = new FormData();
        for (const key in administrativeAttachment) {
            formData.append(key, administrativeAttachment[key])
        }
        return api.post(`/Attachment`, formData)
    },
    byId: (id: string) => api.get(`/Attachment/${id}`),
    update: (attachment: IAttachment) => api.put(`/Attachment/${attachment.id}`, attachment),
    delete: (id: string) => api.del(`/Attachment/${id}`),
};

export default genericAttachments