import {makeAutoObservable, runInAction} from 'mobx'
import {RootStore} from '../RootStore'
import agent from "../../api/Agent"
import {toast} from "react-toastify"
import {
    IReimbursementClaimLineItem,
    IReimbursementClaimLineItemFlag,
    ReimbursementClaimLineItemFormValues
} from '../../models/Reimbursement/ReimbursementClaimLineItem'

export default class ReimbursementClaimLineItemStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
    }

    reimbursementClaimLineItemRegistry = new Map()
    reimbursementClaimLineItem: IReimbursementClaimLineItem | null = null
    loadingInitial = false
    submitting = false


    get reimbursementClaimLineItemsById() {
        return Array.from(this.reimbursementClaimLineItemRegistry.values()).sort(
            (a: IReimbursementClaimLineItem, b: IReimbursementClaimLineItem) => {
                if (!a.reimbursementClaimId && !b.reimbursementClaimId) {
                    //@ts-ignore
                    return a.id > b.id ? -1 : 1
                }

                return -1
            }
        )
    }

    conditionalLoad = (key: string) => {
        key === "assessmentIds" && this.rootStore.assessmentStore.loadAssessment()
        key === "equipmentIds" && this.rootStore.equipmentStore.loadEquipment()
        key === "installationIds" && this.rootStore.installationStore.loadInstallation()
        key === "maintenanceRepairIds" && this.rootStore.maintenanceRepairStore.loadMaintenanceRepair()
        key === "outreachIds" && this.rootStore.outreachStore.loadOutreach()
        key === "shippingAndTaxIds" && this.rootStore.shippingAndTaxStore.loadShippingAndTax()
        key === "trainingIds" && this.rootStore.trainingStore.loadTraining()
        key === "travelIds" && this.rootStore.travelStore.loadTravel()
        key === "administrativeIds" && this.rootStore.administrativeStore.loadAdministrative()
    }

    clearReimbursementClaimLineItems = async () => {
        this.reimbursementClaimLineItemRegistry.clear()
    }

    getReimbursementClaimLineItemByClaimId = async (reimbursementClaimId: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            return await agent.ReimbursementClaimLineItem.byClaimId(reimbursementClaimId)
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    createReimbursementClaimLineItem = async (reimbursementClaimLineItem: ReimbursementClaimLineItemFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newLineItems = await agent.ReimbursementClaimLineItem.create(reimbursementClaimLineItem)
            toast.success('Saved')
            runInAction(() => {
                this.rootStore.modalStore.closeModal('ReimbursementClaimLineItemModal')
                for (let key in newLineItems) {
                    if (newLineItems[key].length > 0) {
                        this.conditionalLoad(key)
                    }
                }
            })
            return newLineItems
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    flagReimbursementClaimLineItem = async (reimbursementClaimLineItem: IReimbursementClaimLineItemFlag) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedReimbursementClaimLineItem = await agent.ReimbursementClaimLineItem.flag(reimbursementClaimLineItem)

            runInAction(() => {
                this.rootStore.modalStore.closeModal('FlagLineItemModal')
                // this.rootStore.reimbursementClaimStore.loadReimbursementClaim()

                for (let key in updatedReimbursementClaimLineItem) {
                    if (!!updatedReimbursementClaimLineItem[key] && updatedReimbursementClaimLineItem[key].length > 0) {
                        this.conditionalLoad(key)
                    }
                }
            })

            toast.success('Saved')
            return updatedReimbursementClaimLineItem
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    removeReimbursementClaimLineItem = async (reimbursementClaimLineItem: IReimbursementClaimLineItem) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let removedReimbursementClaimLineItem = await agent.ReimbursementClaimLineItem.delete(reimbursementClaimLineItem)

            runInAction(() => {
                this.rootStore.modalStore.closeModal('ReimbursementClaimLineItemModal')

                for (let key in removedReimbursementClaimLineItem) {
                    if (!!removedReimbursementClaimLineItem[key] && removedReimbursementClaimLineItem[key].length > 0) {
                        this.conditionalLoad(key)
                    }
                }
            })

            toast.success('Saved')
            return removedReimbursementClaimLineItem
        } catch (error) {
            console.error(error)
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }


}