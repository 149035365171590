import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import AttachmentTable from '../../../../../components/attachment/AttachmentTable';
import {EquipmentAttachmentFormValues} from '../../../../../models/Equipment/EquipmentAttachment';
import {IAttachment} from '../../../../../models/generic/Attachment';
import {RootStoreContext} from '../../../../../stores/RootStore';


export interface EquipmentAttachmentTableProps {
    equipmentId: string;
    disableCreateNew?: boolean;
}

type Props = EquipmentAttachmentTableProps;

const EquipmentAttachmentTable: FunctionComponent<Props> = ({equipmentId, disableCreateNew = false}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentEquipmentId,
        equipmentAttachmentsByDate,
        equipmentAttachmentCount,
        handleChangePage,
        getEquipmentAttachmentsByEquipmentId,
        getEquipmentAttachmentById,
        createEquipmentAttachment,
        deleteEquipmentAttachment
    } = rootStore.equipmentAttachmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment

    // USE EFFECTS
    useEffect(() => {
        if (!!equipmentId) {
            setCurrentEquipmentId(equipmentId);
            getEquipmentAttachmentsByEquipmentId()
        }
    }, [equipmentId, getEquipmentAttachmentsByEquipmentId]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {header: 'Create Equipment Attachment', createAttachment});
    }

    const createAttachment = (attachment: IAttachment) => {
        const newEquipmentAttachment: EquipmentAttachmentFormValues = {...attachment, equipmentId}
        createEquipmentAttachment(newEquipmentAttachment);
    }

    const deleteAttachment = (attachmentId) => {
        deleteEquipmentAttachment(attachmentId);
    }


    return (<AttachmentTable
        attachments={equipmentAttachmentsByDate}
        attachmentCount={equipmentAttachmentCount}
        handleChangePage={handleChangePage}
        openCreateModal={openCreateModal}
        filterPredicate={setPredicate}
        getAttachmentById={getEquipmentAttachmentById}
        getDirectAttachmentById={getGenericDirectAttachmentById}
        deleteAttachment={deleteAttachment}
        disableCreateNew={disableCreateNew}
    />)
};

export default observer(EquipmentAttachmentTable)
