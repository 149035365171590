import {makeAutoObservable, runInAction} from 'mobx';
import {User, UserManager, UserManagerSettings, WebStorageStateStore} from 'oidc-client-ts';
import History from '../AppHistory';
import {RootStore} from './RootStore';
import {AuthenticatorClaims, TenantMembership} from "../types/claim-types";

const profileURL = 'https://rolkaloube.com/roles'

const {
    REACT_APP_CLIENTID,
    REACT_APP_CLIENT_SECRET,
    REACT_APP_STS_AUTHORITY,
    REACT_APP_CLIENT_SCOPE,
    REACT_APP_CLIENT_ROOT,
} = process.env;


type PotentiallyFlattened<T> = T extends (infer U)[] ? U | U[] : T;

export type CallbackState = { from?: string };
export type UserWithCallbackState = User & {
    state?: CallbackState;
    profile: {
        // oidc-client-ts will flatten single-value arrays
        [P in keyof AuthenticatorClaims]-?: PotentiallyFlattened<
            AuthenticatorClaims[P]
        >;
    };
};


export default class OidcStore {

    // rootStore: RootStore;
    userManager: UserManager

    constructor(public readonly rootStore: RootStore, manager?: UserManager) {
        makeAutoObservable(this)

        const settings: UserManagerSettings = {
            authority: REACT_APP_STS_AUTHORITY ||'',
            client_id: REACT_APP_CLIENTID || '',
            client_secret: REACT_APP_CLIENT_SECRET,
            // tslint:disable-next-line:object-literal-sort-keys
            post_logout_redirect_uri: `${REACT_APP_CLIENT_ROOT}`,
            response_type: "code",
            scope: REACT_APP_CLIENT_SCOPE,
            redirect_uri: window.location.origin + `/signin-callback`,
            silent_redirect_uri: window.location.origin + `/silent-renew`,
            userStore: new WebStorageStateStore({store: window.localStorage}),
            extraQueryParams: {
                claims: JSON.stringify({
                    userinfo: {
                        sub: { essential: true },
                        name: { essential: true },
                        email: { essential: true },
                        email_verified: { essential: true },
                        'https://rolkaloube.com/roles': { essential: true },
                    },
                    id_token: {
                        sub: { essential: true },
                        name: { essential: true },
                        email: { essential: true },
                        email_verified: { essential: true },
                        'https://rolkaloube.com/roles': { essential: true },
                    },
                }),
            }
        };
        this.rootStore = rootStore

        this.userManager = manager || new UserManager(settings);
        this.userManager.startSilentRenew()

        this.getUser()
    }


    shouldCancel: boolean = false;

    user: User | null = null

    get isLoggedIn() {
        return !!this.user;
    }

    get isAdmin() {
        return this.hasRole('admin');
    }

    get isRLUser() {
        return this.isTenantOf('526f6c6b-6120-4c6f-b562-652061757468')
    }

    get isFCC() {
        return this.isTenantOf('Federal Communications Commission')
    }

    get roles() {
        // @ts-ignore
        return this.user !== null && this.user.profile[profileURL] ? this.user?.profile[profileURL][0]?.roles : []
    }

    componentDidMount() {
        this.getUser();
    }
    componentWillUnmount() {
        this.shouldCancel = true;
    }

    public login = () => {
        this.userManager.signinRedirect()
    };

    public loginCallback = async (url: string) => {
        const user = await this.userManager.signinCallback(url);
        runInAction(() => {
            this.user = user || null;
            History.push('/Home');
        });
    };

    public renewToken = async (): Promise<User | null> => {
        return this.userManager.signinSilent()
    }

    public getToken = async () => {
        const user = await this.getUser();
        if (!!user && user?.id_token) {
            return user.id_token;
        } else if (user) {
            await this.renewToken();
            return this.user?.id_token;
        } else {
            this.rootStore.errorStore.addError(new Error('user is not logged in'));
        }
    }

    public signinSilentCallback = async (url: string) => {
        await this.userManager
            .signinSilentCallback(url)
            .then((d) => {
                this.getUser();
            })
            .catch(error => {
                this.rootStore.errorStore.addError(error);
            });
    };
    public signinSilent = async (url: string) => {
        await this.userManager
            .signinSilent()
            .then(() => {
                this.getUser();
            })
            .catch(error => {
                this.rootStore.errorStore.addError(error);
            });
    };

    public logout = () => {
        this.userManager.signoutRedirect();
    };

    public getUser = async () => {
        const user = await this.userManager.getUser();
        runInAction(() => {
            if (user) {
                this.user = user;
            }
        });

        return user;
    };

    public hasAnyRole = (shouldHave: string[]) => {
        if (!this.user) return false
        if (!this.user?.profile[profileURL]) return false
        if (!shouldHave) return true
        const roles = this.user?.profile[profileURL][0].roles
        return roles?.some(role => shouldHave.includes(role))
    }

    public hasAllRoles = (shouldHave: string[]) => {
        if (!this.user) return false
        if (!this.user?.profile[profileURL]) return false
        if (!shouldHave) return true
        if (!shouldHave.every) return false
        // const roles = this.user?.profile[profileURL][0].roles
        return shouldHave.every(role => this.roles?.includes(role))
    }

    public hasRole = (role: string) => {
        if (!this.user) return false
        if (!this.user?.profile[profileURL]) return false
        if (!role) return true
        // const roles = this.user?.profile[profileURL][0]?.roles
        return this.roles?.includes(role)
    }

    public isTenantOf = (shouldHave: string) => {
        if (!this.user) return false
        if (!this.user?.profile[profileURL]) return false
        if (!shouldHave) return true
        if (!!(this.user?.profile[profileURL]as Array<TenantMembership>).length) {
            let tenants = this.user.profile[profileURL] as Array<TenantMembership>
            return tenants.filter(i => i.tenant.name === shouldHave || i.tenant.id === shouldHave).length > 0
        } else {
            const {name, id} = (this.user?.profile[profileURL] as TenantMembership).tenant
            return name === shouldHave || id === shouldHave
        }
    }

}