import React, {FunctionComponent, useEffect, useState} from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid'

interface OwnProps {
    page?: number
    totalRows: number
    rowsPerPage?: Rows

    onPageChange?(page: number, rows: number): void

    onRowChange?(pages: number, rows: number): void
}

type Props = OwnProps;

const availableRows = [5, 10, 20, 50, 100,] as const
export type Rows = typeof availableRows[number] | number
const Pagination: FunctionComponent<Props> = ({
                                                  page = 1,
                                                  rowsPerPage = 5,
                                                  totalRows = 0,
                                                  onPageChange,
                                                  onRowChange
                                              }) => {
    // Classes
    const activeClasses = 'bg-blue-50 border-blue-500 text-blue-600 relative inline-flex items-center px-3 py-2  border-[1px] text-sm font-medium'
    const pageClasses = 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-3 py-2 border-r-transparent first:border-l-transparent border-[1px] focus:border-r-gray-300 text-sm font-medium'

    const startBtnClasses = 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
    const endBtnClasses = 'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'

    const btnClasses = 'disabled:bg-gray-200 disabled:hover:cursor-not-allowed relative inline-flex items-center justify-center px-2 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-[#ffffff] hover:bg-gray-50'

    const focusClasses = 'focus:ring-2 focus:ring-blue-600  focus:outline-none focus:z-10'

    const [localPage, setLocalPage] = useState(page)
    const [localTotalPages, setLocalTotalPages] = useState(Math.ceil(totalRows / rowsPerPage));
    const [localRows, setLocalRows] = useState(rowsPerPage);
    const [localPages, setLocalPages] = useState([1]);

    const handleOverUnder = (newPage: number, totalPages: number) => {
        if (!newPage || !totalPages) return
        const crementor = totalPages > 3 ? 2 : 1
        let min = Math.max(1, newPage - crementor)
        let max = Math.min(totalPages, newPage + crementor)
        const newPages = [newPage]

        while (true) {
            if (max - min < (totalPages > 4 ? 4 : totalPages - 1)) {
                if (max < totalPages && newPage - min < 2) max++
                if (min > 1 && max - newPage < 2) min--
            } else {
                break
            }
        }

        while (true) {
            if (newPages[newPages.length - 1] >= max && newPages[0] <= min) break

            if (newPages[newPages.length - 1] < max)
                newPages.push(newPages[newPages.length - 1] + 1)

            if (newPages[0] > min)
                newPages.unshift(newPages[0] - 1)

        }

        setLocalPages(newPages)
    }

    const handlePageChange = (newPage: number) => {
        setLocalPage(newPage)
        handleOverUnder(newPage, localTotalPages)
        !!onPageChange && onPageChange(newPage, localRows)
    }

    const handleRowChange = (e) => {
        e.persist()
        const {value} = e.currentTarget
        setLocalRows(value)
        setLocalTotalPages(totalRows / value)
        setLocalPage(1)
        handleOverUnder(1, totalRows / value)
        !!onRowChange && onRowChange( 1, value)
    }

    useEffect(() => {
        setLocalTotalPages(Math.ceil(totalRows / rowsPerPage))
    }, [rowsPerPage, totalRows]);

    useEffect(() => {
        handleOverUnder(1, localTotalPages)
    }, [localTotalPages]);

    return (<>
        <div className="py-3 flex items-center justify-between print:hidden">
            <div className="grow flex justify-between sm:hidden">
                <button type={'button'} className={`${focusClasses} ${btnClasses}`}
                        onClick={() => handlePageChange(localPage - 1)}
                        disabled={localPage - 1 <= 0}>
                    <ChevronLeftIcon className="h-5 w-5 mr-2" aria-hidden="true"/> Previous
                </button>

                <button type={'button'} className={`${focusClasses} ${btnClasses}`}
                        onClick={() => handlePageChange(localPage + 1)}
                        disabled={localPage >= localTotalPages}>
                    Next<ChevronRightIcon className="h-5 w-5 ml-2" aria-hidden="true"/>
                </button>
            </div>


            <div className="hidden sm:grow sm:flex sm:items-center sm:justify-between">
                <div>
                    {!!totalRows ? <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{(localPage - 1) * localRows + 1}</span> to <span
                        className="font-medium">{Math.min(totalRows, localPage * localRows)}</span> of{' '}
                        <span className="font-medium">{totalRows}</span> results
                    </p> : <p>No data available</p>}

                    {!!onRowChange && <p className="text-sm text-gray-700">
                        rows<span title={'per'}>/</span>page <select value={localRows} disabled={totalRows <= 1}
                                                                     className={'focus:outline-none focus:ring-2 focus:ring-blue-500 rounded hover:bg-gray-200 focus:bg-white disabled:cursor-not-allowed'}
                                                                     onChange={handleRowChange}>
                        {availableRows.map(v => <option value={v} key={v}>{v}</option>)}
                    </select>
                    </p>}
                </div>
                <div>
                    <nav className="relative inline-flex rounded-md" aria-label="Pagination">
                        <button type={'button'} className={`${focusClasses} ${startBtnClasses}`}
                                onClick={() => handlePageChange(localPage - 1)}
                                disabled={localPage - 1 <= 0}>
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>

                        {localPages.map((num) =>
                            <button aria-current={localPage === num ? 'page' : 'false'}
                                    type={'button'} key={num}
                                    className={`${focusClasses} ${localPage === num ? activeClasses : pageClasses}`}
                                    onClick={() => handlePageChange(num)}
                            >{num}</button>
                        )}

                        <button type={'button'} className={`${focusClasses} ${endBtnClasses}`}
                                onClick={() => handlePageChange(localPage + 1)}
                                disabled={localPage >= localTotalPages}>
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    </nav>
                </div>
            </div>
        </div>

        {/*<JSONView data={{*/}
        {/*    totalRows,*/}
        {/*    page,*/}
        {/*    rowsPerPage,*/}
        {/*    localPage,*/}
        {/*    localPages,*/}
        {/*    localTotalPages,*/}
        {/*    localRows,*/}
        {/*}}/>*/}
    </>);
};


export default Pagination;
