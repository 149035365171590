import React, {FunctionComponent, useCallback, useContext, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {debounce} from 'lodash'
import {IAdministrative} from '../../../../models/Administrative/Administrative';
import {RootStoreContext} from '../../../../stores/RootStore';
import AssignTechnician from "../../../../components/generic/AssignTechnician";

export interface AssignTechnicianToAdministrativeProps {
    administrative: IAdministrative
}

type Props = AssignTechnicianToAdministrativeProps;

const AssignTechnicianToAdministrative: FunctionComponent<Props> = ({administrative}) => {
    // Stores
    const rootStore = useContext(RootStoreContext)
    const {editAdministrative} = rootStore.administrativeStore
    const {
        clearTechnicians,
        handleChangePage,
        loadTechnicians,
        page,
        pageCount,
        setPredicate,
        technicianCount,
        techniciansList,
    } = rootStore.techniciansStore
    const {closeModal} = rootStore.modalStore

    // Effects
    useEffect(() => {
        loadTechnicians()
    }, [loadTechnicians, clearTechnicians]);

    // Functions
    const handleChange = (e) => {
        const {value, name} = e.currentTarget;
        handleSearch({
            n: name,
            v: value
        })
    };

    const handleSearch = useCallback(
        // tslint:disable-next-line
        debounce((value) => {
            const {n, v} = value
            setPredicate(n, v)
        }, 500),
        []
    );

    const assignTechnician = (technicianId) => {
        let newAdministrative = {
            ...administrative, technicianId
        }
        newAdministrative.technicianId = technicianId
        editAdministrative(newAdministrative).then(() => close())
    }

    const close = () => closeModal('AssignTechnicianToAdministrativeModal')

    return (<AssignTechnician onClick={assignTechnician} onChange={handleChange} options={techniciansList}
                              pageCount={pageCount} totalItems={technicianCount} page={page}
                              onPageChange={handleChangePage}
                              onCancel={close}/>);
};

export default observer(AssignTechnicianToAdministrative);
