import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {RootStore} from './RootStore'
import {IMassCost, IMassCostUpdateFormValues, SubItem} from "../models/MassCost/MassCost";
import Agent from "../api/Agent";
import {arraySum, isEmptyObject} from "../lib/utilities";
import {toast} from "react-toastify";


export default class MassCostStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.clearMassCost()
                this.loadMassCost()
            }
        )
    }

    currentSubItems = new Map()

    administrationsRegistry = new Map()
    assessmentsRegistry = new Map()
    equipmentsRegistry = new Map()
    installationsRegistry = new Map()
    maintenanceRepairsRegistry = new Map()
    outreachesRegistry = new Map()
    shippingAndTaxesRegistry = new Map()
    trainingsRegistry = new Map()
    travelsRegistry = new Map()

    page = 0
    limit = 1000000

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.clearMassCost()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object') {
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get massCostTotal() {
        return arraySum(this.currentSubItemsList.map(({amount}) => amount))
    }

    get currentSubItemsList(): SubItem[] {
        return Array.from(this.currentSubItems.values())
    }

    get administrativesList() {
        return Array.from(this.administrationsRegistry.values())
    }

    get assessmentsList() {
        return Array.from(this.assessmentsRegistry.values())
    }

    get equipmentsList() {
        return Array.from(this.equipmentsRegistry.values())
    }

    get installationsList() {
        return Array.from(this.installationsRegistry.values())
    }

    get maintenanceRepairList() {
        return Array.from(this.maintenanceRepairsRegistry.values())
    }

    get outreachList() {
        return Array.from(this.outreachesRegistry.values())
    }

    get shippingAndTaxesList() {
        return Array.from(this.shippingAndTaxesRegistry.values())
    }

    get trainingList() {
        return Array.from(this.trainingsRegistry.values())
    }

    get travelsList() {
        return Array.from(this.travelsRegistry.values())
    }

    get allLineItemsList() {
        return [
            ...this.administrativesList,
            ...this.assessmentsList,
            ...this.equipmentsList,
            ...this.installationsList,
            ...this.maintenanceRepairList,
            ...this.outreachList,
            ...this.shippingAndTaxesList,
            ...this.trainingList,
            ...this.travelsList,
        ]
    }

    clearAdministratives = () => this.administrationsRegistry.clear()

    clearAssessments = () => this.assessmentsRegistry.clear()

    clearEquipments = () => this.equipmentsRegistry.clear()

    clearInstallations = () => this.installationsRegistry.clear()

    clearMaintenanceRepair = () => this.maintenanceRepairsRegistry.clear()

    clearOutreach = () => this.outreachesRegistry.clear()

    clearShippingAndTaxes = () => this.shippingAndTaxesRegistry.clear()

    clearTraining = () => this.trainingsRegistry.clear()

    clearTravel = () => this.travelsRegistry.clear()

    clearMassCost = (costs?: IMassCost) => {
        const isEmpty = isEmptyObject(costs);

        (isEmpty || !!costs?.administrationsDto) && this.clearAdministratives();
        (isEmpty || !!costs?.assessmentsDto) && this.clearAssessments();
        (isEmpty || !!costs?.equipmentsDto) && this.clearEquipments();
        (isEmpty || !!costs?.installationsDto) && this.clearInstallations();
        (isEmpty || !!costs?.maintenanceRepairsDto) && this.clearMaintenanceRepair();
        (isEmpty || !!costs?.outreachesDto) && this.clearOutreach();
        (isEmpty || !!costs?.shippingAndTaxesDto) && this.clearShippingAndTaxes();
        (isEmpty || !!costs?.trainingsDto) && this.clearTraining();
        (isEmpty || !!costs?.travelsDto) && this.clearTravel();
    }

    setRegistries = async (costs: IMassCost) => {
        this.clearMassCost(costs)

        costs?.administrationsDto?.forEach((massCost) => {
            this.administrationsRegistry.set(massCost.id, massCost)
        })

        costs?.assessmentsDto?.forEach((massCost) => {
            this.assessmentsRegistry.set(massCost.id, massCost)
        })

        costs?.equipmentsDto?.forEach((massCost) => {
            this.equipmentsRegistry.set(massCost.id, massCost)
        })

        costs?.installationsDto?.forEach((massCost) => {
            this.installationsRegistry.set(massCost.id, massCost)
        })

        costs?.maintenanceRepairsDto?.forEach((massCost) => {
            this.maintenanceRepairsRegistry.set(massCost.id, massCost)
        })

        costs?.outreachesDto?.forEach((massCost) => {
            this.outreachesRegistry.set(massCost.id, massCost)
        })

        costs?.shippingAndTaxesDto?.forEach((massCost) => {
            this.shippingAndTaxesRegistry.set(massCost.id, massCost)
        })

        costs?.trainingsDto?.forEach((massCost) => {
            this.trainingsRegistry.set(massCost.id, massCost)
        })

        costs?.travelsDto?.forEach((massCost) => {
            this.travelsRegistry.set(massCost.id, massCost)
        })
    }

    loadMassCost = async () => {
        this.currentSubItems.clear()
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const massCostsEnvelope = await Agent.MassCost.list(this.axiosParams)

            runInAction(() => {
                this.setRegistries(massCostsEnvelope)
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    handleSelectAll = () => {
        this.currentSubItems.clear();
        this.allLineItemsList.forEach(lineItem => {
            lineItem.subItems.forEach(subItem => {
                this.currentSubItems.set(subItem.id, subItem);
            })
        })
    }

    handleCheckBox = (subItem: SubItem) => {
        const sub = !!this.currentSubItems.get(subItem.id)
        if (sub)
            this.currentSubItems.delete(subItem.id)
        else
            this.currentSubItems.set(subItem.id, subItem)
    }

    editSubItem = (id: string, amount: number | string) => {
        const subItem = this.currentSubItems.get(id)
        if (!!subItem)
            this.currentSubItems.set(id, {...subItem, amount})
        else
            console.error("You done messed up A A Ron!")
    }

    addCostToCurrentSubItems = (amount: number) => {
        this.currentSubItemsList.forEach((sub) => this.currentSubItems.set(sub.id, {
            ...sub,
            amount: (+sub.amount + +amount)
        }))
    }

    divideCostAmongstSubItems = (amount: number) => {
        //    t = the total amount to be distributed
        //    d = the number of current subitems
        //    toFixed() = truncates number to fixed decimal
        //    (t - (toFixed(t/d, 2)d)) + (toFixed(t/d, 2))
        //    (t - (toFixed(t/d, 2)d)) + (toFixed(t/d, 2)) + toFixed(t/(d-1), 2) === t

        let recordCount = this.currentSubItemsList.length
        let perItem = +(amount / recordCount).toFixed(2)
        let remainder = amount - (perItem * recordCount)
        let adjusted = +(remainder + perItem).toFixed(2)

        this.currentSubItemsList.forEach((sub, idx) => this.currentSubItems.set(sub.id, {
            ...sub,
            amount: (+sub.amount + (idx === 0 ? adjusted : perItem))
        }))
    }

    applyHourlyCost = (amount: number) => {
        // We are just going to truncate decimals for Simplicity
        // https://rolkaloube.atlassian.net/wiki/spaces/MREU/pages/2335703042/Mass+Cost+Rounding
        this.currentSubItemsList.forEach((sub) => {
            this.currentSubItems.set(sub.id, {
                ...sub,
                amount: +(+sub.amount + ((!!sub.time && isNaN(sub.time) ? 0 : +sub.time) * +amount)).toFixed(2)
            })
        })
    }

    saveMassCosts = async (subItems: IMassCostUpdateFormValues) => {
        try {
            await this.rootStore.subItemStore.updateSubItems(subItems)
            await this.loadMassCost()
        } catch (e) {
            toast.error('Something went wrong!')
        }
    }
}