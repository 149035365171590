import React, {Dispatch, Fragment, FunctionComponent, SetStateAction, useEffect, useState} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {getClassName} from "../../lib/getClassName";

// TODO Add sizing, side, and backdrop toggle

export interface SlideOverProps {
    title?: string
    header?: string
    children?: React.ReactNode
    open?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full'
    onClose?: Dispatch<SetStateAction<any>> | ((open?: boolean) => boolean)

    afterClose?(): void | any
}


type Props = SlideOverProps;

const SlideOver: FunctionComponent<Props> = ({
                                                 title,
                                                 header,
                                                 open = true,
                                                 onClose,
                                                 afterClose,
                                                 size = 'lg',
                                                 children
                                             }) => {
    const [isOpen, setIsOpen] = useState(open)


    const sizeClass = getClassName({
        'max-w-2xl': size === 'sm',
        'max-w-3xl': size === 'md',
        'max-w-4xl': size === 'lg',
        'max-w-5xl': size === 'xl',
        'max-w-6xl': size === '2xl',
        '': size === 'full',
    })


    useEffect(() => {
        setIsOpen(open)
    }, [open]);

    const close = () => {
        setIsOpen(false)
        const internalClose = async () => {
            if (!!onClose) {
                let closed = await onClose(false)
                if (!!afterClose && closed) {
                    afterClose()
                }
            }
        }

        internalClose()
    }

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                {/*<div className="fixed inset-0"/>*/}

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur"/>
                            </Transition.Child>
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className={`pointer-events-auto w-screen z-20 ${sizeClass}`}>
                                    <div className="flex h-full flex-col overflow-y-auto bg-white py-6 shadow-xl ">
                                        <div className="px-4 sm:px-6 -mt-1 pb-4 border-b">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title
                                                    className="text-lg font-medium text-gray-900">{title || header}</Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={close}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 grow px-4 sm:px-6">
                                            {children}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
};

export default SlideOver;
