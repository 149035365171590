import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IShippingAndTaxNote, ShippingAndTaxNoteFormValues} from '../../models/ShippingAndTax/ShippingAndTaxNote'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'

export default class ShippingAndTaxNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.shippingAndTaxNoteRegistry.clear()
                this.loadShippingAndTaxNote()
            }
        )
        reaction(
            () => this.currentShippingAndTaxId,
            () => {

                this.page = 0
                this.shippingAndTaxNoteRegistry.clear()
                this.loadShippingAndTaxNote()
            }
        )
    }

    shippingAndTaxNoteRegistry = new Map()
    shippingAndTaxNote: IShippingAndTaxNote | null = null
    editMode = false


    currentShippingAndTaxId = '';
    shippingAndTaxNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentShippingAndTaxId = (shippingAndTaxId: string) => {
        this.currentShippingAndTaxId = shippingAndTaxId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.shippingAndTaxNoteCount / this.limit)
    }

    get shippingAndTaxNotesById() {
        return Array.from(this.shippingAndTaxNoteRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get shippingAndTaxNotesByDate() {
        return Array.from(this.shippingAndTaxNoteRegistry.values()).sort(
            (a, b) => dayjs(b.updatedOn).isBefore(a.updatedOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getShippingAndTaxNote = (id: string) => {
        return this.shippingAndTaxNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.shippingAndTaxNoteRegistry.clear()
        this.loadShippingAndTaxNote()
    }

    clearShippingAndTaxNote = () => {
        this.shippingAndTaxNoteRegistry.clear()
    }

    loadShippingAndTaxNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentShippingAndTaxId && typeof this.currentShippingAndTaxId !== undefined) {
                const shippingAndTaxNotesEnvelope = await Agent.ShippingAndTaxNotes.byShippingAndTaxId(this.currentShippingAndTaxId, this.axiosParams);
                const {modelsDto, modelCount} = shippingAndTaxNotesEnvelope;
                runInAction( () => {
                    modelsDto.forEach((shippingAndTaxNote: IShippingAndTaxNote) => {
                        this.shippingAndTaxNoteRegistry.set(shippingAndTaxNote.id, shippingAndTaxNote)
                    })
                    this.shippingAndTaxNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction( () => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getShippingAndTaxNoteById = async (id: string) => {
        let shippingAndTaxNote = this.getShippingAndTaxNote(id)
        if (shippingAndTaxNote) {
            this.shippingAndTaxNote = shippingAndTaxNote
            return shippingAndTaxNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                shippingAndTaxNote = await Agent.ShippingAndTaxNotes.byId(id)
                runInAction( () => {
                    this.shippingAndTaxNote = shippingAndTaxNote
                    this.shippingAndTaxNoteRegistry.set(shippingAndTaxNote.id, shippingAndTaxNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return shippingAndTaxNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction( () => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createShippingAndTaxNote = async (shippingAndTaxNote: IShippingAndTaxNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newShippingAndTaxNote = await Agent.ShippingAndTaxNotes.create(shippingAndTaxNote)
            await this.loadShippingAndTaxNote()
            runInAction( () => {
                this.shippingAndTaxNoteRegistry.set(newShippingAndTaxNote.id, newShippingAndTaxNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction( () => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editShippingAndTaxNote = (shippingAndTaxNote: ShippingAndTaxNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.ShippingAndTaxNotes.update(shippingAndTaxNote).then((updatedShippingAndTaxNote) => {
            runInAction( () => {
                this.shippingAndTaxNoteRegistry.set(updatedShippingAndTaxNote.id, updatedShippingAndTaxNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteShippingAndTaxNote = async (shippingAndTaxNote: IShippingAndTaxNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.ShippingAndTaxNotes.delete(shippingAndTaxNote.id)
            runInAction( () => {
                this.shippingAndTaxNoteRegistry.delete(shippingAndTaxNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            runInAction( () => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }

    }


}