import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import AssignTechnicianToTraining, {
    AssignTechnicianToTrainingProps
} from "../../../pages/consumers/training/components/Training/AssignTechnicianToTraining";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, AssignTechnicianToTrainingProps {
}

type Props = OwnProps;

// @ts-ignore
const AssignTechnicianToTrainingModal = NiceModal.create<Props>(({training, header, onClose}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver title={header} onClose={handleClose} open={modal.visible} afterClose={modal.remove}>
            <AssignTechnicianToTraining training={training}/>
        </SlideOver>
    )
})

export default AssignTechnicianToTrainingModal;
