import React, {FunctionComponent, useContext, useState} from 'react';
import {RootStoreContext} from "../../../../stores/RootStore";
import {IReimbursementClaimLineItemFlag} from "../../../../models/Reimbursement/ReimbursementClaimLineItem";
import TextArea from "../../../../components/form/TextArea";
import Button from "../../../../components/generic/Button";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface FlagLineItemFormProps {
    lineItemFlag: IReimbursementClaimLineItemFlag
}

type Props = FlagLineItemFormProps;

const FlagLineItemForm: FunctionComponent<Props> = ({lineItemFlag}) => {
    // Mobx Store
    const rootStore = useContext(RootStoreContext);
    const {flagReimbursementClaimLineItem} = rootStore.reimbursementClaimLineItemStore;
    const {closeModal} = rootStore.modalStore;


    // Local state
    const [currentLineItemFlag, ] = useState(lineItemFlag);
    const [currentComment, setCurrentComment] = useState('');

    // Functions
    const handleSubmit = (e) => {
        e.preventDefault()
        let lineItemsToFlag = {...currentLineItemFlag}
        for (let key in lineItemsToFlag) {
            if (typeof lineItemsToFlag[key] === 'object' && !!lineItemsToFlag[key].length) {
                lineItemsToFlag[key][0].category = 'Line Item Flagged'
                lineItemsToFlag[key][0].comment = currentComment
            }
        }
        flagReimbursementClaimLineItem(lineItemsToFlag)
    }

    const handleChange = (e) => {
        setCurrentComment(e.currentTarget.value)
    }

    return (<>
        <form onSubmit={handleSubmit} className={'flex flex-col h-full'}>
            <div className="flex h-full">
                <div >

                    <div className="py-2 w-full text-lg font-bold">
                        Flagging a line item cannot be undone. Please be sure you really want to perform this action.
                    </div>
                    <div className="py-2 w-full">
                        <TextArea name={'comment'} onChange={handleChange} label={'Comments'}
                                  placeholder={'Possible wrong consumer'} rows={6}/>
                    </div>
                </div>
            </div>

            <div className="flex justify-end">
                <Button color={"green"} iconLeft={<CheckIcon className={'h-5 w-5'}/>} type={'submit'}>Save</Button>
                <span className="mr-2"/>
                <Button color={"red"} iconLeft={<XMarkIcon className={'h-5 w-5'}/>} onClick={() => {
                    closeModal('FlagLineItemModal')
                }}>Cancel</Button>
            </div>
        </form>
    </>);
};

export default FlagLineItemForm;
