import axios from "axios";
import {IOutreachNote, IOutreachNoteEnvelope, OutreachNoteFormValues} from "../../models/Outreach/OutreachNote";
import {api, responseBody} from "./apiBase.service";

const OutreachNotes = {
    list: (params: URLSearchParams): Promise<IOutreachNoteEnvelope> =>
        axios
            .get("/Outreach/Note", {params: params})
            .then(responseBody),
    create: (Outreach: OutreachNoteFormValues) =>
        api.post(`/Outreach/Note`, Outreach),
    update: (Outreach: IOutreachNote) =>
        api.put(`/Outreach/Note/${Outreach.id}`, Outreach),
    delete: (id: string) =>
        api.del(`/Outreach/Note/${id}`),
    byId: (id: string) => api.get(`/Outreach/Note/${id}`),
    byOutreachId: (equipmentId: string, params: URLSearchParams): Promise<IOutreachNoteEnvelope> =>
        axios.get(`/Outreach/${equipmentId}/Note`, {params: params})
            .then(responseBody),
};

export default OutreachNotes