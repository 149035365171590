import {Envelope} from "../generic/Envelope";
import {ISubItem} from "../SubItems/SubItem";

export interface IAdministrativeEnvelope extends Envelope<IAdministrative> {}

export interface IAdministrative {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string
    entityState?: string
    technicianId?: string
    technicianName?: string
    description?: string
    datePerformed: Date
    time?: number
    costs?: number
    currentStatus?: string
    isFlagged?: boolean
    subItems?: ISubItem[]
    attachmentCount?: number
}

export class AdministrativeFormValues implements IAdministrative {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string = ''
    entityState?: string
    technicianId?: string
    technicianName?: string
    description?: string
    datePerformed: Date = new Date()
    time?: number
    costs?: number
    currentStatus?: string
    isFlagged?: boolean
    subItems?: ISubItem[]
    attachmentCount?: number

    constructor(administrative?: IAdministrative) {
        if (!!administrative) {
            Object.assign(this, administrative)
        }
    }
}