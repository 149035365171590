import {Envelope} from "./generic/Envelope";

export interface IAttestorEnvelope extends Envelope<IAttestor> {
}

export interface IAttestor {
    id?: string
    firstName: string
    middleName?: string
    lastName: string
    address1: string
    address2?: string
    businessName?: string
    city: string
    state: string
    zipCode: string
    title?: string
    phone?: string
    email?: string
    entityId: string
    entity?: string
}


//interface extention for custom built items like time in a datetime
export interface IAttestorFormValues extends Partial<IAttestor> {
    //time?:Date
}

//empty form model
export class AttestorFormValues implements IAttestorFormValues {
    id?: string
    firstName: string = ''
    middleName?: string
    lastName: string = ''
    address1: string = ''
    address2?: string
    businessName?: string
    city: string = ''
    state: string = ''
    zipCode: string = ''
    title?: string
    phone?: string
    email?: string
    entityId: string = ''
    entity?: string

    constructor(attestor?: IAttestor) {
        if (attestor) {
            Object.assign(this, attestor)
        }

        Object.assign(this);
    }
}