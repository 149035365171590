import React, {FunctionComponent, useCallback, useContext, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {debounce} from 'lodash'
import {IMaintenanceRepair} from "../../../../../models/MaintenanceRepair/MaintenanceRepair";
import AssignTechnician from "../../../../../components/generic/AssignTechnician";

export interface AssignTechnicianToMaintenanceRepairProps {
    maintenanceRepair: IMaintenanceRepair
}

type Props = AssignTechnicianToMaintenanceRepairProps;

const AssignTechnicianToMaintenanceRepair: FunctionComponent<Props> = ({maintenanceRepair}) => {
    // Stores
    const rootStore = useContext(RootStoreContext)
    const {editMaintenanceRepair} = rootStore.maintenanceRepairStore
    const {
        setPredicate,
        loadTechnicians,
        clearTechnicians,
        techniciansList,
        handleChangePage,
        page,
        pageCount,
        technicianCount,
    } = rootStore.techniciansStore
    const {closeModal} = rootStore.modalStore

    // Effects
    useEffect(() => {
        loadTechnicians()
    }, [loadTechnicians, clearTechnicians]);

    // Use Effects
    useEffect(() => {
        return () => {
            setPredicate()
        }
    }, []);


    // Functions
    const handleChange = (e) => {
        const {value, name} = e.currentTarget;
        handleSearch({
            n: name,
            v: value
        })
    };

    const handleSearch = useCallback(
        // tslint:disable-next-line
        debounce((value) => {
            const {n, v} = value
            setPredicate(n, v)
        }, 500),
        []
    );

    const assignTechnician = (technicianId) => {

        let newMaintenanceRepair = {
            ...maintenanceRepair,
            technicianId
        }

        newMaintenanceRepair.technicianId = technicianId
        editMaintenanceRepair(newMaintenanceRepair).then(() => close())
    }

    const close = () => closeModal('AssignTechnicianToMaintenanceRepairModal')

    return (
        <AssignTechnician onClick={assignTechnician} onChange={handleChange} options={techniciansList}
                          totalItems={technicianCount}
                          page={page} pageCount={pageCount} onPageChange={handleChangePage}
                          onCancel={close}/>
    );
};

export default observer(AssignTechnicianToMaintenanceRepair);
