import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IAllocation, IAllocationApproval} from '../../models/Allocation/Allocation'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class AllocationStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.allocationRegistry.clear()
                this.loadAllocation()
            }
        )
    }

    allocationRegistry = new Map()
    allocation: IAllocation | null = null

    allocationCount = 0
    page = 0
    limit = 20

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    setPredicates = (predicates: {}) => {
        this.allocationRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.allocationCount / this.limit)
    }

    get allocationsList() {
        return Array.from(this.allocationRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get allocationsByDate() {
        return Array.from(this.allocationRegistry.values()).sort(
            (a, b) => b.dateCreated - a.dateCreated//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getAllocation = (id: string) => {
        return this.allocationRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.allocationRegistry.clear()
        this.loadAllocation()
    }

    clearAllocation = () => {
        this.allocationRegistry.clear()
    }

    loadAllocation = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const allocationsEnvelope = await Agent.Allocations.list(this.axiosParams)
            const {modelsDto, modelCount} = allocationsEnvelope

            runInAction(() => {
                modelsDto.forEach((allocation: IAllocation) => {
                    this.allocationRegistry.set(allocation.id, allocation)
                })
                this.allocationCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    loadNakedAllocations = async (predicates?: {}) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)

            if (!!predicates) {
                Object.entries(predicates).forEach(([key, value]) => {
                    if (!!value) {
                        // @ts-ignore
                        params.append(key, value)
                    }
                })
            }

            const allocations = await Agent.Allocations.list(params)
            const {modelsDto} = allocations

            return modelsDto
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getAllocationById = async (id: string) => {

        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let allocation = await Agent.Allocations.byId(id)
            runInAction(() => {
                this.allocationRegistry.set(allocation.id, allocation)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return allocation
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    editAllocation = async (allocation: IAllocation) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newAllocation = await Agent.Allocations.update(allocation)
            runInAction(() => {
                this.allocationRegistry.set(allocation.id, allocation)
                this.rootStore.modalStore.closeModal('AllocationModal')
            })
            toast.success('Saved')
            return newAllocation
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    approveAllocations = async (allocationApproval: IAllocationApproval) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newAllocation = await Agent.Allocations.approve(allocationApproval)
            runInAction(() => {
                this.loadAllocation()
                this.rootStore.modalStore.closeModal('AllocationModal')
            })
            toast.success('Saved')
            return newAllocation
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

}