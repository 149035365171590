import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from '../../stores/RootStore';
import {AttachmentFormValues, IAttachment} from '../../models/generic/Attachment';
import Input from "../Inputs/Input";
import Button from "../generic/Button";
import FileDragAndDrop from "../form/FileDragAndDrop";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import JSONView from "../generic/JSONView";
import Toggle from "../generic/Toggle";
import {inputOnChange} from "../../lib/utilities";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface AttachmentFormProps {
    createAttachment: (attachment: IAttachment) => void
    showInternal?: boolean
}

type Props = AttachmentFormProps;

const AttachmentForm: React.FC<Props> = ({createAttachment, showInternal = false}) => {
    // store
    const rootStore = useContext(RootStoreContext);
    const {isFCC, isRLUser} = rootStore.oidcStore;
    const {closeModal} = rootStore.modalStore;
    const {user} = rootStore.oidcStore
    const [lEntityState, setLEntityState, refreshLEntityState] = useLocalStorage('currentEntityState', null)
    const [lEntities, , refreshLEntities] = useLocalStorage('entityStates', null)

    // state
    const [currentAttachment, setCurrentAttachment] = useState(new AttachmentFormValues());

    // Functions
    const handleChange = (e) => {
        inputOnChange(e, setCurrentAttachment)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!lEntityState) {
            refreshLEntityState((newEntityState) => {
                if (!newEntityState) {
                    return refreshLEntities((newEntities) => {
                        setLEntityState(newEntities[0])
                        return newEntities[0]
                    })
                }

                return newEntityState
            })
        }

        let tmpAttachment = {...currentAttachment, entityId: lEntityState.entityId ?? lEntities[0].entityId}

        tmpAttachment.name = tmpAttachment.file.name;
        tmpAttachment.createdByName = user?.profile.email || '';

        createAttachment(tmpAttachment)
    }

    const handleFile = (files) => {
        let tmpAttachment = {...currentAttachment}
        tmpAttachment.file = files[0]
        setCurrentAttachment(tmpAttachment)
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit}
              className={'overflow-y-auto overflow-x-hidden grow flex flex-col h-full'}>
            <div className={'grow h-full'}>
                <div>
                    <Input type="textarea"
                           label={'Description'}
                           value={currentAttachment.description}
                           name={'description'}
                           onChange={handleChange}
                           maxLength={8000}
                           rows={5}
                           placeholder={'101 Secrets of Time Travel.pdf'}
                    />
                    <div className={'text-red-600'}>
                        <span className={`shake ${currentAttachment.description.length < 7900 ? 'hide' : ''}`}>
                            {8000 - currentAttachment.description.length} remaining characters</span>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <FileDragAndDrop maxNumberOfFiles={1} acceptedFileTypes={'.pdf,.xls,.xlsx,.jpg,.jpeg,.png'}
                                     name={'file'} label={'File'} onChange={handleFile} required />
                </div>
                {showInternal && (isFCC || isRLUser) && <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1 mt-4">
                    <Toggle label={'internal only'} name={'internalOnly'} checked={currentAttachment.internalOnly}
                            onChange={() => setCurrentAttachment(prev => {
                                return {
                                    ...prev,
                                    internalOnly: !prev.internalOnly
                                }
                            })}/>
                </div>}
                <JSONView data={currentAttachment}/>
            </div>

            <div className={'flex justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'}
                        type={'submit'} disabled={!currentAttachment.file}>Save</Button>
                <span className={'m-2'}/>
                <Button name={'cancel'} onClick={() => closeModal('AttachmentModal')} color="red"
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>

    );
};

export default observer(AttachmentForm)