export class axiosError extends Error {
    response: { status: any; data: any; config: any };

    constructor(msg: string, response: { status: any; data: any; config: any }) {
        super(msg);
        this.response = response;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, axiosError.prototype);
    }

    getMessage() {
        return this.message;
    }

    getResponse() {
        return this.response;
    }
}