import React, {FunctionComponent} from 'react';
import './Skeleton.css'


interface OwnProps {
  active?: boolean
  animated?: boolean
  width?: number | string
  height?: number | string
  circle?: boolean
  rounded?: boolean
  count?: number,
  position?: "" | "centered" | "right"
  sizes?: "small" | "medium" | "large"
  row?: boolean
}

type Props = OwnProps;

const Skeleton: FunctionComponent<Props> = ({
                                              active = true,
                                              animated = true,
                                              width,
                                              height,
                                              circle,
                                              rounded = false,
                                              count = 1,
                                              position,
                                              sizes,
                                              row= true,
                                              ...attributes
                                            }) => {


  const style: any = {
    // @ts-ignore
    height: height === undefined ? null : (isNaN(height) ? height : height + 'px'),
    // @ts-ignore
    width: width === undefined ? null : (isNaN(width) ? width : width + 'px'),
    borderRadius: circle ? '50%' : null
  }

  if (active) {
    return (
        <div
            className={`skeleton ${animated ? 'is-animated' : ''} is-${position}  is-${sizes} ${row ? 'flex-row' : 'flex-col'}`}
            {...attributes}>
          {Array.from(new Array(count)).map((_, i) => {
            return (<div
                className={`skeleton-item ${rounded ? 'is-rounded' : ''} ${!!row && 'm-0 mr-2'}`}
                style={style}
                key={i}/>)
          })}
        </div>
    );
  }

  return <></>
};

export default Skeleton;
