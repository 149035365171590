import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from '../../../../stores/RootStore';
import AttachmentTable from '../../../../components/attachment/AttachmentTable';
import {IAttachment} from '../../../../models/generic/Attachment';
import {ShippingAndTaxAttachmentFormValues} from '../../../../models/ShippingAndTax/ShippingAndTaxAttachment';


export interface ShippingAndTaxAttachmentTableProps {
    shippingAndTaxId: string;
    disableCreateNew?: boolean;
}

type Props = ShippingAndTaxAttachmentTableProps;

const ShippingAndTaxAttachmentsTable: FunctionComponent<Props> = ({shippingAndTaxId, disableCreateNew = false}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentShippingAndTaxId,
        shippingAndTaxAttachmentsByDate,
        shippingAndTaxAttachmentCount,
        handleChangePage,
        getShippingAndTaxAttachmentsByShippingAndTaxId,
        getShippingAndTaxAttachmentById,
        createShippingAndTaxAttachment,
        deleteShippingAndTaxAttachment
    } = rootStore.shippingAndTaxAttachmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment

    // USE EFFECTS
    useEffect(() => {
        if (!!shippingAndTaxId) {
            setCurrentShippingAndTaxId(shippingAndTaxId)
            getShippingAndTaxAttachmentsByShippingAndTaxId()
        }
    }, [shippingAndTaxId]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {createAttachment, header: 'Create ShippingAndTax Attachment'});
    }

    const createAttachment = (attachment: IAttachment) => {
        const newShippingAndTaxAttachment: ShippingAndTaxAttachmentFormValues = {...attachment, shippingAndTaxId}
        createShippingAndTaxAttachment(newShippingAndTaxAttachment);
    }

    const deleteAttachment = (attachmentId) => {
        deleteShippingAndTaxAttachment(attachmentId);
    }


    return (<>
        <AttachmentTable
            attachments={shippingAndTaxAttachmentsByDate}
            attachmentCount={shippingAndTaxAttachmentCount}
            handleChangePage={handleChangePage}
            openCreateModal={openCreateModal}
            filterPredicate={setPredicate}
            getAttachmentById={getShippingAndTaxAttachmentById}
            deleteAttachment={deleteAttachment}
            getDirectAttachmentById={getGenericDirectAttachmentById}
            disableCreateNew={disableCreateNew}
        />
    </>)
};

export default observer(ShippingAndTaxAttachmentsTable)
