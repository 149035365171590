import React, {FunctionComponent, useCallback, useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";

import {debounce} from 'lodash'
import {displayDate, displayPhone} from "../../../../../lib/utilities";
import {IEquipment} from "../../../../../models/Equipment/Equipment";
import Title from "../../../../../components/generic/Title";
import Input from "../../../../../components/Inputs/Input";
import Button from '../../../../../components/generic/Button';
import {ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface AssignConsumerToEquipmentProps {
    equipment: IEquipment
}

type Props = AssignConsumerToEquipmentProps;

const AssignConsumerToEquipment: FunctionComponent<Props> = ({equipment}) => {
    // Stores
    const rootStore = useContext(RootStoreContext)
    const {editEquipment} = rootStore.equipmentStore
    const {
        setPredicate,
        loadConsumers,
        clearConsumers,
        consumersList,
        handleChangePage,
        page,
        pageCount
    } = rootStore.consumerStore
    const {closeModal} = rootStore.modalStore

    // Sates
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState('');

    // Effects
    useEffect(() => {
        loadConsumers()
    }, [loadConsumers, clearConsumers]);

    useEffect(() => {
        return () => {
            setPredicate()
        }
    }, []);


    // Functions
    const handleChange = (e) => {
        const {value, name} = e.currentTarget;
        switch (name) {
            case 'firstName':
                setFirstName(value)
                break
            case 'middleName':
                setMiddleName(value)
                break
            case 'lastName':
                setLastName(value)
                break
            case 'dob':
                setDob(value)
                break
        }
        handleSearch({
            n: name,
            v: value
        })
    };

    const handleSearch = useCallback(
        // tslint:disable-next-line
        debounce((value) => {
            const {n, v} = value
            setPredicate(n, v)
        }, 500),
        []
    );

    const assignConsumer = (consumerId) => {

        let newEquipment = {
            ...equipment
        }

        newEquipment.consumerId = consumerId
        editEquipment(newEquipment)
    }

    return (
        <div className={'h-full flex flex-col'}>
            <form onSubmit={(e) => e.preventDefault()} autoComplete={'off'}>
                <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-1'}>
                    <Input label={'First Name'} name={'firstName'} value={firstName} type={'search'}
                           placeholder={'Martin'} onChange={handleChange}/>
                    <Input label={'Middle Name'} name={'middleName'} value={middleName} type={'search'}
                           placeholder={'Seamus'} onChange={handleChange}/>
                    <Input label={'Last Name'} name={'lastName'} value={lastName} type={'search'}
                           placeholder={'McFly'} onChange={handleChange}/>
                    <Input label={'Date of Birth'} name={'dob'} value={dob} type={'date'}
                           onChange={handleChange}/>
                </div>
            </form>

            <div
                className={'overflow-auto px-3 py-2 flex-1 h-full'}>{consumersList.length > 0 ? consumersList.map((consumer) => {
                return (<button className={'bg-light py-2 px-3 shadow-md border mb-3 cursor-pointer w-full text-left rounded-lg focus:bg-indigo-600 focus:text-white'}
                             onClick={() => assignConsumer(consumer.id)}>

                    <Title size={'lg'} as={'p'}>
                        {consumer.firstName} {consumer.middleName} {consumer.lastName}
                    </Title>

                    <div className="text-muted text-italic sm:flex sm:gap-8">
                        <address className={'text-sm sm:text-base w-full'}>
                            {consumer.address1}&nbsp;
                            {consumer.address2}&nbsp;
                            <br/>
                            {consumer.city}&nbsp;
                            {consumer.state},&nbsp;
                            {consumer.zipCode}
                        </address>

                        <div className="text-sm sm:text-base w-full">
                            <p>{displayPhone(consumer.phone)}</p>
                            <p>{consumer.email}</p>
                            <p><span className={'sr-only'}>Date of Birth</span>
                                <span>{displayDate(consumer.dob, null, true)}</span></p>
                        </div>
                    </div>


                </button>)
            }) : <p>No results found</p>}</div>

            <div className="sm:flex sm:gap-4 sm:justify-between">
                <div>
                    <Button color={"red"}
                            onClick={() => {
                                handleChangePage(1, 5)
                                closeModal('AssignConsumerToEquipmentModal')
                            }}
                            iconLeft={<XMarkIcon className={'h-6 w-6'}/>}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="flex gap-4 mt-6 sm:mt-0">
                    <Button
                        color={'dark'}
                        onClick={() => handleChangePage(page, 5)}
                        disabled={page === 0}
                        iconLeft={<ChevronLeftIcon className={'h-6 w-6'}/>}
                    >Previous</Button>
                    <Button color={'dark'}
                            onClick={() => handleChangePage(page + 2, 5)}
                            disabled={page === pageCount - 1}
                            iconRight={<ChevronRightIcon className={'h-6 w-6'}/>}
                    >Next</Button>
                </div>
            </div>
        </div>
    );
};

export default observer(AssignConsumerToEquipment);
