import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {AttestorFormValues, IAttestor} from "../../../../../models/Attestor";
import Input from "../../../../../components/Inputs/Input";
import {inputOnChange} from "../../../../../lib/utilities";
import Select from "../../../../../components/generic/Select";
import phoneInputOptions from "../../../../../data/cleave/phoneInputOptions";
import zipcodeInputOptions from "../../../../../data/cleave/zipcodeInputOptions";
import EntityAutoCompleteInput from "../../../../../components/form/EntityAutoCompleteInput";
import Button from "../../../../../components/generic/Button";
import {useLocalStorage} from "../../../../../hooks/useLocalStorage";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {useLocation} from "react-router-dom";

export interface AttestorFormProps {
    attestor: IAttestor | undefined;
    callback?: () => (void | any)
}

type Props = AttestorFormProps

const AttestorForm: React.FC<Props> = ({attestor}) => {
    const rootStore = useContext(RootStoreContext);
    const {createAttestor, editAttestor} = rootStore.attestorStore;
    const {closeModal} = rootStore.modalStore;
    const {getStates} = rootStore.stateStore;

    const [currentAttestor, setCurrentAttestor] = useState(new AttestorFormValues(attestor));
    const [localEntityStates,] = useLocalStorage('entityStates', []);
    const {search} = useLocation()


    useEffect(() => {
        if (!currentAttestor.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentAttestor((prev: AttestorFormValues) => {
                return {
                    ...prev,
                    entityId: localEntityStates[0].entityId || '',
                    entity: localEntityStates[0].entityId ? `${localEntityStates[0].entityName}` : ''
                }
            })
        }
    }, [localEntityStates])


    const handleSubmit = (e) => {
        e.preventDefault()
        let attestor = {...currentAttestor}
        if (!attestor.id) {
            let newAttestor = {
                ...attestor
            }
            delete newAttestor.id
            createAttestor(newAttestor).then(() => {
                const params = new URLSearchParams(search)
                if (params && params.get('closeWindow')) window.close()
            })
        } else {
            editAttestor(attestor);
        }
    };


    const handleInput = (e) => {
        inputOnChange(e, setCurrentAttestor)
    }


    return (
        <form onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full justify-between'}>
            <div>
                <div className={'grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-1'}>
                    <Input
                        onChange={handleInput}
                        name='title'
                        label='Title'
                        placeholder='Mr.'
                        value={currentAttestor.title}
                    />
                    <Input
                        onChange={handleInput}
                        name='firstName'
                        label='First Name'
                        placeholder='Biff'
                        value={currentAttestor.firstName}
                        required
                    />
                    <Input
                        onChange={handleInput}
                        name='middleName'
                        label='Middle Name'
                        placeholder='Howard'
                        value={currentAttestor.middleName}
                    />
                    <Input
                        onChange={handleInput}
                        name='lastName'
                        label='Last Name'
                        placeholder='Tannen'
                        value={currentAttestor.lastName}
                        required
                    />
                </div>

                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                    <Input
                        onChange={handleInput}
                        name='address1'
                        label='Address 1'
                        placeholder='123 Sheplan Drive'
                        value={currentAttestor.address1}
                        required
                    />
                    <Input
                        onChange={handleInput}
                        name='address2'
                        placeholder='Apt 12'
                        label='Address 2'
                        value={currentAttestor.address2}
                    />
                </div>


                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <Input
                        onChange={handleInput}
                        name='businessName'
                        label='Business Name'
                        placeholder='The Institute for Time Future Gambling'
                        value={currentAttestor.businessName}
                    />
                </div>

                <div className={'grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-1'}>
                    <Input
                        onChange={handleInput}
                        name='city'
                        label='City'
                        placeholder='Hill Crest'
                        value={currentAttestor.city}
                        required
                    />
                    <Select
                        style={{minWidth: '21.5ch'}}
                        name='state'
                        required
                        value={currentAttestor.state || 'Select State/Territory'}
                        options={getStates}
                        onChange={handleInput}
                        label={'State/Territory'}
                    />
                    <Input
                        onChange={handleInput}
                        name='zipCode'
                        label='Zip Code'
                        placeholder='90210'
                        value={currentAttestor.zipCode}
                        style={{width: '12ch'}}
                        options={zipcodeInputOptions}
                        required
                    />
                </div>

                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                    <Input
                        onChange={handleInput}
                        name='phone'
                        label='Phone'
                        placeholder='(957) 555-2314'
                        options={phoneInputOptions}
                        pattern='^\(?([2-9][0-8][0-9])\)?[-.●\s]?([2-9][0-9]{2})[-.●\s]?([0-9]{4})$'
                        type={'tel'}
                        value={currentAttestor.phone}
                    />

                    <Input
                        onChange={handleInput}
                        name='email'
                        label='Email'
                        placeholder='biffdude@future.org'
                        value={currentAttestor.email}
                    />
                </div>

                <div className={'grid grid-cols-1 sm:grid-cols- gap-x-4 gap-y-1'}>
                    <EntityAutoCompleteInput
                        required
                        name={'entityId'}
                        isLocked={!!localEntityStates && localEntityStates.length === 1}
                        placeholder={'The Institute for Time Travel'}
                        displayValue={currentAttestor?.entityId ? (currentAttestor.entity || 'Entity already selected') : ''}
                        onChange={(value: { id: string, name: string }) => handleInput({
                            currentTarget: {
                                name: 'entityId',
                                value: value.id
                            }
                        })}
                    />
                </div>
            </div>

            <div className={'flex justify-end gap-4'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'}
                        color={'green'}
                        type='submit'>Save</Button>
                <Button onClick={() => closeModal('AttestorModal')}
                        color="red"
                        name={'cancel'}
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>
                    Cancel
                </Button>
            </div>
        </form>
    );
};
export default observer(AttestorForm);