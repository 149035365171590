import React, {FunctionComponent} from 'react';
import {getClassName} from "../../lib/getClassName";

interface OwnProps {
    title?: string
    className?: string
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl'
}

type Props = OwnProps;

const HR: FunctionComponent<Props> = ({title, className = '', size='sm'}) => {
    const titleSize = getClassName({
        'text-xs': size === 'xs',
        'text-sm': size === 'sm',
        'text-base': size === 'base',
        'text-lg': size === 'lg',
        'text-xl': size === 'xl',
        'text-2xl': size === '2xl',
        'text-3xl': size === '3xl',
        'text-4xl': size === '4xl',
        'text-5xl': size === '5xl',
        'text-6xl': size === '6xl',
        'text-7xl': size === '7xl',
        'text-8xl': size === '8xl',
        'text-9xl': size === '9xl',
    })

    return (
        <div className={`relative my-2 text-gray-500  ${className}`}>
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
            {!!title && <div className="relative flex justify-start">
                <span className={`pr-2 bg-white ${titleSize}`}>{title}</span>
            </div>}
        </div>
    );
};

export default HR;
