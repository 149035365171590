import React from "react";

// @ts-ignore
interface ISelect extends React.HTMLProps<HTMLSelectElement> {
    placeholder?: string
    label?: string
    options: Array<any>
    valueName?: string
    keyName?: string
    text?: string
    prepend?: boolean
    accented?: boolean
    disablePlaceholder?: boolean
}

const Select: React.FC<ISelect> = ({
                                       options,
                                       label,
                                       placeholder = 'Select...',
                                       className = '',
                                       valueName= 'value',
                                       keyName= 'key',
                                       text= 'text',
                                       prepend = false,
                                       accented = true,
                                       disablePlaceholder= false,
                                       children,
                                       ...rest
                                   }) => {


    if (prepend) {
        return (<>
            <div
                className={`w-full mb-3 flex rounded h-9 ${className}`}>
                <label htmlFor={rest.name}
                       className={`py-1 px-3  rounded-l m-0 border whitespace-nowrap inline-flex items-center justify-center ${accented ? 'bg-blue-800 border-blue-800 text-white' : 'bg-gray-200 border-gray-200'}`}>
                    {label}
                    {rest.required && <span className={'text-red-600 text-xs'}> (required)</span>}</label>
                <select className={`focus:outline-none bg-gray-200/25 py-1 px-2 border border-slate-300 border-l-0 rounded-r w-full disabled:cursor-not-allowed disabled:bg-gray-400/25`} {...rest}>
                    <option value={''} disabled={disablePlaceholder}>{placeholder}</option>
                    {options.map(opt => {
                        return <option value={opt[valueName]}
                                       key={opt[keyName]}>{opt[text]}</option>
                    })}
                </select>
            </div>
        </>)
    }


    return (<div className={'mb-3'}>
        <label htmlFor={rest.name} className={'block'}>{label}{rest.required &&
        <span className={'text-red-600 text-xs'}> (required)</span>}</label>
        <select
            className={`peer border border-slate-300 bg-gray-200/25 w-full py-2 px-2 align-middle rounded-md ${className} mb-3 disabled:cursor-not-allowed disabled:bg-gray-400/25 focus:outline-none`} {...rest}>
            <option value={''}>{placeholder}</option>
            {options.map(opt => {
                return <option value={opt[valueName]}
                               key={opt[keyName]}>{opt[text]}</option>
            })}
        </select>

        <p className="-mt-2 invisible peer-invalid:visible text-red-600 text-sm peer-disabled:hidden peer-valid:hidden px-1">
            Please complete this field
        </p>
    </div>)
}

export default Select