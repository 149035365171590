import {Envelope} from "../generic/Envelope";
import {ISubItem} from "../SubItems/SubItem";

export interface IMaintenanceRepairEnvelope extends Envelope<IMaintenanceRepair> {}

export interface IMaintenanceRepair {
    auxiliaryAidCosts?: number
    consumerId?: string
    consumerName?: string
    createdByName?: string
    createdOn?: string
    currentStatus?: string
    datePerformed: Date
    dateRequested?: Date
    dateReturned: Date
    dateSent?: Date
    entityState?: string
    entityStateId: string
    equipmentId?: string
    equipmentName?: string
    id?: string
    otherCosts?: number
    rmrwTime?: number
    subItems?: ISubItem[]
    technicianId?: string
    technicianName?: string
    timeMaterialsCosts?: number
    isFlagged?: boolean
    travelIds?: string[]
    updatedByName?: string
    updatedOn?: string
    attachmentCount?: number
}

export class MaintenanceRepairFormValues implements IMaintenanceRepair {
    auxiliaryAidCosts?: number
    consumerId?: string
    consumerName?: string
    createdByName?: string
    createdOn?: string
    currentStatus?: string
    datePerformed: Date = new Date()
    dateRequested?: Date
    dateReturned: Date = new Date()
    dateSent?: Date
    entityState?: string
    entityStateId: string = ''
    equipmentId?: string
    equipmentName?: string
    id?: string
    otherCosts?: number
    rmrwTime?: number
    subItems?: ISubItem[]
    technicianId?: string
    technicianName?: string
    timeMaterialsCosts?: number
    travelIds?: string[]
    isFlagged?: boolean
    updatedByName?: string
    updatedOn?: string
    attachmentCount?: number

    constructor(maintenanceRepair?: IMaintenanceRepair) {
        Object.assign(this, maintenanceRepair);
    }
}