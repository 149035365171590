import {makeAutoObservable} from 'mobx'
import {RootStore} from './RootStore'
import {IEntityState} from "../models/Entity/EntityState";
import {uuid} from "../lib/utilities";
import React from "react";

// const storage = window.localStorage

export default class CommonStore {
    // private currentEntityFromStorage = JSON.parse(storage.getItem('currentEntityState') || '')
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
    }

    isSubmitting = false;
    isLoadingText = 'Loading Application...';
    sideBar = false;
    title: string | React.ReactNode = "Go Back";
    titleIcon: any;
    currentEntityState = {} as IEntityState
    currentLoadingList = new Set()

    get isLoading() {
        return this.currentLoadingList.size > 0
    }

    setTitle = (title: string | React.ReactNode = 'Go Back', textTitle?: string) => {
        this.title = title
        let pageTitle = 'NDBEDP Centralized Database'
        if (!!textTitle) {
            pageTitle = `${textTitle} | ${pageTitle}`
        }else if (!!title && typeof title === "string") {
            pageTitle = `${title} | ${pageTitle}`
        }
        document.title = pageTitle
    }

    setTitleIcon = (icon: any = '') => {
        this.titleIcon = icon
    }

    setIsSubmitting = (submitting = false) => {
        this.isSubmitting = submitting
    }

    setIsLoading = (loadingId: string = ''): string => {
        let id = ''
        if (!loadingId) {
            id = uuid()
            this.currentLoadingList.add(id)
        }

        if (!!loadingId) {
            this.currentLoadingList.delete(loadingId)
        }
        return id
    }
    toggleSideBar = () => {
        this.sideBar = !this.sideBar
    }

    setCurrentEntityState = (entityState) => {
        if (!entityState && entityState !== 'undefined') {
            entityState = this.rootStore.entityStateStore.entityStatesByName[0]
        }
        this.currentEntityState = entityState
        window.localStorage.setItem('currentEntityState', JSON.stringify(entityState))
    }


    clearAllStorePredicates = () => {
        Object.keys(this.rootStore).forEach(store => {
            if ('setPredicate' in this.rootStore[store]) {
                this.rootStore[store].setPredicate()
            }
        })
    }
}