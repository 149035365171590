import React, {Fragment, FunctionComponent, useContext} from 'react';
import {Link} from 'react-router-dom'
import {RootStoreContext} from "../stores/RootStore";
import {observer} from "mobx-react-lite";
import {Menu, Transition} from '@headlessui/react';
import {classNames} from "../lib/utilities";
import {ArrowRightOnRectangleIcon, UserCircleIcon} from "@heroicons/react/24/outline";

interface OwnProps {}

type Props = OwnProps;

const UserDropDown: FunctionComponent<Props> = () => {
    const rootStore = useContext(RootStoreContext)
    const {user, logout} = rootStore.oidcStore;
    return (<>
        {/* Profile dropdown */}
        <Menu as="div" className="ml-3 relative">
            <div>
                <Menu.Button className="flex justify-center items-centerp-1 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-black text-white md:h-8 md:w-8 h-6 w-6">
                    <span className="sr-only">Open user menu</span>
                    <span className={'m-0 p-0'} aria-hidden={true}>{user?.profile.name?.split(' ').map(letters => letters[0]).join('')}</span>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-md border-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                            {({ active }) => (
                                <a href={`${process.env.REACT_APP_AUTH_PROVIDER_BASE_URL}`}
                                    className={classNames(active ? 'bg-gray-100' : '', 'hover:no-underline flex items-center px-2 py-2 text-sm !text-gray-700 border-b-[1px]')}
                                >Hello,&nbsp;<span className="font-weight-bold">{user?.profile.name?.split(' ')[0]}!</span>
                                </a>
                            )}
                        </Menu.Item>
                    <Menu.Item>
                            {({ active }) => (
                                <a href={`${process.env.REACT_APP_AUTH_PROVIDER_BASE_URL}`} className={classNames(active ? 'bg-gray-100' : '', 'hover:no-underline flex items-center px-2 py-2 text-sm !text-gray-700')}>
                                    <UserCircleIcon className={'h-4 w-4 mr-2'}/>
                                    My Account
                                </a>
                            )}
                        </Menu.Item>
                    {/* <Menu.Item>
                        {({active}) => (
                           <div className={classNames(active ? 'bg-gray-100' : '', 'hover:no-underline flex items-center px-2 py-2 text-sm !text-gray-700')}>
                               <ArrowsRightLeftIcon className={'h-4 w-4 mr-2'}/>
                               Switch State Program
                           </div>
                        )}
                    </Menu.Item> */}
                    <Menu.Item>
                        {({active}) => (
                           <button  onClick={logout} className={classNames(active ? 'bg-gray-100' : '', 'hover:no-underline flex items-center px-2 py-2 text-sm !text-gray-700 w-full text-left')}>
                               <ArrowRightOnRectangleIcon className={'h-4 w-4 mr-2'}/>Sign Out
                           </button>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    </>);
};

export default observer(UserDropDown);
