import axios from "axios";
import {
    IShippingAndTax,
    IShippingAndTaxEnvelope,
    ShippingAndTaxFormValues
} from "../../models/ShippingAndTax/ShippingAndTax";
import {api, responseBody} from "./apiBase.service";

const ShippingAndTaxes = {
    list: (
        params: URLSearchParams
    ): Promise<IShippingAndTaxEnvelope> =>
        axios.get("/ShippingAndTax", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/ShippingAndTax/${id}`),
    create: (ShippingAndTax: ShippingAndTaxFormValues) =>
        api.post(`/ShippingAndTax/`, ShippingAndTax),
    update: (ShippingAndTax: IShippingAndTax) =>
        api.put(`/ShippingAndTax/${ShippingAndTax.id}`, ShippingAndTax),
    delete: (id: string) =>
        api.del(`ShippingAndTax/${id}`)
};

export default ShippingAndTaxes