import {Envelope} from "../generic/Envelope";

export interface IAllocationEnvelope extends Envelope<IAllocation> {}

export interface IAllocation {
    createdByName?: string
    createdOn?: string
    updatedByName?: string
    updatedOn?: string
    id?: string
    programYearId?: string
    programYear?: string
    entityStateId?: string
    entityState?: string
    state?: string
    stateAllocationAmount?: number
    maxAdminstativeTotal?: number
    maxTrainTrainerTotal?: number
    maxOutreachTotal?: number
    usedStateAllocationAmount?: number
    usedAdministrativeTotal?: number
    usedOutreachTotal?: number
    usedTrainTrainerTotal?: number
    maxAdministrativePercent?: number
    maxTrainTheTrainerPercent?: number
    maxOutreachPercent?: number
    currentStatus?: string
}

export class AllocationFormValues implements IAllocation {
    createdByName?: string
    createdOn?: string
    updatedByName?: string
    updatedOn?: string
    id?: string
    programYearId?: string
    programYear?: string
    entityStateId?: string
    entityState?: string
    state?: string
    stateAllocationAmount?: number
    maxAdminstativeTotal?: number
    maxTrainTrainerTotal?: number
    maxOutreachTotal?: number
    usedStateAllocationAmount?: number
    usedAdministrativeTotal?: number
    usedOutreachTotal?: number
    usedTrainTrainerTotal?: number
    maxAdministrativePercent?: number
    maxTrainTheTrainerPercent?: number
    maxOutreachPercent?: number
    currentStatus?: string

    constructor(budget?: IAllocation) {
        if (!!budget) {
            Object.assign(this, budget)
        }
    }
}


export interface IAllocationApproval {
    budgetIds: string[]
    approve: boolean
}

export class AllocationApproval implements IAllocationApproval {
    budgetIds: string[] = []
    approve: boolean = false

    constructor(AllocationReallocationRequestApproval: IAllocationApproval) {
        if (AllocationReallocationRequestApproval) {
            Object.assign(this, AllocationReallocationRequestApproval)
        }
    }
}