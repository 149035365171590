import {Envelope} from "../generic/Envelope";
import {ISubItem} from "../SubItems/SubItem";

export interface IAssessmentEnvelope extends Envelope<IAssessment> {
}

export interface IAssessment {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string
    entityState?: string
    consumerId?: string
    consumerName?: string
    technicianId?: string
    technicianName?: string
    dateRequested?: Date
    datePerformed: Date
    eligibilityCosts?: number
    assessorTime?: number
    assessmentCosts?: number
    auxiliaryAidCosts?: number
    otherCosts?: number
    currentStatus?: string
    isFlagged?: boolean
    travelIds?: string[]
    subItems?: ISubItem[]
    attachmentCount?: number
}

export class AssessmentFormValues implements IAssessment {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string = ''
    entityState?: string
    consumerId?: string
    consumerName?: string
    technicianId?: string
    technicianName?: string
    dateRequested?: Date
    datePerformed: Date = new Date()
    eligibilityCosts?: number
    assessorTime?: number
    assessmentCosts?: number
    auxiliaryAidCosts?: number
    otherCosts?: number
    currentStatus?: string
    isFlagged?: boolean
    travelIds?: string[]
    subItems?: ISubItem[]
    attachmentCount?: number

    constructor(assessment?: IAssessment) {
        if (assessment) {
            Object.assign(this, assessment);
        }
    }
}