import React, {FunctionComponent} from 'react';
import BackArrow from "./generic/BackArrow";
import UserDropDown from "./UserDropDown";
import {observer} from "mobx-react-lite";


interface OwnProps extends Omit<React.HTMLProps<HTMLDivElement>, 'title'>{
    title: string | React.ReactNode
    icon?: any
}

type Props = OwnProps;

const TitleBar: FunctionComponent<Props> = ({title, icon, className, ...rest}) => {

    const Icon = icon

    return (
        <div
            className={`${className ?? ''} flex items-center justify-between bg-white md:px-6 md:py-2 sm:px-4 sm:py-2 py-3 pr-4 pl-2`} {...rest}>
            <h1 className={'mb-0 text-lg  md:text-xl flex items-center'}>
                {/*@ts-ignore*/}
                <span className={'mr-2'}>{!icon ? <BackArrow/> : <Icon className={'h-6 w-6'}/>}</span>
                {title}
            </h1>
            <nav className={'flex'}>
                {/*<UserNotifications className={'mr-2'}/> TODO for v2.*/}
                <UserDropDown/>
            </nav>
        </div>
    );
};

export default observer(TitleBar);
