import React, {lazy, Suspense, useContext} from 'react';
import {Redirect, Route} from "react-router-dom";
import LoadingComponent from "../layout/LoadingComponent";
import {RootStoreContext} from "../stores/RootStore";

const AdministrativeById = lazy(() => import('../pages/program/administrative/AdministrativeById'));
const AdministrativeDashboard = lazy(() => import('../pages/program/administrative/AdministrativeDashboard'));
const AssessmentById = lazy(() => import("../pages/consumers/assessments/AssessmentById"));
const Assessments = lazy(() => import("../pages/consumers/assessments/AssessmentsDashboard"));
const AttestorById = lazy(() => import('../pages/administration/attestors/AttestorById'))
const AuditReportById = lazy(() => import("../pages/reports/auditReport/AuditReportById"));
const AuditReportDashboard = lazy(() => import("../pages/reports/auditReport/AuditReportDashboard"));
const BudgetDashBoard = lazy(() => import('../pages/administration/allocation/AllocationDashboard'))
const Callback = lazy(() => import("./oidc/CallBack"));
const ComingSoon = lazy(() => import("./generic/ComingSoon"));
const ConsumerById = lazy(() => import("../pages/consumers/consumers/ConsumerById"));
const ConsumerSearchDashboard = lazy(() => import("../pages/consumers/consumers/Search"));
const ContactById = lazy(() => import("../pages/administration/contacts/ContactById"));
const ContactsDashboard = lazy(() => import("../pages/administration/contacts/ContactsDashboard"));
const EmbeddedReportById = lazy(() => import('../pages/reports/embeddedReport/EmbeddedReportById'))
const EmbeddedPdfReportById = lazy(() => import('../pages/reports/embeddedReport/EmbeddedPdfReportById'))
const EmbeddedReportDashboard = lazy(() => import('../pages/reports/embeddedReport/EmbeddedReportDashboard'))
const EntityById = lazy(() => import("../pages/administration/entities/EntityById"));
const EntityDashboard = lazy(() => import("../pages/administration/entities/EntityDashboard"));
const EntityRedirect = lazy(() => import("../pages/administration/entities/EntityRedirect"));
const EquipmentById = lazy(() => import("../pages/consumers/equipment/EquipmentById"));
const EquipmentDashboard = lazy(() => import("../pages/consumers/equipment/EquipmentDashboard"));
const HomeDashboard = lazy(() => import("../pages/home/HomeDashboard"));
const Installation = lazy(() => import("../pages/consumers/installations/InstallationDashboard"));
const InstallationById = lazy(() => import("../pages/consumers/installations/InstallationById"));
const MaintenanceRepair = lazy(() => import('../pages/consumers/maintenanceRepair/MaintenanceRepairDashboard'));
const MaintenanceRepairById = lazy(() => import('../pages/consumers/maintenanceRepair/MaintenanceRepairById'))
const MassCostsDashboard = lazy(() => import('../pages/massCost/MassCostDashboard'))
const MergeConflict = lazy(() => import('../pages/consumers/consumers/MergeConflict'))
const NationalOutreachBudgetById = lazy(() => import('../pages/nationalOutreachBudget/NationalOutreachBudgetById'))
const NationalOutreachBudgetDashboard = lazy(() => import('../pages/nationalOutreachBudget/NationalOutreachBudgetDashboard'))
const NoPermissions = lazy(() => import('../pages/NoPermission'))
const NotAuthorized = lazy(() => import("../layout/NotAuthorized"));
const NotFound = lazy(() => import("../layout/NotFound"));
const NotificationDashbord = lazy(() => import ("../pages/administration/notification/NotificationDashboard"))
const OutreachById = lazy(() => import('../pages/program/outreach/OutreachById'));
const OutreachDashboard = lazy(() => import('../pages/program/outreach/OutreachDashboard'));
const PrivateRoute = lazy(() => import("../layout/PrivateRoute"));
const ProgramYearById = lazy(() => import("../pages/administration/program-year/ProgramYearById"));
const ProgramYearDashboard = lazy(() => import("../pages/administration/program-year/ProgramYearDashboard"));
const ReimbursementById = lazy(() => import("../pages/reimbursements/ReimbursementById"));
const ReimbursementDashboard = lazy(() => import("../pages/reimbursements/ReimbursementDashboard"));
const SemiAnnualReportById = lazy(() => import('../pages/reports/semiAnnualReport/SemiAnnualReportById'))
const SemiAnnualReportDashboard = lazy(() => import('../pages/reports/semiAnnualReport/SemiAnnualReportDashboard'))
const ShippingAndTaxById = lazy(() => import('../pages/program/shippingAndTax/ShippingAndTaxById'))
const ShippingAndTaxDashboard = lazy(() => import('../pages/program/shippingAndTax/ShippingAndTaxDashboard'))
const SilentRenew = lazy(() => import("./oidc/SilentRenew"));
const Teapot = lazy(() => import("../layout/Teapot"));
const TechnicianById = lazy(() => import("../pages/administration/technicians/TechnicianById"));
const TechnicianDashboard = lazy(() => import("../pages/administration/technicians/TechnicianDashboard"));
const Test = lazy(() => import('../pages/Test'))
const Training = lazy(() => import('../pages/consumers/training/TrainingDashboard'))
const TrainingById = lazy(() => import('../pages/consumers/training/TrainingById'))
const Travel = lazy(() => import('../pages/consumers/travel/TravelDashboard'))
const TravelById = lazy(() => import('../pages/consumers/travel/TravelById'))
// const UserDashboard = lazy(() => import("../pages/user/UserDashboard"));


const Routes: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const {hasRole, isRLUser, isFCC, roles} = rootStore.oidcStore;

      return (
        <Suspense fallback={<LoadingComponent active content={'Loading application'}/>}>
            {/*HOME this might go away*/}
            <PrivateRoute exact path='/home' component={HomeDashboard}/>

            {/*ADMINISTRATION*/}
            <Route exact path='/administration'><Redirect to={'/administration/allocations'}/></Route>
            <PrivateRoute exact path='/administration/attestor/:id' component={AttestorById}
                          requirements={hasRole('admin')}/>

            <PrivateRoute exact path='/administration/allocations' component={BudgetDashBoard}
                          requirements={hasRole('admin') && (isRLUser || isFCC)}/>

            <PrivateRoute exact path='/administration/program-year' component={ProgramYearDashboard}
                          requirements={hasRole('admin') && (isRLUser || isFCC)}/>
            <PrivateRoute exact path='/administration/program-year/:id' component={ProgramYearById}
                          requirements={hasRole('admin') && (isRLUser || isFCC)}/>

            <PrivateRoute exact path='/administration/entity-management' component={EntityDashboard}
                          requirements={hasRole('admin') && (isRLUser || isFCC)}/>
            <PrivateRoute exact path='/administration/entity-management/:id' component={EntityById}
                          requirements={hasRole('admin') && (isRLUser || isFCC)}/>
            <PrivateRoute exact path='/administration/entity-management/state/:id' component={EntityRedirect}
                          requirements={hasRole('admin') && (isRLUser || isFCC)}/>

            <PrivateRoute exact path='/administration/notification/' component={NotificationDashbord}
                          requirements={hasRole('admin')}/>

            <PrivateRoute exact path='/administration/contacts' component={ContactsDashboard}
                          requirements={hasRole('admin')}/>
            <PrivateRoute exact path='/administration/contact/:id' component={ContactById}
                          requirements={hasRole('admin')}/>

            {/*PROGRAM*/}
            <Route exact path='/program'><Redirect to={'/program/administrative'}/></Route>
            <PrivateRoute exact path='/program/administrative' component={AdministrativeDashboard}/>
            <PrivateRoute exact path='/program/administrative/:id' component={AdministrativeById}/>

            <PrivateRoute exact path='/program/outreach' component={OutreachDashboard}/>
            <PrivateRoute exact path='/program/outreach/:id' component={OutreachById}/>

            <PrivateRoute exact path='/program/shipping-and-tax' component={ShippingAndTaxDashboard}/>
            <PrivateRoute exact path='/program/shipping-and-tax/:id' component={ShippingAndTaxById}/>

              <Route exact path='/program/technician'><Redirect to={'/program/technicians'}/></Route>
            <PrivateRoute exact path='/program/technicians' component={TechnicianDashboard}/>
            <PrivateRoute exact path='/program/technician/:id' component={TechnicianById}/>

            {/*CONSUMERS*/}
            <Route exact path='/services'><Redirect to={'/services/consumers'}/></Route>
            <Route exact path='/services/consumer'><Redirect to={'/services/consumers'}/></Route>
            <PrivateRoute exact path='/services/consumer/:id' component={ConsumerById}/>
            <PrivateRoute exact path='/services/consumers' component={ConsumerSearchDashboard}/>

            <Route exact path='/services/assessment'><Redirect to={'/services/assessments'}/></Route>
            <PrivateRoute exact path='/services/assessments' component={Assessments}/>
            <PrivateRoute exact path='/services/assessment/:id' component={AssessmentById}/>

            <PrivateRoute exact path='/services/equipment' component={EquipmentDashboard}/>
            <PrivateRoute exact path='/services/equipment/:id' component={EquipmentById}/>

            <PrivateRoute exact path='/services/installation' component={Installation}/>
            <PrivateRoute exact path='/services/installation/:id' component={InstallationById}/>

            <PrivateRoute exact path='/services/training' component={Training}/>
            <PrivateRoute exact path='/services/training/:id' component={TrainingById}/>

            <PrivateRoute exact path='/services/maintenance-and-repair' component={MaintenanceRepair}/>
            <PrivateRoute exact path='/services/maintenance-and-repair/:id' component={MaintenanceRepairById}/>

            <PrivateRoute exact path='/services/merge-conflict/:id' component={MergeConflict}/>

            <PrivateRoute exact path='/services/travel' component={Travel}/>
            <PrivateRoute exact path='/services/travel/:id' component={TravelById}/>


            {/*REIMBURSEMENT*/}
            <Route exact path='/reimbursement'><Redirect to={'/reimbursements/search'}/></Route>
            <PrivateRoute exact path='/reimbursements/search' component={ReimbursementDashboard}/>
            <PrivateRoute exact path='/reimbursement/:id' component={ReimbursementById}/>
            <PrivateRoute exact path='/reimbursements/submit' component={ComingSoon}/>

            {/*REPORTS*/}
            <Route exact path='/reports'><Redirect to={'/reports/semi-annual-reports'}/></Route>
            <PrivateRoute exact path='/reports/semi-annual-reports' component={SemiAnnualReportDashboard}/>
            <PrivateRoute exact path='/reports/semi-annual-report/:id' component={SemiAnnualReportById}/>
              <Route exact path='/reports/semiAnnualReports'>
                    <Redirect to={'/reports/semi-annual-reports'}/>
              </Route>
              <Route exact path='/reports/semiAnnualReport/:id' render={(props) => (
                  <Redirect to={`/reports/semi-annual-report/${props.match.params.id}`}/>
              )}></Route>
              <Route exact path='/reports/semiAnnualReport/:id/:filterId' render={(props) => (
                  <Redirect to={`/reports/semi-annual-report/${props.match.params.id}/${props.match.params.filterId}`}/>
              )}></Route>

            <PrivateRoute exact path='/reports/embedded-reports' component={EmbeddedReportDashboard}/>
            <PrivateRoute exact path='/reports/embedded-report/:id' component={EmbeddedReportById}/>
            <PrivateRoute exact path='/reports/embedded-report/:id/:filterId' component={EmbeddedReportById}/>
            <PrivateRoute exact path='/reports/embedded-pdf-report/:id' component={EmbeddedPdfReportById}/>
            <PrivateRoute exact path='/reports/embedded-pdf-report/:id/:filterId' component={EmbeddedPdfReportById}/>
            <Route exact path='/reports/embeddedReports'>
                <Redirect to={'/reports/embedded-reports'}/>
            </Route>
            <Route exact path='/reports/embeddedReport/:id' render={(props) => (
                <Redirect to={`/reports/embedded-report/${props.match.params.id}`}/>
            )}></Route>
            <Route exact path='/reports/embeddedReport/:id/:filterId' render={(props) => (
                <Redirect to={`/reports/embedded-report/${props.match.params.id}/${props.match.params.filterId}`}/>
            )}></Route>

            <PrivateRoute exact path='/reports/audit-reports' component={AuditReportDashboard}/>
            <PrivateRoute exact path='/reports/audit-report/:id' component={AuditReportById}/>


            {/* National Outreach */}
              <Route exact path='/national-outreach-budget'><Redirect to={'/national-outreach/search'}/></Route>
              <Route exact path='/national-outreach-budgets/search'><Redirect to={'/national-outreach/search'}/></Route>
              <PrivateRoute requirements={hasRole('national-outreach-budget')} exact
                            path='/national-outreach/search' component={NationalOutreachBudgetDashboard}/>

              <Route exact path='/national-outreach-budgets/:id([a-z0-9-]{36})' render={(props) =>
                  <Redirect to={`/national-outreach/${props.match.params.id}`}/>
              }/>
              <Route exact path='/national-outreach-budget/:id([a-z0-9-]{36})' render={(props) =>
                  <Redirect to={`/national-outreach/${props.match.params.id}`}/>
              }/>

              <PrivateRoute requirements={hasRole('national-outreach-budget')} exact path='/national-outreach/:id([a-z0-9-]{36})'
                            component={NationalOutreachBudgetById}/>



              {/* MASS COSTS */}
            <PrivateRoute exact path='/mass-costs' component={MassCostsDashboard}/>


            {/*USER ACCOUNT*/}
            {/*<Route exact path='/account'><Redirect to={'/account/my-profile'}/></Route>*/}
            {/*<PrivateRoute exact path='/account/my-profile' component={UserDashboard}/>*/}


            {/*  MISC  */}
            <PrivateRoute exact path='/test' component={Test} requirements={hasRole('developer') && isRLUser}/>
            <Route exact path="/no-permission"><Redirect to={'/no-permissions'}/></Route>
            <Route exact path="/no-permissions" component={NoPermissions}/>


            {/*AUTHENTICATION*/}
            <Route exact path="/signin-callback" component={Callback}/>
            <Route exact path="/silent-renew" component={SilentRenew}/>

            {/*ERROR AND EASTER EGGS*/}
            <Route path='/NotFound' component={NotFound}/>
            <Route path='/NotAuthorized' component={NotAuthorized}/>
            <Route path='/Teapot' component={Teapot}/>

        </Suspense>
    )
}

export default Routes
