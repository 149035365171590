import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {OptionType} from "./form/ComboBox";
import Agent from "../api/Agent";
import {splitCamelCase} from "../lib/utilities";
import Autocomplete from "./Inputs/Autocomplete";

const NotificationTypeAutoComplete: React.FC<any> = ({...rest}) => {
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([] as OptionType[]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams()
                params.append('limit', '10000000')
                params.append('offset', `0`)
                const {modelsDto} = await Agent.NotificationType.list(params)
                setAutoCompleteOptions(modelsDto.map(opt => {
                    return {
                        id: opt.id,
                        name: splitCamelCase(`${opt.type}`.trim())
                    }
                }).sort((optA, optB) => optA.name > optB.name ? 1 : -1))
            } catch (e) {
            }
        }

        fetchData()

    }, []);

    return (
        <Autocomplete {...rest} label="Notification Type" options={autoCompleteOptions}/>
    );
};

export default observer(NotificationTypeAutoComplete);
