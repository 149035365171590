import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RootStoreContext} from "../../../stores/RootStore";

import {ITraining} from "../../../models/Training/Training";
import {arraySum, displayDate, displayDollarAmount} from "../../../lib/utilities";
import {observer} from "mobx-react-lite";
import {TrashIcon} from "@heroicons/react/24/solid";
import TD from "../../table/TD";
import TR from '../../table/TR';
import TBody from "../../table/TBody";
import THead from '../../table/THead';
import Table from "../../table/Table";
import TrainingAutoCompleteInput from "../TrainingAutoCompleteInput";

interface OwnProps {
    onChange?: (e) => (void)
    name?: string
    selected?: string[]
    entityStateId?: string
}

type Props = OwnProps;

const TrainingMultiselect: FunctionComponent<Props> = ({entityStateId, onChange, name, selected}) => {
    //Stores
    const rootStore = useContext(RootStoreContext);
    const {loadNakedTraining, trainingsByDate, getTrainingByIds} = rootStore.trainingStore
    const {newSubItemQueue} = rootStore.subItemStore


    // Local State
    const [availableTrainings, setAvailableTrainings] = useState([] as ITraining[]);
    const [selectedTrainings, setSelectedTrainings] = useState([] as Array<ITraining>);

    // Use Effects
    useEffect(() => {
        loadNakedTraining().then(data => setAvailableTrainings([...data]))
        if (!!selected && selected?.length > 0) {
            getTrainingByIds(selected).then(data => {
                if (!!data) setSelectedTrainings(data)
            })
        }
    }, [trainingsByDate, newSubItemQueue]);

    // Functions
    const handleRemove = (idx): void => {
        setSelectedTrainings((prev) => {
            prev.splice(idx, 1)
            !!onChange && onChange({
                currentTarget: {
                    name,
                    value: prev
                }
            })
            return prev
        })
    }

    const handleSelected = (data): void => {
        if (!!data) {
            let newTraining = availableTrainings.find((training: ITraining) => {
                return training.id === data.id
            })

            // @ts-ignore
            setSelectedTrainings(prev => {
                let tmp = [...prev, newTraining]
                // let tmp = [...prev]
                !!onChange && onChange({
                    currentTarget: {
                        name,
                        value: tmp.map(ids => ids?.id)
                    }
                })
                return tmp
            })
        }
    }

    return (<>
        <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                <TrainingAutoCompleteInput
                    entityStateId={entityStateId}
                    onChange={handleSelected}
                    // @ts-ignore
                    filterIds={selectedTrainings?.map((selected: ITraining) => selected.id) ?? []}
                />
        </div>

        <Table>
            <THead>
                <TR>
                    <TD/>
                    <TD>Consumer</TD>
                    <TD>Technician</TD>
                    <TD>Date Performed</TD>
                    <TD>Training Time</TD>
                    <TD>Total Cost</TD>
                </TR>
            </THead>
            <TBody>
                {selectedTrainings.map((training: ITraining, idx) => {
                    return (<TR key={idx}>
                        <TD><TrashIcon className={'text-red-600 h-6 w-6'} onClick={() => handleRemove(idx)}/></TD>
                        <TD>{training?.consumerName || 'Train-The-Trainer'}</TD>
                        <TD>{training?.technicianName}</TD>
                        <TD>{displayDate(training?.datePerformed)}</TD>
                        <TD>{training?.trainingTime} {!!training?.trainingTime && training?.trainingTime > 1 ? 'hours' : 'hour'}</TD>
                        <TD>{displayDollarAmount(arraySum([
                            training?.trainingLaborCosts,
                            training?.instructionalMaterialCosts,
                            training?.auxiliaryAidCosts,
                            training?.otherExpenses,
                        ]))}</TD>
                    </TR>)
                })}
            </TBody>
        </Table>
    </>);
};

export default observer(TrainingMultiselect);
