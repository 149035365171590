import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";
import ShippingAndTaxAttachmentsTable, {
    ShippingAndTaxAttachmentTableProps
} from "../../../pages/program/shippingAndTax/components/ShippingAndTaxAttachmentsTable";


interface OwnProps extends SlideOverProps, ShippingAndTaxAttachmentTableProps {}

type Props = OwnProps;

// @ts-ignore
const ShippingAndTaxAttachmentsTableModal = NiceModal.create<Props>(({onClose, header, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <ShippingAndTaxAttachmentsTable {...rest}/>
        </SlideOver>
    )
})

export default ShippingAndTaxAttachmentsTableModal;
