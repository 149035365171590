import React, {FunctionComponent, useState} from 'react';
import Input from "../Inputs/Input";

import {ITechnician} from "../../models/Technician";
import Button from "./Button";
import {ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";

interface OwnProps {
    onClick(e): void

    onChange(e): void

    options: Array<ITechnician>
    totalItems: number
    page: number
    pageCount: number

    onPageChange(pageNumber): void

    onCancel(): void
}

type Props = OwnProps;

const AssignTechnician: FunctionComponent<Props> = ({
                                                        onClick,
                                                        onChange,
                                                        options,
                                                        page,
                                                        onPageChange,
                                                        totalItems,
                                                        pageCount,
                                                        onCancel
                                                    }) => {

    const [active, setActive] = useState(-1);


    return (<div className={'flex flex-col h-full'}>
        <span className="sr-only">Use the following inputs to filter assignable technicians. There is not submit button. Technicians will be filtered as you type. Tab to the list below to select Technician.</span>
        <form onSubmit={(e) => e.preventDefault()} autoComplete={'off'} aria-describedby={'techSelectDescription'}>
            <div className={'grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-1'}>
                <Input label={'First Name'} name={'firstName'} type={'search'}
                       onChange={onChange}/>
                <Input label={'Middle Name'} name={'middleName'} type={'search'}
                       onChange={onChange}/>
                <Input label={'Last Name'} name={'lastName'} type={'search'}
                       onChange={onChange}/>
                <Input label={'Company'} name={'company'} type={'search'}
                       onChange={onChange}/>
            </div>
        </form>

        <div className="flex flex-col grow mb-3">
            {totalItems > 0 ? options.map((technician, idx) =>
                <button type="button"
                        className={`focus:ring-2 focus:ring-indigo-500 focus:outline-none focus:bg-blue-600 focus:text-white py-2 px-3 border w-full text-left ${active === idx ? 'active' : ''}`}
                        aria-current={active === idx}
                        onFocus={() => setActive(idx)}
                        onBlur={() => setActive(-1)}
                        onClick={() => onClick(technician.id)}
                >{technician.firstName} {technician.middleName} {technician.lastName} from {technician.company}</button>
            ) : <p>No items available</p>}
        </div>

        <div className="flex gap-4 justify-between">
            <div className="flex gap-4">
                <Button color={'dark'}
                        onClick={() => onPageChange(page)}
                        disabled={page === 0}
                        iconLeft={<ChevronLeftIcon className={'h-6 w-6'}/>}
                >Previous</Button>
                <Button color={'dark'}
                        onClick={() => onPageChange(page + 2)}
                        disabled={page === pageCount - 1}
                        iconRight={<ChevronRightIcon className={'h-6 w-6'}/>}
                >Next</Button>
            </div>
            <div>{/*  Do not Delete  */}</div>
            <div className="flex gap-4">
                <Button color={"red"}
                        onClick={() => {
                            onPageChange(1)
                            onCancel()
                        }}
                        iconLeft={<XMarkIcon className={'h-6 w-6'}/>}
                >Cancel</Button>
            </div>
        </div>
    </div>);
};

export default AssignTechnician;
