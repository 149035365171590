import {api} from "./apiBaseAuthless.service";

const NotificationsBlackList = {
    put: (query: string, optid: string) =>
        api.post(`/Notification/${query}?OptToken=${optid}`),
    delete: (id: string) =>
        api.del(`/Notification/${id}`),
}

export default NotificationsBlackList;
