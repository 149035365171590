import React, {FunctionComponent, useCallback, useContext, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {debounce} from 'lodash'
import {IAssessment} from '../../../../models/Assessment/Assessment';
import {RootStoreContext} from '../../../../stores/RootStore';
import AssignTechnician from "../../../../components/generic/AssignTechnician";

export interface AssignTechnicianToAssessmentProps {
    assessment: IAssessment
}

type Props = AssignTechnicianToAssessmentProps;

const AssignTechnicianToAssessment: FunctionComponent<Props> = ({assessment}) => {
    // Stores
    const rootStore = useContext(RootStoreContext)
    const {editAssessment} = rootStore.assessmentStore
    const {
        clearTechnicians,
        handleChangePage,
        loadTechnicians,
        page,
        pageCount,
        setPredicate,
        technicianCount,
        techniciansList,
    } = rootStore.techniciansStore
    const {closeModal} = rootStore.modalStore

    // Effects
    useEffect(() => {
        loadTechnicians()
    }, [loadTechnicians, clearTechnicians]);

    useEffect(() => {
        return () => {
            setPredicate()
        }
    }, []);


    // Functions
    const handleChange = (e) => {
        const {value, name} = e.currentTarget;
        handleSearch({
            n: name,
            v: value
        })
    };

    const handleSearch = useCallback(
        debounce((value) => {
            const {n, v} = value
            setPredicate(n, v)
        }, 500),
        []
    );

    const assignTechnician = (technicianId) => {

        let newAssessment = {
            ...assessment,
            technicianId
        }

        newAssessment.technicianId = technicianId
        editAssessment(newAssessment).then(() => close())
    }

    const close = () => {
        closeModal('AssignTechnicianToAssessmentModal')
    }
    return (
        <AssignTechnician onClick={assignTechnician} onChange={handleChange} options={techniciansList}
                          totalItems={technicianCount}
                          page={page} pageCount={pageCount} onPageChange={handleChangePage}
                          onCancel={close}/>
    );
};

export default observer(AssignTechnicianToAssessment);
