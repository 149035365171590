import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";
import InstallationAttachmentsTable, {
    InstallationAttachmentTableProps
} from "../../../pages/consumers/installations/components/Installations/InstallationAttachmentsTable";


interface OwnProps extends SlideOverProps, InstallationAttachmentTableProps {}

type Props = OwnProps;

// @ts-ignore
const InstallationAttachmentsTableModal = NiceModal.create<Props>(({onClose, header, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <InstallationAttachmentsTable {...rest}/>
        </SlideOver>
    )
})

export default InstallationAttachmentsTableModal;
