import AdministrativeAttachmentStore from "./Administrative/AdministrativeAttachmentStore";
import AdministrativeNoteStore from "./Administrative/AdministrativeNoteStore";
import AdministrativeStore from "./Administrative/AdministrativeStore";
import AssessmentAttachmentStore from "./Assessments/AssessmentAttachmentStore";
import AssessmentNoteStore from "./Assessments/AssessmentNoteStore";
import AssessmentStore from "./Assessments/AssessmentStore";
import AttestorStore from "./AttestorStore";
import AuditReportStore from "./AuditReport/AuditReportStore";
import AuditReportAttachmentStore from "./AuditReport/AuditReportAttachmentStore";
import ReallocationRequestStore from "./Allocations/ReallocationRequestStore";
import AllocationStore from "./Allocations/AllocationStore";
import CommonStore from "./CommonStore";
import ConsumerAttachmentStore from "./Consumers/ConsumerAttachmentStore";
import ConsumerMergeStore from "./Consumers/ConsumerMergeStore";
import ConsumerNoteStore from "./Consumers/ConsumerNoteStore";
import ConsumerTransferStore from "./Consumers/ConsumerTransferStore";
import ConsumerStore from "./Consumers/ConsumerStore";
import ContactsStore from "./ContactsStore";
import EmbeddedReportStore from "./EmbeddedReportStore";
import EntityStateStore from "./Enitities/EntityStateStore";
import EntityStore from "./Enitities/EntityStore";
import EquipmentAttachmentStore from "./Equipment/EquipmentAttachmentStore";
import EquipmentNoteStore from "./Equipment/EquipmentNoteStore";
import EquipmentStore from "./Equipment/EquipmentStore";
import ErrorStore from "./ErrorStore";
import GenericAttachmentStore from "./GenericAttachmentStore";
import InstallationAttachmentStore from "./Installations/InstallationAttachmentStore";
import InstallationNoteStore from "./Installations/InstallationNoteStore";
import InstallationStore from "./Installations/InstallationStore";
import LineItemAttachmentStore from './LineItemAtachmentStore'
import MaintenanceRepairAttachmentStore from "./MaintenanceRepair/MaintenanceRepairAttachmentStore";
import MaintenanceRepairNoteStore from "./MaintenanceRepair/MaintenanceRepairNoteStore";
import MaintenanceRepairStore from "./MaintenanceRepair/MaintenanceRepairStore";
import MassCostStore from "./MassCostStore";
import ModalStore from "./ModalStore";
import NotificationStore from "./Notifications/NotificationStore";
import NationalOutreachBudgetAttachmentStore from "./NationalOutreachBudget/NationalOutreachBudgetAttachmentStore";
import NationalOutreachBudgetStore from "./NationalOutreachBudget/NationalOutreachBudgetStore";
import NationalOutreachBudgetNoteStore from "./NationalOutreachBudget/NationalOutreachBudgetNoteStore";
import NotificationTypeStore from "./Notifications/NotificationTypeStore";
import OidcStore from "./OidcStore";
import OutreachAttachmentStore from "./Outreach/OutreachAttachmentStore";
import OutreachNoteStore from "./Outreach/OutreachNoteStore";
import OutreachStore from "./Outreach/OutreachStore";
import ProgramYearStore from "./ProgramYearStore";
import ReimbursementClaimLineItemStore from "./Reimbursements/ReimbursementClaimLineItemStore";
import ReimbursementClaimNoteStore from "./Reimbursements/ReimbursementClaimNoteStore";
import ReimbursementClaimStore from "./Reimbursements/ReimbursementClaimStore";
import ReimbursementSpreadSheetStore from "./Reimbursements/ReimbursementSpreadSheetStore";
import SemiAnnualReportAttachmentStore from "./SemiAnnualReport/SemiAnnualReportAttachmentStore";
import SemiAnnualReportStore from "./SemiAnnualReport/SemiAnnualReportStore";
import ShippingAndTaxAttachmentStore from "./ShippingAndTax/ShippingAndTaxAttachmentStore";
import ShippingAndTaxNoteStore from "./ShippingAndTax/ShippingAndTaxNoteStore";
import ShippingAndTaxStore from "./ShippingAndTax/ShippingAndTaxStore";
import EntityStateFilingFrequencyStore from "./EntityStateFilingFrequencyStore";
import StateStore from "./StateStore";
import SubItemAttachmentStore from "./SubItems/SubItemAttachmentStore";
import SubItemStore from "./SubItems/SubItemsStore";
import TechniciansStore from "./TechniciansStore";
import TrainingAttachmentStore from "./Training/TrainingAttachmentStore";
import TrainingNoteStore from "./Training/TrainingNoteStore";
import TrainingStore from "./Training/TrainingStore";
import TravelAttachmentStore from "./Travel/TravelAttachmentStore";
import TravelNoteStore from "./Travel/TravelNoteStore";
import TravelStore from "./Travel/TravelStore";
import {createContext} from "react";
import ReimbursementClaimAttachmentStore from "./Reimbursements/ReimbursementClaimAttachmentStore";

// configure({enforceActions:"always"});
export class RootStore {
    administrativeAttachmentStore: AdministrativeAttachmentStore
    administrativeNoteStore: AdministrativeNoteStore
    administrativeStore: AdministrativeStore
    assessmentAttachmentStore: AssessmentAttachmentStore
    assessmentNoteStore: AssessmentNoteStore
    assessmentStore: AssessmentStore
    attestorStore: AttestorStore
    auditReportStore: AuditReportStore
    auditReportAttachmentStore: AuditReportAttachmentStore
    reallocationRequestStore: ReallocationRequestStore
    allocationStore: AllocationStore
    commonStore: CommonStore
    consumerAttachmentStore: ConsumerAttachmentStore
    consumerMergeStore: ConsumerMergeStore
    consumerNoteStore: ConsumerNoteStore
    consumerTransferStore: ConsumerTransferStore
    consumerStore: ConsumerStore
    contactsStore: ContactsStore
    embeddedReportStore: EmbeddedReportStore
    entityStateStore: EntityStateStore
    entityStore: EntityStore
    equipmentAttachmentStore: EquipmentAttachmentStore
    equipmentNoteStore: EquipmentNoteStore
    equipmentStore: EquipmentStore
    errorStore: ErrorStore
    genericAttachment: GenericAttachmentStore
    installationAttachmentStore: InstallationAttachmentStore
    installationNoteStore: InstallationNoteStore
    installationStore: InstallationStore
    lineItemAttachmentStore: LineItemAttachmentStore
    maintenanceRepairAttachmentStore: MaintenanceRepairAttachmentStore
    maintenanceRepairNoteStore: MaintenanceRepairNoteStore
    maintenanceRepairStore: MaintenanceRepairStore
    massCostStore: MassCostStore
    modalStore: ModalStore
    nationalOutreachBudgetAttachmentStore: NationalOutreachBudgetAttachmentStore
    nationalOutreachBudgetStore: NationalOutreachBudgetStore
    nationalOutreachBudgetNoteStore: NationalOutreachBudgetNoteStore
    notificationStore: NotificationStore
    notificationTypeStore: NotificationTypeStore
    oidcStore: OidcStore
    outreachAttachmentStore: OutreachAttachmentStore
    outreachNoteStore: OutreachNoteStore
    outreachStore: OutreachStore
    programYearStore: ProgramYearStore;
    reimbursementClaimAttachmentStore: ReimbursementClaimAttachmentStore
    reimbursementClaimLineItemStore: ReimbursementClaimLineItemStore
    reimbursementClaimNoteStore: ReimbursementClaimNoteStore
    reimbursementClaimStore: ReimbursementClaimStore
    reimbursementSpreadSheetStore: ReimbursementSpreadSheetStore
    semiAnnualReportAttachmentStore: SemiAnnualReportAttachmentStore
    semiAnnualReportStore: SemiAnnualReportStore
    shippingAndTaxAttachmentStore: ShippingAndTaxAttachmentStore
    shippingAndTaxNoteStore: ShippingAndTaxNoteStore
    shippingAndTaxStore: ShippingAndTaxStore
    entityStateFilingFrequencyStore: EntityStateFilingFrequencyStore
    stateStore: StateStore
    subItemAttacmentStore: SubItemAttachmentStore
    subItemStore: SubItemStore
    techniciansStore: TechniciansStore
    trainingAttachmentStore: TrainingAttachmentStore
    trainingNoteStore: TrainingNoteStore
    trainingStore: TrainingStore
    travelAttachmentStore: TravelAttachmentStore
    travelNoteStore: TravelNoteStore
    travelStore: TravelStore

    constructor() {
        this.administrativeAttachmentStore = new AdministrativeAttachmentStore(this);
        this.administrativeNoteStore = new AdministrativeNoteStore(this);
        this.administrativeStore = new AdministrativeStore(this);
        this.assessmentAttachmentStore = new AssessmentAttachmentStore(this);
        this.assessmentNoteStore = new AssessmentNoteStore(this);
        this.assessmentStore = new AssessmentStore(this);
        this.attestorStore = new AttestorStore(this);
        this.auditReportStore = new AuditReportStore(this);
        this.auditReportAttachmentStore = new AuditReportAttachmentStore(this);
        this.reallocationRequestStore = new ReallocationRequestStore(this)
        this.allocationStore = new AllocationStore(this)
        this.commonStore = new CommonStore(this);
        this.consumerAttachmentStore = new ConsumerAttachmentStore(this);
        this.consumerMergeStore = new ConsumerMergeStore(this);
        this.consumerNoteStore = new ConsumerNoteStore(this);
        this.consumerTransferStore = new ConsumerTransferStore(this);
        this.consumerStore = new ConsumerStore(this);
        this.contactsStore = new ContactsStore(this);
        this.embeddedReportStore = new EmbeddedReportStore(this);
        this.entityStore = new EntityStore(this);
        this.entityStateStore = new EntityStateStore(this, this.entityStore);
        this.equipmentAttachmentStore = new EquipmentAttachmentStore(this);
        this.equipmentNoteStore = new EquipmentNoteStore(this);
        this.equipmentStore = new EquipmentStore(this);
        this.errorStore = new ErrorStore(this);
        this.genericAttachment = new GenericAttachmentStore(this)
        this.installationAttachmentStore = new InstallationAttachmentStore(this);
        this.installationNoteStore = new InstallationNoteStore(this);
        this.installationStore = new InstallationStore(this);
        this.lineItemAttachmentStore = new LineItemAttachmentStore(this)
        this.maintenanceRepairAttachmentStore = new MaintenanceRepairAttachmentStore(this);
        this.maintenanceRepairNoteStore = new MaintenanceRepairNoteStore(this);
        this.maintenanceRepairStore = new MaintenanceRepairStore(this);
        this.massCostStore = new MassCostStore(this)
        this.modalStore = new ModalStore(this);
        this.nationalOutreachBudgetAttachmentStore = new NationalOutreachBudgetAttachmentStore(this);
        this.nationalOutreachBudgetStore = new NationalOutreachBudgetStore(this);
        this.nationalOutreachBudgetNoteStore = new NationalOutreachBudgetNoteStore(this);
        this.notificationStore = new NotificationStore(this);
        this.notificationTypeStore = new NotificationTypeStore(this);
        this.oidcStore = new OidcStore(this);
        this.outreachAttachmentStore = new OutreachAttachmentStore(this);
        this.outreachNoteStore = new OutreachNoteStore(this);
        this.outreachStore = new OutreachStore(this);
        this.programYearStore = new ProgramYearStore(this);
        this.reimbursementClaimAttachmentStore = new ReimbursementClaimAttachmentStore(this)
        this.reimbursementClaimLineItemStore = new ReimbursementClaimLineItemStore(this)
        this.reimbursementClaimNoteStore = new ReimbursementClaimNoteStore(this)
        this.reimbursementClaimStore = new ReimbursementClaimStore(this)
        this.reimbursementSpreadSheetStore = new ReimbursementSpreadSheetStore(this)
        this.semiAnnualReportAttachmentStore = new SemiAnnualReportAttachmentStore(this);
        this.semiAnnualReportStore = new SemiAnnualReportStore(this);
        this.shippingAndTaxAttachmentStore = new ShippingAndTaxAttachmentStore(this);
        this.shippingAndTaxNoteStore = new ShippingAndTaxNoteStore(this);
        this.shippingAndTaxStore = new ShippingAndTaxStore(this);
        this.entityStateFilingFrequencyStore = new EntityStateFilingFrequencyStore(this);
        this.stateStore = new StateStore(this);
        this.subItemAttacmentStore = new SubItemAttachmentStore(this)
        this.subItemStore = new SubItemStore(this)
        this.techniciansStore = new TechniciansStore(this)
        this.trainingAttachmentStore = new TrainingAttachmentStore(this);
        this.trainingNoteStore = new TrainingNoteStore(this);
        this.trainingStore = new TrainingStore(this);
        this.travelAttachmentStore = new TravelAttachmentStore(this);
        this.travelNoteStore = new TravelNoteStore(this);
        this.travelStore = new TravelStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());