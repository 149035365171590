import React, {FunctionComponent} from 'react';

interface OwnProps extends React.HTMLProps<HTMLAnchorElement> {
}

type Props = OwnProps;

const SkipNav: FunctionComponent<Props> = ({children, ...rest}) => {
    return (
        <a {...rest} className="absolute px-3 py-2 transition-all -translate-y-full focus:translate-y-0 bg-indigo-800 text-gray-100 text-base font-medium rounded-b-lg mx-4 focus:outline-none focus:ring-4 focus:ring-indigo-500  hover:no-underline hover:bg-indigo-900 hover:text-white z-10">
            {children}
        </a>
    );
};

export default SkipNav;
