import React, {useContext, useEffect, useState} from 'react'
import {RootStoreContext} from "../../stores/RootStore";
import {observer} from "mobx-react-lite";
import {ComboBoxProps, OptionType} from "./ComboBox";
import Agent from "../../api/Agent";
import {displayDate} from "../../lib/utilities";
import Autocomplete from "../Inputs/Autocomplete";

interface OwnProps extends Omit<ComboBoxProps, 'options'>{
    filterIds?: string[]

}

type Props = OwnProps

const EquipmentAutoCompleteInput: React.FC<Props> = ({
                                                         filterIds,
                                                         ...rest
                                                     }) => {

    // Mobx
    const rootStore = useContext(RootStoreContext)
    const {programYearsById} = rootStore.programYearStore

    // local state
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([] as OptionType[]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams()
                params.append('limit', '10000000')
                params.append('offset', `0`)

                const {modelsDto} = await Agent.ProgramYear.list(params)
                setAutoCompleteOptions(modelsDto.map(opt => {
                    return {
                        id: opt.id,
                        name: `${opt.title ?? ''} [${displayDate(opt.startDate) ?? ''} to ${displayDate(opt.endDate) ?? ''}]`.trim()
                    }
                })
                    .filter(programYear => optionsFilter(programYear))
                    .sort((optA, optB) => optA.name > optB.name ? 1 : -1))
            }catch (e){

            }
        }

        fetchData()
    }, [programYearsById, filterIds]);


    const optionsFilter = (option) => {
        if (filterIds) {
            return !filterIds.includes(option.id)
        } else {
            return true;
        }
    }

    const handleChange = (e) => {
        if (!!rest.onChange) rest?.onChange(e)
    }

    return (
        <Autocomplete {...rest} options={autoCompleteOptions} label={'Program Year'} placeholder={'Program Year 22/23'}  onChange={handleChange}/>
    );
};
export default observer(EquipmentAutoCompleteInput);