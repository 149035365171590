import {ConsumerFormValues, IConsumer, IConsumerEnvelope} from "../../models/Consumer/Consumer";
import {api} from './apiBase.service'

const Consumers = {
    list: (params: URLSearchParams): Promise<IConsumerEnvelope> =>
        api.get("/Consumer", {params: params}),
    create: (consumer: ConsumerFormValues) =>
        api.post(`/Consumer/`, consumer),
    update: (consumer: IConsumer) =>
        api.put(`/Consumer/${consumer.id}`, consumer),
    delete: (id: string) =>
        api.del(`/Consumer/${id}`),
    byId: (consumerId: string) =>
        api.get(`/Consumer/${consumerId}`),

};


export default Consumers