import {AuditReportFormValues, IAuditReportAccept, IAuditReportEnvelope} from "../../models/AuditReport/AuditReport";
import {api} from "./apiBase.service";

const AuditReports = {
    list: (params: URLSearchParams): Promise<IAuditReportEnvelope> =>
        api.get('/AuditReport', {params: params}),
    byId: (id: string) =>
        api.get(`/AuditReport/${id}`),
    create: (auditReport: AuditReportFormValues) =>
        api.post(`/AuditReport/`, auditReport),
    delete: (id: string) =>
        api.del(`/AuditReport/${id}`),
    acceptById: (id: string, auditReportAccept: IAuditReportAccept) =>
        api.put(`/AuditReport/${id}/Accept`, auditReportAccept),
}

export default AuditReports