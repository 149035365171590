import axios from "axios";
import {
    IShippingAndTaxAttachmentEnvelope,
    ShippingAndTaxAttachmentFormValues
} from "../../models/ShippingAndTax/ShippingAndTaxAttachment";
import {api, responseBody} from "./apiBase.service";

const ShippingAndTaxAttachments = {
    list: (params: URLSearchParams): Promise<IShippingAndTaxAttachmentEnvelope> =>
        axios
            .get("/ShippingAndTax/Attachment", {params: params})
            .then(responseBody),
    create: (travelAttachment: ShippingAndTaxAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in travelAttachment) {
            formData.append(key, travelAttachment[key])
        }
        return api.post(`/ShippingAndTax/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/ShippingAndTax/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/ShippingAndTax/Attachment/${id}`),
    byShippingAndTaxId: (travelId: string, params: URLSearchParams): Promise<IShippingAndTaxAttachmentEnvelope> =>
        axios.get(`/ShippingAndTax/${travelId}/Attachment`, {params}).then(responseBody)
};

export default ShippingAndTaxAttachments