import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {ISemiAnnualReportEnvelope} from "../../models/SemiAnnualReport/SemiAnnualReport";
import {IAttachment} from "../../models/generic/Attachment";

const SemiAnnualReport = {
    list: (params: URLSearchParams): Promise<ISemiAnnualReportEnvelope> => {
        return axios
            .get("/SemiAnnualReport", {params: params})
            .then(responseBody)
    },
    byId: (id: string) =>
        api.get(`/SemiAnnualReport/${id}`),
    review: (id: string, submit?: Partial<IAttachment>) => {
        let formData = new FormData();
        for (const key in submit) {
            formData.append(key, submit[key])
        }

        return api.put(`/SemiAnnualReport/${id}/Review`, formData)
    },
    approve: (id: string) =>
        api.put(`/SemiAnnualReport/${id}/Approve`, {approve: true}),
    reject: (id: string) =>
        api.put(`/SemiAnnualReport/${id}/Approve`, {approve: false})
};

export default SemiAnnualReport