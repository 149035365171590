import React, {FunctionComponent, useContext} from 'react';

import {RootStoreContext} from "../../stores/RootStore";
import {IAttachment} from "../../models/generic/Attachment";
import {SubItemAttachmentFormValues} from "../../models/SubItems/SubItemAttachment";
import {observer} from "mobx-react-lite";
import ClickableIcon from "../clickables/ClickableIcon";
import {CheckCircleIcon, PaperClipIcon} from "@heroicons/react/24/solid";
import Table from "../table/Table";
import THead from "../table/THead";
import TR from "../table/TR";
import TD from "../table/TD";
import TBody from '../table/TBody';
import {displayDollarAmount} from "../../lib/utilities";

interface OwnProps {
}

type Props = OwnProps;

const SubItemQueueTable: FunctionComponent<Props> = () => {
    // Mobx Stores
    const rootStore = useContext(RootStoreContext);
    const {openModal} = rootStore.modalStore
    const {createSubItemAttachment} = rootStore.subItemAttacmentStore
    const {
        newSubItemQueue
    } = rootStore.subItemStore


    // Local State


    // Functions
    const subItemAttachmentModal = (subItemId) => {
        openModal('AttachmentModal', {
            header: 'New Cost Attachment', createAttachment: (attachment: IAttachment) => {
                const newSubItemAttachment: SubItemAttachmentFormValues = {...attachment, subItemId}
                createSubItemAttachment(newSubItemAttachment);
            }
        })
    }


    // Mark Up
    if (newSubItemQueue.length <= 0) return <></>

    return (<>
        <div className="mt-4"/>
        <Table>
            <THead>
                <TR>
                    <TD>Category</TD>
                    <TD>Description</TD>
                    <TD>Cost</TD>
                    <TD>Time</TD>
                    <TD>Page #</TD>
                    <TD/>
                </TR>
            </THead>

            <TBody>
                {newSubItemQueue.map(subItem =>
                        // @TODO inline editing
                        <TR>
                            <TD header={'Category'}>{`${subItem.categoryDescription}`}</TD>
                            <TD header={'Description'}>{subItem.description}</TD>
                            <TD header={'Cost'}>{displayDollarAmount(subItem.amount ?? 0)}</TD>
                            <TD header={'Time'}>{subItem.time?.toFixed(2)} hours</TD>
                            <TD header={'Page #'}>{subItem.pageNumber}</TD>
                            <TD className={'text-right'}>
                                {
                                    !!subItem.hasAttachment ?
                                        <CheckCircleIcon className={' h-6 w-6 text-blue-500'}/> :
                                        <ClickableIcon color={'green'}
                                                       tooltip={'add attachment'}
                                                       onClick={() => subItemAttachmentModal(subItem.id)}>
                                            <PaperClipIcon className={'h-6 w-6'}/>
                                        </ClickableIcon>
                                }
                            </TD>
                        </TR>
                )}
            </TBody>
        </Table>
    </>);
};

export default observer(SubItemQueueTable);
