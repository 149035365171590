import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import {EntityFormValues, IEntity} from "../../../../models/Entity/Entity";
import Button from "../../../../components/generic/Button";
import {inputOnChange} from "../../../../lib/utilities";
import Input from "../../../../components/Inputs/Input";
import Select from "../../../../components/generic/Select";
import phoneInputOptions from "../../../../data/cleave/phoneInputOptions";
import zipcodeInputOptions from "../../../../data/cleave/zipcodeInputOptions";
import {CheckIcon} from "@heroicons/react/24/solid";

export interface EntityFormProps {
    entity: IEntity | undefined;
}

type Props = EntityFormProps

const EntityForm: React.FC<Props> = ({entity}) => {
    const rootStore = useContext(RootStoreContext);

    const {createEntity, editEntity} = rootStore.entityStore;
    const {closeModal} = rootStore.modalStore;
    const {getStates} = rootStore.stateStore;
    const [currentEntity, setCurrentEntity] = useState(new EntityFormValues(entity));

    const handleSubmit = async () => {
        if (!entity) {
            let newEntity = {
                ...currentEntity
            };
            await createEntity(newEntity);
        } else {
            await editEntity(currentEntity);
        }
    }

    const handleChange = (e) => {
        inputOnChange(e, setCurrentEntity)
    }

    return (
        <form onSubmit={handleSubmit} className={'flex flex-col justify-between flex-1 h-full'}>
            <div className={'flex-1'}>
                <Input
                    name='id'
                    placeholder='1ab186f2-5a28-4894-af20-773a67a85960'
                    label='Entity ID'
                    onChange={handleChange}
                    value={currentEntity.id}
                    pattern={'^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$'}
                    required
                    options={{
                        blocks: [8, 4, 4, 4, 12],
                        delimiter: '-'
                    }}
                />
                <Input
                    name='name'
                    placeholder='Blind / Biff Association'
                    label='Name'
                    value={currentEntity.name}
                    onChange={handleChange}
                    required
                />

                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-4'}>
                    <Input
                        name='address1'
                        placeholder='123 Hoverboard Ct'
                        label='Address 1'
                        value={currentEntity.address1}
                        onChange={handleChange}
                        required
                    />
                    <Input
                        name='address2'
                        placeholder='Suite 120'
                        label='Address 2'
                        value={currentEntity.address2}
                        onChange={handleChange}
                    />
                </div>

                <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-1'}>
                    <Input
                        name='city'
                        placeholder='Hill Crest'
                        label='City'
                        value={currentEntity.city}
                        onChange={handleChange}
                        required
                    />
                    <Select
                        name='state'
                        placeholder='Select State or Territory'
                        label='State / Territory'
                        options={getStates}
                        value={currentEntity.state}
                        onChange={handleChange}
                        required
                    />
                    <Input
                        name='zipCode'
                        placeholder='90210'
                        label='Zip Code'
                        value={currentEntity.zipCode}
                        onChange={handleChange}
                        options={zipcodeInputOptions}
                        required
                    />
                </div>

                <Input
                    name='phone'
                    placeholder='(978) 555-1953'
                    label='Phone'
                    value={currentEntity.phone}
                    onChange={handleChange}
                    options={phoneInputOptions}
                    pattern='^\(?([2-9][0-8][0-9])\)?[-.●\s]?([2-9][0-9]{2})[-.●\s]?([0-9]{4})$'
                    type={'tel'}
                    required
                />
            </div>

            <div className={'flex justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}>Save</Button>
                <span className="mr-2"/>
                <Button onClick={() => closeModal('EntityModal')} color={'red'}>Cancel</Button>
            </div>
        </form>
    );
};
export default observer(EntityForm);