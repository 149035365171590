import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IAuditReport, IAuditReportAccept} from '../../models/AuditReport/AuditReport'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class AuditReportStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.auditReportRegistry.clear()
                this.loadAuditReports()
            }
        )
    }

    auditReportRegistry = new Map()
    auditReport: IAuditReport | null = null

    auditReportCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.auditReportRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.auditReportCount / this.limit)
    }

    get auditReportList() {
        // @ts-ignore
        return Array.from(this.auditReportRegistry.values()).sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.auditReportRegistry.clear()
        this.loadAuditReports()
    }

    clearAuditReports = () => {
        this.auditReportRegistry.clear()
    }

    loadAuditReports = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const auditReportEnvelope = await Agent.AuditReports.list(this.axiosParams)
            const {modelsDto, modelCount} = auditReportEnvelope

            runInAction(() => {
                this.clearAuditReports()
                modelsDto.forEach((auditReport) => {
                    this.auditReportRegistry.set(auditReport.id, auditReport)
                })
                this.auditReportCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getAuditReportById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let auditReport = await Agent.AuditReports.byId(id)
            runInAction(() => {
                this.auditReport = auditReport
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return auditReport
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createAuditReport = async (auditReport: IAuditReport) => {
        let tmpAuditReport;
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            tmpAuditReport = await Agent.AuditReports.create(auditReport)
            await this.loadAuditReports();
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('AuditReportModal')
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
        return tmpAuditReport;
    }

    acceptAuditReport = async (id: string, auditReportAccept: IAuditReportAccept) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newAuditReport = await Agent.AuditReports.acceptById(id, auditReportAccept)
            runInAction(() => {
                this.loadAuditReports()
                this.rootStore.modalStore.closeModal('AuditReportModal')
            })
            toast.success('Saved')
            return newAuditReport
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteAuditReport = async (auditReport: IAuditReport) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            if (!auditReport.id) throw new Error('Audit Report Id is required to delete record')
            await Agent.AuditReports.delete(auditReport.id)
            runInAction(() => {
                this.auditReportRegistry.delete(auditReport.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.loadAuditReports()
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }



    acceptCheck = (auditReport: IAuditReport) => {
        const {isFCC} = this.rootStore.oidcStore;
        return auditReport.documentCount > 0 && isFCC;
    }
}