import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {ConsumerFormValues, IConsumer} from "../../../../../models/Consumer/Consumer";
import {AttestorFormValues} from "../../../../../models/Attestor";
import EntityStateAutoCompleteInput from "../../../../../components/form/EntityStatesAutoCompleteInput";
import Title from "../../../../../components/generic/Title";
import {inputOnChange, objectDateConverter} from "../../../../../lib/utilities";
import Input from "../../../../../components/Inputs/Input";
import Select from "../../../../../components/generic/Select";
import phoneInputOptions from "../../../../../data/cleave/phoneInputOptions";
import dateInputOptions from "../../../../../data/cleave/dateInputOptions";
import {createStatuses, editingStatuses} from "./comsumerStatus";
import Button from "../../../../../components/generic/Button";
import AttestorsAutoCompleteInput from "../../../../../components/form/AttestorsAutoCompleteInput";
import JSONView from "../../../../../components/generic/JSONView";
import {useLocalStorage} from "../../../../../hooks/useLocalStorage";
import {CheckIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";
import Checkbox from "../../../../../components/Inputs/Checkbox";

export interface ConsumerFormProps {
    consumer: IConsumer | undefined;
    editing?: boolean
}

const ConsumerForm: React.FC<ConsumerFormProps> = ({consumer, editing = false}) => {
    const maxSteps = 2;

    // Stores
    const rootStore = useContext(RootStoreContext)
    const {currentEntityState} = rootStore.commonStore
    const {createConsumer, editConsumer} = rootStore.consumerStore
    const {loadAttestors, getAttestorById} = rootStore.attestorStore
    const {getStates} = rootStore.stateStore
    const {openModal, closeModal} = rootStore.modalStore
    const {entityStatesByName} = rootStore.entityStateStore

    // Local State
    const [step, setStep] = useState(0);
    const [animation, setAnimation] = useState('from-right');
    const [maxStep,] = useState(maxSteps);
    const [currentConsumer, setCurrentConsumer] = useState(new ConsumerFormValues(consumer));
    const [currentAttestor, setCurrentAttestor] = useState(new AttestorFormValues(undefined));
    const [statusOptions, setStatusOptions] = useState(createStatuses);
    const [invalidFields, setInvalidFields] = useState(!consumer?.id ? [
        ['firstName', 'lastName', 'address1', 'city', 'state', 'zipCode', 'dob', 'phone', 'programStatus',
            // 'firstContactDate'
        ],
        ['attestorId'],
        ['entityStateId'],
    ] : [[], [], []]);

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);

    useEffect(() => {
        if (!!currentConsumer.entityStateId) {
            setInvalidFields(invalidFields.map(i => i.filter(j => j !== 'entityStateId')))
        }
    }, [currentConsumer]);

    useEffect(() => {
        objectDateConverter(consumer, setCurrentConsumer)
        if (!!currentConsumer.attestorId) {
            getAttestorById(currentConsumer.attestorId).then((d) => {
                return setCurrentAttestor(d)
            })
        }

        if (!!consumer) {
            setStatusOptions(editingStatuses)
        }
    }, []);

    useEffect(() => {
        loadAttestors()
    }, [loadAttestors]);

    useEffect(() => {
        if (!!consumer)
            setCurrentConsumer(consumer);
    }, [consumer]);

    useEffect(() => {
        if (!currentConsumer.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentConsumer((prev: ConsumerFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates])

    useEffect(() => {
        if (!currentConsumer.entityStateId && (!!entityStatesByName && entityStatesByName.length === 1)) {
            setCurrentConsumer((prev: ConsumerFormValues) => {
                return {
                    ...prev,
                    entityStateId: currentEntityState.id || '',
                    entityState: currentEntityState.id ? `${currentEntityState.stateName} (${currentEntityState.entityName})` : ''
                }
            })
        }
    }, [currentEntityState])


    // Functions
    const prev = () => {
        setAnimation('to-left')
        setTimeout(() => {
            let inc = (step - 1) === 2 && editing ? 2 : 1
            setStep(step > 0 ? step - inc : 0)
            setAnimation('from-right')
        }, 600)
    }

    const next = () => {
        setAnimation('to-left')
        setTimeout(() => {
            let inc = (step + 1) === 2 && editing ? 2 : 1
            setStep(step < maxStep ? step + inc : 3)
            setAnimation('from-right')
        }, 600)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!currentConsumer.id) {
            let newConsumer = {
                ...currentConsumer
            }
            delete newConsumer.id
            createConsumer(newConsumer);
        } else if (currentConsumer.programStatus === 'Ineligible') {
            openModal('ConfirmationModal', {
                header: 'Confirm Ineligibility',
                onConfirm: () => editConsumer(currentConsumer),
                body: <>
                    <p>By marking this person as ineligible, their information will be removed from the Centralized
                        Database if they have never received services from the program. This will protect the PII of
                        individuals who are not and have never been part of the program.</p>

                    <p>Are you sure you want to mark this record as ineligible?</p>
                </>
            })
        } else {
            editConsumer(currentConsumer);
        }
    };


    const noPhoneReq = (checked) => {
        let tmpInvalidFields = [...invalidFields]
        if (checked) {
            tmpInvalidFields[0].splice(tmpInvalidFields[0].indexOf('phone'), 1)
        } else {
            tmpInvalidFields[0].push('phone')
        }

        setInvalidFields(tmpInvalidFields)
    }

    const handleChange = (e) => {
        const {name, value} = e.currentTarget
        inputOnChange(e, setCurrentConsumer)

        handleInputValidation(e.currentTarget);

        if (name === 'attestorId') {
            getAttestorById(value).then((d) =>
                setCurrentAttestor(d)
            ).catch(err => console.error(err))
        }
    }

    const handleInputValidation = (target) => {
        const {name, value, validity} = target
        const tmpInvalidFields = [...invalidFields]
        const invalidFieldExists = !!tmpInvalidFields[step] ? tmpInvalidFields[step].includes(name) : false
        let isValid = true;

        switch (name) {
            case 'attestorId' || 'entityStateId':
                if (!value) isValid = false;
                break;
            default:
                if (!!validity) isValid = validity.valid;
        }

        if (isValid && invalidFieldExists) {
            const indexOfField = tmpInvalidFields[step].indexOf(name)
            tmpInvalidFields[step].splice(indexOfField, 1)
            setInvalidFields(tmpInvalidFields)
        }
        if (!isValid && !invalidFieldExists) {
            tmpInvalidFields[step].push(name)
            setInvalidFields(tmpInvalidFields)
        }
    }

    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    const handleNoPhone = (e) => {
        const {checked} = e.currentTarget;
        if (checked) {
            noPhoneReq(checked)
            setCurrentConsumer(prev => {
                return {...prev, hasNoPhone: checked, phone: ''}
            })
            const invalidPhoneIdx = invalidFields[0].indexOf('phone')
            if (invalidPhoneIdx >= 0)
                invalidFields[0].splice(invalidPhoneIdx, 1)
        } else {
            noPhoneReq(checked)
            handleChange(e)
        }
    }

    return (
        <form autoComplete={'off'}
              onSubmit={handleSubmit}
              className={'flex flex-col flex-1 h-full overflow-x-hidden'}
        >
            <div className={'flex-1'}>
                <div className={`${step === 0 ? animation : 'hide'}`}>
                    <Title as={'h3'} size={'2xl'}>Basic Info</Title>
                    <div className={'grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-1'}>
                        <div className={'w-full'}><Input
                            name='firstName'
                            placeholder={'Emmett'}
                            required
                            label='First Name'
                            value={currentConsumer.firstName}
                            onChange={handleChange}
                        /></div>
                        <div className={'w-full'}><Input
                            // style={{width: '5ch'}}
                            name='middleName'
                            placeholder={'Lathrop'}
                            label='Middle name'
                            value={currentConsumer.middleName}
                            onChange={handleChange}
                        /></div>
                        <div className={'w-full'}><Input
                            name='lastName'
                            placeholder={'Brown'}
                            required
                            label='Last Name'
                            value={currentConsumer.lastName}
                            onChange={handleChange}
                        /></div>
                    </div>


                    <div className={'grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-1'}>
                        <div className={'w-full'}>
                            <Input label={'Date of Birth'}
                                   onChange={handleChange}
                                   value={`${currentConsumer.dob ?? ''}`}
                                   name={'dob'}
                                   options={dateInputOptions}
                                   errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                                   pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                                   placeholder={'10/26/1985'}
                                   required
                            />
                        </div>
                        <div className={'w-full'}>
                            <Input
                                type="tel"
                                name='phone'
                                placeholder={'(323) 555-8864'}
                                pattern={'^\\(?([2-9][0-8][0-9])\\)?[-.●\\s]?([2-9][0-9]{2})[-.●\\s]?([0-9]{4})$'}
                                required={!currentConsumer.hasNoPhone}
                                disabled={currentConsumer.hasNoPhone}
                                label='Phone'
                                value={currentConsumer.phone}
                                onChange={handleChange}
                                options={phoneInputOptions}
                            />

                            <Checkbox name={'hasNoPhone'} label={'Consumer has no phone #'}
                                      onChange={handleNoPhone} checked={currentConsumer.hasNoPhone}/>
                        </div>

                        <div className={'w-full'}><Input
                            type="email"
                            name='email'
                            placeholder={'radioactiveman@future.org'}
                            label='Email'
                            value={currentConsumer.email}
                            onChange={handleChange}
                        /></div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                        {/*<div className='w-full'>*/}
                        {/*    <Input label={'First Contact Date'}*/}
                        {/*           onChange={handleChange}*/}
                        {/*           value={`${currentConsumer.firstContactDate ?? ''}`}*/}
                        {/*           name={'firstContactDate'}*/}
                        {/*           options={dateInputOptions}*/}
                        {/*           errMsg={'Please provide a valid Date e.g. "09/17/1972"'}*/}
                        {/*           pattern="^(\d{2})\/(\d{2})\/(\d{4})$"*/}
                        {/*           placeholder={'10/26/1985'}*/}
                        {/*           required*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className='w-full'>
                            <Select options={statusOptions}
                                    value={currentConsumer.programStatus || ''}
                                    name={'programStatus'}
                                    label='Program Status'
                                    onChange={handleChange}
                                    required
                            />
                        </div>
                    </div>


                    <Title as={'h3'} size={'2xl'} className={'mt-4'}>Address</Title>
                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <div className={'w-full'}><Input
                            name='address1'
                            placeholder={'1640 Riverside Drive'}
                            required
                            label='Address 1'
                            value={currentConsumer.address1}
                            onChange={handleChange}
                        /></div>
                        <div className={'w-full'}><Input
                            name='address2'
                            placeholder={'Apt 1'}
                            label='Address 2'
                            value={currentConsumer.address2}
                            onChange={handleChange}
                        /></div>
                    </div>

                    <div className={'grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-1'}>
                        <div className={'w-full'}><Input
                            name='city'
                            placeholder={'Hill Valley'}
                            required
                            label='City'
                            value={currentConsumer.city}
                            onChange={handleChange}
                        /></div>

                        <div className={'w-full'}>
                            <Select
                                className={'min-w-64'}
                                name='state'
                                required
                                value={currentConsumer.state || 'Select State/Territory'}
                                placeholder={'Select State/Territory'}
                                options={getStates}
                                onChange={handleChange}
                                label={'State/Territory'}
                            /></div>

                        <div className={'w-full'}><Input
                            name='zipCode'
                            placeholder='91905'
                            label='Zip Code'
                            value={currentConsumer.zipCode}
                            onChange={handleChange}
                            options={{
                                numericOnly: true,
                                blocks: [5]
                            }}
                            required
                        /></div>
                    </div>
                </div>


                <div className={`${step === 1 ? animation : 'hide'}`}>
                    <Title as={'h3'} size={'2xl'}>Attestor</Title>
                    <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                        <div className={'w-full'}>
                            <AttestorsAutoCompleteInput
                                onChange={({id}) => handleAutoComplete(id, 'attestorId')}
                                required
                                name={'attestor'}
                                displayValue={currentConsumer.attestorName ?? consumer?.attestorName}
                            />
                        </div>
                    </div>

                    {!!currentConsumer.attestorId && <>
                        <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-1">
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'} label={'Title'}
                                       value={currentAttestor.title}
                                       name={'title'}/></div>
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'First Name'} value={currentAttestor.firstName}
                                       name={'firstName'}/>
                            </div>
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'Middle Name'}
                                       value={currentAttestor.middleName}
                                       name={'middleName'}/></div>
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'Last Name'} value={currentAttestor.lastName}
                                       name={'lastName'}/>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'Business Name'} value={currentAttestor.businessName}
                                       name={'businessName'}/>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'Email Address'} value={currentAttestor.email}
                                       name={'email'}/>
                            </div>
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'Phone #'} value={currentAttestor.phone}
                                       name={'phone'}/></div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'Address #1'} value={currentAttestor.address1}
                                       name={'address1'}/>
                            </div>
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'Address #2'} value={currentAttestor.address2}
                                       name={'address2'}/>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-1">
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'} label={'City'}
                                       value={currentAttestor.city}
                                       name={'city'}/></div>
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'} label={'State'}
                                       value={currentAttestor.state}
                                       name={'state'}/></div>
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'Postal / Zip Code'}
                                       value={currentAttestor.zipCode}
                                       name={'zipCode'}/></div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                            <div className='w-full'>
                                <Input disabled className={'bg-light disabled'}
                                       label={'Entity'}
                                       value={currentAttestor.entity}
                                       name={'entity'}/></div>
                        </div>
                    </>}

                </div>

                <div className={`${step === 2 ? animation : 'hide'}`}>
                    <Title as={'h3'} size={'2xl'}>ID & State Program</Title>
                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <div className={'w-full'}>
                            <Input
                                name='customId'
                                placeholder='183245'
                                label='Custom ID'
                                value={currentConsumer.customId}
                                className={'mb-3'}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={'w-full'}>
                            <EntityStateAutoCompleteInput
                                required
                                isLocked={!currentConsumer.id && !!localEntityStates && localEntityStates.length === 1}
                                onChange={({id}) => handleAutoComplete(id, 'entityStateId')}
                                name={'entityStateId'}
                                placeholder={'California'}
                                displayValue={(currentConsumer?.entityStateId) ? (currentConsumer?.entityState || 'State Program already selected') : 'Select State Program'}
                            />
                        </div>
                    </div>
                </div>

                <JSONView data={currentConsumer}/>
                <JSONView data={invalidFields}/>
            </div>


            <div className={'flex justify-between'}>
                <div>
                    <Button color='dark' iconLeft={<ChevronLeftIcon className={'h-5 w-5'}/>} onClick={prev}
                            disabled={step === 0}>Back</Button>
                    <span className={'m-2'}/>

                    <Button color='dark' id={'consumerNextBtn'} iconRight={<ChevronRightIcon className={'h-5 w-5'}/>}
                            onClick={next}
                            disabled={step === maxStep || invalidFields[step].length !== 0}>Next</Button>
                </div>

                <div>{/* DO NOT DELETE */}</div>

                <div>
                    <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'} type={'submit'}
                            disabled={step !== maxSteps || invalidFields[maxSteps].length > 0}>Save</Button>
                    <span className={'m-2'}/>
                    <Button
                        onClick={() => {
                            closeModal('ConsumerModal')
                        }}
                        iconLeft={<XMarkIcon className={'h-6 w-6'}/>}
                        color="red">Cancel</Button>
                </div>
            </div>

        </form>
    );
};
export default observer(ConsumerForm);