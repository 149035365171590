import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import agent from '../../api/Agent'
import {
    IReimbursementClaim,
    IReimbursementClaimApproval,
    IReimbursementClaimPayment,
    IReimbursementClaimSubmitAttachment,
    ReimbursementClaimFormValues
} from '../../models/Reimbursement/ReimbursementClaim'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class ReimbursementClaimStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.reimbursementClaimRegistry.clear()
                this.loadReimbursementClaim()
            }
        )
    }

    reimbursementClaimRegistry = new Map()
    reimbursementClaim: IReimbursementClaim | null = null

    reimbursementClaimCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.reimbursementClaimCount / this.limit)
    }

    get reimbursementClaimsList() {
        return Array.from(this.reimbursementClaimRegistry.values())
    }

    getReimbursementClaim = (id: string) => {
        return this.reimbursementClaimRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.reimbursementClaimRegistry.clear()
        this.loadReimbursementClaim()
    }

    clearReimbursementClaim = () => {
        this.reimbursementClaimRegistry.clear()
    }

    loadReimbursementClaim = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const reimbursementClaimsEnvelope = await Agent.ReimbursementClaim.list(this.axiosParams)
            const {modelsDto, modelCount} = reimbursementClaimsEnvelope

            runInAction(() => {
                this.clearReimbursementClaim();
                modelsDto.length > 0 && modelsDto.forEach((reimbursementClaim: IReimbursementClaim) => {
                    this.reimbursementClaimRegistry.set(reimbursementClaim.id, reimbursementClaim)
                })
                this.reimbursementClaimCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createReimbursementClaim = async (reimbursementClaim: IReimbursementClaim) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newReimbursementClaim = await Agent.ReimbursementClaim.create(reimbursementClaim)
            await this.loadReimbursementClaim()
            runInAction(() => {
                this.reimbursementClaimRegistry.set(newReimbursementClaim.id, newReimbursementClaim)
                this.rootStore.modalStore.closeModal('ReimbursementClaimModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newReimbursementClaim
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getReimbursementClaimById = async (reimbursementClaimId) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let currentReimbursementClaim
        try {
            currentReimbursementClaim = await agent.ReimbursementClaim.byId(reimbursementClaimId)
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return currentReimbursementClaim
    }

    editReimbursementClaim = async (reimbursementClaim: ReimbursementClaimFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newReimbursementClaim = await Agent.ReimbursementClaim.update(reimbursementClaim)
            runInAction(() => {
                this.clearReimbursementClaim()
                this.reimbursementClaimRegistry.set(newReimbursementClaim.id, newReimbursementClaim)
                this.rootStore.modalStore.closeModal('ReimbursementClaimModal')
            })
            toast.success('Saved')
            return newReimbursementClaim
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    approveReimbursementClaim = async (reimbursementClaimId: string, reimbursementClaimApproval: IReimbursementClaimApproval) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newReimbursementClaim = await Agent.ReimbursementClaim.approve(reimbursementClaimId, reimbursementClaimApproval)
            await this.loadReimbursementClaim()
            runInAction(() => {
                this.reimbursementClaimRegistry.set(newReimbursementClaim.id, newReimbursementClaim)
            this.rootStore.modalStore.closeModal('ApproveRejectClaimModal')
            })
            toast.success('Saved')
            return newReimbursementClaim
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
            window.location.reload();
        }
    }

    payReimbursementClaim = async (reimbursementClaimId: string, reimbursementClaimPayment: IReimbursementClaimPayment) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newReimbursementClaim = await Agent.ReimbursementClaim.payment(reimbursementClaimId, reimbursementClaimPayment)
            runInAction(() => {
                this.clearReimbursementClaim()
                this.reimbursementClaimRegistry.set(newReimbursementClaim.id, newReimbursementClaim)
                this.rootStore.modalStore.closeModal('ReimbursementClaimPaymentModal')
            })
            toast.success('Saved')

            return newReimbursementClaim
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
            window.location.reload();
        }
    }

    calculateReimbursementClaim = async (reimbursementClaimId: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newReimbursementClaim = await Agent.ReimbursementClaim.calculate(reimbursementClaimId)
            runInAction(() => {
                this.reimbursementClaimRegistry.set(newReimbursementClaim.id, newReimbursementClaim)
                this.clearReimbursementClaim()
            })
            toast.success('Complete!')
            return newReimbursementClaim
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    submitReimbursementClaim = async (reimbursementClaimId: string, reimbursementClaimSubmit: IReimbursementClaimSubmitAttachment) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newReimbursementClaim = await Agent.ReimbursementClaim.submit(reimbursementClaimId, reimbursementClaimSubmit)
            runInAction(() => {
                this.loadReimbursementClaim()
                this.reimbursementClaimRegistry.set(newReimbursementClaim.id, newReimbursementClaim)
                this.rootStore.modalStore.closeModal('ReimbursementClaimSubmitModal')
            })
            toast.success('Saved')
            return newReimbursementClaim
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
            window.location.reload();
        }
    }

}