import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {AssessmentFormProps} from "../../../pages/consumers/assessments/components/AssessmentForm";
import {modalClose} from "../genericFunctions";
import EntityStateFilingFrequencyForm
    from "../../../pages/administration/entities/components/EntityStateFilingFrequencyForm";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, AssessmentFormProps {
}

type Props = OwnProps;

// @ts-ignore
const EntityStateFilingFrequencyModal = NiceModal.create<Props>(({ onClose, header, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <EntityStateFilingFrequencyForm {...rest}/>
        </SlideOver>
    )
})

export default EntityStateFilingFrequencyModal;
