import './assets/styles/main.sass';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Router} from 'react-router-dom';
import App from './layout/App';
import 'react-toastify/dist/ReactToastify.min.css';
import History from './AppHistory';

const container = document.getElementById('root')
// @ts-ignore
const root = createRoot(container);

root.render(
    <Router history={History}>
        <App/>
    </Router>
)

