import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IInstallation, InstallationFormValues} from '../../models/Installation/Installation'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import {displayDate} from "../../lib/utilities";


export default class InstallationStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.installationRegistry.clear()
                this.loadInstallation()
            }
        )
    }

    // Observables
    installationRegistry = new Map()
    installation: IInstallation | null = null
    editMode = false

    installationCount = 0
    page = 0
    limit = 5

    predicate = new Map()


// Computed Values
    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.installationCount / this.limit)
    }

    get installationsList() {
        // @ts-ignore
        return Array.from(this.installationRegistry.values())//.sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get installationsByDate() {
        return Array.from(this.installationRegistry.values())
        // .sort(
        //     (a, b) => b.dateCreated - a.dateCreated//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        // )
    }

    get installationAutoCompleteOptions() {
        let Equipments: {
            key: string
            value: string
        }[] = []


        this.installationsList.map((installation: IInstallation) => {
            Equipments.push({
                key: `${installation.id}`,
                value: `${installation.equipmentName} ${displayDate(installation.datePerformed)}`
            })
        })
        return Equipments;
    };


    // Actions
    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.installationRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    getInstallation = (id: string) => {
        return this.installationRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.installationRegistry.clear()
        this.loadInstallation()
    }

    clearInstallation = () => {
        this.installationRegistry.clear()
    }

    loadInstallation = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const installationsEnvelope = await Agent.Installations.list(this.axiosParams)
            const {modelsDto, modelCount} = installationsEnvelope

            runInAction(() => {
                this.clearInstallation()
                modelsDto.forEach((installation: IInstallation) => {
                    this.installationRegistry.set(installation.id, installation)
                })
                this.installationCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }
    loadNakedInstallation = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)

            const installationsEnvelope = await Agent.Installations.list(params)
            const {modelsDto} = installationsEnvelope
            return modelsDto
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getInstallationById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let installation = await Agent.Installations.byId(id)
            runInAction(() => {
                this.installation = installation
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return installation
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getInstallationByIds = async (ids: string[]) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '1000000')
            params.append('offset', `0`)

            for (let id of ids) {
                params.append('ids', id)
            }

            let {modelsDto} = await Agent.Installations.list(params)

            return modelsDto
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createInstallation = async (installation: IInstallation) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newInstallation = await Agent.Installations.create(installation)
            runInAction(() => {
                this.installationRegistry.set(newInstallation.id, newInstallation)
                this.rootStore.modalStore.closeModal('InstallationModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newInstallation
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editInstallation = async (installation: InstallationFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedInstallation = await Agent.Installations.update(installation)
            runInAction(() => {
                this.installationRegistry.set(updatedInstallation.id, updatedInstallation)
                this.rootStore.modalStore.closeModal('InstallationModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return updatedInstallation
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteInstallation = async (installation: IInstallation) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            if (!installation.id) throw  new Error('Installation Id required to delete record')
            await Agent.Installations.delete(installation.id)
            runInAction(() => {
                this.installationRegistry.delete(installation.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


}