import React, {FunctionComponent, useContext} from 'react';
import AttachmentForm from "./AttachmentForm";
import {RootStoreContext} from "../../stores/RootStore";

export interface LineItemAttachmentFormProps {
    assessmentIds?: string[],
    reimbursementClaimIds?: string[],
    equipmentIds?: string[],
    installationIds?: string[],
    maintenanceRepairIds?: string[],
    outreachIds?: string[],
    shippingAndTaxIds?: string[],
    trainingIds?: string[],
    travelIds?: string[],
    consumerIds?: string[],
    subItemIds?: string[],
    administrativeAttachmentIds?: string[]

    createFunction?(attachment): any | void
}

type Props = LineItemAttachmentFormProps;

const LineItemAttachmentForm: FunctionComponent<Props> = ({
                                                              assessmentIds,
                                                              reimbursementClaimIds,
                                                              equipmentIds,
                                                              installationIds,
                                                              maintenanceRepairIds,
                                                              outreachIds,
                                                              shippingAndTaxIds,
                                                              trainingIds,
                                                              travelIds, consumerIds,
                                                              subItemIds,
                                                              administrativeAttachmentIds,
                                                              createFunction,
                                                          }) => {
    const rootStore = useContext(RootStoreContext);
    const {createLineItemAttachment} = rootStore.lineItemAttachmentStore


    // Functions
    const createAttachment = (attachment) => {
        let tmpAttachment = {
            ...attachment, assessmentIds,
            reimbursementClaimIds,
            equipmentIds,
            installationIds,
            maintenanceRepairIds,
            outreachIds,
            shippingAndTaxIds,
            trainingIds,
            travelIds,
            consumerIds,
            subItemIds,
            administrativeAttachmentIds
        }
        Object.keys(tmpAttachment).forEach((k) => tmpAttachment[k] == null && delete tmpAttachment[k]);
        if (!!createFunction) {
            createFunction(tmpAttachment)
        } else {
            createLineItemAttachment(tmpAttachment)
        }
    }

    return (<>
        <AttachmentForm createAttachment={createAttachment}/>
    </>);
};

export default LineItemAttachmentForm;
