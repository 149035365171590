import axios from "axios";
import {
    IMaintenanceRepairAttachmentEnvelope,
    MaintenanceRepairAttachmentFormValues
} from "../../models/MaintenanceRepair/MaintenanceRepairAttachment";
import {api, responseBody} from "./apiBase.service";

const MaintenanceRepairAttachments = {
    list: (params: URLSearchParams): Promise<IMaintenanceRepairAttachmentEnvelope> =>
        axios
            .get("/MaintenanceRepair/Attachment", {params: params})
            .then(responseBody),
    create: (maintenanceRepairAttachment: MaintenanceRepairAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in maintenanceRepairAttachment) {
            formData.append(key, maintenanceRepairAttachment[key])
        }
        return api.post(`/MaintenanceRepair/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/MaintenanceRepair/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/MaintenanceRepair/Attachment/${id}`),
    byMaintenanceRepairId: (maintenanceRepairId: string, params: URLSearchParams): Promise<IMaintenanceRepairAttachmentEnvelope> =>
        axios.get(`/MaintenanceRepair/${maintenanceRepairId}/Attachment`, {params}).then(responseBody)
};

export default MaintenanceRepairAttachments