import React, {FunctionComponent, useEffect} from 'react';

import {IEquipment} from "../../../models/Equipment/Equipment";
import {arraySum, displayDate, displayDollarAmount, summarizeText} from "../../../lib/utilities";
import {observer} from "mobx-react-lite";
import {useAPIPager} from "../../../hooks/useAPIPager";
import Agent from "../../../api/Agent";
import {useSelectTable} from "../../../hooks/useSelectTable";
import Table from '../../table/Table';
import THead from '../../table/THead';
import TR from '../../table/TR';
import TBody from '../../table/TBody';
import TD from "../../table/TD";
import Pagination from "../../table/Pagination";
import Skeleton from "../../generic/Skeleton";
import Checkbox from "../../Inputs/Checkbox";

interface OwnProps {
    onChange?: (e: string[]) => (void)
    selected?: string[]
    predicates?: Map<any, any>
}

type Props = OwnProps;

const EquipmentSelectTable: FunctionComponent<Props> = ({onChange, selected, predicates}) => {
    // Custom Hooks
    const {items, count, handleChangePage, isLoading} = useAPIPager(Agent.Equipments, predicates)
    const {handleCheckAll, handleCheck, selectedItems} = useSelectTable(selected);

    // Use Effects
    useEffect(() => {
        !!onChange && onChange(selectedItems)
    }, [selectedItems]);

    return (<>
        <span className="sr-only" id="equipmentSelectTable">Equipment</span>
        <Table className={'max-w-full'} aria-describedby={'equipmentSelectTable'}>
            <THead>
                <TR>
                    <TD className={'th min-w-max whitespace-nowrap'}>
                        {items.length > 0 && <>
                            <span id="selectAllEquipment" className="sr-only">Select all</span>
                            <Checkbox
                                checked={items.every(i => selectedItems?.includes(i.id))}
                                className="border-gray-300 rounded"
                                onChange={(e) => handleCheckAll(e, items)}
                                label={<span className={'sr-only'}>Select</span>}
                            /></>}
                    </TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Equipment</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Consumer</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Date Received</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Cost</TD>
                </TR>
            </THead>
            <TBody>
                {items?.map((equipment: IEquipment, idx) => {
                    return (<TR key={idx}>
                        {isLoading ? <TD colSpan={100}><Skeleton/></TD> : <>
                            <TD className={'td min-w-max whitespace-nowrap'}>
                                <Checkbox checked={selectedItems.includes(equipment.id ?? '')}
                                          label={<span className={'sr-only'}>Select</span>}
                                          onChange={handleCheck} value={equipment.id} />
                            </TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{summarizeText(equipment?.equipmentName)}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{equipment?.consumerName || 'Inventory'}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{displayDate(equipment?.dateReceived)}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>
                                {displayDollarAmount(arraySum([
                                    equipment.accessoriesCosts,
                                    equipment.alertDevicesCosts,
                                    equipment.nonNdbedpCosts,
                                    equipment.otherCosts,
                                    equipment.otsHardwareCosts,
                                    equipment.partialPaymentCosts,
                                    equipment.softwareApplicationsCosts,
                                    equipment.specializedEquipmentCosts,
                                    equipment.warrantyCosts,
                                    equipment.returnedCosts,
                                ]))}
                            </TD>
                        </>}
                    </TR>)
                })}
            </TBody>
        </Table>
        <Pagination totalRows={count} onPageChange={handleChangePage} onRowChange={handleChangePage}/>
    </>);
};

export default observer(EquipmentSelectTable);
