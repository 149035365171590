import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import EquipmentAutoCompleteInput from '../../../../../components/form/EquipmentAutoCompleteInput';
import {RootStoreContext} from '../../../../../stores/RootStore';
import {XMarkIcon} from "@heroicons/react/24/solid";

interface OwnProps {
    consumerId?: string;
    value?: string[];
    onChange: (equipmentIds: string[]) => void;
}

type Props = OwnProps;

interface EquipmentListItem {
    equipmentId: string;
    equipmentName: string;
}

const EquipmentMultiselect: FunctionComponent<Props> = ({onChange, value, consumerId}) => {
    // Stores
    const rootStore = useContext(RootStoreContext);
    const {getEquipmentsByIds} = rootStore.equipmentStore;


    // Local State and Refs
    const isDirty = useRef<boolean>(false);
    const [currentEquipmentIds, setCurrentEquipmentIds] = useState([] as string[]);
    const [currentEquipments, setEquipments] = useState([] as EquipmentListItem[]);


    // USE EFFECTS
    useEffect(() => {
        if (!!value && value.length > 0 && !isDirty.current) {
            isDirty.current = true;
            setCurrentEquipmentIds(value)
            getEquipmentsByIds(value)
                .then(equipments =>
                    //@ts-ignore
                    setEquipments(() =>
                        equipments?.map(equipment => {
                            return {
                                equipmentId: equipment.id,
                                equipmentName: equipment.equipmentName
                            }
                        })))
        }
    }, [])

    useEffect(() => {
        !!onChange && onChange(currentEquipmentIds)
    }, [currentEquipmentIds])


    // FUNCTIONS
    const handleEquipment = (equipmentId, name, equipmentName) => {
        if (!!equipmentId) {
            let tmp = [...currentEquipments];
            tmp.push({equipmentId, equipmentName});
            setEquipments(tmp);
            let tmpIds = [...currentEquipmentIds];
            tmpIds.push(equipmentId)
            setCurrentEquipmentIds(tmpIds)

        }
    }

    const removeEquipment = (equipmentId) => {
        setEquipments(prev => prev.filter((e) => e.equipmentId !== equipmentId));
        setCurrentEquipmentIds(prev => prev.filter((id) => id !== equipmentId))
    }


    return (<>
        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                <EquipmentAutoCompleteInput
                    consumerId={consumerId} filterIds={currentEquipmentIds}
                    onChange={({id, name}) => handleEquipment(id, 'equipmentId', name)}/>
        </div>

        <div className={'flex gap-4 flex-wrap'}>{currentEquipments.map((e) => {
            return (e?.equipmentName ?
                <span
                    className={'font-medium text-base bg-black text-white focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 focus:outline-none inline-flex py-2  px-3 rounded gap-2 whitespace-nowrap'}>
                    <span className={'font-weight-bold'}>{e.equipmentName}</span>
                    <span role={'button'}>
                        <span className="sr-only">remove item</span>
                        <XMarkIcon onClick={() => removeEquipment(e.equipmentId)} role={'button'} aria-label={'delete'}
                              className={'h-6 w-6 text-red-600'}/></span>
                </span>
                : <></>)
        })}</div>
    </>);
};

export default observer(EquipmentMultiselect)
