import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import Button from "../../../../components/generic/Button";
import Select from "../../../../components/generic/Select";
import {inputOnChange} from "../../../../lib/utilities";
import EntityStateAutoCompleteInput from "../../../../components/form/EntityStatesAutoCompleteInput";
import ProgramYearAutoCompleteInput from "../../../../components/form/ProgramYearAutoCompleteInput";
import {IEntityStateFilingFrequency} from "../../../../models/EntityStateFilingFrequency";
import JSONView from "../../../../components/generic/JSONView";
import {freqOptions} from "../../../../data/entity/filingFrequencyOptions";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface EntityStateFilingFrequencyFormProps {
}

type Props = EntityStateFilingFrequencyFormProps

const EntityStateFilingFrequencyForm: React.FC<Props> = () => {
    // Mobx
    const rootStore = useContext(RootStoreContext);
    const {createEntityStateFilingFrequency} = rootStore.entityStateFilingFrequencyStore;
    const {closeModal} = rootStore.modalStore;


    // Local State
    const [currentEntityStateFilingFrequency, setCurrentEntityStateFilingFrequency] = useState({} as IEntityStateFilingFrequency);


    // Functions
    const handleSubmit = (e) => {
        e.preventDefault()
        createEntityStateFilingFrequency(currentEntityStateFilingFrequency)
    };
    
    const handleInput = (e) => inputOnChange(e, setCurrentEntityStateFilingFrequency)

    const handleAutoComplete = (e) => {
        handleInput({currentTarget: {...e}})
    }


    return (
        <form onSubmit={handleSubmit} className={'flex flex-col justify-between gap-3 grow h-full'}>
            <div className={'flex gap-4 flex-col'}>
                <EntityStateAutoCompleteInput name={'entityStateId'} onChange={({id}) => handleAutoComplete({
                    name: 'entityStateId',
                    value: id
                })} value={currentEntityStateFilingFrequency.entityStateId}/>
                <ProgramYearAutoCompleteInput name={'programYearId'} onChange={({id}) => handleAutoComplete({
                    name: 'programYearId',
                    value: id
                })} value={currentEntityStateFilingFrequency.programYearId}/>
                <Select name={'filingFrequency'} options={freqOptions} label={'Filing Frequency'}
                        value={currentEntityStateFilingFrequency.filingFrequency} onChange={handleInput}
                />
                    <JSONView data={currentEntityStateFilingFrequency}/>
            </div>

            <div className={'text-right'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}>Save</Button>
                <span className="mr-2"/>
                <Button name={'cancel'} iconLeft={<XMarkIcon className={'h-5 w-5'}/>} onClick={() => closeModal('EntityStateFilingFrequencyModal')}
                        color={'red'}
                >Cancel</Button>
            </div>
        </form>
);
};
export default observer(EntityStateFilingFrequencyForm);