import React, {FunctionComponent} from 'react';

import {Link} from "react-router-dom";

interface OwnProps {
    current?: boolean
    icon?: React.ReactNode | any
    as?: string | React.ReactNode | typeof Link
    children?: React.ReactNode

    [x: string]: any
}

type Props = OwnProps;

const SidebarItem: FunctionComponent<Props> = ({current = false, icon, as = 'span', children, ...rest}) => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    let Tag

    if (!!as) Tag = as

    const Icon = icon

    return (
        <div>
            <Tag {...rest}
                 className={classNames(
                     current
                         ? 'bg-gray-800'
                         : 'bg-gray-900 text-gray-300 hover:bg-gray-800 hover:text-gray-200',
                     'group w-full flex items-center pl-2 py-2 text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:text-gray-200 text-gray-200 transition-all'
                 )}
            >
                {/*// @ts-ignore*/}
                {!!icon && <Icon
                    className={
                        'mr-3 h-5 w-5 item-center !flex items-center text-gray-200'
                    }
                    aria-hidden="true"
                />}
                {children}
            </Tag>
        </div>
    );
};

export default SidebarItem;
