import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {
    IReimbursementClaimNote,
    IReimbursementClaimNoteEnvelope,
    ReimbursementClaimNoteFormValues
} from "../../models/Reimbursement/ReimbursementClaimNote";

const ReimbursementClaimNotes = {
    list: (params: URLSearchParams): Promise<IReimbursementClaimNoteEnvelope> =>
        axios
            .get("/ReimbursementClaim/Note", {params: params})
            .then(responseBody),
    create: (ReimbursementClaim: ReimbursementClaimNoteFormValues) =>
        api.post(`/ReimbursementClaim/Note`, ReimbursementClaim),
    update: (ReimbursementClaim: IReimbursementClaimNote) =>
        api.put(`/ReimbursementClaim/Note/${ReimbursementClaim.id}`, ReimbursementClaim),
    delete: (id: string) =>
        api.del(`/ReimbursementClaim/Note/${id}`),
    byId: (id: string) => api.get(`/ReimbursementClaim/Note/${id}`),
    byReimbursementClaimId: (reimbursementClaimId: string, params: URLSearchParams): Promise<IReimbursementClaimNoteEnvelope> =>
        axios.get(`/ReimbursementClaim/${reimbursementClaimId}/Note`, {params: params})
            .then(responseBody),
};

export default ReimbursementClaimNotes