import {Envelope} from "../generic/Envelope";

export interface IConsumerTransferEnvelope extends Envelope<IConsumerTransfer> {
}

export interface IConsumerTransfer {
    id: string;
    consumerId: string;
    createdByName: string;
    createdOn: string;
    updatedByName?: string;
    updatedOn?: string;
    originalEntityStateId: string;
    originalEntityState: string;
    originalStateId: string;
    originalState: string;
    transferToStateId: string;
    transferToState: string;
    transferToEntityStateId?: string;
    transferToEntityState?: string;
    firstName: string;
    middleName: string;
    lastName: string;
}

//empty form model
export class ConsumerTransferFormValues implements IConsumerTransfer {
    id: string = '';
    consumerId: string = '';
    createdByName: string = '';
    createdOn: string = '';
    updatedByName?: string;
    updatedOn?: string;
    originalEntityStateId: string = '';
    originalEntityState: string = '';
    originalStateId: string = '';
    originalState: string = '';
    transferToStateId: string = '';
    transferToState: string = '';
    transferToEntityStateId?: string;
    transferToEntityState?: string;
    firstName: string = '';
    middleName: string = '';
    lastName: string = '';

    constructor(consumerTransfer?: IConsumerTransfer) {
        if (consumerTransfer) {
            Object.assign(this, consumerTransfer);
        }
    }
}