import React, {FunctionComponent, useContext} from 'react';
import {Disclosure} from '@headlessui/react'

import {RootStoreContext} from "../../stores/RootStore";
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/24/solid";

interface OwnProps {
    data: any
    title?: string
}

type Props = OwnProps;

const JSONView: FunctionComponent<Props> = ({data, title = 'View Json'}) => {
    const rootStore = useContext(RootStoreContext);
    const {hasRole, isRLUser} = rootStore.oidcStore;
    return (
        <>
            {hasRole('developer') && isRLUser ?
                <div className="w-full p-2 mx-auto block print:hidden">
                    <Disclosure>
                        {({open}) => (
                            <>
                                <Disclosure.Button
                                    className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 border mb-1">
                                    <span>{title}</span>
                                    {open ? <ChevronDownIcon className={'h-6 w-6'}/> : <ChevronRightIcon className={'h-6 w-6'}/>}
                                </Disclosure.Button>

                                <Disclosure.Panel
                                    className="px-4 pt-4 pb-2 text-sm text-gray-500 bg-gray-100 rounded-lg max-h-96 overflow-auto">
                                <pre>
                                    {JSON.stringify(data, null, 2)}
                                </pre>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </div> : <></>
            }
        </>
    );
};

export default JSONView;







