import axios from "axios";
import {
    AssessmentNoteFormValues,
    IAssessmentNote,
    IAssessmentNoteEnvelope
} from "../../models/Assessment/AssessmentNote";
import {api, responseBody} from "./apiBase.service";

const AssessmentNotes = {
    list: (params: URLSearchParams): Promise<IAssessmentNoteEnvelope> =>
        axios
            .get("/Assessment/Note", {params: params})
            .then(responseBody),
    create: (Assessment: AssessmentNoteFormValues) =>
        api.post(`/Assessment/Note`, Assessment),
    update: (Assessment: IAssessmentNote) =>
        api.put(`/Assessment/Note/${Assessment.id}`, Assessment),
    delete: (id: string) =>
        api.del(`/Assessment/Note/${id}`),
    byId: (id: string) => api.get(`/Assessment/Note/${id}`),
    byAssessmentId: (equipmentId: string, params: URLSearchParams): Promise<IAssessmentNoteEnvelope> =>
        axios.get(`/Assessment/${equipmentId}/Note`, {params: params})
            .then(responseBody),
};

export default AssessmentNotes