import React, {FunctionComponent, useCallback, useContext, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {debounce} from 'lodash'
import {ITraining} from "../../../../../models/Training/Training";
import AssignTechnician from "../../../../../components/generic/AssignTechnician";

export interface AssignTechnicianToTrainingProps {
    training: ITraining
}

type Props = AssignTechnicianToTrainingProps;

const AssignTechnicianToTraining: FunctionComponent<Props> = ({training}) => {
    // Stores
    const rootStore = useContext(RootStoreContext)
    const {editTraining} = rootStore.trainingStore
    const {
        setPredicate,
        loadTechnicians,
        clearTechnicians,
        techniciansList,
        handleChangePage,
        page,
        pageCount,
        technicianCount,
    } = rootStore.techniciansStore
    const {closeModal} = rootStore.modalStore

    // Effects
    useEffect(() => {
        loadTechnicians()
    }, [loadTechnicians, clearTechnicians]);

    useEffect(() => {
        return () => {
            setPredicate()
        }
    }, []);


    // Functions
    const handleChange = (e) => {
        const {value, name} = e.currentTarget;
        handleSearch({
            n: name,
            v: value
        })
    };

    const handleSearch = useCallback(
        // tslint:disable-next-line
        debounce((value) => {
            const {n, v} = value
            setPredicate(n, v)
        }, 500),
        []
    );

    const assignTechnician = (technicianId) => {
        let newTraining = {
            ...training,
            technicianId
        }

        newTraining.technicianId = technicianId
        editTraining(newTraining).then(() => close())
    }


    const close = () => closeModal('AssignTechnicianToTrainingModal')
    return (
        <AssignTechnician onClick={assignTechnician} onChange={handleChange} options={techniciansList}
                          totalItems={technicianCount}
                          page={page} pageCount={pageCount} onPageChange={handleChangePage}
                          onCancel={close}/>
    );
};

export default observer(AssignTechnicianToTraining);
