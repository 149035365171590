import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {AdministrativeFormValues, IAdministrative} from '../../models/Administrative/Administrative'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class AdministrativeStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.administrativeRegistry.clear()
                this.loadAdministrative()
            }
        )

    }

    administrativeRegistry = new Map()
    administrative: IAdministrative | null = null
    editMode = false

    administrativeCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.administrativeRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.administrativeCount / this.limit)
    }

    get administrativesByDate() {
        // @ts-ignore
        return Array.from(this.administrativeRegistry.values())//.sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get administrativesList() {
        return Array.from(this.administrativeRegistry.values())
    }

    getAdministrative = (id: string) => {
        return this.administrativeRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.administrativeRegistry.clear()
        this.loadAdministrative()
    }

    clearAdministrative = () => {
        this.administrativeRegistry.clear()
    }

    loadAdministrative = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const administrativesEnvelope = await Agent.Administratives.list(this.axiosParams)
            const {modelsDto, modelCount} = administrativesEnvelope

            runInAction(() => {
                this.clearAdministrative()
                modelsDto.forEach((administrative: IAdministrative) => {
                    this.administrativeRegistry.set(administrative.id, administrative)
                })
                this.administrativeCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    loadNakedAdministrative = async (predicates?: {}) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)

            if (!!predicates) for (let key in predicates) {
                params.append(key, predicates[key])
            }

            const assessmentsEnvelope = await Agent.Administratives.list(params)
            const {modelsDto} = assessmentsEnvelope

            return modelsDto
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getAdministrativeById = async (id: string) => {

        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let administrative = await Agent.Administratives.byId(id)
            runInAction(() => {
                this.administrative = administrative
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return administrative
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getAdministrativesByIds = async (ids: string[]) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            if (!ids) {
                return []
            }

            const params = new URLSearchParams()
            params.append('limit', '1000000')
            params.append('offset', `0`)

            for (let id of ids) {
                params.append('ids', id)
            }

            let {modelsDto} = await Agent.Administratives.list(params)

            return modelsDto
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


    createAdministrative = async (administrative: IAdministrative) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newAdministrative = await Agent.Administratives.create(administrative)
            this.loadAdministrative()
            runInAction(() => {
                this.administrativeRegistry.set(newAdministrative.id, newAdministrative)
                this.rootStore.modalStore.closeModal('AdministrativeModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newAdministrative
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editAdministrative = async (administrative: AdministrativeFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newAdministrative = await Agent.Administratives.update(administrative)
            runInAction(() => {
                this.administrativeRegistry.set(newAdministrative.id, newAdministrative)
                this.rootStore.modalStore.closeModal('AdministrativeModal')
            })
            toast.success('Saved')
            return newAdministrative
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteAdministrative = async (administrative: IAdministrative) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.Administratives.delete(administrative.id ?? '')
            runInAction(() => {
                this.administrativeRegistry.delete(administrative.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


}