import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {ITravelAttachment, TravelAttachmentFormValues} from '../../models/Travel/TravelAttachment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class TravelAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.travelAttachmentRegistry.clear()
                this.loadTravelAttachment()
            }
        )
        reaction(
            () => this.currentTravelId,
            () => {
                this.page = 0
                this.travelAttachmentRegistry.clear()
                !!this.currentTravelId ? this.getTravelAttachmentsByTravelId() : this.loadTravelAttachment()
            }
        )
    }

    travelAttachmentRegistry = new Map()
    travelAttachment: ITravelAttachment | null = null
    editMode = false


    currentTravelId = '';
    travelAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentTravelId = (travelId: string) => {
        this.currentTravelId = travelId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!!predicate) {
            if (predicate !== 'all') {
                this.predicate.delete(predicate)
                if (!!value) this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
            } else {
                this.predicate.clear()
            }
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.travelAttachmentCount / this.limit)
    }

    get travelAttachmentsById() {
        return Array.from(this.travelAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get travelAttachmentsByDate() {
        return Array.from(this.travelAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getTravelAttachment = (id: string) => {
        return this.travelAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.travelAttachmentRegistry.clear()
        this.loadTravelAttachment()
    }

    clearTravelAttachment = () => {
        this.travelAttachmentRegistry.clear()
    }

    loadTravelAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentTravelId) {
                const travelAttachmentsEnvelope = await Agent.TravelAttachments.list(this.axiosParams);
                const {modelsDto, modelCount} = travelAttachmentsEnvelope;

                runInAction(() => {
                    this.clearTravelAttachment();
                    modelsDto.forEach((travelAttachment: ITravelAttachment) => {
                        this.travelAttachmentRegistry.set(travelAttachment.id, travelAttachment)
                    })
                    this.travelAttachmentCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getTravelAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let travelAttachment = await Agent.TravelAttachments.byId(id)
            runInAction(() => {
                this.travelAttachment = travelAttachment
                this.travelAttachmentRegistry.set(travelAttachment.id, travelAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return travelAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getTravelAttachmentsByTravelId = async (travelId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let travelAttachmentEnvelope = await Agent.TravelAttachments.byTravelId(travelId || this.currentTravelId, this.axiosParams)
            const {modelsDto, modelCount} = travelAttachmentEnvelope
            runInAction(() => {
                this.clearTravelAttachment()
                modelsDto.forEach(travelAttachment => {
                    this.travelAttachmentRegistry.set(travelAttachment.id, travelAttachment)
                })

                this.travelAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createTravelAttachment = async (travelAttachment: TravelAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newAttachment = await Agent.TravelAttachments.create(travelAttachment)

            await (!!this.currentTravelId ? this.getTravelAttachmentsByTravelId() : this.loadTravelAttachment())
            runInAction(() => {
                this.travelAttachmentRegistry.set(newAttachment.id, newAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('AttachmentModal')
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteTravelAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.TravelAttachments.delete(id)
            runInAction(() => {
                this.travelAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('TravelAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }
    }

}