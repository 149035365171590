import React, {FunctionComponent, useContext, useEffect, useState} from 'react'
import {RootStoreContext} from "../../stores/RootStore";
import {observer} from "mobx-react-lite";
import {ComboBoxProps, OptionType} from "./ComboBox";
import Agent from "../../api/Agent";
import Autocomplete from "../Inputs/Autocomplete";
import {useIdle} from "react-use";

interface OwnProps extends Omit<ComboBoxProps, 'options'> {
    filterIds?: string[]
    consumerId?: string
    defaultDemo?: boolean
    canAddNew?: boolean
}

type Props = OwnProps

const AttestorsAutoCompleteInput: FunctionComponent<Props> = ({
                                                                  defaultDemo = true,
                                                                  filterIds,
                                                                  consumerId,
                                                                  canAddNew = true,
                                                                  ...rest
                                                              }) => {

    // Mobx
    const rootStore = useContext(RootStoreContext)
    const {attestorsById} = rootStore.attestorStore

    // local state
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([] as OptionType[]);
    const isIdle = useIdle(5000)

    const fetchData = async () => {
        try {
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)
            const {modelsDto} = await Agent.Attestors.list(params)
            setAutoCompleteOptions(() => {
                const opts = modelsDto.map(opt => {
                    return {
                        id: opt.id,
                        name: `${opt.title ?? ''} ${opt.firstName ?? ''} ${opt.middleName ?? ''} ${opt.lastName ?? ''}`.trim()
                    }
                }).sort((optA, optB) => optA.name > optB.name ? 1 : -1)

                if (canAddNew) opts.unshift({id: 'create-new-attestor', name: '+ New Attestor'})
                return opts
            })
        } catch (e) {

        }
    }


    useEffect(() => {
        fetchData()
    }, [attestorsById]);

    useEffect(() => {
      if (!isIdle) fetchData()
    }, [isIdle]);

    const createNew = () => {
        window.open(`/Home?modal=AttestorModal&mp=${JSON.stringify({header: "New Attestor"})}&closeWindow=true`, '_blank')
    }

    const handleChange = (e) => {
        if (e.id === 'create-new-attestor')
            return createNew()
        // if (e.id === 'refresh-attestor-list')
        //     return refresh()
        return !!rest.onChange && rest?.onChange(e)
    }

    return (
        <Autocomplete {...rest} options={autoCompleteOptions} label={'Attestor'} onChange={handleChange}>
        </Autocomplete>
    );
};
export default observer(AttestorsAutoCompleteInput);