import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import {
    ReimbursementClaimPaymentFormProps
} from "../../../pages/reimbursements/components/claims/ReimbursementClaimPaymentForm";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";
import NationalOutreachBudgetPaymentForm
    from "../../../pages/nationalOutreachBudget/components/NationalOutreachBudgetPaymentForm";


interface OwnProps extends SlideOverProps, ReimbursementClaimPaymentFormProps {
}

type Props = OwnProps;

// @ts-ignore
const NationalOutreachBudgetPaymentModal = NiceModal.create<Props>(({nationalOutreachBudget, onClose, header, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <NationalOutreachBudgetPaymentForm NationalOutreachBudget={nationalOutreachBudget} {...rest}/>
        </SlideOver>
    )
})

export default NationalOutreachBudgetPaymentModal;
