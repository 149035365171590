import axios from "axios";
import {AttestorFormValues, IAttestor, IAttestorEnvelope} from "../../models/Attestor";
import {api, responseBody} from "./apiBase.service";

const Attestors = {
    list: (
        params: URLSearchParams
    ): Promise<IAttestorEnvelope> => {
        return axios
            .get("/Attestor", {params: params})
            .then(responseBody)

    },
    create: (attestor: AttestorFormValues) =>
        api.post(`/Attestor/`, attestor),
    update: (attestor: IAttestor) =>
        api.put(`/Attestor/${attestor.id}`, attestor),
    delete: (id: string) =>
        api.del(`/Attestor/${id}`),
    byId: (attestorId: string) =>
        api.get(`/Attestor/${attestorId}`),

};

export default Attestors