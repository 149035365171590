import {RootStore} from "../RootStore";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import agent from "../../api/Agent";

export default class NotificationTypeStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
    }

    predicate = new Map();
    NotificationTypeRegistry = new Map();
    NotificationTypeCount = 0;
    page = 0
    limit = 10000

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get NotificationTypeById() {
        return Array.from(this.NotificationTypeRegistry.values()).sort((a, b) => b.id - a.id)
    }

    setPage = (page: number) => {
        this.page = page
    }
    setLimit = (limit: number) => {
        this.limit = limit
    }

    loadNotificationTypes = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const NotificationTypeEnvelope = await agent.NotificationType.list(this.axiosParams)
            let {modelsDto, modelCount} = NotificationTypeEnvelope;
            runInAction(() => {
                modelsDto.forEach((NotificationType) => {
                    this.NotificationTypeRegistry.set(NotificationType.id, NotificationType)
                })
                this.NotificationTypeCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }
}