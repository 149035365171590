export const freqOptions = [
    {
        text: 'Semi-Annual',
        value: 'Semi-Annual',
        key: 'Semi-Annual',
    }, {
        text: 'Quarterly',
        value: 'Quarterly',
        key: 'Quarterly',
    }, {
        text: 'Monthly',
        value: 'Monthly',
        key: 'Monthly',
    },
]
