import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {ITravelNote, TravelNoteFormValues} from '../../models/Travel/TravelNote'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'

export default class TravelNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.travelNoteRegistry.clear()
                this.loadTravelNote()
            }
        )
        reaction(
            () => this.currentTravelId,
            () => {

                this.page = 0
                this.travelNoteRegistry.clear()
                this.loadTravelNote()
            }
        )
    }

    travelNoteRegistry = new Map()
    travelNote: ITravelNote | null = null
    editMode = false


    currentTravelId = '';
    travelNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentTravelId = (travelId: string) => {
        this.currentTravelId = travelId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!!predicate) {
            if (predicate !== 'all') {
                this.predicate.delete(predicate)
                if (!!value) this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
            } else {
                this.predicate.clear()
            }
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.travelNoteCount / this.limit)
    }

    get travelNotesById() {
        return Array.from(this.travelNoteRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get travelNotesByDate() {
        return Array.from(this.travelNoteRegistry.values()).sort(
            (a, b) => dayjs(b.updatedOn).isBefore(a.updatedOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getTravelNote = (id: string) => {
        return this.travelNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.travelNoteRegistry.clear()
        this.loadTravelNote()
    }

    clearTravelNote = () => {
        this.travelNoteRegistry.clear()
    }

    loadTravelNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentTravelId && typeof this.currentTravelId !== undefined) {
                const travelNotesEnvelope = await Agent.TravelNotes.byTravelId(this.currentTravelId, this.axiosParams);
                const {modelsDto, modelCount} = travelNotesEnvelope;
                runInAction(() => {
                    modelsDto.forEach((travelNote: ITravelNote) => {
                        this.travelNoteRegistry.set(travelNote.id, travelNote)
                    })
                    this.travelNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getTravelNoteById = async (id: string) => {
        let travelNote = this.getTravelNote(id)
        if (travelNote) {
            this.travelNote = travelNote
            return travelNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                travelNote = await Agent.TravelNotes.byId(id)
                runInAction(() => {
                    this.travelNote = travelNote
                    this.travelNoteRegistry.set(travelNote.id, travelNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return travelNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createTravelNote = async (travelNote: ITravelNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newTravelNote = await Agent.TravelNotes.create(travelNote)
            await this.loadTravelNote()
            runInAction(() => {
                this.travelNoteRegistry.set(newTravelNote.id, newTravelNote)
                this.rootStore.modalStore.closeModal('NoteModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editTravelNote = (travelNote: TravelNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.TravelNotes.update(travelNote).then((updatedTravelNote) => {
            runInAction(() => {
                this.travelNoteRegistry.set(updatedTravelNote.id, updatedTravelNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(err => {
            console.error(err)
            toast.error('Problem updating travel note')
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteTravelNote = async (travelNote: ITravelNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.TravelNotes.delete(travelNote.id)
            runInAction(() => {
                this.travelNoteRegistry.delete(travelNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }

    }


}