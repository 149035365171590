import {ISubItemAttachmentEnvelope} from "../../models/SubItems/SubItemAttachment";
import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {NotificationFormValues} from "../../models/Notification/Notification";

const Notifications = {
    list: (params: URLSearchParams): Promise<ISubItemAttachmentEnvelope> =>
        axios
            .get("/Notification", {params: params})
            .then(responseBody),
    create: (notification: NotificationFormValues) => {
        return api.post(`/Notification`, notification)
    },
    delete: (id: string) =>
        api.del(`/Notification/${id}`),
}

export default Notifications;