import React, {FunctionComponent} from 'react'
import {Switch} from '@headlessui/react'
import {getClassName} from "../../lib/getClassName";

interface OwnProps extends Omit<React.HTMLProps<any>, 'onChange'> {
    checked: boolean
    label?: string
    labelPosition?: 'top' | 'bottom' | 'left' | 'right'
    name?: string
    onChange(checked: boolean): void
}

type Props = OwnProps

const Toggle: FunctionComponent<Props> = ({label, labelPosition = 'right', className, ...rest}) => {
    // functions
    const classNames = (...classes) => classes.filter(Boolean).join(' ');

    const containerClasses = getClassName({
        'flex': true,
        'items-center': labelPosition === 'left' || labelPosition === 'right',
        'flex-col': labelPosition === 'top' || labelPosition === 'bottom',
    })

    const labelClasses = getClassName({
        'mb-0 ml-2': labelPosition === 'right',
        'mb-0 mt-2': labelPosition === 'bottom',
        'mb-0 mr-2': labelPosition === 'left',
        'mb-2': labelPosition === 'top',
    })

    return (<Switch.Group>
        <div className={`${containerClasses} ${className}`}>
            {label && (labelPosition === 'left' || labelPosition === 'top') &&
                <Switch.Label className={`${labelClasses}`}>{label}</Switch.Label>}
            {/* @ts-ignore */}
            <Switch
                type={'checkbox'}
                {...rest}
                className={classNames(
                    rest.checked ? 'bg-blue-800' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                )}
            >
            <span
                className={classNames(
                    rest.checked ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white transform ring-0 transition ease-in-out duration-200'
                )}
            >
        <span
            className={classNames(
                rest.checked ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
        >
          <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
            className={classNames(
                rest.checked ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
        >
          <svg className="h-3 w-3 text-blue-800" fill="currentColor" viewBox="0 0 12 12">
            <path
                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
          </svg>
        </span>
      </span>
            </Switch>

            {label && (labelPosition === 'right' || labelPosition === 'bottom') &&
                <Switch.Label className={`${labelClasses}`}>{label}</Switch.Label>}
        </div>
    </Switch.Group>)
}

export default Toggle
