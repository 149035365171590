import axios from "axios";
import {responseBody} from "./apiBase.service";
import {INotificationEnvelope} from "../../models/Notification/NotificationType";

const NotificationType = {
    list: (params: URLSearchParams): Promise<INotificationEnvelope> =>
        axios
            .get("/NotificationType/", {params: params})
            .then(responseBody)
}

export default NotificationType;