import React, {FunctionComponent, useContext, useState} from 'react';
import {RootStoreContext} from "../../../../stores/RootStore";
import TextArea from "../../../../components/form/TextArea";
import {inputOnChange} from "../../../../lib/utilities";
import Button from "../../../../components/generic/Button";
import {IReimbursementClaimApproval} from "../../../../models/Reimbursement/ReimbursementClaim";
import JSONView from "../../../../components/generic/JSONView";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface ApproveRejectClaimFormProps {
    reimbursementClaimId: string
    reimbursementClaimApproval: IReimbursementClaimApproval
}

type Props = ApproveRejectClaimFormProps;

const ApproveRejectForm: FunctionComponent<Props> = ({reimbursementClaimId, reimbursementClaimApproval}) => {
    // Mobx Store
    const rootStore = useContext(RootStoreContext);
    const {approveReimbursementClaim} = rootStore.reimbursementClaimStore;
    const {closeModal} = rootStore.modalStore;


    // Local state
    const [currentClaimApproval, setCurrentClaimApproval] = useState(reimbursementClaimApproval);

    // Functions
    const handleSubmit = (e) => {
        e.preventDefault()
        approveReimbursementClaim(reimbursementClaimId, currentClaimApproval)
    }

    const handleChange = (e) => inputOnChange(e, setCurrentClaimApproval)

    return (<>
        <form onSubmit={handleSubmit} className={'flex flex-col h-full'}>
                <div className={'h-full'}>
                    <div className="py-2 w-full text-lg">
                        Once <span className="font-bold uppercase">{reimbursementClaimApproval.result}</span> this action cannot
                        be undone.
                    </div>
                    <div className="py-2 w-full">
                        <TextArea name={'comment'} onChange={handleChange}
                                  label={`Comments`}
                                  placeholder={'Roads? Where we\'re going we don\'t need roads.'} rows={6}/>
                    </div>
                </div>

            <div className="flex justify-end">
                <Button color={"green"} iconLeft={<CheckIcon className={'h-5 w-5'}/>} type={'submit'}>Save</Button>
                <span className="mr-2"/>
                <Button color={"red"} iconLeft={<XMarkIcon className={'h-5 w-5'}/>} onClick={() => {
                    closeModal('ApproveRejectClaimModal')
                }}>Cancel</Button>
            </div>
        </form>

        <JSONView data={currentClaimApproval}/>
    </>);
};

export default ApproveRejectForm;
