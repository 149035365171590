import axios from "axios";
import {EntityFormValues, IEntity, IEntityEnvelope} from "../../models/Entity/Entity";
import {api, responseBody} from "./apiBase.service";

const Entities = {
    list: (
        params: URLSearchParams
    ): Promise<IEntityEnvelope> =>
        axios
            .get("/Entity", {params: params})
            .then(responseBody),

    create: (entity: EntityFormValues) =>
        api.post(`/Entity/`, entity),
    update: (entity: IEntity) =>
        api.put(`/Entity/${entity.id}`, entity),
    delete: (id: string) =>
        api.del(`/Entity/${id}`),
    byId: (id: string) =>
        api.get(`/Entity/${id}`),
};

export default Entities