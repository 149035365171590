import axios from "axios";
import {ITravelNote, ITravelNoteEnvelope, TravelNoteFormValues} from "../../models/Travel/TravelNote";
import {api, responseBody} from "./apiBase.service";

const TravelNotes = {
    list: (params: URLSearchParams): Promise<ITravelNoteEnvelope> =>
        axios
            .get("/Travel/Note", {params: params})
            .then(responseBody),
    create: (Travel: TravelNoteFormValues) =>
        api.post(`/Travel/Note`, Travel),
    update: (Travel: ITravelNote) =>
        api.put(`/Travel/Note/${Travel.id}`, Travel),
    delete: (id: string) =>
        api.del(`/Travel/Note/${id}`),
    byId: (id: string) => api.get(`/Travel/Note/${id}`),
    byTravelId: (travelId: string, params: URLSearchParams): Promise<ITravelNoteEnvelope> =>
        axios.get(`/Travel/${travelId}/Note`, {params: params})
            .then(responseBody),
};

export default TravelNotes