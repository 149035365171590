import React, {useState} from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";
import {IReimbursementClaim} from "../../../models/Reimbursement/ReimbursementClaim";
import Select from "../../generic/Select";
import Button from "../../generic/Button";
import {ArrowUturnRightIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {inputOnChange} from "../../../lib/utilities";
import ReimbursementClaim from "../../../api/service/reimbursementClaim.service";
import reimbursementClaimService from "../../../api/service/reimbursementClaim.service";
import ReimbursementClaimService from "../../../api/service/reimbursementClaim.service";
import {ReimbursementClaimDTORevert} from "../../../models/Reimbursement/ReimbursementClaimDTORevert";


interface OwnProps extends SlideOverProps {
    reimbursementClaim: IReimbursementClaim
}

type Props = OwnProps;

// @ts-ignore
const ReimbursementClaimRevertModal = NiceModal.create<Props>(({reimbursementClaim, onClose}) => {
    const _opts = {
        "Reviewed": ["Submitted"],
        "Approved": ["Submitted", "Reviewed"],
        "Payment Requested": ["Submitted", "Reviewed", "Approved"],
        "Payment Completed": ["Submitted", "Reviewed", "Approved", "Payment Requested"],
    }

    const modal = useModal()

    const [model, setModel] =  useState(new ReimbursementClaimDTORevert());

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    const handleChange = (e) => inputOnChange(e, setModel)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (reimbursementClaim.id)
            await ReimbursementClaimService.revert(reimbursementClaim.id, model)

        handleClose(e)
    }

    const statusOptions = (): any[] => {
        if (reimbursementClaim.currentStatus)
            return _opts[reimbursementClaim.currentStatus]?.map(x => {return {text: x, value: x, key: x}}) || []
        return []
    }


    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove}
                   title={'Roolback Reimbursement'}>
            <form onSubmit={handleSubmit}>
                <Select name={'status'} options={statusOptions()} onChange={handleChange}/>

                <div className={'flex gap-3'}>
                    <Button type={'submit'} color={'blue'}><ArrowUturnRightIcon className={'h-4 w-4 mr-2'}/>rollback</Button>
                    <Button onClick={handleClose} color={'red'}><XMarkIcon className={'h-4 w-4 mr-2'}/>cancel</Button></div>
            </form>
        </SlideOver>
    )
})

export default ReimbursementClaimRevertModal;
