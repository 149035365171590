import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {ConsumerNoteFormValues, IConsumerNote} from '../../models/Consumer/ConsumerNote'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class ConsumerNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.consumerNoteRegistry.clear()
                this.loadConsumerNote()
            }
        )

        reaction(
            () => this.currentConsumerId,
            () => {

                this.page = 0
                this.consumerNoteRegistry.clear()
                this.loadConsumerNote()
            }
        )
    }

    consumerNoteRegistry = new Map()
    consumerNote: IConsumerNote | null = null
    editMode = false


    currentConsumerId?: string = '';
    consumerNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentConsumerId = (consumerId?: string) => {
        this.currentConsumerId = consumerId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.consumerNoteCount / this.limit)
    }

    get consumerNotesById() {
        return Array.from(this.consumerNoteRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get consumerNotesByDate() {
        return Array.from(this.consumerNoteRegistry.values()).sort(
            (a, b) => dayjs(b.updatedOn).isBefore(a.updatedOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getConsumerNote = (id: string) => {
        return this.consumerNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.consumerNoteRegistry.clear()
        this.loadConsumerNote()
    }

    clearConsumerNote = () => {
        this.consumerNoteRegistry.clear()
    }

    loadConsumerNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentConsumerId) {
                const consumerNotesEnvelope = await Agent.ConsumerNotes.byConsumerId(this.currentConsumerId, this.axiosParams);
                const {modelsDto, modelCount} = consumerNotesEnvelope;
                runInAction(() => {
                    modelsDto.forEach((consumerNote: IConsumerNote) => {
                        this.consumerNoteRegistry.set(consumerNote.id, consumerNote)
                    })
                    this.consumerNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getConsumerNoteById = async (id: string) => {
        let consumerNote = this.getConsumerNote(id)
        if (consumerNote) {
            this.consumerNote = consumerNote
            return consumerNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                consumerNote = await Agent.ConsumerNotes.byId(id)
                runInAction(() => {
                    this.consumerNote = consumerNote
                    this.consumerNoteRegistry.set(consumerNote.id, consumerNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return consumerNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createConsumerNote = async (consumerNote: IConsumerNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newNote = await Agent.ConsumerNotes.create(consumerNote)
            await this.loadConsumerNote()
            runInAction(() => {
                this.consumerNoteRegistry.set(newNote.id, newNote)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editConsumerNote = (consumerNote: ConsumerNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.ConsumerNotes.update(consumerNote).then((data) => {
            runInAction(() => {
                this.consumerNoteRegistry.set(data.id, data)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteConsumerNote = async (consumerNote: IConsumerNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.ConsumerNotes.delete(consumerNote.id)
            runInAction(() => {
                this.consumerNoteRegistry.delete(consumerNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


}