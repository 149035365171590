import axios from "axios";
import {ITrainingAttachmentEnvelope, TrainingAttachmentFormValues} from "../../models/Training/TrainingAttachment";
import {api, responseBody} from "./apiBase.service";


const TrainingAttachments = {
    list: (params: URLSearchParams): Promise<ITrainingAttachmentEnvelope> =>
        axios
            .get("/Training/Attachment", {params: params})
            .then(responseBody),
    create: (trainingAttachment: TrainingAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in trainingAttachment) {
            formData.append(key, trainingAttachment[key])
        }
        return api.post(`/Training/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/Training/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/Training/Attachment/${id}`),
    byTrainingId: (trainingId: string, params: URLSearchParams): Promise<ITrainingAttachmentEnvelope> =>
        axios.get(`/Training/${trainingId}/Attachment`, {params}).then(responseBody)
};

export default TrainingAttachments