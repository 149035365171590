import axios from "axios";
import {EquipmentFormValues, IEquipment, IEquipmentEnvelope, IEquipmentReturn} from "../../models/Equipment/Equipment";
import {api, responseBody} from "./apiBase.service";

const Equipments = {
    list: (
        params: URLSearchParams
    ): Promise<IEquipmentEnvelope> =>
        axios
            .get("/Equipment", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/Equipment/${id}`),
    create: (Equipment: EquipmentFormValues) =>
        api.post(`/Equipment/`, Equipment),
    update: (Equipment: IEquipment) =>
        api.put(`/Equipment/${Equipment.id}`, Equipment),
    delete: (id: string) =>
        api.del(`/Equipment/${id}`),
    return: (Equipment: IEquipmentReturn) =>
        api.put(`/Equipment/${Equipment.id}/return`, Equipment),
};

export default Equipments