import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {
    INationalOutreachBudgetEnvelope,
    NationalOutreachAllocationFormValues,
    NationalOutreachBudgetApprovalFormValues,
    NationalOutreachBudgetPaymentFormValues
} from "../../models/NationalOutreach/NationalOutreachBudget";

const NationalOutreachBudgets = {
    list: (params: URLSearchParams): Promise<INationalOutreachBudgetEnvelope> =>
        axios
            .get("/NationalOutreachBudget", {params: params})
            .then(responseBody),
    byId: (nationalOutreachBudgetId: string) =>
        api.get(`/NationalOutreachBudget/${nationalOutreachBudgetId}`),

    create: (nationalOutReachBudget: NationalOutreachAllocationFormValues) =>
        api.post(`/NationalOutreachBudget`, nationalOutReachBudget),

    update: (nationalOutReachBudget: NationalOutreachAllocationFormValues) =>
        api.put(`/NationalOutreachBudget/${nationalOutReachBudget.id}`, nationalOutReachBudget),

    approve: (nationalOutReachBudget: NationalOutreachBudgetApprovalFormValues) =>
        api.put(`/NationalOutreachBudget/${nationalOutReachBudget.id}/approve`, nationalOutReachBudget),

    review: (nationalOutReachBudget: NationalOutreachBudgetApprovalFormValues) =>
        api.put(`/NationalOutreachBudget/${nationalOutReachBudget.id}/review`, nationalOutReachBudget),

    submit: (nationalOutReachBudget: NationalOutreachAllocationFormValues) =>
        api.put(`/NationalOutreachBudget/${nationalOutReachBudget.id}/submit`, nationalOutReachBudget),
    payment: (id:string, nationalOutReachBudget: NationalOutreachBudgetPaymentFormValues) =>
        api.put(`/NationalOutreachBudget/${id}/payment`, nationalOutReachBudget),

    delete: (id: string) =>
        api.del(`/NationalOutreachBudget/${id}`),
};

export default NationalOutreachBudgets