import React, {FunctionComponent, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import EntityAutoCompleteInput from "../../../../../components/form/EntityAutoCompleteInput";
import {XMarkIcon} from "@heroicons/react/24/solid";

interface OwnProps {
    consumerId?: string;
    value?: EntityListItem[];
    onChange: (entityIds: EntityListItem[]) => void;
}

type Props = OwnProps;

export interface EntityListItem {
    entityId: string;
    entityName: string;
}

const EntityMultiselect: FunctionComponent<Props> = ({onChange, value}) => {

    // USE STATES
    const [currentEntities, setEntities] = useState([] as EntityListItem[]);
    const [currentEntityIds, setCurrentEntityIds] = useState([] as string[]);

    // USE EFFECTS
    // TODO Revisit once Dan IDs filter to entity get endpoint.
    useEffect(() => {
        if (!!value) {
            setCurrentEntityIds([...value.map((e): string => e.entityId)]);
            setEntities([...value])
        }
    }, [])

    useEffect(() => {
        !!onChange && onChange(currentEntities);
    }, [currentEntityIds])

    // FUNCTIONS
    const handleEntityChange = (entityId, entityName) => {
        if (entityId) {
            setEntities([...currentEntities, {entityId, entityName}]);
            setCurrentEntityIds([...currentEntityIds, entityId]);
        }
    }

    const removeEntity = (entityId) => {
        const localEntities = currentEntities.filter((e) => e.entityId !== entityId);
        setEntities(localEntities);
        const localEntityIds = currentEntityIds.filter(id => id !== entityId);
        setCurrentEntityIds(localEntityIds);
    }

    return (<>
        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
            <EntityAutoCompleteInput name={'entityContacts'} label={"Entity"}
                                     onChange={({id, name}) => handleEntityChange(id, name)}
            />
        </div>

        {currentEntities.map((e) => {
            return (e?.entityName ?
                <span className={'inline-block mt-1 mr-2 pt-2 pb-2 pl-3 pr-3 rounded-md bg-black text-white focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 focus:outline-none'}>
                    <span className={'font-weight-bold'}>{e.entityName}</span>
                    <XMarkIcon onClick={() => removeEntity(e.entityId)} role={'button'} aria-label={'delete'} className={'inline-block h-6 w-6 text-red-600 ml-2'}/>
                </span>
                : <></>)
        })}
    </>);
};

export default observer(EntityMultiselect)
