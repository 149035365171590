import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IOutreach, OutreachFormValues} from '../../models/Outreach/Outreach'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class OutreachStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.outreachRegistry.clear()
                this.loadOutreach()
            }
        )
    }

    outreachRegistry = new Map()
    outreach: IOutreach | null = null
    editMode = false

    outreachCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.outreachRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.outreachCount / this.limit)
    }

    get outreachsList() {
        // @ts-ignore
        return Array.from(this.outreachRegistry.values())//.sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get outreachsByDate() {
        return Array.from(this.outreachRegistry.values())
        // .sort(
        //     (a, b) => b.dateCreated - a.dateCreated//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        // )
    }

    getOutreach = (id: string) => {
        return this.outreachRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.outreachRegistry.clear()
        this.loadOutreach()
    }

    clearOutreach = () => {
        this.outreachRegistry.clear()
    }

    loadOutreach = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const outreachsEnvelope = await Agent.Outreachs.list(this.axiosParams)
            const {modelsDto, modelCount} = outreachsEnvelope

            runInAction(() => {
                this.clearOutreach()
                modelsDto.forEach((outreach: IOutreach) => {
                    this.outreachRegistry.set(outreach.id, outreach)
                })
                this.outreachCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    loadNakedOutreach = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)
            const outreachsEnvelope = await Agent.Outreachs.list(params)
            const {modelsDto} = outreachsEnvelope

            return modelsDto
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getOutreachById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let outreach = await Agent.Outreachs.byId(id)
            runInAction(() => {
                this.outreach = outreach
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return outreach
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getOutreachsByIds = async (ids: string[]) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '1000000')
            params.append('offset', `0`)

            for (let id of ids) {
                params.append('ids', id)
            }

            let {modelsDto} = await Agent.Outreachs.list(params)

            return modelsDto
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


    createOutreach = async (outreach: OutreachFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newOutreach = await Agent.Outreachs.create(outreach)
            this.loadOutreach()
            runInAction(() => {
                this.outreachRegistry.set(newOutreach.id, newOutreach)
                this.rootStore.modalStore.closeModal('OutreachModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newOutreach
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editOutreach = async (outreach: OutreachFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedOutreach = await Agent.Outreachs.update(outreach)
            runInAction(() => {
                this.outreachRegistry.set(updatedOutreach.id, updatedOutreach)
                this.rootStore.modalStore.closeModal('OutreachModal')
            })
            toast.success('Saved')
            return updatedOutreach
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteOutreach = async (outreach: IOutreach) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.Outreachs.delete(`${outreach.id}`)
            runInAction(() => {
                this.outreachRegistry.delete(outreach.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }

    }


}