import {useState} from "react";

export const useSelectTable = (initSelected: string[] = []) => {
    // Local State
    const [selectedItems, setSelectedItems] = useState(initSelected);

    // Functions
    const handleCheck = (e): void => {
        const {value, checked} = e.currentTarget
        setSelectedItems(prev => {
            let tmp = [...prev]
            if (checked) {
                tmp.push(value)
            } else {
                tmp = tmp.filter(t => t !== value)
            }
            return tmp
        })
    }

    const handleCheckAll = (e, items): void => {
        const ids = items.map(i => i.id)
        const {checked} = e.currentTarget
        setSelectedItems(prev => {
            let tmp = [...prev]
            if (checked) {
                tmp.push(...ids)
            } else {
                tmp = tmp.filter(t => !ids.includes(t))
            }
            return tmp
        })
    }


    return {handleCheck, handleCheckAll, selectedItems}
}