import {Envelope} from "./Envelope";

export interface NoteEnvelope extends Envelope<Note> {
}

export interface Note {
    id: string
    createdById?: string
    createdByName?: string
    createdOn?: Date
    updatedById?: string
    updatedByName?: string
    updatedOn?: Date
    deleted?: boolean
    comment?: string
    category?: string
}

//empty form model
export class NoteFormValues {
    id: string = ''
    createdById?: string
    createdByName?: string
    createdOn?: Date
    updatedById?: string
    updatedByName?: string
    updatedOn?: Date
    deleted?: boolean
    comment?: string
    category?: string

    constructor(note?: Note) {
        if (!!note) {
            Object.assign(this, note);
        }
    }
}
