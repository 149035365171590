import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import {
    IReimbursementClaimSpreadsheet,
    IReimbursementClaimSpreadsheetUpload
} from "../../models/Reimbursement/ReimbursementClaimSpreadsheet";


export default class ReimbursementSpreadSheetStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.reimbursementSpreadSheetRegistry.clear()
                this.loadReimbursementSpreadSheet()
            }
        )
    }

    reimbursementSpreadSheetRegistry = new Map()
    reimbursementSpreadSheet: IReimbursementClaimSpreadsheet | null = null

    reimbursementSpreadSheetCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.reimbursementSpreadSheetCount / this.limit)
    }

    get reimbursementSpreadSheetsById() {
        return Array.from(this.reimbursementSpreadSheetRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get reimbursementSpreadSheetsByDate() {
        return Array.from(this.reimbursementSpreadSheetRegistry.values()).sort(
            (a, b) => b.dateCreated - a.dateCreated//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getReimbursementSpreadSheet = (id: string) => {
        return this.reimbursementSpreadSheetRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.reimbursementSpreadSheetRegistry.clear()
        this.loadReimbursementSpreadSheet()
    }

    clearReimbursementSpreadSheet = () => {
        this.reimbursementSpreadSheetRegistry.clear()
    }

    loadReimbursementSpreadSheet = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const {modelsDto, modelCount} = await Agent.ReimbursementClaimSpreadsheet.list(this.axiosParams)

            runInAction(() => {
                // @ts-ignore
                modelsDto.forEach((reimbursementSpreadSheet: IReimbursementClaimSpreadsheet) => {
                    this.reimbursementSpreadSheetRegistry.set(reimbursementSpreadSheet.id, reimbursementSpreadSheet)
                })
                this.reimbursementSpreadSheetCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createReimbursementSpreadSheet = async (reimbursementSpreadSheet: IReimbursementClaimSpreadsheetUpload) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newReimbursementSpreadSheet = await Agent.ReimbursementClaimSpreadsheet.create(reimbursementSpreadSheet)
            this.loadReimbursementSpreadSheet()
            runInAction(() => {
                this.reimbursementSpreadSheetRegistry.set(newReimbursementSpreadSheet.id, newReimbursementSpreadSheet)
                this.rootStore.modalStore.closeModal('ReimbursementSpreadSheetModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newReimbursementSpreadSheet
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getReimbursementSpreadSheetById = async (reimbursementSpreadSheet: IReimbursementClaimSpreadsheet) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newReimbursementSpreadSheet = await Agent.ReimbursementClaimSpreadsheet.byId(reimbursementSpreadSheet)
            toast.success('Saved')
            return newReimbursementSpreadSheet
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }
    failSpreadsheetRequest = async (spreadsheetId: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let failedSpreadsheetRequest = await Agent.ReimbursementClaimSpreadsheet.fail(spreadsheetId)
            runInAction(() => {
                this.loadReimbursementSpreadSheet()
                this.reimbursementSpreadSheetRegistry.set(failedSpreadsheetRequest.id, failedSpreadsheetRequest)
                this.rootStore.modalStore.closeModal('ConfirmationModal')
            })
            toast.success('Saved')
            return failedSpreadsheetRequest
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }
}