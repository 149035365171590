import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import {
    IReimbursementClaimNote,
    ReimbursementClaimNoteFormValues
} from "../../models/Reimbursement/ReimbursementClaimNote";

export default class ReimbursementClaimNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.reimbursementClaimNoteRegistry.clear()
                this.loadReimbursementClaimNote()
            }
        )
        reaction(
            () => this.currentReimbursementClaimId,
            () => {

                this.page = 0
                this.reimbursementClaimNoteRegistry.clear()
                this.loadReimbursementClaimNote()
            }
        )
    }

    reimbursementClaimNoteRegistry = new Map()
    reimbursementClaimNote: IReimbursementClaimNote | null = null
    editMode = false


    currentReimbursementClaimId = '';
    reimbursementClaimNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentReimbursementClaimId = (reimbursementClaimId: string) => {
        this.currentReimbursementClaimId = reimbursementClaimId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.reimbursementClaimNoteCount / this.limit)
    }

    get reimbursementClaimNotesList() {
        return Array.from(this.reimbursementClaimNoteRegistry.values())
    }

    getReimbursementClaimNote = (id: string) => {
        return this.reimbursementClaimNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.reimbursementClaimNoteRegistry.clear()
        this.loadReimbursementClaimNote()
    }

    clearReimbursementClaimNote = () => {
        this.reimbursementClaimNoteRegistry.clear()
    }

    loadReimbursementClaimNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentReimbursementClaimId && typeof this.currentReimbursementClaimId !== undefined) {
                const reimbursementClaimNotesEnvelope = await Agent.ReimbursementClaimNotes.byReimbursementClaimId(this.currentReimbursementClaimId, this.axiosParams);
                const {modelsDto, modelCount} = reimbursementClaimNotesEnvelope;
                runInAction(() => {
                    modelsDto.forEach((reimbursementClaimNote: IReimbursementClaimNote) => {
                        this.reimbursementClaimNoteRegistry.set(reimbursementClaimNote.id, reimbursementClaimNote)
                    })
                    this.reimbursementClaimNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getReimbursementClaimNoteById = async (id: string) => {
        let reimbursementClaimNote = this.getReimbursementClaimNote(id)
        if (reimbursementClaimNote) {
            this.reimbursementClaimNote = reimbursementClaimNote
            return reimbursementClaimNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                reimbursementClaimNote = await Agent.ReimbursementClaimNotes.byId(id)
                runInAction(() => {
                    this.reimbursementClaimNote = reimbursementClaimNote
                    this.reimbursementClaimNoteRegistry.set(reimbursementClaimNote.id, reimbursementClaimNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return reimbursementClaimNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createReimbursementClaimNote = async (reimbursementClaimNote: IReimbursementClaimNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newReimbursementClaimNote = await Agent.ReimbursementClaimNotes.create(reimbursementClaimNote)
            await this.loadReimbursementClaimNote()
            runInAction(() => {
                this.reimbursementClaimNoteRegistry.set(newReimbursementClaimNote.id, newReimbursementClaimNote)
                this.rootStore.modalStore.closeModal('NoteModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editReimbursementClaimNote = (reimbursementClaimNote: ReimbursementClaimNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.ReimbursementClaimNotes.update(reimbursementClaimNote).then((updatedReimbursementClaimNote) => {
            runInAction(() => {
                this.reimbursementClaimNoteRegistry.set(updatedReimbursementClaimNote.id, updatedReimbursementClaimNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteReimbursementClaimNote = async (reimbursementClaimNote: IReimbursementClaimNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.ReimbursementClaimNotes.delete(reimbursementClaimNote.id)
            runInAction(() => {
                this.reimbursementClaimNoteRegistry.delete(reimbursementClaimNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }

    }


}