import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {
    IInstallationAttachment,
    InstallationAttachmentFormValues
} from '../../models/Installation/InstallationAttachment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class InstallationAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.installationAttachmentRegistry.clear()
                this.loadInstallationAttachment()
            }
        )

        reaction(
            () => this.currentInstallationId,
            () => {
                this.page = 0
                this.installationAttachmentRegistry.clear()
                !!this.currentInstallationId ? this.getInstallationAttachmentsByInstallationId() : this.loadInstallationAttachment()
            }
        )
    }

    installationAttachmentRegistry = new Map()
    installationAttachment: IInstallationAttachment | null = null
    editMode = false


    currentInstallationId = '';
    installationAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentInstallationId = (installationId: string) => {
        this.currentInstallationId = installationId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.installationAttachmentCount / this.limit)
    }

    get installationAttachmentsById() {
        return Array.from(this.installationAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get installationAttachmentsByDate() {
        return Array.from(this.installationAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getInstallationAttachment = (id: string) => {
        return this.installationAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.installationAttachmentRegistry.clear()
        this.loadInstallationAttachment()
    }

    clearInstallationAttachment = () => {
        this.installationAttachmentRegistry.clear()
    }

    loadInstallationAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const installationAttachmentsEnvelope = await Agent.InstallationAttachments.list(this.axiosParams);
            const {modelsDto, modelCount} = installationAttachmentsEnvelope;

            runInAction(() => {
                this.clearInstallationAttachment()
                modelsDto.forEach((installationAttachment: IInstallationAttachment) => {
                    this.installationAttachmentRegistry.set(installationAttachment.id, installationAttachment)
                })
                this.installationAttachmentCount = modelCount
            })
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getInstallationAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let installationAttachment = await Agent.InstallationAttachments.byId(id)
            runInAction(() => {
                this.installationAttachment = installationAttachment
                this.installationAttachmentRegistry.set(installationAttachment.id, installationAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return installationAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getInstallationAttachmentsByInstallationId = async (installationId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let installationAttachmentEnvelope = await Agent.InstallationAttachments.byInstallationId(installationId || this.currentInstallationId, this.axiosParams)
            const {modelsDto, modelCount} = installationAttachmentEnvelope
            runInAction(() => {
                modelsDto.forEach(installationAttachment => {
                    this.installationAttachmentRegistry.set(installationAttachment.id, installationAttachment)
                })

                this.installationAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createInstallationAttachment = async (installationAttachment: InstallationAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newInstallationAttachment = await Agent.InstallationAttachments.create(installationAttachment)
            await (!!this.currentInstallationId ? this.getInstallationAttachmentsByInstallationId() : this.loadInstallationAttachment())

            runInAction(() => {
                this.installationAttachmentRegistry.set(newInstallationAttachment.id, newInstallationAttachment)
                this.rootStore.modalStore.closeModal('AttachmentModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteInstallationAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.InstallationAttachments.delete(id)
            runInAction(() => {
                this.installationAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('InstallationAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

}