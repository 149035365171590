import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IOutreachNote, OutreachNoteFormValues} from '../../models/Outreach/OutreachNote'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'

export default class OutreachNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.outreachNoteRegistry.clear()
                this.loadOutreachNote()
            }
        )

        reaction(
            () => this.currentOutreachId,
            () => {

                this.page = 0
                this.outreachNoteRegistry.clear()
                this.loadOutreachNote()
            }
        )
    }

    outreachNoteRegistry = new Map()
    outreachNote: IOutreachNote | null = null
    editMode = false


    currentOutreachId = '';
    outreachNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentOutreachId = (outreachId: string) => {
        if (!!outreachId && outreachId !== 'undefined') this.currentOutreachId = outreachId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!!predicate) {
            if (predicate !== 'all') {
                this.predicate.delete(predicate)
                if (!!value) this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
            } else {
                this.predicate.clear()
            }
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.outreachNoteCount / this.limit)
    }

    get outreachNotesById() {
        return Array.from(this.outreachNoteRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get outreachNotesByDate() {
        return Array.from(this.outreachNoteRegistry.values()).sort(
            (a, b) => dayjs(b.updatedOn).isBefore(a.updatedOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getOutreachNote = (id: string) => {
        return this.outreachNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.outreachNoteRegistry.clear()
        this.loadOutreachNote()
    }

    clearOutreachNote = () => {
        this.outreachNoteRegistry.clear()
    }

    loadOutreachNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        let localModelsDto, localModelCount
        try {
            if (!!this.currentOutreachId && this.currentOutreachId !== 'undefined') {
                const outreachNotesEnvelope = await Agent.OutreachNotes.byOutreachId(this.currentOutreachId, this.axiosParams);
                const {modelsDto, modelCount} = outreachNotesEnvelope;
                localModelsDto = modelsDto
                localModelCount = modelCount
            } else {
                const outreachNotesEnvelope = await Agent.OutreachNotes.list(this.axiosParams);
                const {modelsDto, modelCount} = outreachNotesEnvelope;
                localModelsDto = modelsDto
                localModelCount = modelCount
            }

            runInAction(() => {
                localModelsDto.forEach((outreachNote: IOutreachNote) => {
                    this.outreachNoteRegistry.set(outreachNote.id, outreachNote)
                })
                this.outreachNoteCount = localModelCount
            })
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getOutreachNoteById = async (id: string) => {
        let outreachNote = this.getOutreachNote(id)
        if (outreachNote) {
            this.outreachNote = outreachNote
            return outreachNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                outreachNote = await Agent.OutreachNotes.byId(id)
                runInAction(() => {
                    this.outreachNote = outreachNote
                    this.outreachNoteRegistry.set(outreachNote.id, outreachNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return outreachNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createOutreachNote = async (outreachNote: IOutreachNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newOutreachNote = await Agent.OutreachNotes.create(outreachNote)
            await this.loadOutreachNote()
            runInAction(() => {
                this.outreachNoteRegistry.set(newOutreachNote.id, newOutreachNote)
                this.rootStore.modalStore.closeModal('NoteModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editOutreachNote = (outreachNote: OutreachNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.OutreachNotes.update(outreachNote).then((updatedOutreachNote) => {
            runInAction(() => {
                this.outreachNoteRegistry.set(updatedOutreachNote.id, updatedOutreachNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteOutreachNote = async (outreachNote: IOutreachNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.OutreachNotes.delete(outreachNote.id)
            runInAction(() => {
                this.outreachNoteRegistry.delete(outreachNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }

    }


}