import {api} from "./apiBase.service";
import {
    IReimbursementClaimLineItem,
    IReimbursementClaimLineItemFlag,
    ReimbursementClaimLineItemFormValues
} from "../../models/Reimbursement/ReimbursementClaimLineItem";

const ReimbursementClaimLineItem = {
    // list: (params: URLSearchParams): Promise<IReimbursementClaimLineItemEnvelope> =>
    //     axios.get(`/ReimbursementClaimLineItem/`, {params: params}).then(responseBody),
    byClaimId: (ReimbursementClaimId: string) =>
        api.get(`/ReimbursementClaimLineItem/${ReimbursementClaimId}`),
    create: (ReimbursementClaimLineItem: ReimbursementClaimLineItemFormValues) =>
        api.post(`/ReimbursementClaimLineItem/${ReimbursementClaimLineItem.reimbursementClaimId}`, ReimbursementClaimLineItem),
    flag: (ReimbursementClaimLineItem: IReimbursementClaimLineItemFlag) =>
        api.put(`/ReimbursementClaimLineItem/${ReimbursementClaimLineItem.reimbursementClaimId}/Flag`, ReimbursementClaimLineItem),
    delete: (ReimbursementClaimLineItem: IReimbursementClaimLineItem) =>
        api.del(`/ReimbursementClaimLineItem/${ReimbursementClaimLineItem.reimbursementClaimId}`, ReimbursementClaimLineItem),
};

export default ReimbursementClaimLineItem