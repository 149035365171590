import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RootStoreContext} from "../../../stores/RootStore";

import {IMaintenanceRepair} from "../../../models/MaintenanceRepair/MaintenanceRepair";
import {arraySum, displayDate, displayDollarAmount} from "../../../lib/utilities";
import {observer} from "mobx-react-lite";
import MaintenanceRepairAutoCompleteInput from "../MaintenanceRepairAutoCompleteInput";
import Table from '../../table/Table';
import THead from '../../table/THead';
import TR from '../../table/TR';
import TBody from '../../table/TBody';
import TD from '../../table/TD';
import {TrashIcon} from "@heroicons/react/24/solid";

interface OwnProps {
    onChange?: (e) => (void)
    name?: string
    selected?: string[]
    entityStateId?: string
}

type Props = OwnProps;

const MaintenanceRepairMultiselect: FunctionComponent<Props> = ({entityStateId, onChange, name, selected}) => {
    //Stores
    const rootStore = useContext(RootStoreContext);
    const {loadNakedMaintenanceRepair, maintenanceRepairsByDate, getMaintenanceRepairByIds} = rootStore.maintenanceRepairStore
    const {newSubItemQueue} = rootStore.subItemStore


    // Local State
    const [availableMaintenanceRepairs, setAvailableMaintenanceRepairs] = useState([] as IMaintenanceRepair[]);
    const [selectedMaintenanceRepairs, setSelectedMaintenanceRepairs] = useState([] as Array<IMaintenanceRepair>);

    // Use Effects
    useEffect(() => {
        loadNakedMaintenanceRepair().then(data => setAvailableMaintenanceRepairs([...data]))
        if (!!selected && selected?.length > 0) {
            getMaintenanceRepairByIds(selected).then(data => {
                if (!!data) setSelectedMaintenanceRepairs(data)
            })
        }
    }, [maintenanceRepairsByDate, newSubItemQueue]);

    // Functions
    const handleRemove = (idx): void => {
        setSelectedMaintenanceRepairs((prev) => {
            prev.splice(idx, 1)
            !!onChange && onChange({
                currentTarget: {
                    name,
                    value: prev
                }
            })
            return prev
        })
    }

    const handleSelected = (data): void => {
        if (!data.id)
            return

        if (!!data) {
            let newMaintenanceRepair = availableMaintenanceRepairs.find((maintenanceRepair) => {
                return maintenanceRepair.id === data.id
            })

            // @ts-ignore
            setSelectedMaintenanceRepairs(prev => {
                let tmp = [...prev, newMaintenanceRepair]
                // let tmp = [...prev]
                !!onChange && onChange({
                    currentTarget: {
                        name,
                        value: tmp.map(ids => ids?.id)
                    }
                })
                return tmp
            })
        }
    }

    return (<>
        <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                <MaintenanceRepairAutoCompleteInput
                    entityStateId={entityStateId}
                    onChange={handleSelected}
                    filterIds={!!selectedMaintenanceRepairs.length ? selectedMaintenanceRepairs?.map((selected: IMaintenanceRepair) => selected?.id ?? '') : []}
                />
        </div>

        <Table>
            <THead>
                <TR>
                    <TD/>
                    <TD>Consumer</TD>
                    <TD>Technician</TD>
                    <TD>Equipment</TD>
                    <TD>Date Performed</TD>
                    <TD>Repair, Maintenance, Refurbish, or Warranty Time</TD>
                    <TD>Total Cost</TD>
                </TR>
            </THead>
            <TBody>
                {selectedMaintenanceRepairs.map((maintenanceRepair: IMaintenanceRepair, idx) => {
                    return (<TR key={idx}>
                        <TD><TrashIcon className={'text-red-600 h-6 w-6'} onClick={() => handleRemove(idx)}/></TD>
                        <TD>{maintenanceRepair?.consumerName || 'Inventory'}</TD>
                        <TD>{maintenanceRepair?.technicianName ?? ''}</TD>
                        <TD>{maintenanceRepair?.equipmentName ?? ''}</TD>
                        <TD>{displayDate(maintenanceRepair?.datePerformed ?? '')}</TD>
                        <TD>{maintenanceRepair?.rmrwTime ?? ''} {!!maintenanceRepair?.rmrwTime && maintenanceRepair?.rmrwTime > 1 ? 'hours' : 'hour'}</TD>
                        <TD>{displayDollarAmount(arraySum([
                            maintenanceRepair?.auxiliaryAidCosts,
                            maintenanceRepair?.timeMaterialsCosts,
                            maintenanceRepair?.otherCosts,
                        ]))}</TD>
                    </TR>)
                })}
            </TBody>
        </Table>
    </>);
};

export default observer(MaintenanceRepairMultiselect);
