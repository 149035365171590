import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import {IReimbursementClaim, IReimbursementClaimPayment} from "../../../../models/Reimbursement/ReimbursementClaim";
import {inputOnChange} from "../../../../lib/utilities";
import Input from "../../../../components/Inputs/Input";
import Button from "../../../../components/generic/Button";
import {CheckIcon} from "@heroicons/react/24/solid";

export interface ReimbursementClaimPaymentFormProps {
    reimbursementClaim: IReimbursementClaim
}

type Props = ReimbursementClaimPaymentFormProps

const EntityForm: React.FC<Props> = ({reimbursementClaim}) => {
    const rootStore = useContext(RootStoreContext);

    const {payReimbursementClaim} = rootStore.reimbursementClaimStore;
    const {closeModal} = rootStore.modalStore;


    const [currentReimbursementClaim, setCurrentReimbursementClaim] = useState({} as IReimbursementClaimPayment);

    const handleSubmit = (e) => {
        e.preventDefault()
        payReimbursementClaim(reimbursementClaim.id ?? '', currentReimbursementClaim)
    }

    const handleChange = (e) => {
        inputOnChange(e, setCurrentReimbursementClaim)
    }

    return (
        <form onSubmit={handleSubmit} className={'flex flex-col justify-between flex-1 h-full'}>
            <div className={'flex-1'}>
                <Input
                    name='daqId'
                    placeholder='FROXK.TRS1.SPR.ACH.A00000XX'
                    label='DAQ ID'
                    onChange={handleChange}
                    value={currentReimbursementClaim.daqId}
                    required
                />
            </div>

            <div className={'flex justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}>Save</Button>
                <span className="mr-2"/>
                <Button onClick={() => closeModal('ReimbursementClaimPaymentModal')} color={'red'}>Cancel</Button>
            </div>
        </form>
    );
};
export default observer(EntityForm);