import {RootStore} from "../RootStore";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import agent from "../../api/Agent";
import {ISemiAnnualReportAttachment} from "../../models/SemiAnnualReport/SemiAnnualReportAttachment";

export default class SemiAnnualReportAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.semiAnnualReportAttachmentRegistry.clear()
                this.loadSemiAnnualReportAttachments()
            }
        )
    }

    semiAnnualReportAttachmentRegistry = new Map()
    semiAnnualReportAttachment: ISemiAnnualReportAttachment | null = null
    limit = 5
    page = 0
    predicate = new Map()
    semiAnnualReportAttachmentCount = 0;

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get pageCount() {
        return Math.ceil(this.semiAnnualReportAttachmentCount / this.limit)
    }

    get semiAnnualReportAttachmentsList() {
        // @ts-ignore
        return Array.from(this.semiAnnualReportAttachmentRegistry.values()).sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    clearSemiAnnualReportAttachments = async () => {
        this.semiAnnualReportAttachmentRegistry.clear()
    }
    setPage = (page: number) => {
        this.page = page
    }
    setLimit = (limit: number) => {
        this.limit = limit
    }

    setCurrentSemiAnnualReportAttachment = (semiAnnualReportAttachment?) => {
        this.semiAnnualReportAttachment = semiAnnualReportAttachment
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.semiAnnualReportAttachmentRegistry.clear()
        this.loadSemiAnnualReportAttachments()
    }

    loadSemiAnnualReportAttachments = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const SemiAnnualReportEnvelope = await agent.SemiAnnualReportAttachments.list(this.axiosParams)
            let {modelsDto, modelCount} = SemiAnnualReportEnvelope

            runInAction(() => {
                modelsDto.forEach((semiAnnualReport) => {
                    this.semiAnnualReportAttachmentRegistry.set(semiAnnualReport.id, semiAnnualReport)
                })
                this.semiAnnualReportAttachmentCount = modelCount
            })

        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    getSemiAnnualReportAttachmentById = async (id) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let semiAnnualReportAttachment;
        try {
            semiAnnualReportAttachment = await agent.SemiAnnualReportAttachments.byId(id);
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return semiAnnualReportAttachment
    }

    downloadSemiAnnualReportAttachment = async (id) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let attachment;
        try {
            attachment = await agent.SemiAnnualReportAttachments.downloadById(id);
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return attachment;
    }
}