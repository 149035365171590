import React, {FunctionComponent} from 'react';

interface OwnProps extends React.HTMLProps<HTMLDivElement> {
    children?: React.ReactNode
}

type Props = OwnProps;

const Table: FunctionComponent<Props> = ({className, children, ...rest}) => {

  return (
      <div {...rest} role={'table'} className={`md:table w-full text-sm md:text-base ${className}`}>
          {children}
      </div>
  );
};

export default Table;
