import axios from "axios";
import {IInstallation, IInstallationEnvelope, InstallationFormValues} from "../../models/Installation/Installation";
import {api, responseBody} from "./apiBase.service";

const Installations = {
    list: (
        params?: URLSearchParams
    ): Promise<IInstallationEnvelope> =>
        axios
            .get("/Installation", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/Installation/${id}`),
    create: (Installation: InstallationFormValues) =>
        api.post(`/Installation/`, Installation),
    update: (Installation: IInstallation) =>
        api.put(`/Installation/${Installation.id}`, Installation),
    delete: (id: string) =>
        api.del(`/Installation/${id}`),
};

export default Installations