import {makeAutoObservable, reaction, runInAction} from 'mobx';
import {RootStore} from '../RootStore';
import agent from "../../api/Agent";
import {toast} from "react-toastify";
import {EntityFormValues, IEntity} from "../../models/Entity/Entity";
// import {v4 as uuidv4} from 'uuid';

export default class EntityStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.entityRegistry.clear();
                this.loadEntities();
            }
        )
    }

    entityRegistry = new Map();
    entity: IEntity | null = null;
    target = "";
    entityCount = 0;
    page = 0;
    limit = 5;
    predicate = new Map();

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(this.limit));
        params.append('offset', `${this.page ? this.page * this.limit : 0}`);
        this.predicate.forEach((value, key) => {
            params.append(key, value);
        })
        return params;
    }

    get entitiesList() {
        return Array.from(this.entityRegistry.values())
    };

    loadEntities = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const entityEnvelope = await agent.Entities.list(this.axiosParams);
            let {modelsDto, modelCount} = entityEnvelope;

            runInAction(() => {
                modelsDto.forEach((entity) => {
                    this.entityRegistry.set(entity.id, entity);
                })
                this.entityCount = modelCount;
            });
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    };

    clearEntities = async () => {
        this.entityRegistry.clear();
    }
    setPage = (page: number) => {
        this.page = page;
    }
    setLimit = (limit: number) => {
        this.limit = limit;
    }
    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1);
        //sets the row limit
        this.setLimit(newRow);
        //clears all records in the registry so that next page didnt load the prev records
        this.entityRegistry.clear();
        this.loadEntities();
    }

    getEntityById = async (entityId: any) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let e;
        try {
            e = await agent.Entities.byId(entityId);
        } catch (error: any) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
        return e;
    };

    createEntity = async (entity: EntityFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.Entities.create(entity);
            await this.loadEntities();
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('EntityModal')
            });
            toast.success('Saved');
        } catch (error) {
            runInAction(() => {
                this.rootStore.errorStore.addError(error);
            });

        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    };

    editEntity = async (entity: IEntity) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.Entities.update(entity);
            await this.loadEntities();
            toast.success('Saved');
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('EntityModal')
            });
        } catch (error) {
            runInAction(() => {
                this.rootStore.errorStore.addError(error);
                this.rootStore.commonStore.setIsLoading(loadingId)
            });
        }
    };

    getEntityOptions = () => {
        let Entities: {
            key: string;
            value: string;
        }[] = [];

        this.entitiesList.map((entity) => (
            Entities.push({key: entity.id, value: entity.name + (!!entity.state ? " - " + entity.state : '')})
        ));

        return Entities;
    }

}