import {makeAutoObservable, reaction, runInAction} from 'mobx';
import {RootStore} from '../RootStore';
import agent from "../../api/Agent";
import {toast} from "react-toastify";
import {EntityStateFormValues, IEntityState} from "../../models/Entity/EntityState";
import EntityStore from "./EntityStore"

export default class EntityStateStore {
    rootStore: RootStore;
    entityStore: EntityStore;

    constructor(rootStore: RootStore, entityStore: EntityStore) {
        this.rootStore = rootStore
        this.entityStore = entityStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.entityStateRegistry.clear();
                this.loadEntityStates();
            }
        )
    }

    entityStateRegistry = new Map();
    entityState: IEntityState | null = null;
    entityId = "";
    target = "";

    entityStateCount = 0;
    page = 0;
    limit = 1000;
    predicate = new Map();

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get entityStatesByName(): IEntityState[] {
        // @ts-ignore
        return [...this.entityStateRegistry.values()].sort((a: IEntityState, b: IEntityState) => a.stateName > b.stateName ? 1 : -1)
    };

    get entityStatesAutoCompleteOptions() {
        let EntityState: {
            key: string
            value: string
        }[] = []


        this.entityStatesByName.forEach((entityState: IEntityState): void => {
            EntityState.push({
                key: `${entityState.id}`,
                value: `${entityState.stateName}`
            })
        })

        return EntityState.sort((a, b) => a.value > b.value ? 1 : -1)
    };

    loadEntityStates = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const {modelsDto} = await agent.EntityStates.list(this.axiosParams);
            runInAction(() => {
                modelsDto.forEach((entityState) => {
                    this.entityStateRegistry.set(entityState.id, entityState);
                })
            });
            return modelsDto
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            });
        }
    };
    getEntityStatesById = async (id: string, params: URLSearchParams) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            if (!!id && typeof id !== undefined) {
                const entityStateEnvelope = await agent.EntityStates.byId(id, params);
                let {entityStatesDto} = entityStateEnvelope;
                return entityStatesDto
            }
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    };

    clearEntityStates = async () => {
        this.entityStateRegistry.clear();
    }

    getEntityStatesByEntityId = async (id: string, params: URLSearchParams) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            return await agent.EntityStates.byEntityId(id, params);
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    };

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.entityStateRegistry.clear()
        this.loadEntityStates()
    }

    getEntityStateById = async (entityStateId: any): Promise<IEntityState> => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let es;
        try {
            if (!!entityStateId && typeof entityStateId !== undefined) {
                es = await agent.EntityStates.byId(entityStateId);
            }
        } catch (error: any) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId);
        }
        return es;
    };

    createEntityState = async (entityState: EntityStateFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.EntityStates.create(entityState);
            toast.success('Saved');
            runInAction(() => {
                this.loadEntityStates();
                this.entityStore.loadEntities();
                this.rootStore.modalStore.closeModal('EntityStateModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            });
        } catch (error: any) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            });
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    };

    editEntityState = async (entityState: IEntityState) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.EntityStates.update(entityState);
            toast.success('Saved');
            await this.entityStore.loadEntities();
            await this.loadEntityStates();
            runInAction(() => {
                this.rootStore.modalStore.closeModal('EntityStateModal')
            });
        } catch (error: any) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            });
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    };

    getEntityStateByConsumerId = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let consumer = await this.rootStore.consumerStore.getConsumerById(id)
            return await this.getEntityStateById(consumer.entityStateId)
        } catch (e) {
            this.rootStore.errorStore.addError(e)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    getEntityStateByEquipmentId = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let equipment = await this.rootStore.equipmentStore.getEquipmentById(id)
            return await this.getEntityStateById(equipment.entityStateId)
        } catch (e) {
            this.rootStore.errorStore.addError(e)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

}