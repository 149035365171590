export const functionOptions = [
    'Accessories',
    'Amplified phones',
    'Augmentative Communication Device',
    'Braille - Display',
    'Braille - Notetakers, Multipurpose Devices',
    'Hardware - Computers & Laptops',
    'Hardware - Extended Warranty',
    'Instructional Materials',
    'Magnifiers - CCTV & Electronic',
    'Magnifiers - Optical / Handheld',
    'Mobile / Cell Phones',
    'Mounts & Switches',
    'Signalers',
    'Software - Smartphone / Tablet Apps',
    'Software - Support, Upgrades, Maintenance',
    'Software',
    'Tablet / Media Player',
    'Video Phones, Captioned & Fax',
]