import React, {FunctionComponent, useRef, useState} from 'react'
import {Combobox} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../lib/utilities";
import {useVirtualizer} from '@tanstack/react-virtual';

export type OptionType = {
    id: any
    name: string
}

export interface ComboBoxProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'as'> {
    options: OptionType[]
    label?: string
    errMsg?: string
    prepend?: boolean
    accented?: boolean
    displayValue?: string

    onChange?(e: OptionType): void

    [x: string]: any
}

type Props = ComboBoxProps;

const Autocomplete: FunctionComponent<Props> = ({
                                                    options,
                                                    label,
                                                    children,
                                                    placeholder,
                                                    prepend = false,
                                                    accented = true,
                                                    errMsg = 'Please check your input',
                                                    ...rest
                                                }) => {


    const [query, setQuery] = useState('')
    const [selected, setSelected] = useState()

    const filteredItems =
        query === ''
            ? options
            : options.filter((option) =>
                option.name
                    ?.toLowerCase()
                    ?.replace(/\s+/g, '')
                    ?.includes(query
                        ?.toLowerCase()
                        ?.replace(/\s+/g, '')))

    const parentRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null)


    const rowVirtualizer = useVirtualizer({
        count: filteredItems.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 36,
        overscan: 5,
    })

    return <>
        <Combobox as={'div'} value={selected}
                  disabled={rest.disabled}
                  onChange={(e) => {
                      setSelected(e);
                      // @ts-ignore
                      !!rest.onChange && rest.onChange(e)
                  }}
        >

            {({open}) => (
                <div className={`relative flex flex-wrap`}>
                    {!!label && <Combobox.Label
                        className={`${prepend ?
                            `whitespace-nowrap border flex items-center rounded-l-md px-2 focus:outline-none ${accented ? 'border-blue-800 bg-blue-800 text-white' : 'border-gray-300 bg-gray-100 text-gray-700'}`
                            : 'w-full grow mb-1 text-gray-700'} 
                        
                        font-medium shrink`}>
                        {label}{rest.required &&
                        <span className={'text-red-600 text-xs'}> (required)</span>}
                    </Combobox.Label>}
                    <Combobox.Input
                        {...rest}
                        className={`peer ${prepend ? '' : 'block'} bg-gray-200/25 disabled:bg-gray-400/25 flex-0 w-full min-w-0 border border-gray-300 ${prepend && !!label ? 'border-x-0' : 'border-r-0 rounded-l-md '} py-2  px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm`}
                        aria-describedby={`${rest?.name}Placeholder`}
                        /** @ts-ignore */
                        displayValue={(option: OptionType) => !!option?.name ? option?.name : rest?.displayValue ?? ''}
                        onChange={(event) => setQuery(event.target.value)}
                        placeholder={rest?.placeholder ?? ''}
                    />
                    <Combobox.Button
                        className="bg-gray-200/25 peer-disabled:bg-gray-400/25 border border-gray-300 border-l-0 flex items-center rounded-r-md px-2 focus:outline-none shrink">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </Combobox.Button>

                    <br/>
                    <p
                        className={`text-sm peer-invalid:invisible peer-invalid:hidden w-full px-1 mt-2`}
                        id={`${rest.name}Placeholder`}>
                        {placeholder ? `e.g. ${placeholder}` : ''}
                    </p>
                    <p className={`text-red-600 text-sm visible peer-valid:invisible peer-valid:hidden w-full px-1 mt-2`}>
                        {errMsg}
                    </p>

                    {/*<Transition*/}
                    {/*    unmount={false}*/}
                    {/*    as={Fragment}*/}
                    {/*    enter="transition ease-in duration-150"*/}
                    {/*    enterFrom="opacity-0 scale-90"*/}
                    {/*    enterTo="opacity-100 scale-100"*/}
                    {/*    leave="transition ease-in duration-150"*/}
                    {/*    leaveFrom="opacity-100 scale-100"*/}
                    {/*    leaveTo="opacity-0 scale-90"*/}
                    {/*    afterLeave={() => setQuery('')}*/}
                    {/*>*/}
                    <div ref={parentRef}
                         className={`absolute ${open ? 'block' : 'hidden'} border ${prepend ? 'translate-y-10' : 'translate-y-16 mt-1'} z-10 min-h-[100%] max-h-96 w-full overflow-auto rounded-md text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm bg-white`}>

                        <Combobox.Options unmount={false} style={{minHeight: `${rowVirtualizer.getTotalSize() + 36}px`,}}>
                            <Combobox.Option
                                key={'clear'}
                                value={{id: '', name: ''}}
                                className={({active}) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9 flex',
                                        active ? 'bg-red-600 text-white' : 'text-gray-900'
                                    )
                                }

                            ><XMarkIcon className={'mr-2 h-5 w-5 inline-block'}/>Clear</Combobox.Option>
                            {children}
                            {filteredItems?.length > 0 ?
                                <>
                                    {rowVirtualizer.getVirtualItems().map((vi) => (
                                        <Combobox.Option
                                            key={vi.key}
                                            style={{
                                                height: `${vi.size}px`,
                                                transform: `translateY(${vi.end}px)`,
                                            }}
                                            value={filteredItems[vi.index]}
                                            className={({active}) =>
                                                classNames(
                                                    'absolute top-0 left-0 w-full cursor-default select-none py-2 pl-3 pr-9',
                                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                )
                                            }
                                        >
                                            {({active, selected}) => (
                                                <>
                                        <span
                                            className={classNames('block truncate', selected && 'font-semibold')}>{filteredItems[vi.index].name}</span>

                                                    {selected && (
                                                        <span
                                                            className={classNames(
                                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                active ? 'text-white' : 'text-indigo-600'
                                                            )}
                                                        ><CheckIcon className="h-5 w-5" aria-hidden="true"/></span>
                                                    )}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))}</> :
                                <span
                                    className={'block relative cursor-default select-none py-4 font-medium px-3 text-red-700 bg-red-100'}>Nothing matches your search</span>}
                        </Combobox.Options>
                    </div>
                    {/*</Transition>*/}
                </div>
            )}
        </Combobox>
    </>;
};

export default Autocomplete;