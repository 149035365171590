import React from "react";
import {RootStore} from "./RootStore";
import NiceModal from "@ebay/nice-modal-react";
import '../components/modals/ModalRegistry'
import {makeAutoObservable} from "mobx";

export default class ModalStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
    }

    activeModals: string[] = []

    registerModal = (modalName: string, modal: React.FC<any>, props?) => {
        NiceModal.register(modalName, modal, props)
    }

    openModal = (modal: any, ...props) => {
        this.activeModals.push(modal)
        // This timeout is used to provide a buffer between modal open and closes.
        // The buffer is needed so focus can be properly transferred. 508 Compliance
        return setTimeout(() => NiceModal.show(modal, {...props[0]}), 300)
    }

    closeModal = async (modalName: string) => {
        NiceModal.hide(modalName)
        setTimeout(() => {
            NiceModal.remove(modalName)
        }, 300)

        this.activeModals.splice(this.activeModals.indexOf(modalName), 1)
    }
}
