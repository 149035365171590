import axios from "axios";
import {api, responseBody} from "./apiBase.service";

const report = {

    byIdExcel: (id: string, paramId?: string) => {
        const idParameter = paramId ? `?idParameter=${paramId}` : ''
        return api.get(`/Report/${id}/excel${idParameter}`)
    },
    byIdPdf: (id: string, paramId?: string) => {
        const idParameter = paramId ? `?idParameter=${paramId}` : ''
        return api.get(`/Report/${id}/pdf${idParameter}`)
    },
    byIdJson: (id: string, paramId?: string) => {
        const idParameter = paramId ? `?idParameter=${paramId}` : ''
        return api.get(`/Report/${id}/json${idParameter}`)
    },
};

export default report