import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import EntityForm, {EntityFormProps} from "../../../pages/administration/entities/components/EntityForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, EntityFormProps {
}

type Props = OwnProps;

// @ts-ignore
const EntityModal = NiceModal.create<Props>(({entity, onClose, header}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <EntityForm entity={entity}/>
        </SlideOver>
    )
})

export default EntityModal;
