import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {AllocationFormValues, IAllocation} from "../../../../../models/Allocation/Allocation";
import {inputOnChange, suppressOnTabThrough} from "../../../../../lib/utilities";
import Input from '../../../../../components/Inputs/Input';
import Button from "../../../../../components/generic/Button";
import {IConsumer} from "../../../../../models/Consumer/Consumer";
import numberInputOptions from "../../../../../data/cleave/numberInputOptions";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface AllocationFormProps {
    allocation?: IAllocation
    consumer?: IConsumer
}

type Props = AllocationFormProps

const AllocationForm: React.FC<Props> = ({allocation, consumer}) => {
    // store
    const rootStore = useContext(RootStoreContext);
    const {editAllocation} = rootStore.allocationStore
    const {closeModal} = rootStore.modalStore

    // state
    const [currentAllocation, setCurrentAllocation] = useState(new AllocationFormValues(allocation));


    // functions
    const handleSubmit = (e) => {
        e.preventDefault()
        editAllocation(currentAllocation)
    }

    const handleChange = (e) => inputOnChange(e, setCurrentAllocation)

    const handleZeroIfEmpty = (e) => suppressOnTabThrough(e, setCurrentAllocation)

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full'}>
            <div>
            {allocation?.state && <Input label={'State'} disabled value={allocation.state}/>}

                        <Input label={'State Allocation Amount'}
                               name={'stateAllocationAmount'}
                               value={currentAllocation.stateAllocationAmount}
                               options={numberInputOptions}
                               onChange={handleChange}
                               onBlur={handleZeroIfEmpty}
                        />
            </div>

            <div className={'flex justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}>Save</Button>
                <span className={'m-2'}/>
                <Button color="red" onClick={() => closeModal('AllocationModal')}
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>
    );
};
export default observer(AllocationForm)