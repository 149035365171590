import React, {FunctionComponent, useState} from 'react';

import ClickableIcon from "../clickables/ClickableIcon";
import {CloudArrowUpIcon, XMarkIcon} from "@heroicons/react/24/outline";

interface OwnProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
    acceptedFileTypes?: string
    label?: string
    maxNumberOfFiles?: number

    onChange?(files: File[]): void
}

type Props = OwnProps;

const FileDragAndDrop: FunctionComponent<Props> = ({
                                                       acceptedFileTypes,
                                                       onChange,
                                                       label,
                                                       maxNumberOfFiles = 0,
                                                       ...rest
                                                   }) => {
    // Local State
    const [classes, setClasses] = useState('border-gray-300');
    const [currentFiles, setCurrentFiles] = useState([] as File[]);
    const [error, setError] = useState('');

    // Functions
    const handleFiles = (files) => {
        setError('')
        if (!!maxNumberOfFiles && (files.length + currentFiles.length) > maxNumberOfFiles) {
            setClasses('border-red-400')
            return setError('Too many files selected')
        }


        for (let file of files) {
            let ext = file.name.split('.').reverse()[0]
            if (!!acceptedFileTypes && !acceptedFileTypes.toLowerCase().includes(ext.toLowerCase())) {
                setError('One or more of the selected files are of an invalid type')
                setClasses('border-red-400')
                return
            }
        }
        setCurrentFiles(prev => {
            const tmpFiles = [...prev, ...files]

            !!onChange && onChange(tmpFiles)
            return tmpFiles
        })
    }

    const dragEnter = (e) => {
        e.preventDefault()
        setError('')
    }
    const dragLeave = (e) => {
        e.preventDefault()
        setClasses('border-gray-300')
    }
    const dragOver = (e) => {
        e.preventDefault()
        setClasses('border-blue-300')
    }

    const handleFile = (e) => {
        e.persist()
        e.preventDefault();
        setClasses('border-gray-300')
        const {files} = e.dataTransfer ?? e.currentTarget
        handleFiles(files)
    }


    const removeFile = (idx) => setCurrentFiles(prev => {
        let tmpFiles = [...prev]
        tmpFiles.splice(idx, 1)

        !!onChange && onChange(tmpFiles)
        return tmpFiles
    })

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return (<>
            {!!label && <label htmlFor={rest.name}>{label}{rest.required &&
            <span className={'text-xs text-red-600'}> (required)</span>}</label>}
            <div className="mt-1 sm:mt-0">
                <div
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={handleFile}
                    className={`w-full flex justify-center px-6 pt-5 pb-6 text-gray-400 border-2 ${classes}  border-dashed rounded-md`}>
                    <div className="space-y-1 text-center justify-center flex flex-col items-center">
                        <CloudArrowUpIcon  className={'h-16 w-16'}/>
                        <div className="flex justify-center text-sm text-gray-600 mt-3">
                            <label htmlFor="file-upload"
                                   className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                <span className={'text-blue-400'}>Upload a file</span>
                                <input {...rest}
                                                                                             id="file-upload"
                                                                                             name="file-upload"
                                                                                             type="file"
                                                                                             className="sr-only"
                                                                                             accept={acceptedFileTypes ?? ''}
                                                                                             onChange={handleFile}
                            /></label><p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">{acceptedFileTypes?.replaceAll('.', '').replaceAll(',', ', ').toUpperCase()}</p>

                        <p className={`text-xs ${!!error ? 'shake' : 'hide'} text-red-600`}>{error}</p>
                    </div>
                </div>
            </div>

            <div role={'list'}>
                {currentFiles.map((file, idx) => <div
                    className={'flex text-xs transition-all justify-between hover:bg-gray-200 p-2 rounded w-full'} key={idx}>
                    <p className={'p-0 m-0'}><b>{file.name}</b> - {formatBytes(file.size)}</p>
                    <ClickableIcon tooltip={'remove'} color={'red'} tooltipDirection={'left'}
                                   onClick={() => removeFile(idx)}><XMarkIcon className={'h-6 w-6'}/></ClickableIcon>
                </div>)}
            </div>
        </>
    );
};

export default FileDragAndDrop;
