import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {inputOnChange, objectDateConverter} from '../../../../lib/utilities'
import {AdministrativeFormValues, IAdministrative} from '../../../../models/Administrative/Administrative'
import {RootStoreContext} from '../../../../stores/RootStore'
import EntityStateAutoCompleteInput from '../../../../components/form/EntityStatesAutoCompleteInput'
import TechnicianAutoCompleteInput from '../../../../components/form/TechnicianAutoCompleteInput'
import Input from "../../../../components/Inputs/Input";
import TextArea from "../../../../components/form/TextArea";
import Button from "../../../../components/generic/Button";
import dateInputOptions from "../../../../data/cleave/dateInputOptions";
import Toggle from "../../../../components/generic/Toggle";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";


export interface AdministrativeFormProps {
    administrative?: IAdministrative;
}

type Props = AdministrativeFormProps

const AdministrativeForm: React.FC<Props> = ({administrative}) => {
    // store
    const rootStore = useContext(RootStoreContext)
    const {currentEntityState} = rootStore.commonStore
    const {entityStatesByName} = rootStore.entityStateStore
    const {closeModal, openModal} = rootStore.modalStore
    const {createAdministrative, editAdministrative} = rootStore.administrativeStore

    // state
    // const [stateProgram, setStateProgram] = useState({} as IEntityState);
    const [currentAdministrative, setCurrentAdministrative] = useState(new AdministrativeFormValues(administrative));
    const [addCost, setAddCost] = useState(true);

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);


    // USE EFFECTS
    useEffect(() => {
        objectDateConverter(administrative, setCurrentAdministrative)
        if (administrative) {
            setCurrentAdministrative(administrative);
        }
    }, [administrative])

    useEffect(() => {
        if (!currentAdministrative.id && (!!entityStatesByName && entityStatesByName.length === 1)) {
            setCurrentAdministrative((prev: AdministrativeFormValues) => {
                return {
                    ...prev,
                    entityStateId: currentEntityState.id || '',
                    entityState: currentEntityState.id ? `${currentEntityState.stateName} (${currentEntityState.entityName})` : ''
                }
            })
        }
    }, [currentEntityState])

    useEffect(() => {
        if (!currentAdministrative.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentAdministrative((prev: AdministrativeFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates])


    // This function limits the number of decimal places available in the input
    const handleChange = (e) => {
        inputOnChange(e, setCurrentAdministrative)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newAdministrative = {...currentAdministrative}
        if (!newAdministrative.id || newAdministrative.id === '') {
            delete newAdministrative.id
            return createAdministrative(newAdministrative).then(data => addCostAfterSaving(data))
        } else {
            return editAdministrative(newAdministrative)
        }
    }

    const addCostAfterSaving = async (data) => {
        if (!!data && addCost) await openModal('SubItemModal', {
            header: 'New Administrative Cost',
            lineItemId: data.id,
            lineItemType: 'Administrative',
            entityStateId: data.entityStateId,
        })
    }


    const handleAutoComplete = (value, name) => handleChange({currentTarget: {name, value,}})

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit}
              className={'flex flex-col justify-between flex-1 h-full'}>
            <div className={'overflow-y-auto overflow-x-hidden flex-1'}>
                    <div className='w-full'>
                        <EntityStateAutoCompleteInput
                            required
                            isLocked={!currentAdministrative.id && !!localEntityStates && localEntityStates.length === 1}
                            onChange={({id}) => handleAutoComplete(id, 'entityStateId')}
                            name={'entityStateId'}
                            placeholder={'California'}
                            displayValue={(currentAdministrative?.entityStateId) ? (currentAdministrative?.entityState || 'State Program already selected') : ''}
                        />
                    </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <div className='w-full'>
                        <TechnicianAutoCompleteInput
                            name={'technicianId'}
                            onChange={({id}) => handleAutoComplete(id, 'technicianId')}
                            displayValue={currentAdministrative.technicianId ? (currentAdministrative?.technicianName || 'Technician Already Assigned') : ''}
                        />
                    </div>
                </div>


                <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                        <Input label={'Date Performed'}
                               required
                               value={`${currentAdministrative.datePerformed ?? ''}`}
                               name={'datePerformed'}
                               onChange={handleChange}
                               options={dateInputOptions}
                               errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}

                        />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <TextArea
                            label={'Description'}
                            value={currentAdministrative.description}
                            name={'description'}
                            onChange={handleChange}
                            placeholder={'Mad  Scientist'}
                        />
                </div>

                {!currentAdministrative.id ?
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                            <Toggle label={'add cost after saving'} onChange={() => setAddCost(prev => !prev)}  checked={addCost} name={'addCostToggle'}/>
                    </div>
                    : ''}
            </div>

            <div className={'flex justify-end gap-4'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'} type={'submit'}>Save</Button>
                <Button onClick={() => closeModal('AdministrativeModal')} color="red" name={'cancel'}
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>
    );
};
export default observer(AdministrativeForm)