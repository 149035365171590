import React, {FunctionComponent, useState} from 'react';

interface OwnProps extends React.HTMLProps<HTMLTextAreaElement> {
    label: string
    errMsg?: string
}

type Props = OwnProps;

const TextArea: FunctionComponent<Props> = ({label, className, placeholder, errMsg, ...rest}) => {
    const [errorMsg, setErrorMsg] = useState(errMsg ?? 'Please check your input');

    return (
        (
            <div className={'mb-3'}>
                {!!label && (
                    <label
                        htmlFor={rest.id ?? rest.name ?? label?.replaceAll(/[^\d\w]/g, '')}
                        className="block font-medium text-slate-700"
                    >
                        {label}
                        {rest.required &&
                            <span className={'text-red-600 text-xs'}> (required)</span>}
                    </label>
                )}
                <div className="mt-1">
                    <textarea id={rest?.name}
                       className={`peer px-3 py-2 bg-gray-200/25 border shadow-sm border-slate-300 placeholder-slate-400 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 disabled:shadow-none disabled:cursor-not-allowed disabled:bg-gray-200 ${className}`} {...rest}/>
                    <p
                        className="text-sm mt-2 peer-invalid:invisible peer-invalid:hidden px-1"
                        id={`${rest.name}Placeholder`}
                    >
                        {placeholder ? `e.g. ${placeholder}` : ''}
                    </p>
                    <p className="mt-2 invisible peer-invalid:visible text-red-600 text-sm peer-valid:hidden px-1">
                        {errorMsg}
                    </p>
                </div>
            </div>
        )

    );
};

export default TextArea;
        // <div className={'w-full'}>
        //     <label className={'block mb-2'} htmlFor={rest?.name} id={`${rest.name}Label`}>{label}{rest.required &&
        //     <span className={'text-red-600'}>*</span>}</label>
        //     <textarea id={rest?.name} className={`border-2 mb-3 p-2 rounded block w-full ${className ?? ''}`} {...rest}
        //               onChange={handleChange}
        //     />
        // </div>
