import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {
    IShippingAndTaxAttachment,
    ShippingAndTaxAttachmentFormValues
} from '../../models/ShippingAndTax/ShippingAndTaxAttachment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class ShippingAndTaxAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.shippingAndTaxAttachmentRegistry.clear()
                this.loadShippingAndTaxAttachment()
            }
        )
        reaction(
            () => this.currentShippingAndTaxId,
            () => {
                this.page = 0
                this.shippingAndTaxAttachmentRegistry.clear()
                !!this.currentShippingAndTaxId ? this.getShippingAndTaxAttachmentsByShippingAndTaxId() : this.loadShippingAndTaxAttachment()
            }
        )
    }

    shippingAndTaxAttachmentRegistry = new Map()
    shippingAndTaxAttachment: IShippingAndTaxAttachment | null = null
    editMode = false


    currentShippingAndTaxId = '';
    shippingAndTaxAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentShippingAndTaxId = (shippingAndTaxId: string) => {
        this.currentShippingAndTaxId = shippingAndTaxId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.shippingAndTaxAttachmentCount / this.limit)
    }

    get shippingAndTaxAttachmentsById() {
        return Array.from(this.shippingAndTaxAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get shippingAndTaxAttachmentsByDate() {
        return Array.from(this.shippingAndTaxAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getShippingAndTaxAttachment = (id: string) => {
        return this.shippingAndTaxAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.shippingAndTaxAttachmentRegistry.clear()
        this.loadShippingAndTaxAttachment()
    }

    clearShippingAndTaxAttachment = () => {
        this.shippingAndTaxAttachmentRegistry.clear()
    }

    loadShippingAndTaxAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentShippingAndTaxId) {
                const shippingAndTaxAttachmentsEnvelope = await Agent.ShippingAndTaxAttachments.list(this.axiosParams);
                const {modelsDto, modelCount} = shippingAndTaxAttachmentsEnvelope;

                runInAction( () => {
                    this.clearShippingAndTaxAttachment()
                    modelsDto.forEach((shippingAndTaxAttachment: IShippingAndTaxAttachment) => {
                        this.shippingAndTaxAttachmentRegistry.set(shippingAndTaxAttachment.id, shippingAndTaxAttachment)
                    })
                    this.shippingAndTaxAttachmentCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction( () => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getShippingAndTaxAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let shippingAndTaxAttachment = await Agent.ShippingAndTaxAttachments.byId(id)
            runInAction( () => {
                this.shippingAndTaxAttachment = shippingAndTaxAttachment
                this.shippingAndTaxAttachmentRegistry.set(shippingAndTaxAttachment.id, shippingAndTaxAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return shippingAndTaxAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction( () => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getShippingAndTaxAttachmentsByShippingAndTaxId = async (shippingAndTaxId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let shippingAndTaxAttachmentEnvelope = await Agent.ShippingAndTaxAttachments.byShippingAndTaxId(shippingAndTaxId || this.currentShippingAndTaxId, this.axiosParams)
            const {modelsDto, modelCount} = shippingAndTaxAttachmentEnvelope
            runInAction( () => {
                modelsDto.forEach(shippingAndTaxAttachment => {
                    this.shippingAndTaxAttachmentRegistry.set(shippingAndTaxAttachment.id, shippingAndTaxAttachment)
                })

                this.shippingAndTaxAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction( () => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createShippingAndTaxAttachment = async (shippingAndTaxAttachment: ShippingAndTaxAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newAttachment = await Agent.ShippingAndTaxAttachments.create(shippingAndTaxAttachment)
            await this.loadShippingAndTaxAttachment()

            runInAction( () => {
                this.shippingAndTaxAttachmentRegistry.set(newAttachment.id, newAttachment)
                this.rootStore.modalStore.closeModal('AttachmentModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteShippingAndTaxAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.ShippingAndTaxAttachments.delete(id)
            runInAction(() => {
                this.shippingAndTaxAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('ShippingAndTaxAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            runInAction( () => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }
    }


}