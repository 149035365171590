import {Envelope} from '../generic/Envelope';

export interface IEntityStateEnvelope extends Envelope<IEntityState> {
}

export interface IEntityState {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityId: string
    entityName?: string
    stateId?: string
    abbreviation: string
    stateName?: string
    active?: boolean
    startDate: Date
    endDate?: Date
}

export interface IEntityStateFormValues extends Partial<IEntityState> {
}

//empty form model
export class EntityStateFormValues implements IEntityStateFormValues {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityId: string = ''
    entityName?: string
    stateId?: string
    abbreviation: string = ''
    stateName?: string
    active?: boolean
    startDate: Date = new Date()
    endDate?: Date

    constructor(entityState?: IEntityState) {
        if (entityState) {
            Object.assign(this, entityState);
        }
    }
}