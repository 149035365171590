import axios from "axios";
import {EntityStateFormValues, IEntityState, IEntityStateEnvelope} from "../../models/Entity/EntityState";
import {api, responseBody} from "./apiBase.service";

const EntityStates = {
    list: (
        params: URLSearchParams
    ): Promise<IEntityStateEnvelope> =>
        axios
            .get("/EntityState", {params: params})
            .then(responseBody),

    create: (entityState: EntityStateFormValues) =>
        api.post(`/EntityState/`, entityState),
    update: (entityState: IEntityState) =>
        api.put(`/EntityState/${entityState.id}`, entityState),
    delete: (id: string) =>
        api.del(`/EntityState/${id}`),
    byId: (id: string, params?: URLSearchParams) =>
        api.get(`/EntityState/${id}`, {params}),
    byEntityId: (id: string, params?: URLSearchParams) =>
        api.get(`/EntityState/Entity/${id}`, {params}),

};

export default EntityStates