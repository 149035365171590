import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import TechnicianForm, {TechnicianFormProps} from "../../../pages/administration/technicians/components/TechnicianForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, TechnicianFormProps {
}

type Props = OwnProps;

// @ts-ignore
const TechnicianModal = NiceModal.create<Props>(({technician, edit, header, onClose}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <TechnicianForm technician={technician} edit={edit}/>
        </SlideOver>
    )
})

export default TechnicianModal;
