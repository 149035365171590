import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import SubItemAttachmentsTable, {SubItemAttachmentTableProps} from "../../subitems/SubItemAttachmentsTable";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, SubItemAttachmentTableProps {}

type Props = OwnProps;

// @ts-ignore
const SubItemAttachmentsTableModal = NiceModal.create<Props>(({onClose, header, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <SubItemAttachmentsTable {...rest}/>
        </SlideOver>
    )
})

export default SubItemAttachmentsTableModal;
