import axios from "axios";
import {
    EntityStateFilingFrequencyFormValues,
    IEntityStateFilingFrequencyApproval,
    IEntityStateFilingFrequencyEnvelope
} from "../../models/EntityStateFilingFrequency";
import {api, responseBody} from "./apiBase.service";

const EntityStateFilingFrequency = {
    list: (
        params?: URLSearchParams
    ): Promise<IEntityStateFilingFrequencyEnvelope> =>
        axios.get("/EntityStateFilingFrequency", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/EntityStateFilingFrequency/${id}`),
    create: (EntityStateFilingFreq: EntityStateFilingFrequencyFormValues) =>
        api.post(`/EntityStateFilingFrequency/`, EntityStateFilingFreq),
    approve: (EntityStateFilingFreqApproval: IEntityStateFilingFrequencyApproval) =>
        api.put(`/EntityStateFilingFrequency/${EntityStateFilingFreqApproval.id}/approve`, EntityStateFilingFreqApproval),
};

export default EntityStateFilingFrequency