import axios from "axios";
import {
    AdministrativeNoteFormValues,
    IAdministrativeNote,
    IAdministrativeNoteEnvelope
} from "../../models/Administrative/AdministrativeNote";
import {api, responseBody} from "./apiBase.service";

const AdministrativeNotes = {
    list: (params: URLSearchParams): Promise<IAdministrativeNoteEnvelope> =>
        axios
            .get("/Administrative/Note", {params: params})
            .then(responseBody),
    create: (Administrative: AdministrativeNoteFormValues) =>
        api.post(`/Administrative/Note`, Administrative),
    update: (Administrative: IAdministrativeNote) =>
        api.put(`/Administrative/Note/${Administrative.id}`, Administrative),
    delete: (id: string) =>
        api.del(`/Administrative/Note/${id}`),
    byId: (id: string) => api.get(`/Administrative/Note/${id}`),
    byAdministrativeId: (administrativeId: string, params: URLSearchParams): Promise<IAdministrativeNoteEnvelope> =>
        axios.get(`/Administrative/${administrativeId}/Note`, {params: params})
            .then(responseBody),
};

export default AdministrativeNotes