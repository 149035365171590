import {
    AuditReportAttachmentFormValues,
    IAuditReportAttachmentEnvelope,
} from "../../models/AuditReport/AuditReportAttachment";

import {api} from "./apiBase.service";

const AuditReportsAttachment = {
    list: (params: URLSearchParams): Promise<IAuditReportAttachmentEnvelope> =>
        api.get(`/AuditReport/attachment`, {params: params}),
    listById: (id: string, params: URLSearchParams): Promise<IAuditReportAttachmentEnvelope> =>
        api.get(`/AuditReport/${id}/attachment`, {params: params}),
    upload: (auditReportAttachment: AuditReportAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in auditReportAttachment) {
            formData.append(key, auditReportAttachment[key])
        }
        return api.post(`/AuditReport/Attachment`, formData)
    },
    downloadById: (id: string) =>
        api.get(`/AuditReport/Attachment/${id}`),
    delete: (id: string) =>
        api.del(`/AuditReport/Attachment/${id}`)
}

export default AuditReportsAttachment