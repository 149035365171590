import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import NotificationForm, {
    NotificationFormProps
} from "../../../pages/administration/notification/components/NotificationForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, NotificationFormProps {
}

type Props = OwnProps;

// @ts-ignore
const NotificationModal = NiceModal.create<Props>(({Notification, header, onClose, autoFillUser}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <NotificationForm Notification={Notification} autoFillUser={autoFillUser}/>
        </SlideOver>
    )
})

export default NotificationModal;
