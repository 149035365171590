import axios from "axios";
import {ILineItemAttachmentEnvelope, LineItemAttachmentFormValues} from "../../models/LineItemAttachment";
import {api, responseBody} from "./apiBase.service";

const LineItemAttachments = {
    list: (params: URLSearchParams): Promise<ILineItemAttachmentEnvelope> =>
        axios
            .get("/Attachment", {params: params})
            .then(responseBody),
    create: (attachment: LineItemAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in attachment) {
            if (typeof attachment[key] === 'object' && attachment[key].length > 0) {
                for (let item of attachment[key]) {
                    formData.append(key, item)
                }
            } else {
                formData.append(key, attachment[key])
            }
        }
        return api.post(`/Attachment`, formData)
    },
    update: (attachment: LineItemAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in attachment) {
            formData.append(key, attachment[key])
        }
        return api.put(`/Attachment/${attachment.id}`, formData)
    },
    byId: (id: string) => api.get(`/Attachment/${id}`),
    del: (id: string) => api.del(`/Attachment/${id}`),
};

export default LineItemAttachments