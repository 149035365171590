import {useState} from "react";

export const useSessionStorage = (key, initialValue) => {
    const refreshValue = (cb?: (newValue?: any) => void) => {
        let returnVal
        if (typeof window === "undefined") {
            returnVal = initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.sessionStorage.getItem(key);
            // Parse stored json or if none return initialValue
            returnVal = item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.error(error);
            returnVal = initialValue;
        }
        !!cb && cb(returnVal)
        return returnVal
    }

    const [storedValue, setStoredValue] = useState(refreshValue);

    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== "undefined") {
                window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error);
        }
    };
    return [storedValue, setValue, refreshValue];
}