import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import {
    INationalOutreachBudgetNote,
    NationalOutreachBudgetNoteFormValues
} from "../../models/NationalOutreach/NationalOutreachBudgetNote";

export default class NationalOutreachBudgetNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.nationalOutreachBudgetNoteRegistry.clear()
                this.loadNationalOutreachBudgetNote()
            }
        )
        reaction(
            () => this.currentNationalOutreachBudgetId,
            () => {

                this.page = 0
                this.nationalOutreachBudgetNoteRegistry.clear()
                this.loadNationalOutreachBudgetNote()
            }
        )
    }

    nationalOutreachBudgetNoteRegistry = new Map()
    nationalOutreachBudgetNote: INationalOutreachBudgetNote | null = null
    editMode = false


    currentNationalOutreachBudgetId = '';
    nationalOutreachBudgetNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentNationalOutreachBudgetId = (nationalOutreachBudgetId: string) => {
        this.currentNationalOutreachBudgetId = nationalOutreachBudgetId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.nationalOutreachBudgetNoteCount / this.limit)
    }

    get nationalOutreachBudgetNotesList() {
        return Array.from(this.nationalOutreachBudgetNoteRegistry.values())
    }

    getNationalOutreachBudgetNote = (id: string) => {
        return this.nationalOutreachBudgetNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.nationalOutreachBudgetNoteRegistry.clear()
        this.loadNationalOutreachBudgetNote()
    }

    clearNationalOutreachBudgetNote = () => {
        this.nationalOutreachBudgetNoteRegistry.clear()
    }

    loadNationalOutreachBudgetNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentNationalOutreachBudgetId && typeof this.currentNationalOutreachBudgetId !== undefined) {
                const nationalOutreachBudgetNotesEnvelope = await Agent.NationalOutreachBudgetNotes.byNationalOutreachBudgetId(this.currentNationalOutreachBudgetId, this.axiosParams);
                const {modelsDto, modelCount} = nationalOutreachBudgetNotesEnvelope;
                runInAction(() => {
                    modelsDto.forEach((nationalOutreachBudgetNote: INationalOutreachBudgetNote) => {
                        this.nationalOutreachBudgetNoteRegistry.set(nationalOutreachBudgetNote.id, nationalOutreachBudgetNote)
                    })
                    this.nationalOutreachBudgetNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getNationalOutreachBudgetNoteById = async (id: string) => {
        let nationalOutreachBudgetNote = this.getNationalOutreachBudgetNote(id)
        if (nationalOutreachBudgetNote) {
            this.nationalOutreachBudgetNote = nationalOutreachBudgetNote
            return nationalOutreachBudgetNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                nationalOutreachBudgetNote = await Agent.NationalOutreachBudgetNotes.byId(id)
                runInAction(() => {
                    this.nationalOutreachBudgetNote = nationalOutreachBudgetNote
                    this.nationalOutreachBudgetNoteRegistry.set(nationalOutreachBudgetNote.id, nationalOutreachBudgetNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return nationalOutreachBudgetNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createNationalOutreachBudgetNote = async (nationalOutreachBudgetNote: INationalOutreachBudgetNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newNationalOutreachBudgetNote = await Agent.NationalOutreachBudgetNotes.create(nationalOutreachBudgetNote)
            await this.loadNationalOutreachBudgetNote()
            runInAction(() => {
                this.nationalOutreachBudgetNoteRegistry.set(newNationalOutreachBudgetNote.id, newNationalOutreachBudgetNote)
                this.rootStore.modalStore.closeModal('NoteModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editNationalOutreachBudgetNote = (nationalOutreachBudgetNote: NationalOutreachBudgetNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.NationalOutreachBudgetNotes.update(nationalOutreachBudgetNote).then((updatedNationalOutreachBudgetNote) => {
            runInAction(() => {
                this.nationalOutreachBudgetNoteRegistry.set(updatedNationalOutreachBudgetNote.id, updatedNationalOutreachBudgetNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteNationalOutreachBudgetNote = async (nationalOutreachBudgetNote: INationalOutreachBudgetNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.NationalOutreachBudgetNotes.delete(nationalOutreachBudgetNote.id)
            runInAction(() => {
                this.nationalOutreachBudgetNoteRegistry.delete(nationalOutreachBudgetNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }

    }


}