import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import EquipmentReturnForm, {
    EquipmentReturnFormProps
} from "../../../pages/consumers/equipment/components/Equipment/EquipmentReturnForm";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, EquipmentReturnFormProps {
}

type Props = OwnProps;

// @ts-ignore
const EquipmentReturnModal = NiceModal.create<Props>(({equipment, header, onClose, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <EquipmentReturnForm equipment={equipment} {...rest}/>
        </SlideOver>
    )
})

export default EquipmentReturnModal;
