import {RootStore} from "./RootStore";
import agent from "../api/Agent";
import Agent from "../api/Agent";
import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {ContactFormValues, IContact} from '../models/Contact'
import {toast} from "react-toastify";

export default class ContactsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.contactsRegistry.clear();
                this.loadContacts();
            }
        )
    }

    currentContact?: IContact;
    contactsCount = 0;
    contactsRegistry = new Map();
    limit = 5;
    page = 0;
    predicate = new Map();

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(this.limit));
        params.append('offset', `${this.page ? this.page * this.limit : 0}`);
        this.predicate.forEach((value, key) => {
            params.append(key, value);
        });
        return params;
    }

    get contactsList() {
        // @ts-ignore
        return Array.from(this.contactsRegistry.values()).sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get pageCount() {
        return Math.ceil(this.contactsCount / this.limit)
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.contactsRegistry.clear()
        this.loadContacts()
    }

    clearContacts = async () => {
        this.contactsRegistry.clear()
    }

    createContact = async(contact: ContactFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.Contacts.create(contact);
            await this.loadContacts()
            toast.success('Saved')
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('ContactsModal')
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteContact = async (contact: IContact) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            if (!contact.id) throw new Error('Contact Id is required to delete record')
            await Agent.Contacts.delete(contact.id)
            runInAction(() => {
                this.contactsRegistry.delete(contact.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.loadContacts()
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editContact = async (contact: IContact) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedContact = await agent.Contacts.update(contact)
            await this.loadContacts()

            runInAction(() => {
                this.rootStore.modalStore.closeModal('ContactsModal')
                if (!!this.currentContact) {
                    this.setCurrentContact(updatedContact)
                }
            })

            toast.success('Saved')
            return updatedContact
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    getContactsByEntityId = async (entityId: string) => {
        const params = new URLSearchParams();
        params.append('limit', String(this.limit));
        params.append('offset', `${this.page ? this.page * this.limit : 0}`);
        params.append('entityId', entityId);
        let loadingId = this.rootStore.commonStore.setIsLoading();
        let contactsEnvelope;
        try {
            contactsEnvelope = await agent.Contacts.list(params);
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId);
        }

        return contactsEnvelope;
    }

    getContactsById = async (contactId) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let currentContact
        try {
            currentContact = await agent.Contacts.byId(contactId)
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return currentContact
    }

    loadContacts = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading();
        try {
            const contactsEnvelope = await agent.Contacts.list(this.axiosParams);
            let {modelsDto, modelCount} = contactsEnvelope;

            runInAction(() => {
                this.clearContacts();
                modelsDto.forEach((contact) => {
                    this.contactsRegistry.set(contact.id, contact)
                })
                this.contactsCount = modelCount;
            })

        } catch(error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    setCurrentContact = (contact?) => {
        this.currentContact = contact;
    }

    setLimit = (limit: number) => {
        this.limit = limit;
    }

    setPage = (page: number) => {
        this.page = page;
    }

    setPredicate(predicate?: string, value?: any) {
        if (!predicate || predicate === '')
            this.predicate.clear()
        else {
            this.predicate.delete(predicate);
            if (value)
                this.predicate.set(predicate, value);
        }

        this.contactsRegistry.clear();
    }

}
