import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {inputOnChange, objectDateConverter} from '../../../../lib/utilities'
import {IShippingAndTax, ShippingAndTaxFormValues} from '../../../../models/ShippingAndTax/ShippingAndTax'
import {RootStoreContext} from '../../../../stores/RootStore'
import EntityStatesAutoCompleteInput from '../../../../components/form/EntityStatesAutoCompleteInput'
import Input from "../../../../components/Inputs/Input";
import TextArea from "../../../../components/form/TextArea";
import EquipmentMultiselect from "../../../consumers/training/components/Training/EquipmentMultiselect";
import {IEquipment} from "../../../../models/Equipment/Equipment";
import dateInputOptions from "../../../../data/cleave/dateInputOptions";
import Button from "../../../../components/generic/Button";
import ConsumerAutoCompleteInput from "../../../../components/form/ConsumerAutoCompleteInput";
import Toggle from '../../../../components/generic/Toggle'
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import {CheckIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";


export interface ShippingAndTaxFormProps {
    shippingAndTax?: IShippingAndTax
    equipment?: IEquipment
}

type Props = ShippingAndTaxFormProps

const ShippingAndTaxForm: React.FC<Props> = ({shippingAndTax, equipment}) => {
    const maxStep = !!equipment ? 0 : 1

    // Mobx Store
    const rootStore = useContext(RootStoreContext)
    const {currentEntityState} = rootStore.commonStore
    const {entityStatesByName} = rootStore.entityStateStore
    const {closeModal, openModal} = rootStore.modalStore;
    const {createShippingAndTax, editShippingAndTax} = rootStore.shippingAndTaxStore


    // Local State
    const [step, setStep] = useState(0);
    const [animation, setAnimation] = useState('from-right');
    const [currentShippingAndTax, setCurrentShippingAndTax] = useState(shippingAndTax ?? {} as IShippingAndTax);
    const [currentEquipment, setCurrentEquipment] = useState(equipment);
    const [consumerId, setConsumerId] = useState('');
    const [addCost, setAddCost] = useState(true);


    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);


    // USE EFFECTS
    useEffect(() => {
        objectDateConverter(shippingAndTax, setCurrentShippingAndTax)

        if (!!shippingAndTax) {
            setCurrentShippingAndTax(shippingAndTax);
        }
    }, [shippingAndTax])

    useEffect(() => {
        if (!!equipment) {
            setCurrentEquipment(equipment);
        }
    }, [equipment])

    useEffect(() => {
        if (!currentShippingAndTax.entityStateId && (!!entityStatesByName && entityStatesByName.length === 1)) {
            setCurrentShippingAndTax((prev: IShippingAndTax) => {
                return {
                    ...prev,
                    entityStateId: currentEntityState.id || '',
                    entityState: currentEntityState.entityName
                }
            })
        }
    }, [currentEntityState])

    useEffect(() => {
        if (!currentShippingAndTax.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentShippingAndTax((prev: ShippingAndTaxFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates])


    // Functions
    const handleChange = (e) => {
        inputOnChange(e, setCurrentShippingAndTax)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newShippingAndTax = {...currentShippingAndTax}
        if (!newShippingAndTax.id || newShippingAndTax.id === '') {
            delete newShippingAndTax.id
            if (!!currentEquipment?.id && 'id' in currentEquipment) {
                newShippingAndTax.equipmentIds = [currentEquipment.id]
            }
            return createShippingAndTax(newShippingAndTax).then(data => addCostAfterSaving(data))
        } else {
            return editShippingAndTax(newShippingAndTax)
        }
    }

    const addCostAfterSaving = async (data) => {
        if (!!data && addCost) await openModal('SubItemModal', {
            header: 'New Shipping & Tax Cost',
            lineItemId: data.id,
            lineItemType: 'ShippingAndTax',
            entityStateId: data.entityStateId,
        })
    }

    const handleNavigation = (newStep) => {
        setAnimation('to-left')
        setTimeout(() => {
            setStep(newStep)
            setAnimation('from-right')
        }, 600)
    }

    const handleEquipmentIdsChange = (equipmentIds) => {
        let tmpShippingAndTax = {...currentShippingAndTax};
        tmpShippingAndTax.equipmentIds = equipmentIds;
        setCurrentShippingAndTax(tmpShippingAndTax);
    }


    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit}
              className={'flex flex-col justify-between flex-1 h-full overflow-x-hidden'}>
            <div className={''}>
                <div className={`${step === 0 ? animation : 'hide'}`}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                        <EntityStatesAutoCompleteInput
                            required
                            onChange={({id}) => handleAutoComplete(id, 'entityStateId')}
                            displayValue={(currentShippingAndTax.entityStateId) ? (currentShippingAndTax?.entityState || 'State Program already selected') : ''}
                            placeholder={'California'}
                            label={'State Program'}
                            isLocked={!currentShippingAndTax.id && !!localEntityStates && localEntityStates.length === 1}
                            name='entityStateId'
                        />
                        <Input label={'Invoice Date'} name={'invoiceDate'} onChange={handleChange}
                               options={dateInputOptions}
                               errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                               value={`${currentShippingAndTax.invoiceDate ?? ''}`} required/>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <Input label={'Receipt Number'} name={'receiptNumber'} onChange={handleChange}
                               value={currentShippingAndTax.receiptNumber} placeholder={'20151021'}/>

                        {!!equipment && <Input label={'Equipment'} disabled value={equipment?.equipmentName}/>}
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <TextArea label={'Description'} name={'description'} onChange={handleChange}
                                  value={currentShippingAndTax.description}
                                  placeholder={'Doc Brown needed a new flux capacitor for the Delorean.'}/>
                    </div>

                    {!currentShippingAndTax.id ?
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                            <Toggle label={'add cost after saving'} onChange={() => setAddCost(prev => !prev)}
                                    checked={addCost} name={'addCostToggle'}/>
                        </div>
                        : ''}
                </div>


                <div className={`${step === 1 ? animation : 'hide'}`}>
                    <ConsumerAutoCompleteInput onChange={({id}) => setConsumerId(id)}/>
                    <EquipmentMultiselect value={currentShippingAndTax?.equipmentIds ?? []}
                                          onChange={handleEquipmentIdsChange}
                                          consumerId={consumerId}
                    />
                </div>
            </div>

            <div className={'flex justify-between gap-4 mt-3'}>
                {!equipment && <div className={'flex gap-4'}>
                    <Button color='dark' onClick={() => handleNavigation(step - 1)}
                            disabled={step === 0} iconLeft={<ChevronLeftIcon className={'h-5 w-5'}/>}>Back</Button>

                    <Button color='dark' onClick={() => handleNavigation(step + 1)}
                            id={'shippingAndTaxNextBtn'}
                            disabled={step === maxStep}
                            iconRight={<ChevronRightIcon className={'h-5 w-5'}/>}>Next</Button>
                </div>}

                <div className={'w-full'}>{/* DO NOT DELETE */}</div>

                <div className={'flex gap-4'}>
                    <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'}
                            type={'submit'}>Save</Button>
                    <Button onClick={() => closeModal('ShippingAndTaxModal')} color="red" name={'cancel'}
                            iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
                </div>
            </div>

        </form>
    );
};
export default observer(ShippingAndTaxForm)