import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite";
import Agent from "../../api/Agent";
import {ComboBoxProps, OptionType} from "./ComboBox";
import Input from "./Input";
import Skeleton from "../generic/Skeleton";
import Autocomplete from "../Inputs/Autocomplete";

interface  EntityAutoCompleteProps extends Omit<ComboBoxProps, 'options'> {
    isLocked?: boolean
}

const EntityAutoCompleteInput: React.FC<EntityAutoCompleteProps> = ({isLocked = false, ...rest}) => {

    // Local State
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([] as OptionType[]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams()
                params.append('limit', '10000000')
                params.append('offset', `0`)
                const {modelsDto} = await Agent.Entities.list(params)
                setAutoCompleteOptions(modelsDto.map(opt => {
                    return {
                        id: opt.id,
                        name: `${opt.name}`.trim()
                    }
                }).sort((optA, optB) => optA.name > optB.name ? 1 : -1))
            } catch (e) {
            }
        }

        fetchData()

    }, []);


    if (!autoCompleteOptions)
        return <Skeleton/>

    return (!isLocked ?
        <Autocomplete
            placeholder={'Delorean Institute of '}
            label={"Entity"}
            options={autoCompleteOptions}
            {...rest}
        /> :
        <Input
            value={rest.displayValue}
            className={'mb-3'}
            label={'Entity'}
            disabled={true}
        />
    );
};
export default observer(EntityAutoCompleteInput);

