import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";
import MaintenanceRepairAttachmentsTable, {
    MaintenanceRepairAttachmentTableProps
} from "../../../pages/consumers/maintenanceRepair/components/MaintenanceRepair/MaintenanceRepairAttachmentsTable";


interface OwnProps extends SlideOverProps, MaintenanceRepairAttachmentTableProps {}

type Props = OwnProps;

// @ts-ignore
const MaintenanceRepairAttachmentsTableModal = NiceModal.create<Props>(({onClose, header, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <MaintenanceRepairAttachmentsTable {...rest}/>
        </SlideOver>
    )
})

export default MaintenanceRepairAttachmentsTableModal;
