import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {RootStore} from '../RootStore'
import agent from "../../api/Agent"
import {toast} from "react-toastify"
import {ConsumerTransferFormValues, IConsumerTransfer} from '../../models/Consumer/ConsumerTransfer'


export default class ConsumerTransferStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.consumerTransferRegistry.clear()
                this.loadConsumerTransfers()
            }
        )
    }

    consumerTransferRegistry = new Map()
    consumerTransfer: IConsumerTransfer | null = null
    loadingInitial = false
    submitting = false
    target = ""
    consumerTransferCount = 0
    page = 0
    limit = 5
    predicate = new Map()
    currentConsumerTransfer?: IConsumerTransfer

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get consumerTransfersList() {
        // @ts-ignore
        return Array.from(this.consumerTransferRegistry.values()).sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get pageCount() {
        return Math.ceil(this.consumerTransferCount / this.limit)
    }

    clearConsumerTransfers = async () => {
        this.consumerTransferRegistry.clear()
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    setCurrentConsumerTransfer = (consumer?) => {
        this.currentConsumerTransfer = consumer
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.consumerTransferRegistry.clear()
        this.loadConsumerTransfers()
    }

    loadConsumerTransfers = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const consumerEnvelope = await agent.ConsumerTransfers.list(this.axiosParams)
            let {modelsDto, modelCount} = consumerEnvelope

            runInAction(() => {
                this.clearConsumerTransfers()
                modelsDto.forEach((consumer) => {
                    this.consumerTransferRegistry.set(consumer.id, consumer)
                })
                this.consumerTransferCount = modelCount
            })

        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    createConsumerTransfer = async (consumerId: string, consumerTransfer: ConsumerTransferFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const tmpConsumerTransfer = await agent.ConsumerTransfers.create(consumerId, consumerTransfer)
            await this.loadConsumerTransfers()
            await this.rootStore.consumerStore.loadConsumers()

            runInAction(() => {
                this.setCurrentConsumerTransfer(tmpConsumerTransfer);
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('ConsumerTransferModal')
            })

            toast.success('Saved')
            return tmpConsumerTransfer;
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    updateConsumerTransfer = async (consumerId: string, data: object) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedConsumerTransfer = await agent.ConsumerTransfers.update(consumerId, data)
            await this.loadConsumerTransfers()
            await this.rootStore.consumerStore.loadConsumers()

            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.setCurrentConsumerTransfer(updatedConsumerTransfer)
            })

            toast.success('Saved')
            return updatedConsumerTransfer;
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }
}
