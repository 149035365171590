import React, {FunctionComponent, useCallback} from 'react';
import {observer} from "mobx-react-lite";
import {debounce} from 'lodash'
import Input from "../Inputs/Input";

interface OwnProps {
    filterPredicate: (n, v) => void;
}

type Props = OwnProps;

const AttachmentFilters: FunctionComponent<Props> = ({filterPredicate}) => {
    // Functions
    const handleChange = (e) => {
        const {value, name} = e.currentTarget;
        handleSearch({
            n: name,
            v: value
        })
    };

    const handleSearch = useCallback(
        debounce((val) => {
            const {n, v} = val
            filterPredicate(n, v)
        }, 500),
        []
    );


    return (<form autoComplete={'off'} onSubmit={e => {e.preventDefault(); e.stopPropagation()}}>
        <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                <Input label={'File Name'}
                       name={'name'}
                       onChange={handleChange}
                       placeholder={'Receipt.pdf'}
                       prepend
                />
        </div>
    </form>);
};

export default observer(AttachmentFilters)
