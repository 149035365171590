import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {IConsumer} from "../../../../../models/Consumer/Consumer";
import {inputOnChange} from "../../../../../lib/utilities";
import Button from "../../../../../components/generic/Button";
import {ConsumerTransferFormValues} from "../../../../../models/Consumer/ConsumerTransfer";
import Select from "../../../../../components/generic/Select";
import {IState} from "../../../../../models/State";
import ConsumerAutoCompleteInput from "../../../../../components/form/ConsumerAutoCompleteInput";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface ConsumerFormProps {
    consumer?: IConsumer;
}

const ConsumerTransferForm: React.FC<ConsumerFormProps> = ({consumer}) => {
    const rootStore = useContext(RootStoreContext)
    const {closeModal} = rootStore.modalStore;
    const {createConsumerTransfer} = rootStore.consumerTransferStore;
    const {statesById, loadStates, setLimit} = rootStore.stateStore;

    const [currentConsumerId, setCurrentConsumerId] = useState('');
    const [currentConsumerTransfer, setCurrentConsumerTransfer] = useState(new ConsumerTransferFormValues());


    useEffect(() => {

        const fetch = async () => {
            setLimit(100);
            loadStates();
        }

        fetch();
    }, [loadStates,setLimit])

    useEffect(() => {
        setCurrentConsumerId(consumer?.id || '')
    }, [consumer])

    useEffect(() => {
        setCurrentConsumerTransfer((prev: ConsumerTransferFormValues) => {
            return {
                ...prev
            }
        })
    }, [currentConsumerId])

    const handleChange = (e) => {
        inputOnChange(e, setCurrentConsumerTransfer)
    }

    const handleConsumerId = (id) => {
        setCurrentConsumerId(id)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        createConsumerTransfer(currentConsumerId, currentConsumerTransfer)
    }

    return (<form autoComplete={'off'} onSubmit={handleSubmit}
                  className={'flex flex-col flex-1 h-full overflow-x-hidden'}>
        <div className={'flex-1'}>
            {!consumer &&
            <ConsumerAutoCompleteInput onChange={({id}) => {
                handleConsumerId(id)
            }}/>
            }

            <Select className={'min-w-64'} name={'transferToStateId'}
                    value={currentConsumerTransfer.transferToStateId || 'Select State/Territory'}
                    placeholder={'Select State/Territory'} onChange={handleChange} label={'State/Territory'}
                    required options={statesById.map((state: IState) => {
                return {
                    value: state.id,
                    key: `${state.id} ${state.stateName}`,
                    text: state.stateName
                }
            })}
            />
        </div>

        <div>
            <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'} type={'submit'}
                    disabled={!currentConsumerTransfer.transferToStateId}>
                Save
            </Button>
            <span className={'m-2'}/>
            <Button onClick={() => {
                closeModal('ConsumerTransferModal')
            }} color="red" iconLeft={<XMarkIcon className={'h-6 w-6'}/>}>
                Cancel
            </Button>
        </div>
    </form>)
};
export default observer(ConsumerTransferForm);