import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {EquipmentAttachmentFormValues, IEquipmentAttachment} from '../../models/Equipment/EquipmentAttachment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class EquipmentAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.equipmentAttachmentRegistry.clear()
                this.loadEquipmentAttachment()
            }
        )

        reaction(
            () => this.currentEquipmentId,
            () => {
                this.page = 0
                this.equipmentAttachmentRegistry.clear()
                !!this.currentEquipmentId ? this.getEquipmentAttachmentsByEquipmentId() : this.loadEquipmentAttachment()
            }
        )
    }

    equipmentAttachmentRegistry = new Map()
    equipmentAttachment: IEquipmentAttachment | null = null
    editMode = false


    currentEquipmentId = '';
    equipmentAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentEquipmentId = (equipmentId: string) => {
        this.currentEquipmentId = equipmentId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.equipmentAttachmentCount / this.limit)
    }

    get equipmentAttachmentsById() {
        return Array.from(this.equipmentAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get equipmentAttachmentsByDate() {
        return Array.from(this.equipmentAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getEquipmentAttachment = (id: string) => {
        return this.equipmentAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.equipmentAttachmentRegistry.clear()
        this.loadEquipmentAttachment()
    }

    clearEquipmentAttachment = () => {
        this.equipmentAttachmentRegistry.clear()
    }

    loadEquipmentAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const equipmentAttachmentsEnvelope = await Agent.EquipmentAttachments.list(this.axiosParams);
            const {modelsDto, modelCount} = equipmentAttachmentsEnvelope;

            runInAction(() => {
                this.clearEquipmentAttachment();
                modelsDto.forEach((equipmentAttachment: IEquipmentAttachment) => {
                    this.equipmentAttachmentRegistry.set(equipmentAttachment.id, equipmentAttachment)
                })
                this.equipmentAttachmentCount = modelCount
            })
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getEquipmentAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let equipmentAttachment = await Agent.EquipmentAttachments.byId(id)
            runInAction(() => {
                this.equipmentAttachment = equipmentAttachment
                this.equipmentAttachmentRegistry.set(equipmentAttachment.id, equipmentAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return equipmentAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getEquipmentAttachmentsByEquipmentId = async (equipmentId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let equipmentAttachmentEnvelope = await Agent.EquipmentAttachments.byEquipmentId(equipmentId || this.currentEquipmentId, this.axiosParams)
            const {modelsDto, modelCount} = equipmentAttachmentEnvelope
            runInAction(() => {
                modelsDto.forEach(equipmentAttachment => {
                    this.equipmentAttachmentRegistry.set(equipmentAttachment.id, equipmentAttachment)
                })

                this.equipmentAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createEquipmentAttachment = async (equipmentAttachment: EquipmentAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newEquipmentAttachment = await Agent.EquipmentAttachments.create(equipmentAttachment)
            await (!!this.currentEquipmentId ? this.getEquipmentAttachmentsByEquipmentId() : this.loadEquipmentAttachment())

            runInAction(() => {
                this.equipmentAttachmentRegistry.set(newEquipmentAttachment.id, newEquipmentAttachment)
                this.rootStore.modalStore.closeModal('AttachmentModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteEquipmentAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.EquipmentAttachments.delete(id)
            runInAction(() => {
                this.equipmentAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('EquipmentAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }
    }

}