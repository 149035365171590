import axios from "axios";
import {
    IMaintenanceRepair,
    IMaintenanceRepairEnvelope,
    MaintenanceRepairFormValues
} from "../../models/MaintenanceRepair/MaintenanceRepair";
import {api, responseBody} from "./apiBase.service";

const MaintenanceRepair = {
    list: (
        params: URLSearchParams
    ): Promise<IMaintenanceRepairEnvelope> =>
        axios
            .get("/MaintenanceRepair", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/MaintenanceRepair/${id}`),
    create: (MaintenanceRepair: MaintenanceRepairFormValues) =>
        api.post(`/MaintenanceRepair/`, MaintenanceRepair),
    update: (MaintenanceRepair: IMaintenanceRepair) =>
        api.put(`/MaintenanceRepair/${MaintenanceRepair.id}`, MaintenanceRepair),
    delete: (id: string) =>
        api.del(`/MaintenanceRepair/${id}`),
};

export default MaintenanceRepair