import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {RootStore} from '../RootStore'
import agent from "../../api/Agent"
import {toast} from "react-toastify"
import {IConsumerMerge} from '../../models/Consumer/ConsumerMerge'
import History from "../../AppHistory";

export default class ConsumerMergeStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.consumerMergeRegistry.clear()
                this.loadConsumerMerges()
            }
        )

        reaction(
            () => this.currentConsumerId,
            () => {
                this.page = 0
                this.consumerMergeRegistry.clear()
                this.loadConsumerMerges()
            }
        )
    }

    consumerMergeRegistry: Map<any, any> = new Map()
    predicate: Map<any, any> = new Map()
    consumerMergeCount: number = 0
    page: number = 0
    limit: number = 5
    currentConsumerId?: string = undefined

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object') {
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get consumerMergesById() {
        return Array.from(this.consumerMergeRegistry.values()).sort(
            (a: IConsumerMerge, b: IConsumerMerge) => {
                if (!!a.id && !!b.id) {
                    return a.id > b.id ? -1 : 1
                }
                return -1
            }
        )
    }

    get pageCount() {
        return Math.ceil(this.consumerMergeCount / this.limit)
    }

    setCurrentConsumer = (id?: string) => {
        this.currentConsumerId = id
    }

    loadConsumerMerges = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const consumerMergeEnvelope = await agent.ConsumerMerges.list(this.currentConsumerId ?? '', this.axiosParams)
            let {modelsDto, modelCount} = consumerMergeEnvelope

            runInAction(() => {
                this.consumerMergeRegistry.clear()
                modelsDto.forEach((consumerMerge) => {
                    this.consumerMergeRegistry.set(consumerMerge.id, consumerMerge)
                })
                this.consumerMergeCount = modelCount
            })

        } catch (error: any) {
            if (error?.response?.status === 404){
                History.push('/services/consumers')
            } else {
                this.rootStore.errorStore.addError(error);
            }
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    clearConsumerMerges = async () => {
        this.consumerMergeRegistry.clear()
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.consumerMergeRegistry.clear()
        this.loadConsumerMerges()
    }

    mergeConsumers = async (consumerMerge: IConsumerMerge, consumerIds: Array<string>) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.ConsumerMerges.merge(consumerMerge, consumerIds)
            await this.loadConsumerMerges()
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    releaseConsumers = async (consumerIds: Array<string>) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.ConsumerMerges.release(consumerIds)
            await this.loadConsumerMerges()
            toast.success('Saved')
            // runInAction(() => {
            //     this.rootStore.modalStore.closeModal('')
            // })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

}