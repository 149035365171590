import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import Button from "../../../../components/generic/Button";
import {IReimbursementClaim} from "../../../../models/Reimbursement/ReimbursementClaim";
import FileDragAndDrop from "../../../../components/form/FileDragAndDrop";
import {
    ReimbursementClaimSpreadsheetUploadFormValues
} from "../../../../models/Reimbursement/ReimbursementClaimSpreadsheet";
import ReimbursementClaimAutoCompleteInput from "../../../../components/form/ReimbursementClaimAutoCompleteInput";
import {inputOnChange} from "../../../../lib/utilities";
import TextArea from "../../../../components/form/TextArea";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface ReimbursementSpreadSheetFormProps {
    reimbursementClaim: IReimbursementClaim
}

type Props = ReimbursementSpreadSheetFormProps

const ReimbursementSpreadSheetForm: React.FC<Props> = ({reimbursementClaim}) => {
    // store
    const rootStore = useContext(RootStoreContext);
    const {closeModal, openModal} = rootStore.modalStore;
    const {createReimbursementSpreadSheet} = rootStore.reimbursementSpreadSheetStore


    // state
    const [currentAttachment, setCurrentAttachment] = useState(new ReimbursementClaimSpreadsheetUploadFormValues());

    // Functions
    const handleSubmit = (e) => {
        e.preventDefault()
        let tmpAttachment = {...currentAttachment}
        if (!!reimbursementClaim && !!reimbursementClaim.id) tmpAttachment.reimbursementClaimId = reimbursementClaim.id
        tmpAttachment.name = tmpAttachment.file.name;
        createReimbursementSpreadSheet(tmpAttachment).then(data => {
            if (!!data) {
                openModal('OkayModal', {
                    body: 'Your spreadsheet has been uploaded for submission. Once it is finished processing, you will receive an email with the results.',
                })
            }
        })
    }

    const handleFile = (files) => {
        setCurrentAttachment(prev => {
            return {
                ...prev,
                file: files[0]
            }
        })
    }

    const handleChange = (e) => inputOnChange(e, setCurrentAttachment)

    const handleAutoComplete = (e) => inputOnChange({currentTarget: e}, setCurrentAttachment)

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit}
              className={'overflow-y-auto overflow-x-hidden flex-1 flex flex-col h-full'}>
            <div className={'flex-1'}>
                {!reimbursementClaim && <div className="grid grid-cols-1 sm:grid-cols- gap-x-4 gap-y-1">
                    <ReimbursementClaimAutoCompleteInput
                        placeholder={'Reimbursement Claim'}
                        onChange={({id}) => handleAutoComplete({name: 'reimbursementClaimId', value: id})}
                        name='reimbursementClaimId'
                    />
                </div>}

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <TextArea
                        label={'Description'}
                        value={currentAttachment.description}
                        name={'description'}
                        onChange={handleChange}
                        maxLength={8000}
                        rows={10}
                        placeholder={'Radiation suit? Of course, because of all the fallout from the atomic wars.'}
                    />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <FileDragAndDrop onChange={handleFile}
                                     acceptedFileTypes={'.zip'}
                                     maxNumberOfFiles={1}
                        // placeholder={"Please provide a ZIP file containing an XKSX/XLS and PDF"}
                    />
                </div>
            </div>

            <div className={'flex justify-end gap-4'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'}
                        type={'submit'}>Save</Button>
                <Button name={'cancel'} onClick={() => closeModal('ReimbursementSpreadSheetModal')} color="red"
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>
        </form>

    );
};
export default observer(ReimbursementSpreadSheetForm)