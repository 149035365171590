import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {EquipmentFormValues, EquipmentReturnFormValues, IEquipment} from '../../models/Equipment/Equipment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class EquipmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            async () => {
                this.page = 0
                await this.equipmentRegistry.clear()
                this.loadEquipment()
            }
        )
    }

    equipmentRegistry = new Map()
    equipment: IEquipment | null = null
    editMode = false

    equipmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.equipmentRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.equipmentCount / this.limit)
    }

    get equipmentsList() {
        // @ts-ignore
        return Array.from(this.equipmentRegistry.values())
    }

    get equipmentAutoCompleteOptions() {
        let Equipments: {
            key: string
            value: string
        }[] = []


        this.equipmentsList.map((equipment: IEquipment) => {
            Equipments.push({
                key: `${equipment.id}`,
                value: `${equipment.equipmentName}`
            })
        })

        return Equipments.sort((a, b) => a.value > b.value ? 1 : -1);
    };

    getEquipment = (id: string) => {
        return this.equipmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow?: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        if (!!newRow) this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.equipmentRegistry.clear()
        this.loadEquipment()
    }

    clearEquipment = () => {
        this.equipmentRegistry.clear()
    }

    loadEquipment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const equipmentsEnvelope = await Agent.Equipments.list(this.axiosParams)
            const {modelsDto, modelCount} = equipmentsEnvelope


            runInAction(() => {
                this.clearEquipment()
                modelsDto.forEach((equipment: IEquipment) => {
                    this.equipmentRegistry.set(equipment.id, equipment)
                })
                this.equipmentCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getEquipmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let equipment = await Agent.Equipments.byId(id)
            runInAction(() => {
                this.equipment = equipment
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return equipment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })

        }
    }

    getEquipmentsByIds = async (ids: string[]) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '1000000')
            params.append('offset', `0`)

            for (let id of ids) {
                params.append('ids', id)
            }


            let {modelsDto} = await Agent.Equipments.list(params)

            return modelsDto
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createEquipment = async (equipment: IEquipment) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newEquipment = await Agent.Equipments.create(equipment)
            runInAction(() => {
                this.equipmentRegistry.set(newEquipment.id, newEquipment)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('EquipmentModal')
            })
            toast.success('Saved')
            return newEquipment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editEquipment = async (equipment: EquipmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedEquipment = await Agent.Equipments.update(equipment)
            runInAction(() => {
                this.equipmentRegistry.set(updatedEquipment.id, updatedEquipment)
                this.rootStore.modalStore.closeModal('EquipmentModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return updatedEquipment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    returnEquipment = async (equipmentReturn: EquipmentReturnFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let returnEquipment = await Agent.Equipments.return(equipmentReturn)
            runInAction(() => {
                this.equipmentRegistry.set(returnEquipment.id, returnEquipment)
            })
            this.rootStore.modalStore.closeModal('EquipmentReturnModal')
            toast.success('Saved')
            return returnEquipment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteEquipment = async (equipment: IEquipment) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.Equipments.delete(equipment.id ?? '')
            runInAction(() => {
                this.equipmentRegistry.delete(equipment.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }

    }


}
