import axios from "axios";
import {
    IInstallationAttachmentEnvelope,
    InstallationAttachmentFormValues
} from "../../models/Installation/InstallationAttachment";
import {api, responseBody} from "./apiBase.service";

const InstallationAttachments = {
    list: (params: URLSearchParams): Promise<IInstallationAttachmentEnvelope> =>
        axios
            .get("/Installation/Attachment", {params: params})
            .then(responseBody),
    create: (installationAttachment: InstallationAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in installationAttachment) {
            formData.append(key, installationAttachment[key])
        }
        return api.post(`/Installation/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/Installation/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/Installation/Attachment/${id}`),
    byInstallationId: (installationId: string, params: URLSearchParams): Promise<IInstallationAttachmentEnvelope> =>
        axios.get(`/Installation/${installationId}/Attachment`, {params}).then(responseBody)
};

export default InstallationAttachments