import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import LineItemAttachmentForm, {LineItemAttachmentFormProps} from "../../attachment/LineItemAttachmentForm";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, LineItemAttachmentFormProps {
}

type Props = OwnProps;

// @ts-ignore
const AdministrativeModal = NiceModal.create<Props>(({
                                                         header,
                                                         assessmentIds,
                                                         reimbursementClaimIds,
                                                         equipmentIds,
                                                         installationIds,
                                                         maintenanceRepairIds,
                                                         outreachIds,
                                                         shippingAndTaxIds,
                                                         trainingIds,
                                                         travelIds, consumerIds,
                                                         subItemIds,
                                                         administrativeAttachmentIds,
                                                         onClose,
                                                         ...rest
                                                     }) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <LineItemAttachmentForm {...rest} {...{
                header,
                assessmentIds,
                reimbursementClaimIds,
                equipmentIds,
                installationIds,
                maintenanceRepairIds,
                outreachIds,
                shippingAndTaxIds,
                trainingIds,
                travelIds, consumerIds,
                subItemIds,
                administrativeAttachmentIds,
                onClose,
            }}/>
        </SlideOver>
    )
})

export default AdministrativeModal;
