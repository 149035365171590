import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import AssessmentForm, {AssessmentFormProps} from "../../../pages/consumers/assessments/components/AssessmentForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, AssessmentFormProps {
}

type Props = OwnProps;

// @ts-ignore
const AssessmentModal = NiceModal.create<Props>(({assessment, onClose, header}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <AssessmentForm assessment={assessment}/>
        </SlideOver>
    )
})

export default AssessmentModal;
