import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IInstallationNote, InstallationNoteFormValues} from '../../models/Installation/InstallationNote'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'

export default class InstallationNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.installationNoteRegistry.clear()
                this.loadInstallationNote()
            }
        )
        reaction(
            () => this.currentInstallationId,
            () => {

                this.page = 0
                this.installationNoteRegistry.clear()
                this.loadInstallationNote()
            }
        )
    }

    installationNoteRegistry = new Map()
    installationNote: IInstallationNote | null = null
    editMode = false


    currentInstallationId = '';
    installationNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentInstallationId = (installationId: string) => {
        this.currentInstallationId = installationId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.installationNoteCount / this.limit)
    }

    get installationNotesById() {
        return Array.from(this.installationNoteRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get installationNotesByDate() {
        return Array.from(this.installationNoteRegistry.values()).sort(
            (a, b) => dayjs(b.updatedOn).isBefore(a.updatedOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getInstallationNote = (id: string) => {
        return this.installationNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.installationNoteRegistry.clear()
        this.loadInstallationNote()
    }

    clearInstallationNote = () => {
        this.installationNoteRegistry.clear()
    }

    loadInstallationNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentInstallationId) {
                const installationNotesEnvelope = await Agent.InstallationNotes.byInstallationId(this.currentInstallationId, this.axiosParams);
                const {modelsDto, modelCount} = installationNotesEnvelope;
                runInAction(() => {
                    modelsDto.forEach((installationNote: IInstallationNote) => {
                        this.installationNoteRegistry.set(installationNote.id, installationNote)
                        this.rootStore.modalStore.closeModal('NoteModal')
                    })
                    this.installationNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getInstallationNoteById = async (id: string) => {
        let installationNote = this.getInstallationNote(id)
        if (installationNote) {
            this.installationNote = installationNote
            return installationNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                installationNote = await Agent.InstallationNotes.byId(id)
                runInAction(() => {
                    this.installationNote = installationNote
                    this.installationNoteRegistry.set(installationNote.id, installationNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return installationNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createInstallationNote = async (installationNote: IInstallationNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newInstallationNote = await Agent.InstallationNotes.create(installationNote)
            await this.loadInstallationNote()
            runInAction(() => {
                this.installationNoteRegistry.set(newInstallationNote.id, newInstallationNote)
                this.rootStore.modalStore.closeModal('NoteModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editInstallationNote = (installationNote: InstallationNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.InstallationNotes.update(installationNote).then((updatedInstallationNote) => {
            runInAction(() => {
                this.installationNoteRegistry.set(updatedInstallationNote.id, updatedInstallationNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteInstallationNote = async (installationNote: IInstallationNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.InstallationNotes.delete(installationNote.id)
            runInAction(() => {
                this.installationNoteRegistry.delete(installationNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }

    }


}