import React, {FunctionComponent} from 'react';

import {getClassName} from "../../lib/getClassName";


export type BtnColors = 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'indigo' | 'purple' | 'pink' | 'gray' | 'dark' | 'link' | 'darkBlue'

// @ts-ignore
export interface CustomButtonProps extends React.HTMLProps<HTMLButtonElement> {
    // children?: React.ReactNode | string
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
    color?: BtnColors
    size?: 'xs'|'sm' | 'md' | 'lg' | 'xl'
    outline?: boolean
    rounded?: boolean
    shadow?: boolean
    light?: boolean
    type?: "button" | "submit" | "reset"
}

type Props = CustomButtonProps


const Button: FunctionComponent<Props> = ({
                                              color = 'blue',
                                              iconLeft,
                                              iconRight,
                                              size= 'md',
                                              outline = false,
                                              className= '',
                                              rounded = false,
                                              shadow = false,
                                              light = false,
                                              type = 'button',
                                              children,
                                              ...rest
                                          }) => {


    let btn = getClassName({
        'inline-flex items-center border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white font-medium transition-all disabled:cursor-not-allowed disabled:opacity-50 text-center justify-center align-middle': true,

        // Shadow
        'shadow-sm': shadow,

        // Rounding
        'rounded': rounded && size === 'xs',
        'rounded-md': rounded && size !== 'xs',

        // Sizing
        'px-2 py-1 text-xs': size === 'xs',
        'px-3 py-2 text-sm leading-4': size === 'sm',
        'px-4 py-2 text-sm': size === 'md',
        'px-4 py-2 text-base': size === 'lg',
        'px-6 py-3 text-base': size === 'xl',

        // Solid colors
        "text-indigo-800 bg-transparent disabled:hover:text-blue-800 hover:text-blue-900 focus:ring-black hover:underline": color === 'link',
        "text-white bg-black disabled:hover:bg-black hover:bg-gray-900 focus:ring-black": color === 'dark',
        "text-white bg-red-600 disabled:hover:bg-red-600 hover:bg-red-700 focus:ring-red-500": color === 'red' && !outline && !light,
        "text-white bg-orange-600 disabled:hover:bg-orange-600 hover:bg-orange-700 focus:ring-orange-500": color === 'orange' && !outline && !light,
        "text-white bg-yellow-600 disabled:hover:bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500": color === 'yellow' && !outline && !light,
        "text-white bg-green-600 disabled:hover:bg-green-600 hover:bg-green-700 focus:ring-green-500": color === 'green' && !outline && !light,
        "text-white bg-blue-600 disabled:hover:bg-blue-600 hover:bg-blue-700 focus:ring-blue-500": color === 'blue' && !outline && !light,
        "text-white bg-blue-800 disabled:hover:bg-blue-600 hover:bg-blue-700 focus:ring-blue-500": color === 'darkBlue' && !outline && !light,
        "text-white bg-indigo-600 disabled:hover:bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500": color === 'indigo' && !outline && !light,
        "text-white bg-purple-600 disabled:hover:bg-purple-600 hover:bg-purple-700 focus:ring-purple-500": color === 'purple' && !outline && !light,
        "text-white bg-pink-600 disabled:hover:bg-pink-600 hover:bg-pink-700 focus:ring-pink-500": color === 'pink' && !outline && !light,
        "text-white bg-gray-600 disabled:hover:bg-gray-600 hover:bg-gray-700 focus:ring-gray-500": color === 'gray' && !outline && !light,

        // Light colors
        "text-red-800 bg-red-200 disabled:hover:bg-red-200 hover:bg-red-300 focus:ring-red-500": color === 'red' && light && !outline,
        "text-orange-800 bg-orange-200 disabled:hover:bg-orange-200 hover:bg-orange-300 focus:ring-orange-500": color === 'orange' && light && !outline,
        "text-yellow-800 bg-yellow-200 disabled:hover:bg-yellow-200 hover:bg-yellow-300 focus:ring-yellow-500": color === 'yellow' && light && !outline,
        "text-green-800 bg-green-200 disabled:hover:bg-green-200 hover:bg-green-300 focus:ring-green-500": color === 'green' && light && !outline,
        "text-blue-800 bg-blue-200 disabled:hover:bg-blue-200 hover:bg-blue-300 focus:ring-blue-500": color === 'blue' && light && !outline,
        "text-indigo-800 bg-indigo-200 disabled:hover:bg-indigo-200 hover:bg-indigo-300 focus:ring-indigo-500": color === 'indigo' && light && !outline,
        "text-purple-800 bg-purple-200 disabled:hover:bg-purple-200 hover:bg-purple-300 focus:ring-purple-500": color === 'purple' && light && !outline,
        "text-pink-800 bg-pink-200 disabled:hover:bg-pink-200 hover:bg-pink-300 focus:ring-pink-500": color === 'pink' && light && !outline,
        "text-gray-800 bg-gray-200 disabled:hover:bg-gray-200 hover:bg-gray-300 focus:ring-gray-500": color === 'gray' && light && !outline,

        // Outlined
        'border-transparent': !outline,
        'disabled:hover:!bg-transparent disabled:hover:text-current': outline,
        "text-red-700 border-red-700 bg-transparent hover:text-white hover:!bg-red-700 focus:ring-red-500": color === 'red' && outline,
        "text-orange-700 border-orange-700 bg-transparent hover:text-white hover:!bg-orange-700 focus:ring-orange-500": color === 'orange' && outline,
        "text-yellow-700 border-yellow-700 bg-transparent hover:text-white hover:!bg-yellow-700 focus:ring-yellow-500": color === 'yellow' && outline,
        "text-green-700 border-green-700 bg-transparent hover:text-white hover:!bg-green-700 focus:ring-green-500": color === 'green' && outline,
        "text-blue-700 border-blue-700 bg-transparent hover:text-white hover:!bg-blue-700 focus:ring-blue-500": color === 'blue' && outline,
        "text-indigo-700 border-indigo-700 bg-transparent hover:text-white hover:!bg-indigo-700 focus:ring-indigo-500": color === 'indigo' && outline,
        "text-purple-700 border-purple-700 bg-transparent hover:text-white hover:!bg-purple-700 focus:ring-purple-500": color === 'purple' && outline,
        "text-pink-700 border-pink-700 bg-transparent hover:text-white hover:!bg-pink-700 focus:ring-pink-500": color === 'pink' && outline,
        "text-gray-700 border-gray-700 bg-transparent hover:text-white hover:!bg-gray-700 focus:ring-gray-500": color === 'gray' && outline,
    })

    return (
        <button id={rest?.name} type={type} className={`${btn} ${className}`}{...rest}>
            {!!iconLeft && <span className={'mr-2'} aria-hidden={true}>{iconLeft}</span>}
            {children}
            {!!iconRight && <span className={'ml-2'} aria-hidden={true}>{iconRight}</span>}
        </button>
    );
};

export default Button;
