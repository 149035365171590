import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {IProgramYear, IProgramYearEnvelope, ProgramYearFormValues} from "../../models/ProgramYear";

const ProgramYear = {
    list: (
        params: URLSearchParams
    ): Promise<IProgramYearEnvelope> =>
        axios.get('/ProgramYear', {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/ProgramYear/${id}`),
    create: (ProgramYear: ProgramYearFormValues) =>
        api.post(`/ProgramYear`, ProgramYear),
    update: (ProgramYear: IProgramYear) =>
        api.put(`/ProgramYear/${ProgramYear.id}`, ProgramYear),
};

export default ProgramYear