import axios from "axios";
import {api, responseBody} from "./apiBase.service";

const EmbeddedReport = {
    list: (params: URLSearchParams): Promise<any> => (
        axios
            .get("/EmbeddedReport", {params: params})
            .then(responseBody)
    ),
    byId: (id: string, paramId?: string) => {
        const idParameter = paramId ? `?idParameter=${paramId}` : ''
        return api.get(`/EmbeddedReport/${id}${idParameter}`)
    },
    downloadById: (id: string, paramId?: string) => {
        const idParameter = paramId ? `?idParameter=${paramId}` : ''
        return api.get(`EmbeddedReport/${id}/Print${idParameter}`)
    }
};

export default EmbeddedReport