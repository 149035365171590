import axios from "axios";
import {
    IShippingAndTaxNote,
    IShippingAndTaxNoteEnvelope,
    ShippingAndTaxNoteFormValues
} from "../../models/ShippingAndTax/ShippingAndTaxNote";
import {api, responseBody} from "./apiBase.service";

const ShippingAndTaxNotes = {
    list: (params: URLSearchParams): Promise<IShippingAndTaxNoteEnvelope> =>
        axios
            .get("/ShippingAndTax/Note", {params: params})
            .then(responseBody),
    create: (ShippingAndTax: ShippingAndTaxNoteFormValues) =>
        api.post(`/ShippingAndTax/Note`, ShippingAndTax),
    update: (ShippingAndTax: IShippingAndTaxNote) =>
        api.put(`/ShippingAndTax/Note/${ShippingAndTax.id}`, ShippingAndTax),
    delete: (id: string) =>
        api.del(`/ShippingAndTax/Note/${id}`),
    byId: (id: string) => api.get(`/ShippingAndTax/Note/${id}`),
    byShippingAndTaxId: (travelId: string, params: URLSearchParams): Promise<IShippingAndTaxNoteEnvelope> =>
        axios.get(`/ShippingAndTax/${travelId}/Note`, {params: params})
            .then(responseBody),
};

export default ShippingAndTaxNotes