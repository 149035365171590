import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {
    AdministrativeAttachmentFormValues,
    IAdministrativeAttachment
} from '../../models/Administrative/AdministrativeAttachment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class AdministrativeAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.administrativeAttachmentRegistry.clear()
                this.loadAdministrativeAttachment()
            }
        )
        reaction(
            () => this.currentAdministrativeId,
            () => {
                this.page = 0
                this.administrativeAttachmentRegistry.clear()
                !!this.currentAdministrativeId ? this.getAdministrativeAttachmentsByAdministrativeId() : this.loadAdministrativeAttachment()
            }
        )
    }

    administrativeAttachmentRegistry = new Map()
    administrativeAttachment: IAdministrativeAttachment | null = null
    editMode = false


    currentAdministrativeId = '';
    administrativeAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentAdministrativeId = (administrativeId: string) => {
        this.currentAdministrativeId = administrativeId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.administrativeAttachmentCount / this.limit)
    }

    get administrativeAttachmentsById() {
        return Array.from(this.administrativeAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get administrativeAttachmentsByDate() {
        return Array.from(this.administrativeAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getAdministrativeAttachment = (id: string) => {
        return this.administrativeAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.administrativeAttachmentRegistry.clear()
        this.loadAdministrativeAttachment()
    }

    clearAdministrativeAttachment = () => {
        this.administrativeAttachmentRegistry.clear()
    }

    loadAdministrativeAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentAdministrativeId) {
                const administrativeAttachmentsEnvelope = await Agent.AdministrativeAttachments.list(this.axiosParams);
                const {modelsDto, modelCount} = administrativeAttachmentsEnvelope;
                runInAction(() => {
                    this.clearAdministrativeAttachment()
                    modelsDto.forEach((administrativeAttachment: IAdministrativeAttachment) => {
                        this.administrativeAttachmentRegistry.set(administrativeAttachment.id, administrativeAttachment)
                    })
                    this.administrativeAttachmentCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getAdministrativeAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let administrativeAttachment = await Agent.AdministrativeAttachments.byId(id)
            runInAction(() => {
                this.administrativeAttachment = administrativeAttachment
                this.administrativeAttachmentRegistry.set(administrativeAttachment.id, administrativeAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return administrativeAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getAdministrativeAttachmentsByAdministrativeId = async (administrativeId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let administrativeAttachmentEnvelope = await Agent.AdministrativeAttachments.byAdministrativeId(administrativeId || this.currentAdministrativeId, this.axiosParams)
            const {modelsDto, modelCount} = administrativeAttachmentEnvelope
            runInAction(() => {
                modelsDto.forEach(administrativeAttachment => {
                    this.administrativeAttachmentRegistry.set(administrativeAttachment.id, administrativeAttachment)
                })

                this.administrativeAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createAdministrativeAttachment = async (administrativeAttachment: AdministrativeAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newAttachment = await Agent.AdministrativeAttachments.create(administrativeAttachment)
            await this.loadAdministrativeAttachment()

            runInAction(() => {
                this.administrativeAttachmentRegistry.set(newAttachment.id, newAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('AttachmentModal')
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteAdministrativeAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.AdministrativeAttachments.delete(id)
            runInAction(() => {
                this.administrativeAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('AdministrativeAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }
    }

}