import axios from "axios";
import {ITrainingNote, ITrainingNoteEnvelope, TrainingNoteFormValues} from "../../models/Training/TrainingNote";
import {api, responseBody} from "./apiBase.service";

const TrainingNotes = {
    list: (params: URLSearchParams): Promise<ITrainingNoteEnvelope> =>
        axios
            .get("/Training/Note", {params: params})
            .then(responseBody),
    create: (Training: TrainingNoteFormValues) =>
        api.post(`/Training/Note`, Training),
    update: (Training: ITrainingNote) =>
        api.put(`/Training/Note/${Training.id}`, Training),
    delete: (id: string) =>
        api.del(`/Training/Note/${id}`),
    byId: (id: string) => api.get(`/Training/Note/${id}`),
    byTrainingId: (equipmentId: string, params: URLSearchParams): Promise<ITrainingNoteEnvelope> =>
        axios
            .get(`/Training/${equipmentId}/Note`, {params: params})
            .then(responseBody)
    ,
};

export default TrainingNotes