import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import ReallocationApproveRejectForm, {
    ReallocationApproveRejectFormProps
} from "../../../pages/administration/allocation/components/reallocation/ReallocationApproveRejectForm";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, ReallocationApproveRejectFormProps {
}

type Props = OwnProps;

// @ts-ignore
const ReallocationApproveRejectModal = NiceModal.create<Props>(({reallocationApproval, onClose, header,...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <ReallocationApproveRejectForm reallocationApproval={reallocationApproval} {...rest}/>
        </SlideOver>
    )
})

export default ReallocationApproveRejectModal;
