import {useContext} from "react";
import {RootStoreContext} from "../../../../../stores/RootStore";
import {toast} from "react-toastify";

export const useAttachments = (semiAnnualReport) => {
    const rootStore = useContext(RootStoreContext);
    const {getSemiAnnualReportAttachmentById, downloadSemiAnnualReportAttachment} = rootStore.semiAnnualReportAttachmentStore;


    const requestSnapshotAttachment = async () => {
        let snapshot = undefined;
        const {modelsDto} = await getSemiAnnualReportAttachmentById(semiAnnualReport.id);
        modelsDto.forEach(attachment => {
            if (attachment.isSnapshot)
                snapshot = attachment;
        })
        return snapshot;
    }

    const downloadSnapshotAttachment = async () => {
        const snapshot = await requestSnapshotAttachment()
        if (snapshot) {
            // @ts-ignore
            const {byteStream, name} = await downloadSemiAnnualReportAttachment(snapshot.id);
            let a = document.createElement("a");
            a.href = `data:application/octet-stream;base64,${byteStream}`;
            a.download = name;
            a.click();
        } else
            toast.error('Snapshot not found; unable to download PDF.');
    }

    return {requestSnapshotAttachment, downloadSnapshotAttachment}
}