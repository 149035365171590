import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {RootStore} from '../RootStore'
import agent from "../../api/Agent"
import {toast} from "react-toastify"
import {ConsumerFormValues, IConsumer} from '../../models/Consumer/Consumer'

interface IAutoCompleteOptions {
    key: string
    value: string
}

export default class ConsumerStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.consumerRegistry.clear()
                this.loadConsumers()
            }
        )
    }

    consumerRegistry = new Map()
    consumer: IConsumer | null = null
    loadingInitial = false
    submitting = false
    target = ""
    consumerCount = 0
    page = 0
    limit = 5
    predicate = new Map()
    currentConsumer?: IConsumer

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    setPredicates = (predicates: {}) => {
        this.consumerRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get consumersList() {
        // @ts-ignore
        return Array.from(this.consumerRegistry.values()).sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get consumerAutoCompleteOptions() {
        let Consumer: IAutoCompleteOptions[] = []


        this.consumersList.forEach((consumer: IConsumer): void => {
            Consumer.push({
                key: `${consumer.id}`,
                value: `${consumer.firstName} ${consumer.middleName} ${consumer.lastName}`
            })
        })

        return Consumer.sort((a: IAutoCompleteOptions, b: IAutoCompleteOptions) => a.value > b.value ? 1 : -1)
    };

    get pageCount() {
        return Math.ceil(this.consumerCount / this.limit)
    }

    loadConsumers = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const consumerEnvelope = await agent.Consumers.list(this.axiosParams)
            let {modelsDto, modelCount} = consumerEnvelope

            runInAction(() => {
                this.clearConsumers()
                modelsDto.forEach((consumer) => {
                    this.consumerRegistry.set(consumer.id, consumer)
                })
                this.consumerCount = modelCount
            })

        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    clearConsumers = async () => {
        this.consumerRegistry.clear()
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    setCurrentConsumer = (consumer?) => {
        this.currentConsumer = consumer
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.consumerRegistry.clear()
        this.loadConsumers()
    }

    createConsumer = async (consumer: ConsumerFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const tmpConsumer = await agent.Consumers.create(consumer)
            runInAction(() => {
                this.setCurrentConsumer(tmpConsumer)
                this.consumerRegistry.set(tmpConsumer.id, tmpConsumer)
                this.rootStore.modalStore.closeModal('ConsumerModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return tmpConsumer;
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    editConsumer = async (consumer: IConsumer) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedConsumer = await agent.Consumers.update(consumer)
            runInAction(() => {
                if (!!this.currentConsumer) this.setCurrentConsumer(updatedConsumer)
                this.consumerRegistry.set(updatedConsumer.id, updatedConsumer)
                this.rootStore.modalStore.closeModal('ConsumerModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })

            toast.success('Saved')
            return updatedConsumer
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }


    getConsumerById = async (consumerId) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        let currentConsumer
        try {
            currentConsumer = await agent.Consumers.byId(consumerId)
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

        return currentConsumer
    }
}