import axios, {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {RootStore} from "../../stores/RootStore";
import {useLocation} from "react-router-dom";


const {REACT_APP_API_URL} = process.env;
axios.defaults.baseURL = REACT_APP_API_URL;

axios.interceptors.request.use(
    async (config) => {
        const rootStore = new RootStore()
        const {oidcStore} = rootStore

        let user = await oidcStore.getUser();
        if (!!user) {
            // if (user.expired) {
            //     user = await oidcStore.userManager.sil();
            // }

            let token = user.access_token;
            if (!token) {
                throw Error("token empty");
            }
            // @ts-ignore
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        } else {
            throw new Error("user doesnt exists");
        }

    },
    (error: any) => {
        return Promise.reject(error);
    }
);


axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const rootStore = new RootStore()
        const {renewToken} = rootStore.oidcStore

        let text = '';
        if (error.response.status === 401 && window.location.pathname.toLowerCase() !== '/login') {
            // toast.error(`Unauthorized. Please sign out and back in.`);
            await renewToken();

        }
        if (!!error.response?.data) {
            text = 'error: ' + error.response.data.error?.code + ' || ' + error.response.data.error?.message;
        } else if (!!error.message) {
            // network down
            text = !!error.message ? error.message : 'Server is Down ?';
        } else {
            // http status code and data
            text = error.response.status + ' || ' + error.response.data;
        }
        console.error('axios error message: ', text)

        if (isNetworkError(error)) return toast.error(`Couldn't connect to server`);
        throw error;
    });

const isNetworkError = (err) => !!err.isAxiosError && !err.response

const responseBody = (response: AxiosResponse) => response?.data;

const sleep = (ms: number) => (response: AxiosResponse) =>
    new Promise<AxiosResponse>((resolve) =>
        setTimeout(() => resolve(response), ms)
    );

// TODO Update all services to reference api instead of axios

const api = {
    get: (url: string, params?: {}) =>
        axios.get(url, params).then().then(responseBody),
    post: (url: string, body?: {}) =>
        axios.post(url, body).then().then(responseBody),
    put: (url: string, body: {}, params?: {}) =>
        axios.put(url, body, params).then().then(responseBody),
    del: (url: string, body?: {}) =>
        axios.delete(url, !!body ? {
            data: body
        } : undefined).then().then(responseBody),
};

export {
    responseBody,
    api,
    sleep,
}