import React, {useEffect, useRef, useState} from 'react';
import Cleave from "cleave.js/react";
import {CleaveOptions} from "cleave.js/options";
import {cleaveFormatOptionsDateToArray} from "../../lib/utilities";

interface OwnProps extends React.HTMLProps<HTMLInputElement> {
    errMsg?: string;
    prepend?: boolean;
    accent?: boolean
    options?: CleaveOptions
    onInit?: (cleave) => void
    debounceTime?: number
}

type Props = OwnProps;

// const Input: FunctionComponent<Props> = (
const Input = React.forwardRef<HTMLInputElement | null, Props>(
    ({
         errMsg, placeholder, className, label, prepend = false, accent = true, options, debounceTime = 400,
         onInit, ...rest
     }, ref) => {
        const getErrorMessage = (errMsg) => {
            if (!!errMsg && errMsg !== '') return errMsg;

            const {type} = rest;
            if (!type) return 'Please check your input';

            let msg: string;
            switch (type) {
                case 'email':
                    msg = 'Please provide a valid email address e.g. "you@example.com"';
                    break;
                case 'week':
                    msg = 'Please provide a valid week e.g. "Week 36, 2015"';
                    break;
                case 'url':
                    msg = 'Please provide a valid URL e.g. "www.example.com"';
                    break;
                case 'month':
                    msg = 'Please provide a valid Month e.g. "March 2015"';
                    break;
                case 'datetime-local':
                    msg = 'Please provide a valid DateTime e.g. "09/17/1972 07:00 PM"';
                    break;
                case 'date':
                    msg = 'Please provide a valid Date e.g. "09/17/1972"';
                    break;
                case 'time':
                    msg = 'Please provide a valid Time e.g. "07:00 PM"';
                    break;
                default:
                    msg = 'Please check your input';
            }

            return msg;
        };

        const [errorMsg, setErrorMsg] = useState(getErrorMessage(errMsg))


        useEffect(() => {
          setErrorMsg(getErrorMessage(errMsg))
        }, [errMsg, getErrorMessage]);

        // Cleave JS Nonsense
        let Tag: any = 'input'
        if (!!Object.keys(options ?? {}).length) {
            Tag = Cleave
        }


        useEffect(() => {
            const {properties} = cleaveRef.current;
            if (properties) {
                const {dateFormatter} = properties;
                const {datePattern} = dateFormatter;

                if (options?.dateMax === '') {
                    properties.dateMax = '';
                    dateFormatter.dateMax = [NaN];
                } else if (options?.dateMax) {
                    properties.dateMax = options.dateMax;
                    dateFormatter.dateMax = cleaveFormatOptionsDateToArray(datePattern, options.dateMax);
                }

                if (options?.dateMin === '') {
                    properties.dateMin = '';
                    dateFormatter.dateMin = [NaN];
                } else if (options?.dateMin) {
                    properties.dateMin = options.dateMin;
                    dateFormatter.dateMin = cleaveFormatOptionsDateToArray(datePattern, options.dateMin);
                }
            }
        }, [options])


        const cleaveRef = useRef({} as any);


        const handleCleaveInit = (cleave) => {
            cleaveRef.current = cleave;
            if (!!onInit)
                onInit(cleave)
        }


        if (prepend) {
            return (
                <div className={`flex rounded w-full max-h-fit ${prepend ? 'flex-wrap' : ''}`}>
                    <label
                        htmlFor={rest.id ?? rest.name ?? label?.replaceAll(/[^\d\w]/g, '')}
                        className={`inline-flex items-center rounded-l border border-r-0 px-3 ${accent ? 'bg-blue-800 text-white border-blue-800' : 'bg-gray-50 text-gray-500 border-gray-300'} sm:text-sm`}>{label ?? 'Please provide a label'}{rest.required &&
                        <span className={'text-gray-200 text-xs font-medium'}>&nbsp;(required)</span>}
                    </label>
                    <Tag
                        {...rest}
                        id={rest.id ?? rest.name ?? label?.replaceAll(/[^\d\w]/g, '')}
                        aria-describedby={`${rest.name}Placeholder`}
                        // ref={ref}
                        type={rest.type ?? 'text'}
                        onInit={handleCleaveInit}
                        className={`peer flex-0 bg-gray-200/25 block grow rounded-none rounded-r border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:cursor-not-allowed  disabled:bg-gray-200 invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 ${className}`}
                        options={{...options}}
                        ref={ref}
                    />
                    <br/>
                    <p
                        className="text-sm peer-invalid:invisible peer-invalid:hidden w-full px-1"
                        id={`${rest.name}Placeholder`}
                    >
                        {placeholder ? `e.g. ${placeholder}` : ''}
                    </p>
                    <p className="text-red-600 text-sm visible peer-valid:invisible peer-valid:hidden peer-disabled:invisible peer-disabled:hidden w-full px-1">
                        {errorMsg}
                    </p>
                </div>
            );
        }

        return (
            <div className={'mb-3'}>
                {!!label && (
                    <label
                        htmlFor={rest.id ?? rest.name ?? label?.replaceAll(/[^\d\w]/g, '')}
                        className="block font-medium text-slate-700 mb-1"
                    >
                        {label}
                        {rest.required &&
                            <span className={'text-red-600 text-xs'}> (required)</span>}
                    </label>
                )}
                <div className="mt-1">
                    <Tag
                        {...rest}
                        id={rest.id ?? rest.name ?? label?.replaceAll(/[^\d\w]/g, '')}
                        className={`peer mb-3 px-3 py-2 bg-gray-200/25 border shadow-sm border-slate-300 placeholder-slate-400 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500 disabled:shadow-none disabled:cursor-not-allowed disabled:bg-gray-200 ${className}`}
                        aria-describedby={`${rest.name}Placeholder`}
                        type={rest.type ?? 'text'}
                        onInit={handleCleaveInit}
                        options={{...options}}
                        ref={ref}
                    />
                    <p className="text-sm -mt-2 ml-1 peer-invalid:invisible peer-invalid:hidden peer-disabled:hidden" id={`${rest.name}Placeholder`}>
                        {placeholder ? `e.g. ${placeholder}` : ''}
                    </p>
                    <p className="-mt-2 ml-1 invisible peer-invalid:visible text-red-600 text-sm peer-valid:hidden peer-disabled:hidden">
                        {errorMsg}
                    </p>
                </div>
            </div>
        );
    }
);

export default Input;