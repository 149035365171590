import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {
    IMaintenanceRepairNote,
    MaintenanceRepairNoteFormValues
} from '../../models/MaintenanceRepair/MaintenanceRepairNote'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'

export default class MaintenanceRepairNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.maintenanceRepairNoteRegistry.clear()
                this.loadMaintenanceRepairNote()
            }
        )
        reaction(
            () => this.currentMaintenanceRepairId,
            () => {

                this.page = 0
                this.maintenanceRepairNoteRegistry.clear()
                this.loadMaintenanceRepairNote()
            }
        )
    }

    maintenanceRepairNoteRegistry = new Map()
    maintenanceRepairNote: IMaintenanceRepairNote | null = null
    editMode = false


    currentMaintenanceRepairId = '';
    maintenanceRepairNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentMaintenanceRepairId = (maintenanceRepairId: string) => {
        this.currentMaintenanceRepairId = maintenanceRepairId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.maintenanceRepairNoteCount / this.limit)
    }

    get maintenanceRepairNotesById() {
        return Array.from(this.maintenanceRepairNoteRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get maintenanceRepairNotesByDate() {
        return Array.from(this.maintenanceRepairNoteRegistry.values()).sort(
            (a, b) => dayjs(b.updatedOn).isBefore(a.updatedOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getMaintenanceRepairNote = (id: string) => {
        return this.maintenanceRepairNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.maintenanceRepairNoteRegistry.clear()
        this.loadMaintenanceRepairNote()
    }

    clearMaintenanceRepairNote = () => {
        this.maintenanceRepairNoteRegistry.clear()
    }

    loadMaintenanceRepairNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentMaintenanceRepairId) {
                const maintenanceRepairNotesEnvelope = await Agent.MaintenanceRepairNotes.byMaintenanceRepairId(this.currentMaintenanceRepairId, this.axiosParams);
                const {modelsDto, modelCount} = maintenanceRepairNotesEnvelope;
                runInAction(() => {
                    modelsDto.forEach((maintenanceRepairNote: IMaintenanceRepairNote) => {
                        this.maintenanceRepairNoteRegistry.set(maintenanceRepairNote.id, maintenanceRepairNote)
                    })
                    this.maintenanceRepairNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getMaintenanceRepairNoteById = async (id: string) => {
        let maintenanceRepairNote = this.getMaintenanceRepairNote(id)
        if (maintenanceRepairNote) {
            this.maintenanceRepairNote = maintenanceRepairNote
            return maintenanceRepairNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                maintenanceRepairNote = await Agent.MaintenanceRepairNotes.byId(id)
                runInAction(() => {
                    this.maintenanceRepairNote = maintenanceRepairNote
                    this.maintenanceRepairNoteRegistry.set(maintenanceRepairNote.id, maintenanceRepairNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return maintenanceRepairNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createMaintenanceRepairNote = async (maintenanceRepairNote: IMaintenanceRepairNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newMaintenanceRepairNote = await Agent.MaintenanceRepairNotes.create(maintenanceRepairNote)
            await this.loadMaintenanceRepairNote()
            runInAction(() => {
                this.maintenanceRepairNoteRegistry.set(newMaintenanceRepairNote.id, newMaintenanceRepairNote)
                this.rootStore.modalStore.closeModal('NoteModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editMaintenanceRepairNote = (maintenanceRepairNote: MaintenanceRepairNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.MaintenanceRepairNotes.update(maintenanceRepairNote).then((updatedMaintenanceRepairNote) => {
            runInAction(() => {
                this.maintenanceRepairNoteRegistry.set(updatedMaintenanceRepairNote.id, updatedMaintenanceRepairNote)
            })
            this.rootStore.modalStore.closeModal('NoteModal')
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteMaintenanceRepairNote = async (maintenanceRepairNote: IMaintenanceRepairNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.MaintenanceRepairNotes.delete(maintenanceRepairNote.id)
            runInAction(() => {
                this.maintenanceRepairNoteRegistry.delete(maintenanceRepairNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }

    }


}