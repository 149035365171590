import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {IInstallation, InstallationFormValues} from "../../../../../models/Installation/Installation";
import {formNavigationHandler, inputOnChange, objectDateConverter, today} from "../../../../../lib/utilities";
import ConsumerAutoCompleteInput from '../../../../../components/form/ConsumerAutoCompleteInput';
import TechnicianAutoCompleteInput from '../../../../../components/form/TechnicianAutoCompleteInput';
import Input from "../../../../../components/Inputs/Input";
import dateInputOptions from "../../../../../data/cleave/dateInputOptions";
import Button from "../../../../../components/generic/Button";
import EquipmentMultiselect from "../../../training/components/Training/EquipmentMultiselect";
import {IEntityState} from "../../../../../models/Entity/EntityState";
import Toggle from "../../../../../components/generic/Toggle";
import {useLocalStorage} from "../../../../../hooks/useLocalStorage";
import {CheckIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface InstallationFormProps {
    installation?: IInstallation;
}

type Props = InstallationFormProps

const InstallationForm: React.FC<Props> = ({installation}) => {
    // store
    const rootStore = useContext(RootStoreContext);
    const {closeModal, openModal} = rootStore.modalStore;
    const {createInstallation, editInstallation} = rootStore.installationStore
    const {setPredicate: setEquipmentPredicate} = rootStore.equipmentStore
    const {getEntityStateByConsumerId} = rootStore.entityStateStore

    // state
    const [currentInstallation, setCurrentInstallation] = useState(new InstallationFormValues(installation));
    const [animation, setAnimation] = useState('from-right');
    const [step, setStep] = useState(0);
    const [maxSteps,] = useState(1);
    const [addCost, setAddCost] = useState(true);

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);

    // Use Effects
    useEffect(() => {
        if (!!currentInstallation.consumerId)
            getEntityStateByConsumerId(currentInstallation.consumerId)
                // @ts-ignore
                .then((entityState: IEntityState) => setCurrentInstallation((rest: InstallationFormValues) => {
                    return {
                        ...rest,
                        entityStateId: entityState?.id || '',
                        entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : ''
                    }
                }))
    }, []);

    useEffect(() => {
        if (!currentInstallation.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentInstallation((prev: InstallationFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates])

    useEffect(() => {
        if (!!installation) {
            objectDateConverter(installation, setCurrentInstallation)
        }
    }, [installation])

    // Functions
    const handleSubmit = (e) => {
        e.preventDefault()
        let newInstallation = {...currentInstallation}
        if (!newInstallation.id || newInstallation.id === '' || newInstallation.id === 'undefined') {
            delete newInstallation.id
            return createInstallation(newInstallation).then(data => addCostAfterSaving(data))
        } else {
            return editInstallation(newInstallation)
        }
    }

    const handleConsumerAndState = value => {
        if (!!value) {
            getEntityStateByConsumerId(value)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    // setStateProgram(entityState)
                    setCurrentInstallation((prev: IInstallation) => {
                        return {
                            ...prev,
                            consumerId: value,
                            entityStateId: entityState?.id || '',
                            entityState: entityState?.id ? `${entityState.stateName} (${entityState.entityName})` : ''
                        }
                    })
                })
        } else {
            // setStateProgram({} as IEntityState)
            setCurrentInstallation((prev: IInstallation) => {
                return {
                    ...prev,
                    entityStateId: '',
                    consumerId: ''
                }
            })
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.currentTarget
        if (name === 'consumerId') {
            handleConsumerAndState(value);
            // setEquipmentPredicate('consumerId', currentInstallation.consumerId)
        } else {
            inputOnChange(e, setCurrentInstallation)
        }
    }

    const addCostAfterSaving = async (data) => {
        if (!!data && addCost) await openModal('SubItemModal', {
            header: 'New Installation Cost',
            lineItemId: data.id,
            lineItemType: 'Installation',
            entityStateId: data.entityStateId,
        })
    }

    const toggleUpdateEquipmentDate = async () => {
        setCurrentInstallation(prev => {
            return {
                ...prev,
                updateEquipmentDate: !currentInstallation.updateEquipmentDate
            }
        })
    }

    const handleAutoComplete = (value, name) => handleChange({currentTarget: {name, value,}})

    const handleNavigation = (step) => formNavigationHandler(step, setAnimation, setStep)

    const handleEquipmentIdsChange = (equipmentIds) => {
        setCurrentInstallation(prev => {
            return {
                ...prev,
                equipmentIds
            }
        });
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-1 flex-col h-full'}>
            <div className={'overflow-y-auto overflow-x-hidden flex-1'}>
                <div className={`${step === 0 ? animation : 'hide'}`}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                        <div className='w-full'>
                            {!Object.keys(installation ?? {}).length ?
                                <ConsumerAutoCompleteInput
                                    required
                                    name={'consumerId'}
                                    onChange={({id}) => handleAutoComplete(id, 'consumerId')}
                                    displayValue={(currentInstallation.consumerId) ? (currentInstallation.consumerName ? currentInstallation.consumerName : 'Consumer Already Assigned') : ''}
                                /> : <Input label={'Consumer'} disabled value={currentInstallation.consumerName} name={'consumerId'}/>}
                        </div>

                        <div className='w-full'>
                            <Input
                                value={currentInstallation?.entityState ?? ''}
                                label={'State Program'} disabled/>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <div className='w-full'>
                            <TechnicianAutoCompleteInput
                                name={'technicianId'}
                                onChange={({id}) => handleAutoComplete(id, 'technicianId')}
                                displayValue={(currentInstallation.technicianId) ? (currentInstallation?.technicianName || 'Technician already selected') : ''}
                            />
                        </div>
                    </div>

                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <div className={'w-full'}>
                            <Input label={'Date Requested'}
                                   value={`${currentInstallation.dateRequested ?? ''}`}
                                   name={'dateRequested'}
                                   onChange={handleChange}
                                   options={{
                                       ...dateInputOptions,
                                       dateMax: currentInstallation.datePerformed ? currentInstallation.datePerformed.toString() : today(),
                                   }}
                                   
                                   pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                                   placeholder={'10/26/1985'}
                            />
                        </div>
                        <div className={'w-full'}>
                            <Input label={'Date Performed '}
                                   value={`${currentInstallation.datePerformed ?? ''}`}
                                   name={'datePerformed'}
                                   onChange={handleChange}
                                   options={dateInputOptions}
                                   errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                                   pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                                   placeholder={'10/26/1985'}
                                   required
                            />
                        </div>
                    </div>

                    {!currentInstallation.id ?
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                            <div className={'w-full'}>
                                <Toggle label={'Update equipment(s) date received'} name={'updateEquipmentDate'} onChange={toggleUpdateEquipmentDate} checked={currentInstallation.updateEquipmentDate}/>
                            </div>
                            <div className='w-full'>
                                <Toggle label={'Add cost after saving'} onChange={() => setAddCost(prev => !prev)}  checked={addCost} name={'addCostToggle'}/>
                            </div>
                        </div>
                        : ''}
                </div>


                <div className={`${step === 1 ? animation : 'hide'}`}>
                    <EquipmentMultiselect consumerId={currentInstallation.consumerId}
                                          value={currentInstallation?.equipmentIds}
                                          onChange={handleEquipmentIdsChange}/>
                </div>
            </div>

            <div className={'flex justify-between'}>
                <div>
                    <Button color='dark' onClick={() => handleNavigation(step - 1)}
                            disabled={step <= 0}
                            iconLeft={<ChevronLeftIcon className={'h-5 w-5'}/>}>Back</Button>
                    <span className={'m-2'}/>
                    <Button color='dark' onClick={() => handleNavigation(step + 1)}
                            id={'installationNextBtn'}
                            disabled={step >= maxSteps || maxSteps < 0 || !currentInstallation.consumerId}
                            iconRight={<ChevronRightIcon className={'h-5 w-5'}/>}>Next</Button>
                </div>

                <div>{/* DO NOT DELETE */}</div>

                <div>
                    <span className={'m-2'}/>
                    <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} disabled={step !== maxSteps} color={'green'}
                            type={'submit'}>Save</Button>
                    <span className={'m-2'}/>
                    <Button onClick={() => closeModal('InstallationModal')} name={'cancel'}
                            color="red" iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel
                    </Button></div>
            </div>

        </form>
    );
};
export default observer(InstallationForm)