import {api, responseBody} from './apiBase.service'
import axios from "axios";
import {ISubItemAttachmentEnvelope, SubItemAttachmentFormValues} from "../../models/SubItems/SubItemAttachment";

const SubItemAttachment = {
    list: (params: URLSearchParams): Promise<ISubItemAttachmentEnvelope> =>
        axios
            .get("/SubItem/Attachment", {params: params})
            .then(responseBody),
    create: (subItemAttachment: SubItemAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in subItemAttachment) {
            formData.append(key, subItemAttachment[key])
        }
        return api.post(`/SubItem/Attachment`, formData)
    },
    byId: (id: string) => api.get(`/SubItem/Attachment/${id}`),
    bySubItemId: (subItemId: string, params: URLSearchParams): Promise<ISubItemAttachmentEnvelope> =>
        axios.get(`/SubItem/${subItemId}/Attachment`, {params}).then(responseBody),
    delete: (id: string) => api.del(`/SubItem/Attachment/${id}`),
};


export default SubItemAttachment