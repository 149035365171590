import React, {FunctionComponent} from 'react';

interface OwnProps extends React.HTMLProps<HTMLDivElement>{
    children?: React.ReactNode
}

type Props = OwnProps;

const TBody: FunctionComponent<Props> = ({className, children, ...rest}) => {

  return (
      <div {...rest} role={'rowgroup'} className={`md:table-row-group ${className}`}>
          {children}
      </div>
  );
};

export default TBody;
