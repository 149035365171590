import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import Input from "../../../../components/Inputs/Input";
import {inputOnChange, suppressOnTabThrough} from "../../../../lib/utilities";
import Button from "../../../../components/generic/Button";
import {IProgramYear} from "../../../../models/ProgramYear";
import numberInputOptions from "../../../../data/cleave/numberInputOptions";
import dayjs from "dayjs";
import dateInputOptions from "../../../../data/cleave/dateInputOptions";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import Checkbox from "../../../../components/Inputs/Checkbox";

export interface ProgramYearFormProps {
    programYear?: IProgramYear;
}

type Props = ProgramYearFormProps

const ProgramYearForm: React.FC<Props> = ({programYear}) => {
    const rootStore = useContext(RootStoreContext);
    const {createProgramYear} = rootStore.programYearStore;
    const {closeModal} = rootStore.modalStore;
    const [currentProgramYear, setCurrentProgramYear] = useState(programYear as IProgramYear ?? {});


    useEffect(() => {
        if (!currentProgramYear.id) {
            let year = new Date().getFullYear()
            let startDate = dayjs('07/01/' + (year)).format('MM/DD/YYYY')
            let endDate = dayjs('06/30/' + (year + 1)).format('MM/DD/YYYY')

            if (dayjs().isAfter('06/30/' + year)) {
                startDate = dayjs('07/01/' + (year + 1)).format('MM/DD/YYYY')
                endDate = dayjs('06/30/' + (year + 2)).format('MM/DD/YYYY')
            }


            // @ts-ignore
            setCurrentProgramYear((prev: IProgramYear) => {
                return {...prev, startDate, endDate}
            })
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()
        createProgramYear(currentProgramYear)
    };


    const handleInput = (e) => {
        inputOnChange(e, setCurrentProgramYear)
    }

    const handleZeroIfEmpty = (e) => suppressOnTabThrough(e, setCurrentProgramYear)

    return (
        <form onSubmit={handleSubmit} className={'flex flex-col grow h-full justify-between'} autoComplete={'off'}>
            <div>
                {!currentProgramYear.id && <>
                    <div className={'flex gap-4'}>
                        <div className={'mb-3 w-full'}>
                            <Input label={'Title'}
                                   value={`${currentProgramYear.title ?? ''}`}
                                   name={'title'}
                                   placeholder={'Delorean 22/23'}
                                   onChange={handleInput}
                            /></div>
                    </div>

                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <Input label={'Start Date'}
                               value={`${currentProgramYear.startDate ?? ''}`}
                               name={'startDate'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                               onChange={handleInput}
                               options={{
                                   ...dateInputOptions,
                                   dateMax: undefined
                               }}/>

                        <Input label={'End Date'}
                               value={`${currentProgramYear.endDate ?? ''}`}
                               name={'endDate'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                               onChange={handleInput}
                               options={{
                                   ...dateInputOptions,
                                   dateMax: undefined
                               }}/>
                    </div>

                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <Input
                            onChange={handleInput}
                            name='programYearBudget'
                            label='Program Year Budget'
                            placeholder='$88.00'
                            options={numberInputOptions}
                            value={currentProgramYear.programYearBudget}
                            onBlur={handleZeroIfEmpty}
                        />

                        <Input
                            onChange={handleInput}
                            name='centralizedDbBudget'
                            label='Centralized DB Budget'
                            placeholder='$88.00'
                            options={numberInputOptions}
                            value={currentProgramYear.centralizedDbBudget}
                            onBlur={handleZeroIfEmpty}
                        />
                    </div>

                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <Input
                            onChange={handleInput}
                            name='nationalOutreachBudget'
                            label='National Outreach Budget'
                            placeholder='$88.00'
                            options={numberInputOptions}
                            value={currentProgramYear.nationalOutreachBudget}
                            onBlur={handleZeroIfEmpty}
                        />

                        <Input
                            onChange={handleInput}
                            name='stateAllocations'
                            label='States Allocations'
                            placeholder='$88.00'
                            options={numberInputOptions}
                            value={currentProgramYear.stateAllocations}
                            onBlur={handleZeroIfEmpty}
                        />
                    </div>
                </>}

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <Checkbox label={'Is locked?'} name={'locked'} onChange={handleInput}
                              checked={currentProgramYear.locked}/>
                </div>
            </div>

            <div className={'flex gap-4 justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>}
                        color={'green'}
                        type='submit'>Save</Button>
                <Button onClick={() => closeModal('ProgramYearModal')}
                        color="red"
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>
                    Cancel
                </Button>
            </div>


        </form>
    );
};
export default observer(ProgramYearForm);