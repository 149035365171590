import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../api/Agent'
import {toast} from 'react-toastify'
import {RootStore} from './RootStore'
import {
    EntityStateFilingFrequencyApprovalFormValues,
    IEntityStateFilingFrequency
} from "../models/EntityStateFilingFrequency";


export default class EntityStateFilingFrequencyStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.entityStateFilingFrequencyRegistry.clear()
                this.loadEntityStateFilingFrequency()
            }
        )
    }

    entityStateFilingFrequencyRegistry = new Map()
    entityStateFilingFrequencyCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.entityStateFilingFrequencyRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.entityStateFilingFrequencyCount / this.limit)
    }

    get entityStateFilingFrequenciesList() {
        return Array.from(this.entityStateFilingFrequencyRegistry.values())
    }

    getEntityStateFilingFrequency = (id: string) => {
        return this.entityStateFilingFrequencyRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.entityStateFilingFrequencyRegistry.clear()
        this.loadEntityStateFilingFrequency()
    }

    clearEntityStateFilingFrequency = () => {
        this.entityStateFilingFrequencyRegistry.clear()
    }

    loadEntityStateFilingFrequency = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const entityStateFilingFrequenciesEnvelope = await Agent.EntityStateFilingFrequency.list(this.axiosParams)
            const {modelsDto, modelCount} = entityStateFilingFrequenciesEnvelope

            runInAction(() => {
                modelsDto.forEach((entityStateFilingFrequency: IEntityStateFilingFrequency) => {
                    this.entityStateFilingFrequencyRegistry.set(entityStateFilingFrequency.id, entityStateFilingFrequency)
                })
                this.entityStateFilingFrequencyCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    loadNakedEntityStateFilingFrequency = async (predicates?: {}) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
        this.clearEntityStateFilingFrequency()
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)

            if (!!predicates) for (let key in predicates) {
                params.append(key, predicates[key])
            }

            const assessmentsEnvelope = await Agent.EntityStateFilingFrequency.list(params)
            const {modelsDto} = assessmentsEnvelope

            return modelsDto
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getEntityStateFilingFrequencyById = async (id: string) => {

        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let entityStateFilingFrequency = await Agent.EntityStateFilingFrequency.byId(id)
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return entityStateFilingFrequency
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createEntityStateFilingFrequency = async (entityStateFilingFrequency: IEntityStateFilingFrequency) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newEntityStateFilingFrequency = await Agent.EntityStateFilingFrequency.create(entityStateFilingFrequency)
            runInAction(() => {
                this.loadEntityStateFilingFrequency()
                this.rootStore.modalStore.closeModal('EntityStateFilingFrequencyModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newEntityStateFilingFrequency
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    approveEntityStateFilingFrequency = async (entityStateFilingFrequency: EntityStateFilingFrequencyApprovalFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newEntityStateFilingFrequency = await Agent.EntityStateFilingFrequency.approve(entityStateFilingFrequency)
            runInAction(() => {
                this.entityStateFilingFrequencyRegistry.set(newEntityStateFilingFrequency.id, newEntityStateFilingFrequency)
                this.rootStore.modalStore.closeModal('EntityStateFilingFrequencyModal')
            })
            toast.success('Saved')
            return newEntityStateFilingFrequency
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }


}