import React, {FunctionComponent, useEffect, useRef} from 'react';
import "cleave.js/dist/addons/cleave-phone.us";
import Cleave from 'cleave.js/react'
import {CleaveOptions} from 'cleave.js/options'
import {cleaveFormatOptionsDateToArray} from "../../lib/utilities";

// @ts-ignore
export interface InputProps extends React.HTMLProps<HTMLInputElement> {
    label?: string | JSX.Element
    debounceTime?: number
    prepend?: boolean
    options?: CleaveOptions
    onInit?: (cleave) => void
    accented?: boolean
}

type Props = InputProps;

const Input: FunctionComponent<Props> = ({
                                             label,
                                             className,
                                             debounceTime = 400,
                                             prepend = false,
                                             options,
                                             onInit,
                                             accented = true,
                                             ...rest
                                         }) => {
    let Tag: any = 'input'
    if (!!Object.keys(options ?? {}).length) {
        Tag = Cleave
    }


    const cleaveRef = useRef({} as any);


    useEffect(() => {
        const {properties} = cleaveRef.current;
        if (properties) {
            const {dateFormatter} = properties;
            const {datePattern} = dateFormatter;

            if (options?.dateMax === '') {
                properties.dateMax = '';
                dateFormatter.dateMax = [NaN];
            } else if (options?.dateMax) {
                properties.dateMax = options.dateMax;
                dateFormatter.dateMax = cleaveFormatOptionsDateToArray(datePattern, options.dateMax);
            }

            if (options?.dateMin === '') {
                properties.dateMin = '';
                dateFormatter.dateMin = [NaN];
            } else if (options?.dateMin) {
                properties.dateMin = options.dateMin;
                dateFormatter.dateMin = cleaveFormatOptionsDateToArray(datePattern, options.dateMin);
            }
        }
    }, [options])


    const handleCleaveInit = (cleave) => {
        cleaveRef.current = cleave;
        if (!!onInit)
            onInit(cleave)
    }


    if (rest.type === 'checkbox') {
        return (<div className="relative flex items-start">
            <div className="flex items-center h-5">
                <input  {...rest} id={rest.name} type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 rounded"/>
            </div>
            {!!label &&
                <div className="ml-3 text-sm">
                    <label htmlFor={rest.name} className="font-medium text-gray-700">{label}</label>
                    {rest.required &&
                        <span className={'text-red-600 text-xs'}> (required)</span>}
                </div>}
        </div>);
    } else if (rest.type === 'range') {
        return (
            <div className={'flex'}>
                {!!label &&
                    <label className={'w-full'} htmlFor={rest?.name} id={`${rest.name}Label`}>{label}
                        {rest.required &&
                            <span className={'text-red-600 text-xs'}> (required)</span>}
                    </label>}
                <input {...rest} className="w-full block" id={rest.name}/>
            </div>
        );
    } else if (prepend) {
        return (<>
            <div
                className={`${className ?? ''} flex mb-3 focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 rounded w-full items-center`}>
                {!!label && <div className="">
                    <label htmlFor={rest?.name} id={`${rest.name}Label`}
                           className={`whitespace-nowrap flex items-center py-1 m-0 px-2 border-2 rounded-l border-r-0 ${accented && prepend ? 'bg-blue-800 text-white border-blue-800' : 'bg-gray-200 border-gray-200'}`}>{label}{rest.required &&
                        <span className={'text-red-600 text-xs'}> (required)</span>}</label>
                </div>}
                <Tag id={rest?.name}
                     className={`py-1 m-0 px-2 border-2 rounded-r border-gray-200 focus:ring-0 focus:border-gray-200 border-l-0 focus:outline-none w-full invalid:border-red-300 ${className ?? ''}`} {...rest}
                     onInit={handleCleaveInit}

                     options={{...options}}/>
            </div>
        </>)
    } else {
        return (<div>
            {!!label &&
                <label className={'block text-left'} htmlFor={rest?.name} id={`${rest.name}Label`}>{label}{rest.required &&
                    <span className={'text-red-600 text-xs'}> (required)</span>}</label>}
            <Tag id={rest?.name}
                 className={`mb-3 block w-full border border-gray-400 focus:border-gray-400 py-1 px-2 rounded focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:outline-none invalid:border-red-300 ${className ?? ''}`} {...rest}
                 onInit={handleCleaveInit}
                 options={{...options}}
            />
        </div>);
    }
};

export default Input;