import React, {FunctionComponent} from 'react';

interface OwnProps extends React.HTMLProps<HTMLDivElement>{
    children?: React.ReactNode
    header?: React.ReactNode
}

type Props = OwnProps;

const TD: FunctionComponent<Props> = ({className = '', header, children, ...rest}) => {

  return (<>
      {!!header && <div role={'columnheader'} className={`md:hidden py-1 px-1 text-xs font-bold`}>{header}</div>}
      <div {...rest} role={'cell'} className={`md:py-3 px-2 md:border-b md:table-cell align-middle ${className}`}>{children}</div>
  </>);
};

export default TD;
