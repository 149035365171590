import React, {FunctionComponent, useEffect} from 'react';

import {IAssessment} from "../../../models/Assessment/Assessment";
import {arraySum, displayDate, displayDollarAmount} from "../../../lib/utilities";
import {observer} from "mobx-react-lite";
import {useAPIPager} from "../../../hooks/useAPIPager";
import Agent from "../../../api/Agent";
import {useSelectTable} from "../../../hooks/useSelectTable";
import Table from '../../table/Table';
import TR from '../../table/TR';
import THead from '../../table/THead';
import TBody from '../../table/TBody';
import TD from '../../table/TD';
import Pagination from "../../table/Pagination";
import Skeleton from "../../generic/Skeleton";
import Checkbox from "../../Inputs/Checkbox";

interface OwnProps {
    onChange?: (e: string[]) => (void)
    selected?: string[]
    predicates?: Map<any, any>
}

type Props = OwnProps;

const AssessmentSelectTable: FunctionComponent<Props> = ({onChange, selected, predicates}) => {
    // Custom Hooks
    const {items, count, handleChangePage, isLoading} = useAPIPager(Agent.Assessments, predicates)
    const {handleCheck, handleCheckAll, selectedItems} = useSelectTable(selected);

    // Use Effects
    useEffect(() => {
        !!onChange && onChange(selectedItems)
    }, [selectedItems]);


    return (<>
        <span className="sr-only" id="assessmentsSelectTable">Assessments</span>
        <Table className={'max-w-full'} aria-describedby={'assessmentsSelectTable'}>
            <THead>
                <TR>
                    <TD className={'th min-w-max whitespace-nowrap'}>
                        {items.length > 0 && <>
                            <span id="selectAllAssessments" className="sr-only">Select all</span>
                            <Checkbox label={<span className={'sr-only'}>Select</span>}
                                      checked={items.every(i => selectedItems?.includes(i.id))}
                                      className="border-gray-300 rounded"
                                      onChange={(e) => handleCheckAll(e, items)}/></>}
                    </TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Consumer</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Technician</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Date Performed</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Cost</TD>
                </TR>
            </THead>
            <TBody>
                {items?.map((assessment: IAssessment, idx) => {
                    return (<TR key={idx}>
                        {isLoading ? <TD colSpan={100}><Skeleton/></TD> : <>
                            <TD className={'td min-w-max whitespace-nowrap'}>
                                <Checkbox checked={selectedItems.includes(assessment.id ?? '')}
                                          label={<span className={'sr-only'}>Select</span>}
                                          onChange={handleCheck} value={assessment.id}/>
                            </TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{assessment?.consumerName}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{assessment?.technicianName}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{displayDate(assessment?.datePerformed)}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{displayDollarAmount(arraySum([
                                assessment.assessmentCosts,
                                assessment.auxiliaryAidCosts,
                                assessment.eligibilityCosts,
                                assessment.otherCosts
                            ]))}</TD>
                        </>}
                    </TR>)
                })}
            </TBody>
        </Table>
        <Pagination totalRows={count} onPageChange={handleChangePage} onRowChange={handleChangePage}/>
    </>);
};

export default observer(AssessmentSelectTable);
