import React, {FunctionComponent, useCallback, useContext, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {debounce} from 'lodash'
import {IInstallation} from "../../../../../models/Installation/Installation";
import AssignTechnician from "../../../../../components/generic/AssignTechnician";

export interface AssignTechnicianToInstallationProps {
    installation: IInstallation
}

type Props = AssignTechnicianToInstallationProps;

const AssignTechnicianToInstallation: FunctionComponent<Props> = ({installation}) => {
    // Stores
    const rootStore = useContext(RootStoreContext)
    const {editInstallation} = rootStore.installationStore
    const {
        clearTechnicians,
        handleChangePage,
        loadTechnicians,
        page,
        pageCount,
        setPredicate,
        technicianCount,
        techniciansList,
    } = rootStore.techniciansStore
    const {closeModal} = rootStore.modalStore

    // Effects
    useEffect(() => {
        loadTechnicians()
    }, [loadTechnicians, clearTechnicians]);

    useEffect(() => {
        return () => {
            setPredicate()
        }
    }, []);


    // Functions
    const handleChange = (e) => {
        const {value, name} = e.currentTarget;
        handleSearch({
            n: name,
            v: value
        })
    };

    const handleSearch = useCallback(
        // tslint:disable-next-line
        debounce((value) => {
            const {n, v} = value
            setPredicate(n, v)
        }, 500),
        []
    );

    const assignTechnician = (technicianId) => {

        let newInstallation = {
            ...installation,
            technicianId
        }

        newInstallation.technicianId = technicianId
        editInstallation(newInstallation).then(() => close())
    }

    const close = () => closeModal('AssignTechnicianToInstallationModal')

    return (
        <AssignTechnician onClick={assignTechnician} onChange={handleChange} options={techniciansList}
                          totalItems={technicianCount}
                          page={page} pageCount={pageCount} onPageChange={handleChangePage}
                          onCancel={close}/>
    );
};

export default observer(AssignTechnicianToInstallation);
