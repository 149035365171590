import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import InstallationForm, {
    InstallationFormProps
} from "../../../pages/consumers/installations/components/Installations/InstallationForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, InstallationFormProps {
}

type Props = OwnProps;

// @ts-ignore
const InstallationModal = NiceModal.create<Props>(({installation, onClose, header}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <InstallationForm installation={installation}/>
        </SlideOver>
    )
})

export default InstallationModal;
