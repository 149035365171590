import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RootStoreContext} from "../../../stores/RootStore";

import {IInstallation} from "../../../models/Installation/Installation";
import {arraySum, displayDate, displayDollarAmount} from "../../../lib/utilities";
import {observer} from "mobx-react-lite";
import InstallationAutoCompleteInput from "../InstallationAutoCompleteInput";
import {TrashIcon} from "@heroicons/react/24/solid";
import TD from '../../table/TD';
import TR from '../../table/TR';
import TBody from '../../table/TBody';
import THead from '../../table/THead';
import Table from '../../table/Table';

interface OwnProps {
    onChange?: (e) => (void)
    name?: string
    selected?: string[]
    entityStateId?: string
}

type Props = OwnProps;

const InstallationMultiselect: FunctionComponent<Props> = ({entityStateId, onChange, name, selected}) => {
    //Stores
    const rootStore = useContext(RootStoreContext);
    const {loadNakedInstallation, installationsByDate, getInstallationByIds} = rootStore.installationStore
    const {newSubItemQueue} = rootStore.subItemStore

    // Local State
    const [availableInstallations, setAvailableInstallations] = useState([] as IInstallation[]);
    const [selectedInstallations, setSelectedInstallations] = useState([] as Array<IInstallation>);

    // Use Effects
    useEffect(() => {
        loadNakedInstallation().then(data => setAvailableInstallations([...data]))
        if (!!selected && selected?.length > 0) {
            getInstallationByIds(selected).then(data => {
                if (!!data) setSelectedInstallations(data)
            })
        }
    }, [installationsByDate, newSubItemQueue]);

    // Functions
    const handleRemove = (idx): void => {
        setSelectedInstallations((prev) => {
            prev.splice(idx, 1)
            !!onChange && onChange({
                currentTarget: {
                    name,
                    value: prev
                }
            })
            return prev
        })
    }

    const handleSelected = (data): void => {
        if (!!data) {
            let newInstallation = availableInstallations
                .find((installation: IInstallation) => installation.id === data.id)

            if (!newInstallation) return

            // @ts-ignore
            setSelectedInstallations(prev => {
                let tmp = [...prev, newInstallation]
                // let tmp = [...prev]
                !!onChange && onChange({
                    currentTarget: {
                        name,
                        value: tmp.map(ids => ids?.id)
                    }
                })
                return tmp
            })
        }
    }

    return (<>
        <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                <InstallationAutoCompleteInput
                    entityStateId={entityStateId}
                    onChange={handleSelected}
                    // @ts-ignore
                    filterIds={selectedInstallations?.map((select: IInstallation) => {return !!select ? select.id : ''}) ?? []}
                />
        </div>

        <Table>
            <THead>
                <TR>
                    <TD/>
                    <TD>Consumer</TD>
                    <TD>Technician</TD>
                    <TD>Date Performed</TD>
                    <TD>Date Requested</TD>
                    <TD>Total Cost</TD>
                </TR>
            </THead>
            <TBody>
                {selectedInstallations.map((installation: IInstallation, idx) => {
                    return (<TR key={idx}>
                        <TD><TrashIcon className={'text-red-600 h-6 w-6'} onClick={() => handleRemove(idx)}/></TD>
                        <TD>{installation?.consumerName}</TD>
                        <TD>{installation?.technicianName}</TD>
                        <TD>{displayDate(installation?.datePerformed)}</TD>
                        <TD>{displayDate(installation?.dateRequested)}</TD>
                        <TD>{displayDollarAmount(arraySum([
                            installation?.installationLaborCosts,
                            installation?.instructionalMaterialCosts,
                            installation?.auxiliaryAidCosts,
                            installation?.otherExpenses,
                        ]))}</TD>
                    </TR>)
                })}
            </TBody>
        </Table>
    </>);
};

export default observer(InstallationMultiselect);
