import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import AssignShippingAndTax, {AssignShippingAndTaxProps} from "../../generic/AssignShippingAndTax";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, AssignShippingAndTaxProps {
}

type Props = OwnProps;

// @ts-ignore
const AssignShippingAndTaxModal = NiceModal.create<Props>(({header, onClose, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver title={header} onClose={handleClose} open={modal.visible} afterClose={modal.remove}>
            <AssignShippingAndTax {...rest}/>
        </SlideOver>
    )
})

export default AssignShippingAndTaxModal;
