import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from '../../../../stores/RootStore';
import {IAttachment} from "../../../../models/generic/Attachment";
import {TravelAttachmentFormValues} from "../../../../models/Travel/TravelAttachment";
import AttachmentTable from "../../../../components/attachment/AttachmentTable";


export interface TravelAttachmentTableProps {
    travelId: string;
    disableCreateNew?: boolean;
}

type Props = TravelAttachmentTableProps;

const TravelAttachmentTable: FunctionComponent<Props> = ({travelId, disableCreateNew= false}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentTravelId,
        travelAttachmentsByDate,
        travelAttachmentCount,
        handleChangePage,
        getTravelAttachmentsByTravelId,
        getTravelAttachmentById,
        createTravelAttachment,
        deleteTravelAttachment
    } = rootStore.travelAttachmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment
    // USE EFFECTS
    useEffect(() => {
        if (!!travelId) {
            setCurrentTravelId(travelId)
            getTravelAttachmentsByTravelId()
        }
    }, [travelId, getTravelAttachmentsByTravelId]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {header: 'Create Travel Attachment', createAttachment});
    }

    const createAttachment = (attachment: IAttachment) => {
        const newTravelAttachment: TravelAttachmentFormValues = {...attachment, travelId}
        createTravelAttachment(newTravelAttachment);
    }

    const deleteAttachment = (attachmentId) => {
        deleteTravelAttachment(attachmentId);
    }

    return (<AttachmentTable
        attachments={travelAttachmentsByDate}
        attachmentCount={travelAttachmentCount}
        handleChangePage={handleChangePage}
        openCreateModal={openCreateModal}
        filterPredicate={setPredicate}
        getAttachmentById={getTravelAttachmentById}
        getDirectAttachmentById={getGenericDirectAttachmentById}
        deleteAttachment={deleteAttachment}
        disableCreateNew={disableCreateNew}
    />)
};

export default observer(TravelAttachmentTable)
