import React, {useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import {IContact} from "../../../../../models/Contact";
import Input from "../../../../../components/Inputs/Input";

import {RootStoreContext} from "../../../../../stores/RootStore";
import Button from "../../../../../components/generic/Button";
import {inputOnChange} from "../../../../../lib/utilities";
import EntityMultiselect, {EntityListItem} from "./EntityMultiselect";
import zipcodeInputOptions from "../../../../../data/cleave/zipcodeInputOptions";
import phoneInputOptions from "../../../../../data/cleave/phoneInputOptions";
import Select from "../../../../../components/generic/Select";
import HR from "../../../../../components/generic/HR";
import {IEntity} from "../../../../../models/Entity/Entity";
import {CheckIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface ContactFormProps {
    contact: IContact | undefined;
    editing?: boolean;
    entity?: IEntity;
}

const ContactForm: React.FC<ContactFormProps> = ({contact, entity, editing = false}) => {

    // Stores
    const rootStore = useContext(RootStoreContext);
    const {createContact, editContact} = rootStore.contactsStore;
    const {getStates} = rootStore.stateStore;
    const {closeModal} = rootStore.modalStore;

    // Local State
    const [step, setStep] = useState(0);
    const [animation, setAnimation] = useState('from-right');
    // const [currentContact, setCurrentContact] = useState(contact ?? {} as IContact);
    const [currentContact, setCurrentContact] = useState(contact ?? (
        entity ? {
            entityContacts: [{
                entityId: entity?.id,
                entityName: entity?.name
            }]
        } : {}
    ) as IContact);
    const [maxStep] = useState(1);

    // Functions
    const prev = () => {
        setAnimation('to-left')
        setTimeout(() => {
            let inc = (step - 1) === 2 && editing ? 2 : 1
            setStep(step > 0 ? step - inc : 0)
            setAnimation('from-right')
        }, 600)
    }

    const next = () => {
        setAnimation('to-left')
        setTimeout(() => {
            let inc = (step + 1) === 2 && editing ? 2 : 1
            setStep(step < maxStep ? step + inc : 4)
            setAnimation('from-right')
        }, 600)
    }

    const handleInput = (e) => {
        inputOnChange(e, setCurrentContact);
    }

    const handleEntityChange = (entityContacts: EntityListItem[]) => {
        setCurrentContact(prev => {
            return {
                ...prev,
                entityContacts: entityContacts
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!currentContact.id) {
            let newContact = {
                ...currentContact
            }
            delete newContact.id;
            createContact(newContact);
        } else {
            editContact(currentContact);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full overflow-x-hidden'}
              autoComplete={'off'}>
            <div className={'flex-1'}>
                <div className={`${step === 0 ? animation : 'hide'}`}>
                    <HR title={'Basic Info'}/>
                    <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                        <Input
                            name='name'
                            placeholder={'Emmett Brown'}
                            required
                            label='Full Name'
                            value={currentContact.name}
                            onChange={handleInput}
                        />
                    </div>
                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <Input
                            name='phone'
                            placeholder='(978) 555-1953'
                            label='Phone'
                            value={currentContact.phone}
                            onChange={handleInput}
                            options={phoneInputOptions}
                            pattern='^\(?([2-9][0-8][0-9])\)?[-.●\s]?([2-9][0-9]{2})[-.●\s]?([0-9]{4})$'
                            type={'tel'}
                            required
                        />
                        <Input
                            onChange={handleInput}
                            name='email'
                            label='Email'
                            placeholder='biffdude@future.org'
                            value={currentContact.email}
                            required
                        />
                    </div>
                    <HR title={'Address'}/>
                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <div className={'w-full'}>
                            <Input
                                name='address1'
                                placeholder={'123 River Road'}
                                required
                                label='Address 1'
                                value={currentContact.address1}
                                onChange={handleInput}
                            />
                        </div>
                        <div className={'w-full'}>
                            <Input
                                name='address2'
                                placeholder={'Apt A'}
                                label='Address 2'
                                value={currentContact.address2}
                                onChange={handleInput}
                            />
                        </div>
                    </div>
                    <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-1'}>
                        <Input
                            name='city'
                            placeholder={'Houston'}
                            label='City'
                            value={currentContact.city}
                            onChange={handleInput}
                            required
                        />
                        <Select
                            name='state'
                            value={currentContact.state}
                            placeholder={'Select State'}
                            options={getStates}
                            label={'State'}
                            onChange={handleInput}
                            required
                        />
                        <Input
                            name='zipCode'
                            placeholder='90210'
                            label='Zip Code'
                            value={currentContact.zipCode}
                            onChange={handleInput}
                            options={zipcodeInputOptions}
                            required
                        />
                    </div>
                    <HR title={'Misc'}/>
                    <Input
                        name='jobFunction'
                        label='Job Function'
                        value={currentContact.jobFunction}
                        onChange={handleInput}
                        placeholder={'Equipment Installer'}
                        required
                    />
                </div>

                <div className={`${step === 1 ? animation : 'hide'}`}>
                    <HR title={'Associated Entity'}/>

                    <EntityMultiselect
                        onChange={handleEntityChange}
                        value={currentContact.entityContacts ? currentContact.entityContacts : []}
                    />
                </div>
            </div>

            <div className={'flex justify-between'}>
                <div>
                    <Button color='dark' iconLeft={<ChevronLeftIcon className={'h-5 w-5'}/>} onClick={prev}
                            disabled={step === 0}>Back</Button>
                    <span className={'m-2'}/>

                    <Button color='dark' iconRight={<ChevronRightIcon className={'h-5 w-5'}/>} onClick={next}
                            disabled={step === maxStep}>Next</Button>
                </div>


                <div>
                    <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'} type={'submit'}
                            disabled={step !== maxStep}>Save</Button>
                    <span className={'m-2'}/>
                    <Button
                        onClick={() => {
                            closeModal('ContactsModal')
                        }}
                        iconLeft={<XMarkIcon className={'h-6 w-6'}/>}
                        color="red">Cancel</Button>
                </div>
            </div>
        </form>
    )
}
export default observer(ContactForm);