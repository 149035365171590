import React, {FunctionComponent, useEffect, useState} from 'react';
import Input from "../Inputs/Input";
import {IShippingAndTax} from "../../models/ShippingAndTax/ShippingAndTax";
import {arraySum, displayDate, displayDollarAmount} from "../../lib/utilities";
import Button from "./Button";
import {IEquipment} from "../../models/Equipment/Equipment";
import {ChevronLeftIcon, ChevronRightIcon, PlusIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface AssignShippingAndTaxProps {
    onClick(shipping, equipment): void

    onChange(e): void

    options: Array<IShippingAndTax>
    totalItems: number
    page: number
    pageCount: number

    onPageChange(pageNumber): void

    onCancel(): void

    onNew(): void

    equipment?: IEquipment
}

type Props = AssignShippingAndTaxProps;

const AssignShippingAndTax: FunctionComponent<Props> = ({
                                                            onClick,
                                                            onChange,
                                                            options,
                                                            page,
                                                            onPageChange,
                                                            totalItems,
                                                            pageCount,
                                                            onCancel,
                                                            onNew,
                                                            equipment,
                                                        }) => {

    // Local State
    const [active, setActive] = useState(-1);
    // @TODO Revisit this logic. make it more generic on only pass functionality. Affects EQ Form EQ Details.
    const [currentEquipment, setCurrentEquipment] = useState(equipment)

    // Use Effects
    useEffect(() => {
        setCurrentEquipment(equipment)
    }, [equipment]);

    return (<>
        <span className="sr-only">Use the following inputs to filter assignable Shipping and Tax records. There is not a submit button. Records will be filtered as you type. Tab to the list below to select a record.</span>
        <form onSubmit={(e) => e.preventDefault()} autoComplete={'off'} aria-describedby={'techSelectDescription'}>
            <div className={'form-grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                <Input label={'Receipt Number'} name={'receiptNumber'} type={'search'}
                       onChange={onChange}/>
                <Input label={'Middle Name'} name={'middleName'} type={'search'}
                       onChange={onChange}/>
            </div>
        </form>

        <div className="list-group grow overflow-y-auto">
            {totalItems > 0 ? options.map((shippingAndTax, idx) =>
                <button type="button"
                        className={`flex justify-between list-group-item list-group-item-action ${active === idx ? 'active' : ''}`}
                        aria-current={active === idx}
                        onFocus={() => setActive(idx)}
                        onBlur={() => setActive(-1)}
                        onClick={() => onClick(shippingAndTax, currentEquipment)}
                >
                    <span>Invoice Date {displayDate(shippingAndTax.invoiceDate)}</span>
                    <span>Receipt Number {shippingAndTax.receiptNumber}</span>
                    <span>Total
                        Cost {displayDollarAmount(arraySum([
                            shippingAndTax.shippingCosts,
                            shippingAndTax.shippingOrTaxCosts,
                            shippingAndTax.taxCosts
                        ]))}
                            </span>
                </button>
            ) : <p>No items available</p>}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
            <div className='w-full'>
                <Button color={'dark'}
                        onClick={() => onPageChange(page)}
                        disabled={page === 0}
                        iconLeft={<ChevronLeftIcon className={'h-5 w-5'}/>}>Previous</Button>
                <span className="mr-2"/>
                <Button color={'dark'}
                        onClick={() => onPageChange(page + 2)}
                        disabled={!pageCount || page === pageCount - 1}
                        iconRight={<ChevronRightIcon className={'h-5 w-5'}/>}
                >Next</Button>
            </div>
            <div className="col text-right">
                <Button color={'red'} name={'cancel'} onClick={() => {
                    onPageChange(1)
                    onCancel()
                }} iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel
                </Button>
                <span className="mr-2"/>
                <Button color={'green'} onClick={() => onNew()} iconLeft={<PlusIcon className={'h-5 w-5'}/>}>New
                    Shipping & Tax
                </Button>
            </div>
        </div>
    </>);
};

export default AssignShippingAndTax;
