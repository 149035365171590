import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {RootStore} from '../RootStore'
import agent from "../../api/Agent"
import {toast} from "react-toastify"
import {ISubItem} from '../../models/SubItems/SubItem'
import {ISubItemCategory} from "../../models/SubItems/SubItemCategory";
import {IMassCostUpdateFormValues} from "../../models/MassCost/MassCost";

interface ISubItemQueueItem extends ISubItem {
    hasAttachment?: boolean
}


export default class SubItemStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.subItemRegistry.clear()
                this.loadSubItemCategory()
            }
        )
    }

    subItemRegistry: Map<any, any> = new Map()
    predicate: Map<any, any> = new Map()
    subItemCategoryCount: number = 0
    lineItem?: string
    page: number = 0
    limit: number = 10000
    currentConsumerId?: string = undefined
    newSubItemQueue: Array<ISubItemQueueItem> = []

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get subItemsList() {
        // @ts-ignore
        return Array.from(this.subItemRegistry.values())
    }

    get subItemCategoriesById() {
        return Array.from(this.subItemRegistry.values())
            .sort((a: ISubItemCategory, b: ISubItemCategory) => {
                if (!!a.categoryNumber && !!b.categoryNumber) {
                    return a.categoryNumber - b.categoryNumber
                }
                return -1
            })
    }

    get subItemCategoriesByLineItem() {
        if (this.subItemRegistry.size < 1) {
            return []
        }

        let tmpArr: ISubItemCategory[] = Array.from(this.subItemRegistry.values())
        let lineItemArr = []

        for (let category of tmpArr) {
            let idx = lineItemArr.findIndex((lineItem: ISubItemCategory[]) => lineItem[0].lineItem === category.lineItem)

            if (idx > -1) {
                // @ts-ignore
                lineItemArr[idx].push(category)
            } else {
                // @ts-ignore
                lineItemArr.push([category])
            }
        }
        return lineItemArr
    }

    loadSubItemCategory = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const {modelsDto, modelCount} = await agent.SubItem.list(this.axiosParams)
            this.subItemRegistry.clear()
            runInAction(() => {
                modelsDto
                    .filter((category: ISubItemCategory) => !category.hidden)
                    .forEach((category: ISubItemCategory) => this.subItemRegistry.set(category.categoryNumber, category))
                this.subItemCategoryCount = modelCount
            })
        } catch (e) {
            // this.rootStore.errorStore.addError(e);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    createSubItem = async (subItem: ISubItem) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let newSubItem = await agent.SubItem.create(subItem)
            toast.success('Saved')
            this.subItemRegistry.set(newSubItem.id, newSubItem)
            this.addToNewSubItemQueue(newSubItem)
            return newSubItem
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    updateSubItem = async (subItem: ISubItem) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const updatedSubItem = await agent.SubItem.update(subItem)
            this.rootStore.modalStore.closeModal('SubItemModal')
            this.subItemRegistry.set(updatedSubItem.id, updatedSubItem)
            toast.success('Saved')
            return updatedSubItem
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    updateSubItems = async (subItems: IMassCostUpdateFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const updatedSubItem = await agent.SubItem.updates(subItems)
            // this.rootStore.modalStore.closeModal('SubItemModal')
            this.subItemRegistry.set(updatedSubItem.id, updatedSubItem)
            toast.success('Saved')
            return updatedSubItem
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteSubItem = async (subItem: ISubItem) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const deletedSubItem = await agent.SubItem.delete(subItem)
            this.subItemRegistry.delete(deletedSubItem)
            toast.success('Saved')
            return deletedSubItem
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    addToNewSubItemQueue = (subItem: ISubItemQueueItem) => {
        this.newSubItemQueue.push(subItem)
    }

    removeFromNewSubItemQueue = (subItemId: string) => {
        let idx = this.newSubItemQueue.findIndex((subItem: ISubItem) => subItem.id === subItemId)

        this.newSubItemQueue.splice(idx, 1)
    }

    markSubItemAsAttachedById = (subItemId: string) => {
        let idx = this.newSubItemQueue.findIndex((subItem: ISubItem) => subItem.id === subItemId)
        if (idx >= 0) this.newSubItemQueue[idx].hasAttachment = true
    }

    clearNewSubItemQueue = () => {
        this.newSubItemQueue = []
    }

}