import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {inputOnChange} from "../../../lib/utilities";
import Input from "../../../components/Inputs/Input";
import Button from "../../../components/generic/Button";
import {CheckIcon} from "@heroicons/react/24/solid";
import {RootStoreContext} from "../../../stores/RootStore";
import {INationalOutreachBudget} from "../../../models/NationalOutreach/NationalOutreachBudget";

export interface ReimbursementClaimPaymentFormProps {
    NationalOutreachBudget: INationalOutreachBudget
}

type Props = ReimbursementClaimPaymentFormProps

const NationalOutreachBudgetPaymentForm: React.FC<Props> = ({NationalOutreachBudget}) => {
    const rootStore = useContext(RootStoreContext);

    const {payNationalOutreachBudget} = rootStore.nationalOutreachBudgetStore;
    const {closeModal} = rootStore.modalStore;


    const [currentNationalOutreachBudget, setCurrentNationalOutreachBudget] = useState({} as INationalOutreachBudget);

    const handleSubmit = (e) => {
        e.preventDefault()
        payNationalOutreachBudget(NationalOutreachBudget.id ?? '', currentNationalOutreachBudget)
    }

    const handleChange = (e) => {
        inputOnChange(e, setCurrentNationalOutreachBudget)
    }

    return (
        <form onSubmit={handleSubmit} className={'flex flex-col justify-between flex-1 h-full'}>
            <div className={'flex-1'}>
                <Input
                    name='daqId'
                    placeholder='FROXK.TRS1.SPR.ACH.A00000XX'
                    label='DAQ ID'
                    onChange={handleChange}
                    value={currentNationalOutreachBudget.daqId}
                    required
                />
            </div>

            <div className={'flex justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}>Save</Button>
                <span className="mr-2"/>
                <Button onClick={() => closeModal('nationalOutreachBudgetPaymentModal')} color={'red'}>Cancel</Button>
            </div>
        </form>
    );
};
export default observer(NationalOutreachBudgetPaymentForm);