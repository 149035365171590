import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {
    INationalOutreachBudgetAttachmentEnvelope,
    NationalOutreachBudgetAttachmentFormValues
} from "../../models/NationalOutreach/NationalOutreachBudgetAttachment";


const NationalOutreachBudgetAttachments = {
    list: (params: URLSearchParams): Promise<INationalOutreachBudgetAttachmentEnvelope> =>
        axios
            .get("/NationalOutreachBudget/Attachment", {params: params})
            .then(responseBody),
    create: (nationalOutreachAttachment: NationalOutreachBudgetAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in nationalOutreachAttachment) {
            formData.append(key, nationalOutreachAttachment[key])
        }
        return api.post(`/NationalOutreachBudget/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/NationalOutreachBudget/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/NationalOutreachBudget/Attachment/${id}`),
    byNationalOutreachBudgetId: (nationalOutreachId: string, params: URLSearchParams): Promise<INationalOutreachBudgetAttachmentEnvelope> =>
        axios.get(`/NationalOutreachBudget/${nationalOutreachId}/Attachment`, {params}).then(responseBody)
};

export default NationalOutreachBudgetAttachments