import axios from "axios";
import {IOutreach, IOutreachEnvelope, OutreachFormValues} from "../../models/Outreach/Outreach";
import {api, responseBody} from "./apiBase.service";

const Outreachs = {
    list: (
        params: URLSearchParams
    ): Promise<IOutreachEnvelope> =>
        axios.get("/Outreach", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/Outreach/${id}`),
    create: (Outreach: OutreachFormValues) =>
        api.post(`/Outreach/`, Outreach),
    update: (Outreach: IOutreach) =>
        api.put(`/Outreach/${Outreach.id}`, Outreach),
    delete: (id: string) =>
        api.del(`/Outreach/${id}`),
};

export default Outreachs