import React, {FunctionComponent} from 'react';
import ToolTip from "../generic/ToolTip";
import Button, {CustomButtonProps} from "../generic/Button";
import {PlusIcon} from "@heroicons/react/24/solid";

interface OwnProps extends CustomButtonProps {
    onClick: () => void
    tooltip: string
    icon?: React.ReactNode
    content?: string
}

type Props = OwnProps;

const TableNewButton: FunctionComponent<Props> = ({onClick, tooltip, className = '',content = 'New', icon= <PlusIcon className={'h-5 w-5'}/>, ...rest}) => {
    return (
        <ToolTip content={tooltip} direction={'left'}>
            <Button iconLeft={icon} tabIndex={0} onClick={onClick} className={`whitespace-nowrap !bg-blue-800 hover:!bg-blue-700 !border-blue-800 ${className}`} size={'xs'} {...rest}>{content}</Button>
        </ToolTip>
    );
};

export default TableNewButton;
