import AdministrativeAttachments from "./service/administrativeAttachments.service";
import AdministrativeNotes from "./service/administrativeNotes.service";
import Administratives from "./service/administratives.service";
import Allocations from "./service/allocations.service";
import AssessmentAttachments from "./service/assessmentAttachments.service";
import AssessmentNotes from "./service/assessmentNotes.service";
import Assessments from "./service/assessments.service";
import Attachments from "./service/Attachments.service";
import Attestors from "./service/attestors.service";
import AuditReports from "./service/auditReports.service";
import AuditReportAttachments from "./service/auditReportsAttachment.service";
import ConsumerAttachments from "./service/consumerAttachments.service";
import ConsumerMerges from "./service/consumerMerges.service";
import ConsumerNotes from "./service/consumerNotes.service";
import ConsumerTransfers from "./service/consumerTransfer.service";
import Consumers from "./service/consumers.service";
import Contacts from "./service/contacts.service";
import EmbeddedReport from "./service/embeddedReport.service";
import Report from "./service/report.service";
import Entities from "./service/entities.service";
import EntityStateFilingFrequency from "./service/entityStateFilingFrequency.service";
import EntityStates from "./service/entityStates.service";
import EquipmentAttachments from "./service/equipmentAttachments.service";
import EquipmentNotes from "./service/equipmentNotes.service";
import Equipments from "./service/equipments.service";
import GenericAttachments from './service/genericAttachments.service'
import InstallationAttachments from "./service/installationAttachments.service";
import InstallationNotes from "./service/installationNotes.service";
import Installations from "./service/installations.service";
import LineItemAttachments from "./service/lineItemAttachments.service";
import MaintenanceRepair from "./service/maintenanceRepair.service";
import MaintenanceRepairAttachments from "./service/maintenanceRepairAttachments.service";
import MaintenanceRepairNotes from "./service/maintenanceRepairNotes.service";
import MassCost from "./service/massCost.service";
import NationalOutreachBudgetAttachments from "./service/NationalOutreachBudgetAttachment.service";
import NationalOutreachBudgetNotes from "./service/NationalOutreachBudgetNote.service";
import NationalOutreachBudgets from "./service/NationalOutreachBudget.service";
import Notification from "./service/notification.service";
import NotificationType from "./service/notificationType.service";
import OutreachAttachments from "./service/outreachAttachments.service";
import OutreachNotes from "./service/outreachNotes.service";
import Outreachs from "./service/outreachs.service";
import ProgramYear from "./service/programYear.service";
import ReallocationRequests from "./service/reallocationRequest.service";
import ReimbursementClaim from "./service/reimbursementClaim.service";
import ReimbursementClaimAttachments from "./service/reimbursementClaimAttachments.service";
import ReimbursementClaimLineItem from "./service/reimbursementClaimLineItem.service";
import ReimbursementClaimNotes from "./service/reimbursementClaimNotes.service";
import ReimbursementClaimSpreadsheet from "./service/reimbursementClaimSpreadsheet.service";
import SemiAnnualReport from "./service/semiAnnualReport.service";
import SemiAnnualReportAttachments from "./service/semiAnnualReportAttachments.service";
import ShippingAndTaxAttachments from "./service/shippingAndTaxAttachments.service";
import ShippingAndTaxNotes from "./service/shippingAndTaxNotes.service";
import ShippingAndTaxes from "./service/shippingAndTaxes.service";
import States from "./service/states.service";
import SubItem from "./service/subItem.service";
import SubItemAttachments from './service/subItemAttachment.service';
import Technician from "./service/technician.service";
import Training from "./service/training.service";
import TrainingAttachments from "./service/trainingAttachments.service";
import TrainingNotes from "./service/trainingNotes.service";
import Travel from "./service/travel.service";
import TravelAttachments from "./service/travelAttachments.service";
import TravelNotes from "./service/travelNotes.service";


export default {
    AdministrativeAttachments,
    AdministrativeNotes,
    Administratives,
    Allocations,
    AssessmentAttachments,
    AssessmentNotes,
    Assessments,
    Attachments,
    Attestors,
    AuditReports,
    AuditReportAttachments,
    ConsumerAttachments,
    ConsumerMerges,
    ConsumerNotes,
    ConsumerTransfers,
    Consumers,
    Contacts,
    EmbeddedReport,
    Entities,
    EntityStateFilingFrequency,
    EntityStates,
    EquipmentAttachments,
    EquipmentNotes,
    Equipments,
    GenericAttachments,
    InstallationAttachments,
    InstallationNotes,
    Installations,
    LineItemAttachments,
    MaintenanceRepair,
    MaintenanceRepairAttachments,
    MaintenanceRepairNotes,
    MassCost,
    NationalOutreachBudgetAttachments,
    NationalOutreachBudgetNotes,
    NationalOutreachBudgets,
    Notification,
    NotificationType,
    OutreachAttachments,
    OutreachNotes,
    Outreachs,
    ProgramYear,
    ReallocationRequests,
    ReimbursementClaim,
    ReimbursementClaimAttachments,
    ReimbursementClaimLineItem,
    ReimbursementClaimNotes,
    ReimbursementClaimSpreadsheet,
    Report,
    SemiAnnualReport,
    SemiAnnualReportAttachments,
    ShippingAndTaxAttachments,
    ShippingAndTaxNotes,
    ShippingAndTaxes,
    States,
    SubItem,
    SubItemAttachments,
    Technician,
    Training,
    TrainingAttachments,
    TrainingNotes,
    Travel,
    TravelAttachments,
    TravelNotes,
};
