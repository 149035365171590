import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {AdministrativeNoteFormValues, IAdministrativeNote} from '../../models/Administrative/AdministrativeNote'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'

export default class AdministrativeNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.administrativeNoteRegistry.clear()
                this.loadAdministrativeNote()
            }
        )

        reaction(
            () => this.currentAdministrativeId,
            () => {

                this.page = 0
                this.administrativeNoteRegistry.clear()
                this.loadAdministrativeNote()
            }
        )
    }

    administrativeNoteRegistry = new Map()
    administrativeNote: IAdministrativeNote | null = null
    editMode = false


    currentAdministrativeId = '';
    administrativeNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentAdministrativeId = (administrativeId: string) => {
        this.currentAdministrativeId = administrativeId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.administrativeNoteCount / this.limit)
    }

    get administrativeNotesById() {
        return Array.from(this.administrativeNoteRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get administrativeNotesByDate() {
        return Array.from(this.administrativeNoteRegistry.values()).sort(
            (a, b) => dayjs(b.updatedOn).isBefore(a.updatedOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getAdministrativeNote = (id: string) => {
        return this.administrativeNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.administrativeNoteRegistry.clear()
        this.loadAdministrativeNote()
    }

    clearAdministrativeNote = () => {
        this.administrativeNoteRegistry.clear()
    }

    loadAdministrativeNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentAdministrativeId && typeof this.currentAdministrativeId !== undefined) {
                const administrativeNotesEnvelope = await Agent.AdministrativeNotes.byAdministrativeId(this.currentAdministrativeId, this.axiosParams);
                const {modelsDto, modelCount} = administrativeNotesEnvelope;
                runInAction(() => {
                    modelsDto.forEach((administrativeNote: IAdministrativeNote) => {
                        this.administrativeNoteRegistry.set(administrativeNote.id, administrativeNote)
                    })
                    this.administrativeNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getAdministrativeNoteById = async (id: string) => {
        let administrativeNote = this.getAdministrativeNote(id)
        if (administrativeNote) {
            this.administrativeNote = administrativeNote
            return administrativeNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                administrativeNote = await Agent.AdministrativeNotes.byId(id)
                runInAction(() => {
                    this.administrativeNote = administrativeNote
                    this.administrativeNoteRegistry.set(administrativeNote.id, administrativeNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return administrativeNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createAdministrativeNote = async (administrativeNote: IAdministrativeNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newAdministrativeNote = await Agent.AdministrativeNotes.create(administrativeNote)
            await this.loadAdministrativeNote()
            runInAction(() => {
                this.administrativeNoteRegistry.set(newAdministrativeNote.id, newAdministrativeNote)
                this.rootStore.modalStore.closeModal('NoteModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editAdministrativeNote = (administrativeNote: AdministrativeNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.AdministrativeNotes.update(administrativeNote).then((updatedAdministrativeNote) => {
            runInAction(() => {
                this.administrativeNoteRegistry.set(updatedAdministrativeNote.id, updatedAdministrativeNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteAdministrativeNote = async (administrativeNote: IAdministrativeNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.AdministrativeNotes.delete(administrativeNote.id)
            runInAction(() => {
                this.administrativeNoteRegistry.delete(administrativeNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }

    }


}