import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import {inputOnChange} from "../../../../lib/utilities";
import Button from "../../../../components/generic/Button";
import {IReimbursementClaim, ReimbursementClaimFormValues} from "../../../../models/Reimbursement/ReimbursementClaim";
import EntityStateAutoCompleteInput from "../../../../components/form/EntityStatesAutoCompleteInput";
import ProgramYearAutoCompleteInput from "../../../../components/form/ProgramYearAutoCompleteInput";
import RadioGroup from "../../../../components/generic/RadioGroup";
import {useHistory} from "react-router-dom";
import Select from "../../../../components/generic/Select";
import {dropDownOptions} from "./stateFilingFrequencyDropDownOptions";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface ReimbursementClaimFormProps {
    reimbursementClaim?: IReimbursementClaim;
}

type Props = ReimbursementClaimFormProps

const ReimbursementClaimForm: React.FC<Props> = ({reimbursementClaim}) => {
    // Mobx
    const rootStore = useContext(RootStoreContext);
    const {entityStatesByName} = rootStore.entityStateStore
    const {currentEntityState} = rootStore.commonStore
    const {createReimbursementClaim, editReimbursementClaim} = rootStore.reimbursementClaimStore;
    const {
        setPredicates,
        clearEntityStateFilingFrequency,
        entityStateFilingFrequenciesList
    } = rootStore.entityStateFilingFrequencyStore;
    const {closeModal, openModal} = rootStore.modalStore;

    // Local State
    const [currentReimbursementClaim, setCurrentReimbursementClaim] = useState(reimbursementClaim || {} as IReimbursementClaim);
    const [afterSave, setAfterSave] = useState('ReimbursementClaimLineItemModal');
    const [currentEntityStateId, setCurrentEntityStateId] = useState('');

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);

    // History
    let history = useHistory();

    // Use Effects
    useEffect(() => {
        if (!!currentReimbursementClaim?.entityStateId && currentReimbursementClaim?.entityStateId !== currentEntityStateId && currentReimbursementClaim.programYearId) {
            setPredicates({
                'entityStateId': currentReimbursementClaim?.entityStateId,
                'programYearId': currentReimbursementClaim?.programYearId
            })
            setCurrentEntityStateId(currentReimbursementClaim?.entityStateId ?? '')
        }

        if (!currentReimbursementClaim?.entityStateId || !currentReimbursementClaim?.programYearId) {
            clearEntityStateFilingFrequency()

            if (!!currentEntityStateId)
                setCurrentEntityStateId('')
        }
    }, [currentReimbursementClaim]);

    useEffect(() => {
        if (!currentReimbursementClaim.id && (!!entityStatesByName && entityStatesByName.length === 1)) {
            setCurrentReimbursementClaim((prev: ReimbursementClaimFormValues) => {
                return {
                    ...prev,
                    entityStateId: currentEntityState.id || '',
                    entityState: currentEntityState.stateName
                }
            })
        }
    }, [currentEntityState])

    useEffect(() => {
        if (!currentReimbursementClaim.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentReimbursementClaim((prev: ReimbursementClaimFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates])

    useEffect(() => {
        return clearEntityStateFilingFrequency
    }, []);


    // Functions
    const handleSubmit = (e) => {
        e.preventDefault()
        let tmpReimbursementClaim = {...currentReimbursementClaim}
        if (!reimbursementClaim) {
            createReimbursementClaim(tmpReimbursementClaim).then((reimbursementClaim) => {
                    if (!!reimbursementClaim) {
                        history.push(`/reimbursement/${reimbursementClaim.id}`)
                        return !!afterSave ? openModal(afterSave, {
                            header: `${afterSave === 'ReimbursementClaimLineItemModal' ? 'Add' : 'Upload'} Reimbursement Claim Line Items`,
                            reimbursementClaim
                        }) : null
                    }
                }
            )
        } else {
            editReimbursementClaim(tmpReimbursementClaim);
        }
    };


    const handleInput = (e) => {
        inputOnChange(e, setCurrentReimbursementClaim)
    }

    const handleAutocomplete = (e) => {
        inputOnChange({currentTarget: e}, setCurrentReimbursementClaim)
    }

    const handleRadioChange = (e) => {
        setAfterSave(e)
    }


    return (
        <form onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full justify-between'}
              autoComplete={'off'}>
            <div>
                {!reimbursementClaim && <>

                    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                        <EntityStateAutoCompleteInput
                            required
                            isLocked={!currentReimbursementClaim.id && !!localEntityStates && localEntityStates.length === 1}
                            name={'entityStateId'}
                            placeholder={'California'}
                            displayValue={(currentReimbursementClaim?.entityStateId) ? (currentReimbursementClaim?.entityState || 'State Program already selected') : ''}
                            onChange={({id}) => handleAutocomplete({value: id, name: 'entityStateId'})}
                        />

                        <ProgramYearAutoCompleteInput
                            required
                            name={'programYearId'}
                            onChange={({id}) => handleAutocomplete({value: id, name: 'programYearId'})}
                        />
                    </div>

                    <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                        <Select
                            label={`Period for Filing Frequency${!!entityStateFilingFrequenciesList.length ? ' (' + entityStateFilingFrequenciesList[0].filingFrequency + ')' : ''}`}
                            name={'period'}
                            onChange={handleInput}
                            options={dropDownOptions[!!entityStateFilingFrequenciesList.length ? entityStateFilingFrequenciesList[0].filingFrequency : 'Monthly']}
                            disabled={!entityStateFilingFrequenciesList.length}
                            value={currentReimbursementClaim.period}
                        />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <fieldset aria-labelledby={'field'}>
                            <label id={'field'}>Claim Creation Options <span
                                className={'text-xs text-red-600'}>(required)</span></label>
                            <RadioGroup name={'creationOptions'} required
                                        options={[
                                            {
                                                label: 'add individual line items',
                                                value: 'ReimbursementClaimLineItemModal'
                                            },
                                            {
                                                label: 'upload reimbursement claim spreadsheet',
                                                value: 'ReimbursementSpreadSheetModal'
                                            },
                                            {
                                                label: 'add line items later',
                                                value: null
                                            },
                                        ]}
                                        displayValue={afterSave}
                                        aria-labelledby={'field'}
                                        onChange={handleRadioChange}
                            />
                        </fieldset>
                    </div>
                </>}
            </div>

            <div className={'flex gap-4 justify-end'}>
                    <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>}
                            color={'green'}
                            type='submit'>Save</Button>
                    <Button onClick={() => closeModal('ReimbursementClaimModal')}
                            color="red"
                            iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>
                        Cancel
                    </Button>
            </div>


        </form>
    );
};
export default observer(ReimbursementClaimForm);