import React, {FunctionComponent, useEffect} from 'react';

import {IOutreach} from "../../../models/Outreach/Outreach";
import {arraySum, displayDate, displayDollarAmount} from "../../../lib/utilities";
import {observer} from "mobx-react-lite";
import {useAPIPager} from "../../../hooks/useAPIPager";
import Agent from "../../../api/Agent";
import {useSelectTable} from "../../../hooks/useSelectTable";
import Table from '../../table/Table';
import THead from '../../table/THead';
import TR from '../../table/TR';
import TBody from '../../table/TBody';
import TD from "../../table/TD";
import Pagination from "../../table/Pagination";
import Skeleton from "../../generic/Skeleton";
import Checkbox from "../../Inputs/Checkbox";

interface OwnProps {
    onChange?: (e: string[]) => (void)
    selected?: string[]
    predicates?: Map<any, any>
}

type Props = OwnProps;

const OutreachSelectTable: FunctionComponent<Props> = ({onChange, selected, predicates}) => {
    // Pager
    const {items, count, handleChangePage, isLoading} = useAPIPager(Agent.Outreachs, predicates)
    const {handleCheck, handleCheckAll, selectedItems} = useSelectTable(selected);

    // Use Effects
    useEffect(() => {
        !!onChange && onChange(selectedItems)
    }, [selectedItems]);


    return (<>
        <span className="sr-only" id="outreachSelectTable">Outreach</span>
        <Table className={'max-w-full'} aria-describedby={'outreachSelectTable'}>
            <THead>
                <TR>
                    <TD className={'th min-w-max whitespace-nowrap'}>
                        {items.length > 0 && <>
                            <span id="selectAllOutreach" className="sr-only">Select all</span>
                            <Checkbox label={<span className={'sr-only'}>Select</span>}
                                checked={items.every(i => selectedItems?.includes(i.id))}
                                className="border-gray-300 rounded"
                                onChange={(e) => handleCheckAll(e, items)}/></>}
                    </TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Description</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Technician</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Date Performed</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Cost</TD>
                </TR>
            </THead>
            <TBody>
                {items?.map((outreach: IOutreach, idx) => {
                    return (<TR key={idx}>
                        {isLoading ? <TD colSpan={100}><Skeleton/></TD> : <>
                            <TD className={'td min-w-max whitespace-nowrap'}>
                                <Checkbox checked={selectedItems.includes(outreach.id ?? '')}
                                       onChange={handleCheck} value={outreach.id}
                                       label={<span className={'sr-only'}>Select</span>}
                                />
                            </TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{outreach?.description}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{outreach?.technicianName}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{displayDate(outreach?.datePerformed)}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{displayDollarAmount(arraySum([
                                outreach.outreachParticipationCosts,
                                outreach.auxiliaryAidCosts,
                                outreach.otherCosts,
                                outreach.websiteCosts,
                                outreach.programInformationCosts,
                            ]))}</TD>
                        </>}
                    </TR>)
                })}
            </TBody>
        </Table>
        <Pagination totalRows={count} onPageChange={handleChangePage} onRowChange={handleChangePage}/>
    </>);
};

export default observer(OutreachSelectTable);
