import React, {useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import {INationalOutreachBudget} from "../../../models/NationalOutreach/NationalOutreachBudget";
import {RootStoreContext} from "../../../stores/RootStore";
import {inputOnChange} from "../../../lib/utilities";
import Input from "../../../components/Inputs/Input";
import Button from "../../../components/generic/Button";
import ProgramYearAutoCompleteInput from "../../../components/form/ProgramYearAutoCompleteInput";
import numberInputOptions from "../../../data/cleave/numberInputOptions";
import Select from "../../../components/generic/Select";
import EntityAutoCompleteInput from "../../../components/form/EntityAutoCompleteInput";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface NationalOutreachBudgetFormProps {
    nationalOutreach: INationalOutreachBudget
}

const ContactForm: React.FC<NationalOutreachBudgetFormProps> = ({nationalOutreach}) => {
    const quarterOpts = [
        {
            text: 'Q1',
            value: 1,
        }, {
            text: 'Q2',
            value: 2,
        }, {
            text: 'Q3',
            value: 3,
        }, {
            text: 'Q4',
            value: 4,
        },
    ]
    // Stores
    const rootStore = useContext(RootStoreContext);
    const {
        editNationalOutreachBudget,
        createNationalOutreachBudget,
    } = rootStore.nationalOutreachBudgetStore;
    const {closeModal} = rootStore.modalStore;

    // Local State
    const [currentNOB, setCurrentNOB] = useState(nationalOutreach ?? {} as INationalOutreachBudget);

    // Functions
    const handleInput = (e) => {
        inputOnChange(e, setCurrentNOB);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!nationalOutreach || !Object.keys(nationalOutreach).length) {
            createNationalOutreachBudget(currentNOB);
        } else {
            editNationalOutreachBudget(currentNOB);
        }
    };

    const handleAutoComplete = (e) =>
        handleInput({currentTarget: {...e}})

    return (
        <form onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full overflow-x-hidden'}
              autoComplete={'off'}>
            <div className={'flex-1'}>
                {!nationalOutreach && <>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3 mb-3">
                            <ProgramYearAutoCompleteInput displayValue={currentNOB.programYear}
                                                          onChange={({id}) => handleAutoComplete({
                                                              name: 'programYearId',
                                                              value: id
                                                          })}
                                                          name={'programYearId'}
                                                          required
                            />
                            <EntityAutoCompleteInput displayValue={currentNOB.entity}
                                                     onChange={({id}) => handleAutoComplete({
                                                         name: 'entityId',
                                                         value: id
                                                     })}
                                                     name={'entityStateId'}
                                                     required
                            />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-3">
                        <div className='w-full'>
                            <Select
                                placeholder={'Please select one...'} options={quarterOpts} required
                                label={'Quarter'} name={'quarter'} value={currentNOB.quarter} onChange={handleInput}
                            />
                        </div>
                    </div>
                </>}


                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3">
                    <div className='w-full'>
                        <Input label={'Staff - Perkins Salaries & Benefits'} name={'staffSalary'}
                               value={currentNOB.staffSalary}
                               onChange={handleInput}
                               placeholder={'$88'}
                               options={numberInputOptions}/>
                    </div>
                    <div className='w-full'>
                        <Input label={'Staff - Consultants'} name={'staffConsultants'}
                               value={currentNOB.staffConsultants} onChange={handleInput}
                               placeholder={'$88'}
                               options={numberInputOptions}/>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3">
                    <div className='w-full'>
                        <Input label={'Material Production'} name={'materialProduction'}
                               value={currentNOB.materialProduction} onChange={handleInput}
                               placeholder={'$88'}
                               options={numberInputOptions}/>
                    </div>
                    <div className='w-full'>
                        <Input label={'Purchases of Media'} name={'mediaPurchases'} value={currentNOB.mediaPurchases}
                               onChange={handleInput}
                               placeholder={'$88'}
                               options={numberInputOptions}/>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3">
                    <div className='w-full'>
                        <Input label={'Office Expenses'} name={'officeExpenses'} value={currentNOB.officeExpenses}
                               onChange={handleInput}
                               placeholder={'$88'}
                               options={numberInputOptions}/>
                    </div>
                    <div className='w-full'>
                        <Input label={'Other'} name={'other'} value={currentNOB.other}
                               onChange={handleInput}
                               placeholder={'$88'}
                               options={numberInputOptions}/>
                    </div>
                </div>
            </div>

            <div className={'flex justify-end gap-4'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'}
                        type={'submit'}>Save</Button>
                <Button iconLeft={<XMarkIcon className={'h-5 w-5'}/>}
                        onClick={() => {
                            closeModal('NationalOutreachBudgetModal')
                        }}
                        color="red">Cancel</Button>
            </div>
        </form>
    )
}
export default observer(ContactForm);