import {useEffect, useRef} from "react";
import {useLocalStorage} from "./useLocalStorage";
import dayjs from "dayjs";
import Agent from "../api/Agent";
import {useLocation} from "react-router-dom";


const useRefreshEntityState = () => {
    const [entityStateExpire, setEntityExpire] = useLocalStorage('entityExpire', new Date())
    const [lEntityState, setLEntityState] = useLocalStorage('currentEntityState', null)
    const [lEntityStates, setLEntityStates] = useLocalStorage('entityStates', null)

    const isDirty = useRef(false);
    const isLoading = useRef(false);
    const {pathname} = useLocation()

    useEffect(() => {
        refresh()
    }, []);



    // Functions
    const loadEntityStates = async () => {
        if (isLoading.current) {
            return false
        }
        try {
            isLoading.current = true
            const params = new URLSearchParams()
            params.append('limit', '10000')
            params.append('offset', `0`)
            const {modelsDto} = await Agent.EntityStates.list(params)
            return modelsDto
        } catch (e) {
            console.error(e)
            return null
        }finally {
            isLoading.current = false
        }

    }

    const refresh = async () => {
        if (!pathname.match(/^\/(login|notification\/opt.*?\/).*/gi)) {
            if (dayjs().isAfter(dayjs(entityStateExpire)) || !lEntityStates || !lEntityState || lEntityStates.length === 0 || !isDirty.current) {
                let es = await loadEntityStates()
                if (!es || es.length < 1) {
                    return -1
                }

                setEntityExpire(dayjs().add(1, 'day'))
                try {
                    if (!!es) {
                        isDirty.current = true
                        setLEntityStates([...es])

                        if (!lEntityStates || !lEntityStates.findIndex) return -1

                        let idx = lEntityStates.findIndex((entityState) => entityState?.id === lEntityState?.id)

                        if (!lEntityState || idx < 0) {
                            setLEntityState(es[0])
                        }
                    }
                } catch (e) {
                    console.error(e)
                    console.info('Error refreshing')
                }
            }
        }
    }

    setInterval(refresh, 60 * 1000)

    refresh()

    return refresh
}

export default useRefreshEntityState