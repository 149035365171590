import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {AssessmentFormValues, IAssessment} from '../../models/Assessment/Assessment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class AssessmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.assessmentRegistry.clear()
                this.loadAssessment()
            }
        )
    }

    assessmentRegistry = new Map()
    assessment: IAssessment | null = null
    editMode = false

    assessmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.assessmentRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.assessmentCount / this.limit)
    }

    get assessmentsList() {
        // @ts-ignore
        return Array.from(this.assessmentRegistry.values())//.sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get assessmentsByDate() {
        return Array.from(this.assessmentRegistry.values())
    }

    getAssessment = (id: string) => {
        return this.assessmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.assessmentRegistry.clear()
        this.loadAssessment()
    }

    clearAssessment = () => {
        this.assessmentRegistry.clear()
    }

    loadAssessment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const assessmentsEnvelope = await Agent.Assessments.list(this.axiosParams)
            const {modelsDto, modelCount} = assessmentsEnvelope

            runInAction(() => {
                this.clearAssessment()
                modelsDto.forEach((assessment: IAssessment) => {
                    this.assessmentRegistry.set(assessment.id, assessment)
                })
                this.assessmentCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    loadNakedAssessment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)
            const assessmentsEnvelope = await Agent.Assessments.list(params)
            const {modelsDto} = assessmentsEnvelope

            return modelsDto
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getAssessmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let assessment = await Agent.Assessments.byId(id)
            runInAction(() => {
                this.assessment = assessment
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return assessment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getAssessmentsByIds = async (ids: string[]) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            if (!ids) {
                return []
            }

            const params = new URLSearchParams()
            params.append('limit', '1000000')
            params.append('offset', `0`)

            for (let id of ids) {
                params.append('ids', id)
            }

            let {modelsDto} = await Agent.Assessments.list(params)

            return modelsDto
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createAssessment = async (assessment: IAssessment) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newAssessment = await Agent.Assessments.create(assessment)
            runInAction(() => {
                this.assessmentRegistry.set(newAssessment.id, newAssessment)
                this.rootStore.modalStore.closeModal('AssessmentModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newAssessment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editAssessment = async (assessment: AssessmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedAssessment = await Agent.Assessments.update(assessment)
            runInAction(() => {
                this.assessmentRegistry.set(updatedAssessment.id, updatedAssessment)
                this.rootStore.modalStore.closeModal('AssessmentModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return updatedAssessment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }


    deleteAssessment = async (assessment: IAssessment) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            if (!assessment.id) throw new Error('Assessment Id is required to delete record')
            await Agent.Assessments.delete(assessment.id)
            runInAction(() => {
                this.assessmentRegistry.delete(assessment.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.loadAssessment()
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


}
