import React, {FunctionComponent, useEffect} from 'react';

import {ITraining} from "../../../models/Training/Training";
import {arraySum, displayDate, displayDollarAmount} from "../../../lib/utilities";
import {observer} from "mobx-react-lite";
import {useAPIPager} from '../../../hooks/useAPIPager';
import Agent from "../../../api/Agent";
import {useSelectTable} from "../../../hooks/useSelectTable";
import Table from "../../table/Table";
import THead from "../../table/THead";
import TR from "../../table/TR";
import TBody from "../../table/TBody";
import TD from "../../table/TD";
import Pagination from "../../table/Pagination";
import Skeleton from "../../generic/Skeleton";
import Checkbox from "../../Inputs/Checkbox";

interface OwnProps {
    onChange?: (e: string[]) => (void)
    selected?: string[]
    predicates?: Map<any, any>
}

type Props = OwnProps;

const TrainingSelectTable: FunctionComponent<Props> = ({onChange, selected, predicates}) => {
    // Pager
    const {items, count, handleChangePage, isLoading} = useAPIPager(Agent.Training, predicates)
    const {handleCheck, handleCheckAll, selectedItems} = useSelectTable(selected);

    // Use Effects
    useEffect(() => {
        !!onChange && onChange(selectedItems)
    }, [selectedItems]);


    return (<>
        <span className="sr-only" id="trainingSelectTable">Training</span>
        <Table className={'max-w-full'} aria-describedby={'trainingSelectTable'}>
            <THead>
                <TR>
                    <TD className={'th min-w-max whitespace-nowrap'}>
                        {items.length > 0 && <>
                            <span id="selectAllTraining" className="sr-only">Select all</span>
                            <Checkbox label={<span className={'sr-only'}>Select</span>}
                                      checked={items.every(i => selectedItems?.includes(i.id))}
                                      className="border-gray-300 rounded"
                                      onChange={(e) => handleCheckAll(e, items)}/></>}
                    </TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>For?</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Technician</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Date Performed</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Cost</TD>
                </TR>
            </THead>
            <TBody>
                {items?.map((training: ITraining, idx) => {
                    return (<TR key={idx}>
                        {isLoading ? <TD colSpan={100}><Skeleton/></TD> : <>
                            <TD className={'td min-w-max whitespace-nowrap'}>
                                <Checkbox checked={selectedItems.includes(training.id ?? '')}
                                       onChange={handleCheck} value={training.id}
                                       label={<span className={'sr-only'}>Select</span>}
                                />
                            </TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{training?.isTrainTheTrainer ? 'Train-the-Trainer' : training.consumerName}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{training?.technicianName}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{displayDate(training?.datePerformed)}</TD>
                            <TD className={'td min-w-max whitespace-nowrap'}>{displayDollarAmount(arraySum([
                                training.otherExpenses,
                                training.trainingLaborCosts,
                                training.instructionalMaterialCosts,
                                training.trainTrainerCosts,
                                training.auxiliaryAidCosts,
                            ]))}</TD>
                        </>}
                    </TR>)
                })}
            </TBody>
        </Table>
        <Pagination totalRows={count} onPageChange={handleChangePage} onRowChange={handleChangePage}/>
    </>);
};

export default observer(TrainingSelectTable);
