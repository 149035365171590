import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import {INotification, NotificationFormValues} from "../../../../models/Notification/Notification";
import EntityAutoCompleteInput from "../../../../components/form/EntityAutoCompleteInput";
import Input from "../../../../components/Inputs/Input";
import Button from "../../../../components/generic/Button";
import NotificationTypeAutoComplete from "../../../../components/NotificationTypeAutoComplete";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";

export interface NotificationFormProps {
    Notification: INotification | undefined;
    autoFillUser: boolean
}

type Props = NotificationFormProps

const NotificationForm: React.FC<Props> = ({Notification, autoFillUser = true}) => {
    // Stores
    const rootStore = useContext(RootStoreContext);
    const {createNotification} = rootStore.notificationStore;
    const {closeModal} = rootStore.modalStore;
    const {isLoading} = rootStore.commonStore
    const {user} = rootStore.oidcStore;


    // Local State
    const [currentNotification, setCurrentNotification] = useState(new NotificationFormValues(Notification));

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);

    // Use Effects
    useEffect(() => {
        if (!currentNotification.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentNotification((prev: NotificationFormValues) => {
                return {
                    ...prev,
                    entityId: localEntityStates[0].entityId || '',
                    entityName: localEntityStates[0].entityId ? `${localEntityStates[0].entityName}` : ''
                }
            })
        }
    }, [localEntityStates])

    // Functions
    const handleSubmit = async (e) => {
        e.preventDefault()
        const {...Notification} = currentNotification
        let newNotification = {
            ...Notification
        } as INotification;

        if (autoFillUser)
            newNotification.emailTo = user?.profile.email as string

        // @ts-ignore
        delete newNotification.id
        await createNotification(newNotification);
    };

    const handleChange = (e) => {
        const {name, value} = e.currentTarget
        let tmpNotification = {...currentNotification}
        tmpNotification[name] = value
        setCurrentNotification(tmpNotification)
    }

    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    return (
        <form onSubmit={handleSubmit} className={'flex flex-1 flex-col h-full'} autoComplete={'off'}>
            <div className={'overflow-y-auto overflow-x-hidden flex-1'}>
                <div className={"grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1 mb-3"}>
                    <NotificationTypeAutoComplete onChange={({id}) => handleAutoComplete(id, 'notificationTypeId')}
                                                  name={'notificationType'}/>
                </div>

                <div className={"grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1 mb-3"}>
                    <EntityAutoCompleteInput
                        isLocked={!currentNotification.id && !!localEntityStates && localEntityStates.length === 1}
                        displayValue={currentNotification?.entityId ? (currentNotification?.entityName || 'Entity already selected') : ''}
                        placeholder={'The Institute for Time Travel'}
                        onChange={({id}) => handleAutoComplete(id, 'entityId')}
                        name={'entity'}
                    />
                </div>

                <div className={"grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1 mb-3"}>
                    <Input className={`bg-light ${autoFillUser ? 'disabled' : ''}`} name={'emailTo'} label={'Email'}
                           placeholder={"johnnyBlaze@acc.com"} onChange={handleChange}
                           value={autoFillUser ? user?.profile.email : currentNotification.emailTo}
                           disabled={autoFillUser}/>
                </div>
            </div>


            <div className="flex gap-4 justify-end">
                <Button disabled={isLoading || isLoading} type='submit' iconLeft={<CheckIcon className={'h-5 w-5'}/>}
                        color={'green'}>Save</Button>
                <Button name={'cancel'}
                        onClick={() => closeModal('NotificationModal')}
                        disabled={isLoading || isLoading}
                        color="red" iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>
                    Cancel
                </Button>
            </div>
        </form>
    );
};
export default observer(NotificationForm);