import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import NoteForm, {NoteFormProps} from "../../note/NoteForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from '../../generic/SlideOver';


interface OwnProps extends SlideOverProps, NoteFormProps {
}

type Props = OwnProps;

// @ts-ignore
const NoteModal = NiceModal.create<Props>(({note, createNote, editNote, onClose, header}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <NoteForm note={note} createNote={createNote} editNote={editNote}/>
        </SlideOver>
    )
})

export default NoteModal;
