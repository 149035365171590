import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {RootStore} from './RootStore'
import agent from "../api/Agent"
import {toast} from "react-toastify"
import {AttestorFormValues, IAttestor} from '../models/Attestor'

export default class AttestorStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.attestorRegistry.clear()
                this.loadAttestors()
            }
        )
    }

    attestorRegistry = new Map()
    attestor: IAttestor | null = null
    loadingInitial = false
    submitting = false
    target = ""
    attestorCount = 0
    page = 0
    limit = 1_000_000_000
    predicate = new Map()

    setPredicate = (predicate: string, value: string | Date = '') => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get attestorsById() {
        return Array.from(this.attestorRegistry.values()).sort(
            // @ts-ignore
            (b: IAttestor, a: IAttestor) => (a.id > b.id ? -1 : 1)
        )
    }

    loadAttestors = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const attestorEnvelope = await agent.Attestors.list(this.axiosParams)
            let {modelsDto, modelCount} = attestorEnvelope

            runInAction(() => {
                modelsDto.forEach((attestor) => {
                    // appeal.submittedOn=new Date(appeal.submittedOn)
                    this.attestorRegistry.set(attestor.id, attestor)
                })
                this.attestorCount = modelCount
            })

        } catch (error) {
            throw error
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    clearAttestors = async () => {
        this.attestorRegistry.clear()
    }
    setPage = (page: number) => {
        this.page = page
    }
    setLimit = (limit: number) => {
        this.limit = limit
    }
    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.attestorRegistry.clear()
        this.loadAttestors()
    }

    createAttestor = async (attestor: AttestorFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.Attestors.create(attestor)
            await this.loadAttestors()
            toast.success('Saved')
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('AttestorModal')
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    editAttestor = async (attestor: AttestorFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.Attestors.update(attestor)
            await this.loadAttestors()
            this.rootStore.modalStore.closeModal('AttestorModal')
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    get attestorOptions() {
        var Attestors: {
            key: string
            value: string
        }[] = []

        this.attestorsById.map((attestor: IAttestor) => {
            Attestors.push({
                key: `${attestor.id}`,
                value: `${attestor.firstName || ''} ${attestor.middleName || ''} ${attestor.lastName || ''}\n${attestor.email || ''} ${attestor.phone || ''}\n\n${attestor.address1 || ''} ${attestor.address2 || ''}\n${attestor.city || ''} ${attestor.state || ''}, ${attestor.zipCode || ''}`
            })
        })

        return Attestors
    }

    getAttestorById = async (attestorId) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            return await agent.Attestors.byId(attestorId)
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }

    }
}
