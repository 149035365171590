const allStatuses = [
    // {
        // text: "Waitlist",
        // value: "Waitlist",
        // when: []
    // },
    {
        text: "Eligible",
        value: "Eligible",
        when: []
    }, {
        text: "Ineligible",
        value: "Ineligible",
        when: ['edit']
    }, {
        text: "Deceased",
        value: "Deceased",
        when: []
    }, {
        text: "Pending",
        value: "Pending",
        when: []
    }
]

const editingStatuses = allStatuses.filter((status) => !status.when.length || status.when.includes('edit'))

const createStatuses = allStatuses.filter((status) => !status.when.length)



export {
    allStatuses,
    createStatuses,
    editingStatuses,
}