import React, {Fragment, useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite'
import {Redirect, Route, RouteComponentProps, Switch, useLocation, withRouter} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import LoadingComponent from './LoadingComponent';
import {RootStoreContext} from '../stores/RootStore';
import SideBar from '../components/sidebar/SideBar';
import Routes from '../components/Routes'

import NiceModal from '@ebay/nice-modal-react'
import LoginPage from "../pages/home/Login";
import TitleBar from "../components/TitleBar";
import NotificationOpt from "../pages/NotificationOpt";
import BreadCrumb from "../components/BreadCrumb";
import useRefreshEntityState from "../hooks/useRefreshEntityState";
import SkipNav from "../components/generic/SkipNav";
import {Dialog, Transition} from '@headlessui/react';
import {Bars3BottomLeftIcon, XMarkIcon} from "@heroicons/react/24/solid";

import PrivacyPolicy from '../pages/public/PrivacyPolicy'
import TermsOfService from "../pages/public/TermsOfService"

const App: React.FC<RouteComponentProps> = () => {
    const rootStore = useContext(RootStoreContext);
    const {isLoading, isLoadingText, titleIcon, title} = rootStore.commonStore;
    const {getUser, user} = rootStore.oidcStore;
    const {openModal} = rootStore.modalStore

    useRefreshEntityState()
    const {pathname, search} = useLocation()

    const [userLoading, setUserLoading] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false)


    // const script = useRef(<script/>);

    useEffect(() => {
        setUserLoading(() => {
            getUser().finally(
                async () => {
                    // await refreshEntityStates()
                    setUserLoading(false)
                }
            );
            return true
        })

    }, [getUser])


    useEffect(() => {
        document.addEventListener('keyup', getToken);
        return () => {
            document.removeEventListener('keyup', getToken)
        }
    }, [user]);


    useEffect(() => {
        try {
            let params = new URLSearchParams(search)
            let modal = params.get('modal')
            let props = params.get('mp')

            if (props) props = JSON.parse(props)

            if (modal)
                openModal(modal, props ?? {})
        } catch (e) {
            console.log(e)
        }
    }, []);

    const getToken = async (event) => {
        if (event.ctrlKey && event.key === '/') {
            try {
                let token = (!!user?.access_token ? `Bearer ${user?.access_token}` : 'No Token available')
                await window.navigator.clipboard.writeText(token)
                toast.info('Token Copied')
            } catch {
                toast.error('Token could not be captured')
            }
        }
    }

    return (
        <>
            <SkipNav href={'#main'}>Skip to main content</SkipNav>
            {/* create public and private routes */}
            <Route exact path={'/'}><Redirect to={'/home'}/></Route>
            <Route exact sensitive={false} path='/login' component={LoginPage}/>
            <Route exact path='/notification/OptOut/:id'><NotificationOpt/></Route>
            <Route exact path='/notification/OptIn/:id'><NotificationOpt/></Route>
            {/*COMPLIANCE*/}
            <Route path='/privacy-policy' component={PrivacyPolicy}/>
            <Route exact path='/pp' render={() =>
                <Redirect to={`/privacy-policy`}/>
            }/>
            <Route path='/terms-of-service' component={TermsOfService}/>
            <Route exact path='/tos' render={() =>
                <Redirect to={`/terms-of-service`}/>
            }/>


            {!pathname.match(/^\/(terms-of-service|privacy-policy|login|notification\/opt.*?\/).*/gi) &&
                <Route path={'/(.+)'} render={() => (<>
                        <NiceModal.Provider>
                            <Transition.Root show={sidebarOpen} as={Fragment}>
                                <Dialog as="div" className="relative z-40 xl:hidden print:hidden"
                                        onClose={setSidebarOpen}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transition-opacity ease-linear duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity ease-linear duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 "/>
                                    </Transition.Child>

                                    <div className="fixed inset-0 flex z-40">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transition ease-in-out duration-300 transform"
                                            enterFrom="-translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transition ease-in-out duration-300 transform"
                                            leaveFrom="translate-x-0"
                                            leaveTo="-translate-x-full"
                                        >
                                            <Dialog.Panel
                                                className="relative flex-1 flex flex-col max-w-xs w-full">
                                                <SideBar/>
                                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                                    <button
                                                        type="button"
                                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                        onClick={() => setSidebarOpen(false)}
                                                    >
                                                        <span className="sr-only">Close sidebar</span>
                                                        <XMarkIcon className="h-10 w-10 text-white bg-black/75 rounded-full p-2 shadow" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition.Root>

                            {/* Static sidebar for desktop */}
                            <div className="hidden xl:flex xl:w-80 xl:flex-col xl:fixed xl:inset-y-0 print:hidden">
                                <SideBar/>
                            </div>

                            {/* Padding is added at md to make room for the sidebar */}
                            <div className="xl:pl-80 flex flex-col h-full">
                                <div className="sticky top-0 z-10 flex-shrink-0 flex shadow-md bg-white print:hidden">
                                    <button
                                        type="button"
                                        className="sm:px-4 px-2 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 xl:hidden"
                                        onClick={() => setSidebarOpen(true)}
                                    >
                                        <span className="sr-only">Open sidebar</span>
                                        <Bars3BottomLeftIcon
                                            className="md:h-10 md:w-10 sm:h-8 sm:w-8 h-6 w-6 text-black"
                                            aria-hidden="true"/>
                                    </button>
                                    <div className={'w-full shadow-md'}>
                                        <TitleBar title={title} icon={titleIcon} className={'border-b'}/>
                                        <BreadCrumb className={'sm:block hidden'}/>
                                    </div>
                                </div>

                                <main className="flex-1 print:max-w-[8in]" id={'main'}>
                                    <div className="py-6 print:p-0 h-full">
                                        <div className="px-4 sm:px-6 xl:px-8 print:p-0 h-full">
                                            <Switch>
                                                <Routes/>
                                            </Switch>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </NiceModal.Provider>
                        {/* load ToastR container */}
                        <ToastContainer position='top-right'/>
                    </>
                )}/>
            }
            {/*<TOSAndPPBanner/>*/}
            <LoadingComponent active={isLoading || userLoading} content={isLoadingText}/>
        </>
    );
};
export default withRouter(observer(App));