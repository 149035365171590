import {
    ArchiveBoxIcon,
    BanknotesIcon,
    BellIcon,
    BookOpenIcon,
    BuildingOfficeIcon,
    CalendarIcon,
    ChartBarIcon,
    ClipboardIcon,
    Cog8ToothIcon,
    CurrencyDollarIcon,
    DocumentMagnifyingGlassIcon,
    FunnelIcon,
    GiftIcon,
    LifebuoyIcon,
    MagnifyingGlassIcon,
    MapIcon,
    PhoneIcon,
    PuzzlePieceIcon,
    ScaleIcon,
    TruckIcon
} from "@heroicons/react/24/solid";
import {GlobeAltIcon} from "@heroicons/react/24/outline";

const navigationItems = ({hasRole, isTenantOf}) => {
    return [
        {
            icon: Cog8ToothIcon,
            content: 'Administration',
            link: '/administration',
            visible: hasRole('admin'),
            children: [
                {
                    icon: FunnelIcon,
                    link: '/administration/allocations',
                    content: 'Allocation',
                    visible: hasRole('admin') && (isTenantOf('Federal Communications Commission') || isTenantOf('Rolka Loube')),
                }, {
                    icon: CalendarIcon,
                    link: '/administration/program-year',
                    content: 'Program Year',
                    visible: hasRole('admin') && (isTenantOf('Federal Communications Commission') || isTenantOf('Rolka Loube')),
                }, {
                    icon: BuildingOfficeIcon,
                    link: '/administration/entity-management',
                    content: 'Entities',
                    visible: hasRole('admin') && (isTenantOf('Federal Communications Commission') || isTenantOf('Rolka Loube')),
                }, {
                    icon: BellIcon,
                    link: '/administration/notification',
                    content: 'Notifications',
                    visible: hasRole('admin'),
                }, {
                    icon: PhoneIcon,
                    link: '/administration/contacts',
                    content: 'Contacts',
                    visible: hasRole('admin'),
                },
            ]
        }, {
        content: 'Program',
        visible: true,
        icon: ClipboardIcon,
        link: '/program',
        children: [
            {
                icon: Cog8ToothIcon,
                link: '/program/administrative',
                content: 'Administrative',
                visible: true,
            }, {
                icon: GiftIcon,
                link: '/program/outreach',
                content: 'Outreach',
                visible: true,
            }, {
                icon: TruckIcon,
                link: '/program/shipping-and-tax',
                content: 'Shipping & Tax',
                visible: true,
            }, {
                icon: LifebuoyIcon,
                link: '/program/technicians',
                content: 'Technicians',
                visible: true,
            },
        ]
    }, {
        icon: GlobeAltIcon,
        content: 'Services',
        visible: true,
        link: '/consumers',
        children: [
            {
                icon: MagnifyingGlassIcon,
                link: '/services/consumers',
                content: 'Consumers',
                visible: true,
            }, {
                icon: ClipboardIcon,
                link: '/services/assessments',
                content: 'Assessments',
                visible: true,
            }, {
                icon: ArchiveBoxIcon,
                link: '/services/equipment',
                content: 'Equipment',
                visible: true,
            }, {
                icon: PuzzlePieceIcon,
                link: '/services/installation',
                content: 'Installation',
                visible: true,
            }, {
                icon: BookOpenIcon,
                link: '/services/training',
                content: 'Training',
                visible: true,
            }, {
                icon: Cog8ToothIcon,
                link: '/services/maintenance-and-repair',
                content: 'Maintenance/Repair',
                visible: true,
            }, {
                icon: MapIcon,
                link: '/services/travel',
                content: 'Travel',
                visible: true,
            }
        ]
    }, {
        icon: BanknotesIcon,
        content: 'Reimbursements',
        visible: true,
        link: '/reimbursements/search',
    }, {
        icon: BookOpenIcon,
        content: 'Reports',
        link: '/reports',
        visible: true,
        children: [
            {
                icon: CalendarIcon,
                content: 'Semi-Annual Reports',
                link: '/reports/semiAnnualReports',
                visible: true,
            }, {
                icon: ChartBarIcon,
                content: 'Embedded Reports',
                link: '/reports/embeddedReports',
                visible: true,
            }, {
                icon: DocumentMagnifyingGlassIcon,
                content: 'Audit Reports',
                link: '/reports/audit-reports',
                visible: true,
            }
        ]
    }, {
        icon: CurrencyDollarIcon,
        content: 'National Outreach',
        link: '/national-outreach-budgets/search',
        visible: hasRole('national-outreach-budget'),
    }, {
        icon: ScaleIcon,
        content: 'Mass Costs',
        link: '/mass-costs',
        visible: true,
    },
    ]
}
export default navigationItems