import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import AttachmentTable from '../../../../../components/attachment/AttachmentTable';
import {
    MaintenanceRepairAttachmentFormValues
} from '../../../../../models/MaintenanceRepair/MaintenanceRepairAttachment';
import {IAttachment} from '../../../../../models/generic/Attachment';
import {RootStoreContext} from '../../../../../stores/RootStore';


export interface MaintenanceRepairAttachmentTableProps {
    maintenanceRepairId: string;
    disableCreateNew?: boolean;
}

type Props = MaintenanceRepairAttachmentTableProps;

const MaintenanceRepairAttachmentTable: FunctionComponent<Props> = ({maintenanceRepairId, disableCreateNew= false}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentMaintenanceRepairId,
        maintenanceRepairAttachmentsByDate,
        maintenanceRepairAttachmentCount,
        handleChangePage,
        getMaintenanceRepairAttachmentsByMaintenanceRepairId,
        getMaintenanceRepairAttachmentById,
        createMaintenanceRepairAttachment,
        clearMaintenanceRepairAttachment,
        deleteMaintenanceRepairAttachment
    } = rootStore.maintenanceRepairAttachmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment

    // USE EFFECTS
    useEffect(() => {
        if (!!maintenanceRepairId) {
            setCurrentMaintenanceRepairId(maintenanceRepairId);
            getMaintenanceRepairAttachmentsByMaintenanceRepairId()
        }
    }, [maintenanceRepairId, getMaintenanceRepairAttachmentsByMaintenanceRepairId]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {header: 'Create Maintenance/Repair Attachment', createAttachment});
    }

    const createAttachment = (attachment: IAttachment) => {
        const newMaintenanceRepairAttachment: MaintenanceRepairAttachmentFormValues = {
            ...attachment,
            maintenanceRepairId
        }
        createMaintenanceRepairAttachment(newMaintenanceRepairAttachment);
        clearMaintenanceRepairAttachment();
    }

    const deleteAttachment = (attachmentId) => {
        deleteMaintenanceRepairAttachment(attachmentId);
    }


    return (<AttachmentTable
        attachments={maintenanceRepairAttachmentsByDate}
        attachmentCount={maintenanceRepairAttachmentCount}
        handleChangePage={handleChangePage}
        openCreateModal={openCreateModal}
        filterPredicate={setPredicate}
        getAttachmentById={getMaintenanceRepairAttachmentById}
        getDirectAttachmentById={getGenericDirectAttachmentById}
        deleteAttachment={deleteAttachment}
        disableCreateNew={disableCreateNew}
    />)
};

export default observer(MaintenanceRepairAttachmentTable)
