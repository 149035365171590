// Code from https://focux.dev/post/how-to-build-better-tailwind-components-in-react
export const getClassName = (classes) => {
    // we get the name of the classes, remove leading/trailing spaces and undefined classes
    const names = Object.keys(classes)
        .map((v) => v.trim())
        .filter((v) => v);

    let activeClasses = '';

    // we loop through all the classes and check if they are active
    // if they are, we add it to the classes list
    for (const name of names) {
        if (classes[name]) {
            activeClasses += ` ${name}`;
        }
    }

    // we return the class name
    return activeClasses.trim();
};