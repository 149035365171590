import axios from "axios";
import {ITravel, ITravelEnvelope, TravelFormValues} from "../../models/Travel/Travel";
import {api, responseBody} from "./apiBase.service";

const Travel = {
    list: (
        params: URLSearchParams
    ): Promise<ITravelEnvelope> =>
        axios.get("/Travel", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/Travel/${id}`),
    create: (Travel: TravelFormValues) =>
        api.post(`/Travel/`, Travel),
    update: (Travel: ITravel) =>
        api.put(`/Travel/${Travel.id}`, Travel),
    delete: (id: string) =>
        api.del(`/Travel/${id}`),
};

export default Travel