import {observer} from 'mobx-react-lite';
import React, {useContext, useEffect} from 'react';
import {RootStoreContext} from '../../stores/RootStore';
import History from '../../AppHistory';
import Title from "../../components/generic/Title";
import Button from "../../components/generic/Button";
import logo from '../../assets/img/logo4.png'
import {ArrowLeftOnRectangleIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";

const LoginPage = () => {
    const rootStore = useContext(RootStoreContext);
    const {login, getUser} = rootStore.oidcStore;

    useEffect(() => {
        //on load, load the user in the store, then redirect if the user has an active cache
        getUser().then((user) => {
            if (user != null) {
                History.push('/Home')
            } else {
                //window.localStorage.clear()
            }
        })
    }, [getUser])

    useEffect(() => {
        document.title = "Login | NDBEDP Centralized Database"
    }, []);


    return (<div className={'bg-white flex flex-col justify-between items-center p-4 w-full h-screen'}>
        <div>{/* DO NOT DELETE */}</div>
        <div className={'text-center max-w-sm'}>
            {/*<Logo className={'w-72 m-auto'}/>*/}

            <img src={logo} className={'w-80'} alt=""/>

            <div className={'w-80'}>
                <Title className={'m-0 pt-2 whitespace-nowrap text-center sr-only'} size={'2xl'}>NDBEDP
                    Centralized Database</Title>
            </div>
            <Button
                color="dark"
                onClick={login}
                className="w-full mb-3 mt-3"
                iconLeft={<ArrowLeftOnRectangleIcon className={'h-5 w-5'}/>}
                name={'login'}
            >
                Sign in with Rolka Loube
            </Button>

            {/*<p className="text-muted text-center">Don't have an Account?*/}
            {/*    <Link to={"#"} className="d-inline p-0">&nbsp;Register</Link>*/}
            {/*</p>*/}
        </div>

        <div className={'text-center text-gray-600'}>
            <p className={'mb-2'}>&copy; Rolka Loube {new Date().getFullYear()} | All Rights Reserved | NDBEDP
                v{process.env.REACT_APP_VERSION}</p>

            <p className={'flex gap-1 justify-center opacity-80'}>
                <Link to={'/tos'}>terms of service</Link>|
                <Link to={'/pp'}>privacy policy</Link>
            </p>
        </div>
    </div>);
}
export default observer(LoginPage);
