import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {displayDollarAmount, formatPercent, inputOnChange, suppressOnTabThrough} from "../../../../../lib/utilities";
import Input from '../../../../../components/Inputs/Input';
import Button from "../../../../../components/generic/Button";
import numberInputOptions from "../../../../../data/cleave/numberInputOptions";
import {IReallocationRequest} from "../../../../../models/Allocation/ReallocationRequest";
import EntityStateAutoCompleteInput from "../../../../../components/form/EntityStatesAutoCompleteInput";
import ProgramYearAutoCompleteInput from "../../../../../components/form/ProgramYearAutoCompleteInput";
import HR from "../../../../../components/generic/HR";
import JSONView from "../../../../../components/generic/JSONView";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {IAllocation} from "../../../../../models/Allocation/Allocation";
import {toast} from "react-toastify";
import {IProgramYear} from "../../../../../models/ProgramYear";

export interface BudgetReallocationRequestFormProps {
    reallocation?: IReallocationRequest
}

type Props = BudgetReallocationRequestFormProps

const BudgetForm: React.FC<Props> = ({reallocation}) => {
    // store
    const rootStore = useContext(RootStoreContext);
    const {createReallocationRequest} = rootStore.reallocationRequestStore
    const {loadNakedAllocations} = rootStore.allocationStore;
    const {closeModal} = rootStore.modalStore
    const {getProgramYearById} = rootStore.programYearStore;

    // state
    const [currentReallocation, setCurrentReallocation] = useState(reallocation ?? {} as IReallocationRequest);
    const [localTransferToAllocation, setLocalTransferToAllocation] = useState({} as IAllocation)
    const [localTransferFromAllocation, setLocalTransferFromAllocation] = useState({} as IAllocation)
    const [localProgramYear, setLocalProgramYear] = useState({} as IProgramYear)


    // functions
    const handleSubmit = (e) => {
        e.preventDefault()
        createReallocationRequest(currentReallocation)
    }

    const handleChange = (e) => inputOnChange(e, setCurrentReallocation)
    const handleRange = (e) => {
        const {name, value} = e.currentTarget;
        handleChange({currentTarget: {name, value: `${+value / 10000}`}})
    }

    const suppressZeroOnTab = (e) => suppressOnTabThrough(e, setCurrentReallocation)


    const fetchAllocations = (name: string, entityStateId: string, programYearId: string) => {
        if (!entityStateId) {
            if (name === 'entityStateIdToPull') setLocalTransferFromAllocation({} as IAllocation)
            if (name === 'entityStateIdToTransfer') {
                setCurrentReallocation(prev => {
                    return {
                        ...prev,
                        newAdministrativePercentToTransfer: undefined,
                        newTrainTheTrainerPercentToTransfer: undefined,
                        newOutreachPercentToTransfer: undefined,
                    }
                })
                setLocalTransferToAllocation({} as IAllocation)
            }
        } else {
            loadNakedAllocations({entityStateId, programYearId}).then(data => {
                if (!!data) {
                    let allocation = data.find((d: IAllocation) => d.entityStateId === entityStateId)
                    if (!allocation) return toast.warning('Unable to fetch Allocation information')
                    if (name === 'entityStateIdToPull') setLocalTransferFromAllocation(allocation)
                    if (name === 'entityStateIdToTransfer') {
                        setCurrentReallocation(prev => {
                            return {
                                ...prev,
                                newAdministrativePercentToTransfer: allocation?.maxAdministrativePercent,
                                newTrainTheTrainerPercentToTransfer: allocation?.maxTrainTheTrainerPercent,
                                newOutreachPercentToTransfer: allocation?.maxOutreachPercent,
                            }
                        })
                        setLocalTransferToAllocation(allocation)
                    }
                }
            })

        }

    }


    const handleProgramYearAutoComplete = (e) => {
        handleChange({currentTarget: {...e}})
        getProgramYearById(e.value).then(data => !!data ? setLocalProgramYear(data) : setLocalProgramYear({} as IProgramYear))
        fetchAllocations('entityStateIdToPull', currentReallocation.entityStateIdToPull ?? '', e.value)
        fetchAllocations('entityStateIdToTransfer', currentReallocation.entityStateIdToTransfer ?? '', e.value)
    }

    const handleStateAutoComplete = (e) => {
        handleChange({currentTarget: {...e}})
        fetchAllocations(e.name, e.value, currentReallocation.programYearId)
    }


    const calcFrom = () => {
        let results = 0
        if (localTransferFromAllocation.id)
            results = (localTransferFromAllocation.stateAllocationAmount ?? 0) - (localTransferFromAllocation.usedStateAllocationAmount ?? 0)
        else
            results = (localProgramYear.stateAllocations ?? 0) - (localProgramYear.approvedStateAllocations ?? 0)

        return displayDollarAmount(results)
    }

    const calcFromAfter = () => {
        let total
        let offset

        if (!!localTransferFromAllocation.id) {
            total = (localTransferFromAllocation.stateAllocationAmount ?? 0) - (localTransferFromAllocation.usedStateAllocationAmount ?? 0)
            offset = (currentReallocation.amountToPull ?? 0)
        } else {
            total = (localProgramYear.approvedStateAllocations ?? 0)
            offset = (currentReallocation.amountToTransfer ?? 0)
        }

        return displayDollarAmount(total - offset)
    }

    const calcTo = () => {
        let results = 0
        if (localTransferToAllocation.id)
            results = (localTransferToAllocation.stateAllocationAmount ?? 0) - (localTransferToAllocation.usedStateAllocationAmount ?? 0)
        else
            results = (localProgramYear.stateAllocations ?? 0) - (localProgramYear.approvedStateAllocations ?? 0)

        return displayDollarAmount(results)
    }
    const calcToAfter = () => {
        let total
        let offset

        if (!!localTransferToAllocation.id) {
            total = (localTransferToAllocation.stateAllocationAmount ?? 0 - localTransferToAllocation.usedStateAllocationAmount! ?? 0)
            offset = (currentReallocation.amountToTransfer ?? 0)
        } else {
            total = (localProgramYear.stateAllocations ?? 0) - (localProgramYear.approvedStateAllocations ?? 0)
            offset = (currentReallocation.amountToPull ?? 0)
        }

        return displayDollarAmount(+total + +offset)
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full'}>

            <div className={'flex-1'}>
                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <ProgramYearAutoCompleteInput required
                                                  onChange={({id}) => handleProgramYearAutoComplete({
                                                      name: 'programYearId',
                                                      value: id
                                                  })}
                                                  name={'programYearId'}
                    />
                </div>

                <HR title={'From'} className={'mt-2'}/>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                    <div>
                        <EntityStateAutoCompleteInput label={'State to Pull From'}
                                                      onChange={({id}) => handleStateAutoComplete({
                                                          name: 'entityStateIdToPull',
                                                          value: id ?? ''
                                                      })}
                                                      name={'entityStateIdToPull'}
                                                      disabled={!currentReallocation.programYearId}
                        />
                        <div className="flex gap-4  ml-1 mb-3 text-sm text-gray-500">
                            <dt className={'font-medium'}>Available {!!localTransferFromAllocation.id ? 'State' : 'Global'} Allocation</dt>
                            <dd>{calcFrom()}</dd>
                        </div>
                    </div>
                    <div>
                        <Input label={'Amount To Pull'}
                               name={'amountToPull'}
                               options={numberInputOptions}
                               onChange={handleChange}
                               onBlur={suppressZeroOnTab}
                               placeholder={'$88.00'}
                               value={currentReallocation.amountToPull}
                               disabled={!currentReallocation.programYearId && !currentReallocation.entityStateIdToPull}
                        />
                        <div className="flex gap-4 -mt-2 ml-1 mb-3 text-sm text-gray-500">
                            <dt className={'font-medium'}>After Pull</dt>
                            <dd>{calcFromAfter()}</dd>
                        </div>

                    </div>
                </div>


                <HR title={'To'} className={'mt-2'}/>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                    <div>
                        <EntityStateAutoCompleteInput label={'State to Transfer To'}
                                                      onChange={({id}) => handleStateAutoComplete({
                                                          name: 'entityStateIdToTransfer',
                                                          value: id,
                                                      })}
                                                      name={'entityStateIdToTransfer'}
                                                      disabled={!currentReallocation.programYearId}
                        />
                        <div className="flex gap-4  ml-1 mb-3 text-sm text-gray-500">
                            <dt className={'font-medium'}>Available {!!localTransferToAllocation.id ? 'State' : 'Global'} Allocation</dt>
                            <dd>{calcTo()}</dd>
                        </div>
                    </div>
                    <div>
                        <Input label={'Amount To Transfer'}
                               name={'amountToTransfer'}
                               options={numberInputOptions}
                               onChange={handleChange}
                               onBlur={suppressZeroOnTab}
                               placeholder={'$88.00'}
                               value={currentReallocation.amountToTransfer}
                               disabled={!currentReallocation.programYearId && !currentReallocation.entityStateToTransfer}
                        />
                        <div className="flex gap-4 -mt-2 ml-1 mb-3 text-sm text-gray-500">
                            <dt className={'font-medium'}>After Transfer</dt>
                            <dd>{calcToAfter()}</dd>
                        </div>
                    </div>
                </div>


                <HR title={'Modify Budget Caps'} className={'mt-2'}/>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <Input label={'Administrative'}
                            disabled={!currentReallocation.entityStateIdToTransfer}
                            name={'newAdministrativePercentToTransfer'}
                            value={currentReallocation.newAdministrativePercentToTransfer ?? 0}
                            inputMode={'numeric'}
                            min={0} max={100}
                            onChange={handleChange}
                            pattern="^(100(\.0{1,9})?|[1-9]\d*(\.\d{1,9})?|0(\.\d{1,9})?|\d{1,2})$"
                            title="Enter a valid number between 0 and 100"
                    />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <Input label={'Train-the-Trainer'}
                           disabled={!currentReallocation.entityStateIdToTransfer}
                           name={'newTrainTheTrainerPercentToTransfer'}
                           value={currentReallocation.newTrainTheTrainerPercentToTransfer ?? 0}
                           inputMode={'numeric'}
                           min={0} max={100}
                           onChange={handleChange}
                           pattern="^(100(\.0{1,9})?|[1-9]\d*(\.\d{1,9})?|0(\.\d{1,9})?|\d{1,2})$"
                           title="Enter a valid number between 0 and 100"
                    />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <Input label={'Outreach'}
                           disabled={!currentReallocation.entityStateIdToTransfer}
                           name={'newOutreachPercentToTransfer'}
                           value={currentReallocation.newOutreachPercentToTransfer ?? 0}
                           inputMode={'numeric'}
                           min={0} max={100}
                           onChange={handleChange}
                           pattern="^(100(\.0{1,9})?|[1-9]\d*(\.\d{1,9})?|0(\.\d{1,9})?|\d{1,2})$"
                           title="Enter a valid number between 0 and 100"
                    />
                </div>


                <JSONView data={currentReallocation} title={'currentReallocation'}/>
                <JSONView data={localTransferFromAllocation} title={'localTransferFromAllocation'}/>
                <JSONView data={localTransferToAllocation} title={'localTransferToAllocation'}/>
                <JSONView data={localProgramYear} title={'localProgramYear'}/>
            </div>

            <div className={'flex justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}>Save</Button>
                <span className={'m-2'}/>
                <Button color="red" onClick={() => closeModal('BudgetReallocationRequestModal')}
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>
    );
};
export default observer(BudgetForm)