import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from '../../../../stores/RootStore';
import AttachmentTable from '../../../../components/attachment/AttachmentTable';
import {AssessmentAttachmentFormValues} from '../../../../models/Assessment/AssessmentAttachment';
import {IAttachment} from '../../../../models/generic/Attachment';


export interface AssessmentAttachmentTableProps {
    assessmentId: string;
    isLocked?: boolean;
}

type Props = AssessmentAttachmentTableProps;

const AssessmentAttachmentTable: FunctionComponent<Props> = ({assessmentId, isLocked = false}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentAssessmentId,
        assessmentAttachmentsByDate,
        assessmentAttachmentCount,
        handleChangePage,
        getAssessmentAttachmentsByAssessmentId,
        getAssessmentAttachmentById,
        createAssessmentAttachment,
        deleteAssessmentAttachment
    } = rootStore.assessmentAttachmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment

    // USE EFFECTS
    useEffect(() => {
        if (!!assessmentId) {
            setCurrentAssessmentId(assessmentId);
            getAssessmentAttachmentsByAssessmentId()
        }
    }, [assessmentId, getAssessmentAttachmentsByAssessmentId]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {header: 'Create Assessment Attachment', createAttachment}, 'lg');
    }

    const createAttachment = (attachment: IAttachment) => {
        const newAssessmentAttachment: AssessmentAttachmentFormValues = {...attachment, assessmentId}
        createAssessmentAttachment(newAssessmentAttachment);
    }

    const deleteAttachment = (attachmentId) => {
        deleteAssessmentAttachment(attachmentId);
    }


    return (<AttachmentTable
        attachments={assessmentAttachmentsByDate}
        attachmentCount={assessmentAttachmentCount}
        handleChangePage={handleChangePage}
        openCreateModal={openCreateModal}
        filterPredicate={setPredicate}
        getAttachmentById={getAssessmentAttachmentById}
        getDirectAttachmentById={getGenericDirectAttachmentById}
        deleteAttachment={deleteAttachment}
        disableCreateNew={isLocked}
    />)
};

export default observer(AssessmentAttachmentTable)
