import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {inputOnChange, objectDateConverter} from '../../../../lib/utilities'
import {IOutreach, OutreachFormValues} from '../../../../models/Outreach/Outreach'
import {RootStoreContext} from '../../../../stores/RootStore'
import EntityStateAutoCompleteInput from '../../../../components/form/EntityStatesAutoCompleteInput'
import Input from "../../../../components/Inputs/Input";
import TechnicianAutoCompleteInput from "../../../../components/form/TechnicianAutoCompleteInput";
import TextArea from "../../../../components/form/TextArea";
import ConsumerAutoCompleteInput from "../../../../components/form/ConsumerAutoCompleteInput";
import Button from "../../../../components/generic/Button";
import dateInputOptions from "../../../../data/cleave/dateInputOptions";
import {IEntityState} from "../../../../models/Entity/EntityState";
import {AdministrativeFormValues} from "../../../../models/Administrative/Administrative";
import Toggle from "../../../../components/generic/Toggle";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import JSONView from "../../../../components/generic/JSONView";


export interface OutreachFormProps {
    outreach?: IOutreach;
}

type Props = OutreachFormProps

const OutreachForm: React.FC<Props> = ({outreach}) => {
    // store
    const rootStore = useContext(RootStoreContext)
    const {currentEntityState} = rootStore.commonStore
    const {entityStatesByName} = rootStore.entityStateStore
    const {closeModal, openModal} = rootStore.modalStore;
    const {createOutreach, editOutreach} = rootStore.outreachStore
    const {setPredicate} = rootStore.consumerStore
    const {getEntityStateByConsumerId} = rootStore.entityStateStore

    // Local state
    const [currentOutreach, setCurrentOutreach] = useState(outreach ?? {} as IOutreach);
    const [addCost, setAddCost] = useState(true);

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);


    // USE EFFECTS
    useEffect(() => {
        objectDateConverter(outreach, setCurrentOutreach)

        if (outreach) {
            setCurrentOutreach(outreach);
        }

        if (!!outreach?.entityStateId) {
            setPredicate('entityStateId', outreach.entityStateId)
        }

        if (!!currentOutreach.consumerId)
            getEntityStateByConsumerId(currentOutreach.consumerId)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    setCurrentOutreach(prev => {
                        return {
                            ...prev,
                            entityStateId: entityState?.id || '',
                            entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : ''
                        }
                    })
                })
    }, [outreach])

    useEffect(() => {
        if (!currentOutreach.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentOutreach((prev: OutreachFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates])

    useEffect(() => {
        if (!currentOutreach.id && (!!entityStatesByName && entityStatesByName.length === 1)) {
            setCurrentOutreach((prev: AdministrativeFormValues) => {
                return {
                    ...prev,
                    entityStateId: currentEntityState.id || '',
                    entityState: currentEntityState.id ? `${currentEntityState.stateName} (${currentEntityState.entityName})` : ''
                }
            })
        }
    }, [currentEntityState])


    // Funtions
    const handleConsumerAndStatae = (value) => {
        if (!!value) {
            getEntityStateByConsumerId(value)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    setCurrentOutreach((prev: IOutreach) => {
                        return {
                            ...prev,
                            entityStateId: entityState?.id || '',
                            entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : '',
                            consumerId: value
                        }
                    })
                })
        } else {
            setCurrentOutreach((prev: IOutreach) => {
                return {
                    ...prev,
                    entityStateId: '',
                    entityState: '',
                    consumerId: ''
                }
            })
        }

    }

    const handleChange = (e) => {
        const {name, value} = e.currentTarget
        if (name === 'consumerId') {
            handleConsumerAndStatae(value)
        } else {
            inputOnChange(e, setCurrentOutreach)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newOutreach = {...currentOutreach}
        Object.keys(newOutreach).forEach((k) => !newOutreach[k] && delete newOutreach[k]);
        if (!newOutreach.id && (newOutreach.id === '' || newOutreach.id !== 'undefined')) {
            delete newOutreach.id
            return createOutreach(newOutreach).then(data => addCostAfterSaving(data))
        } else {
            return editOutreach(newOutreach)
        }
    }

    const addCostAfterSaving = async (data) => {
        if (!!data && addCost) await openModal('SubItemModal', {
            header: 'New Outreach Cost',
            lineItemId: data.id,
            lineItemType: 'Outreach',
            entityStateId: data.entityStateId,
        })
    }

    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit}
              className={'flex flex-col justify-between flex-1 h-full'}>
            <div className={'overflow-y-auto overflow-x-hidden flex-1'}>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">

                    <EntityStateAutoCompleteInput
                        required
                        isLocked={(!currentOutreach.id && !!localEntityStates && localEntityStates.length === 1)}
                        onChange={({id}) => handleAutoComplete(id, 'entityStateId')}
                        name={'entityStateId'}
                        placeholder={'California'}
                        displayValue={(currentOutreach?.entityStateId) ? (currentOutreach?.entityState || 'State Program already selected') : ''}
                    />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <ConsumerAutoCompleteInput
                        onChange={({id}) => handleAutoComplete(id, 'consumerId')}
                        displayValue={currentOutreach.consumerId ? currentOutreach.consumerName ?? 'Consumer Already Assigned' : ''}
                        placeholder={'Marty McFly'}
                        name={'consumerId'}
                        entityStateId={outreach?.entityStateId}
                        disabled={!currentOutreach?.entityStateId}
                    />
                </div>


                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">

                    <TechnicianAutoCompleteInput
                        onChange={({id}) => handleAutoComplete(id, 'technicianId')}
                        displayValue={currentOutreach.technicianId ? currentOutreach.technicianName ?? 'Technician Already Assigned' : ''}
                        placeholder={'Doc Brown'}
                        name='technicianId'
                    />
                </div>


                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                    <Input
                        label={'Event Date'}
                        value={`${currentOutreach.dateEvent ?? ''}`}
                        name={'dateEvent'}
                        onChange={handleChange}
                        options={{
                            ...dateInputOptions,
                            dateMax: undefined
                        }}

                        pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                        placeholder={'10/26/1985'}
                    />
                    <Input label={'Date Performed'}
                           value={`${currentOutreach.datePerformed ?? ''}`}
                           name={'datePerformed'}
                           onChange={handleChange}
                           options={dateInputOptions}
                           errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                           pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                           placeholder={'10/26/1985'}
                           required
                    />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <TextArea
                        label={'Description'}
                        value={currentOutreach.description}
                        name={'description'}
                        onChange={handleChange}
                        placeholder={'Consumer needed info on time travel'}
                        rows={3}
                    /></div>

                {!currentOutreach.id ?
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <Toggle label={'add cost after saving'}
                                onChange={() => setAddCost(prev => !prev)} checked={addCost}
                                name={'addCostToggle'}/></div> : ''}
                <JSONView data={currentOutreach}/>

            </div>

            <div className={'flex gap-4 justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'}
                        type={'submit'}>Save</Button>
                <Button
                    onClick={() => closeModal('OutreachModal')} name={'cancel'}
                    color="red"
                    iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>
        </form>
    );
};
export default observer(OutreachForm)