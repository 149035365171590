import axios from "axios";
import {EquipmentNoteFormValues, IEquipmentNote, IEquipmentNoteEnvelope} from "../../models/Equipment/EquipmentNote";
import {api, responseBody} from "./apiBase.service";

const EquipmentNotes = {
    list: (params: URLSearchParams): Promise<IEquipmentNoteEnvelope> =>
        axios
            .get("/Equipment/Note", {params: params})
            .then(responseBody),
    create: (Equipment: EquipmentNoteFormValues): Promise<IEquipmentNote> =>
        api.post(`/Equipment/Note`, Equipment),
    update: (Equipment: IEquipmentNote): Promise<IEquipmentNote> =>
        api.put(`/Equipment/Note/${Equipment.id}`, Equipment),
    delete: (id: string): Promise<string> =>
        api.del(`/Equipment/Note/${id}`),
    byId: (id: string) => api.get(`/Equipment/Note/${id}`),
    byEquipmentId: (equipmentId: string, params: URLSearchParams): Promise<IEquipmentNoteEnvelope> =>
        axios
            .get(`/Equipment/${equipmentId}/Note`, {params: params})
            .then(responseBody)
    ,
};

export default EquipmentNotes