import React, {FunctionComponent, useEffect} from 'react';

import {IAdministrative} from "../../../models/Administrative/Administrative";
import {displayDate} from "../../../lib/utilities";
import {observer} from "mobx-react-lite";
import {useAPIPager} from "../../../hooks/useAPIPager";
import Agent from "../../../api/Agent";
import {useSelectTable} from "../../../hooks/useSelectTable";
import Table from '../../table/Table';
import THead from '../../table/THead';
import TR from '../../table/TR';
import TD from "../../table/TD";
import TBody from '../../table/TBody';
import Pagination from "../../table/Pagination";
import Skeleton from "../../generic/Skeleton";
import Checkbox from "../../Inputs/Checkbox";

interface OwnProps {
    onChange?: (e: string[]) => (void)
    selected?: string[]
    predicates?: Map<any, any>
}

type Props = OwnProps;

const AdministrativeSelectTable: FunctionComponent<Props> = ({onChange, selected, predicates}) => {
    // Custom Hooks
    const {items, count, handleChangePage, isLoading} = useAPIPager(Agent.Administratives, predicates)
    const {handleCheck, handleCheckAll, selectedItems} = useSelectTable(selected);

    // Use Effects
    useEffect(() => {
        !!onChange && onChange(selectedItems)
    }, [selectedItems]);

    return (<>
        <span className="sr-only" id="administrativesSelectTable">Administrative</span>
        <Table className={'max-w-full'} aria-describedby={'administrativesSelectTable'}>
            <THead>
                <TR>
                    <TD className={'th min-w-max whitespace-nowrap'}>
                        {items.length > 0 && <>
                            <span id="selectAllAdministrative" className="sr-only">Select all</span>
                            <Checkbox
                                label={<span className={'sr-only'}>Select</span>}
                                checked={items.every(i => selectedItems?.includes(i.id))}
                                className="border-gray-300 rounded"
                                onChange={(e) => handleCheckAll(e, items)}/></>}
                    </TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Description</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Technician</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Date Performed</TD>
                    <TD className={'th min-w-max whitespace-nowrap'}>Cost</TD>
                </TR>
            </THead>
            <TBody>
                {items?.map((administrative: IAdministrative, idx) => <TR key={idx}>
                    {isLoading ? <TD colSpan={100}><Skeleton/></TD> : <>
                        <TD className={'td min-w-max whitespace-nowrap'}>
                            <Checkbox checked={selectedItems.includes(administrative.id ?? '')} label={<span className={'sr-only'}>Select</span>}
                                   onChange={handleCheck} value={administrative.id}/>
                        </TD>
                        <TD className={'td min-w-max whitespace-nowrap'}>{administrative?.description}</TD>
                        <TD className={'td min-w-max whitespace-nowrap'}>{administrative?.technicianName}</TD>
                        <TD className={'td min-w-max whitespace-nowrap'}>{displayDate(administrative?.datePerformed)}</TD>
                        <TD className={'td min-w-max whitespace-nowrap'}>${administrative.costs}</TD>
                    </>}
                </TR>)}
            </TBody>
        </Table>
        <Pagination totalRows={count} onPageChange={handleChangePage} onRowChange={handleChangePage}/>
    </>);
};

export default observer(AdministrativeSelectTable);
