import React from 'react'
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import SubItemForm, {SubItemFormProps} from "../../subitems/SubItemForm";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, SubItemFormProps {
}

type Props = OwnProps;

// @ts-ignore
const SubItemModal = NiceModal.create<Props>(({lineItemId, lineItemType, entityStateId, header, onClose, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver title={header} onClose={handleClose} open={modal.visible} afterClose={modal.remove}>
            <SubItemForm lineItemId={lineItemId} onCancel={handleClose} lineItemType={lineItemType}
                         entityStateId={entityStateId} {...rest}/>
        </SlideOver>
    )
})

export default SubItemModal;
