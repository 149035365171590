import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {EquipmentNoteFormValues, IEquipmentNote} from '../../models/Equipment/EquipmentNote'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class EquipmentNoteStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.equipmentNoteRegistry.clear()
                this.loadEquipmentNote()
            }
        )
        reaction(
            () => this.currentEquipmentId,
            () => {

                this.page = 0
                this.equipmentNoteRegistry.clear()
                this.loadEquipmentNote()
            }
        )
    }

    equipmentNoteRegistry = new Map()
    equipmentNote: IEquipmentNote | null = null
    editMode = false


    currentEquipmentId = '';
    equipmentNoteCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentEquipmentId = (equipmentId: string) => {
        this.currentEquipmentId = equipmentId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.equipmentNoteCount / this.limit)
    }

    get equipmentNotesById() {
        return Array.from(this.equipmentNoteRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get equipmentNotesByDate() {
        return Array.from(this.equipmentNoteRegistry.values()).sort(
            (a, b) => dayjs(b.updatedOn).isBefore(a.updatedOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getEquipmentNote = (id: string) => {
        return this.equipmentNoteRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.equipmentNoteRegistry.clear()
        this.loadEquipmentNote()
    }

    clearEquipmentNote = () => {
        this.equipmentNoteRegistry.clear()
    }

    loadEquipmentNote = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            if (!!this.currentEquipmentId) {
                const equipmentNotesEnvelope = await Agent.EquipmentNotes.byEquipmentId(this.currentEquipmentId, this.axiosParams);
                const {modelsDto, modelCount} = equipmentNotesEnvelope;
                runInAction(() => {

                    modelsDto.forEach((equipmentNote: IEquipmentNote) => {
                        this.equipmentNoteRegistry.set(equipmentNote.id, equipmentNote)
                    })
                    this.equipmentNoteCount = modelCount
                })
            }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getEquipmentNoteById = async (id: string) => {
        let equipmentNote = this.getEquipmentNote(id)
        if (equipmentNote) {
            this.equipmentNote = equipmentNote
            return equipmentNote
        } else {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                equipmentNote = await Agent.EquipmentNotes.byId(id)
                runInAction(() => {
                    this.equipmentNote = equipmentNote
                    this.equipmentNoteRegistry.set(equipmentNote.id, equipmentNote)
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return equipmentNote
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createEquipmentNote = async (equipmentNote: IEquipmentNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newEquipmentNote = await Agent.EquipmentNotes.create(equipmentNote)
            await this.loadEquipmentNote()
            runInAction(() => {
                this.equipmentNoteRegistry.set(newEquipmentNote.id, newEquipmentNote)
                this.rootStore.modalStore.closeModal('NoteModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editEquipmentNote = (equipmentNote: EquipmentNoteFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        Agent.EquipmentNotes.update(equipmentNote).then((updatedEquipmentNote) => {
            runInAction(() => {
                this.equipmentNoteRegistry.set(updatedEquipmentNote.id, updatedEquipmentNote)
                this.rootStore.modalStore.closeModal('NoteModal')
            })
            toast.success('Saved')
        }).catch(error => {
            this.rootStore.errorStore.addError(error)
        }).finally(() => this.rootStore.commonStore.setIsLoading(loadingId))


    }

    deleteEquipmentNote = async (equipmentNote: IEquipmentNote) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.EquipmentNotes.delete(equipmentNote.id)
            runInAction(() => {
                this.equipmentNoteRegistry.delete(equipmentNote.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }

    }


}