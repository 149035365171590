import React, {FunctionComponent, useCallback, useContext, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {debounce} from 'lodash'
import {RootStoreContext} from "../../../../stores/RootStore";
import {ITravel} from "../../../../models/Travel/Travel";
import AssignTechnician from "../../../../components/generic/AssignTechnician";

export interface AssignTechnicianToTravelProps {
    travel: ITravel
}

type Props = AssignTechnicianToTravelProps;

const AssignTechnicianToTravel: FunctionComponent<Props> = ({travel}) => {
    // Stores
    const rootStore = useContext(RootStoreContext)
    const {editTravel} = rootStore.travelStore
    const {
        setPredicate,
        loadTechnicians,
        clearTechnicians,
        techniciansList,
        handleChangePage,
        page,
        pageCount,
        technicianCount
    } = rootStore.techniciansStore
    const {closeModal} = rootStore.modalStore

    // Effects
    useEffect(() => {
        loadTechnicians()
    }, [loadTechnicians, clearTechnicians]);

    // Functions
    const handleChange = (e) => {
        const {value, name} = e.currentTarget;
        handleSearch({
            n: name,
            v: value
        })
    };

    const handleSearch = useCallback(
        // tslint:disable-next-line
        debounce((value) => {
            const {n, v} = value
            setPredicate(n, v)
        }, 500),
        []
    );

    const assignTechnician = (technicianId) => {

        let newTravel = {
            ...travel,
            technicianId
        }

        // newTravel.technicianId = technicianId
        editTravel(newTravel).then(() => close())
    }

    const close = () => closeModal('AssignTechnicianToTravelModal')
    return (
        <AssignTechnician onClick={assignTechnician} onChange={handleChange} options={techniciansList}
                          totalItems={technicianCount}
                          page={page} pageCount={pageCount} onPageChange={handleChangePage}
                          onCancel={close}/>
    );
};

export default observer(AssignTechnicianToTravel);
