import NiceModal from "@ebay/nice-modal-react";
import AdministrativeAttachmentsTableModal from "./form/AdministrativeAttachmentsTableModal";
import AdministrativeModal from "./form/AdministrativeModal";
import AllocationModal from "./form/AllocationModal";
import ApproveRejectClaimModal from "./form/ApproveRejectClaimModal";
import AssessmentAttachmentsTableModal from "./form/AssessmentAttachmentsTableModal";
import AssessmentModal from "./form/AssessmentModal";
import AssignConsumerToEquipmentModal from "./assignment/AssignConsumerToEquipmentModal";
import AssignShippingAndTaxModal from "./assignment/AssignShippingAndTaxModal";
import AssignTechnicianToAdministrativeModal from "./assignment/AssignTechnicianToAdministrativeModal";
import AssignTechnicianToAssessmentModal from "./assignment/AssignTechnicianToAssessmentModal";
import AssignTechnicianToInstallationModal from "./assignment/AssignTechnicianToInstallationModal";
import AssignTechnicianToMaintenanceRepairModal from "./assignment/AssignTechnicianToMaintenanceRepairModal";
import AssignTechnicianToTrainingModal from "./assignment/AssignTechnicianToTrainingModal";
import AssignTechnicianToTravelModal from "./assignment/AssignTechnicianToTravelModal";
import AttachmentModal from "./form/AttachmentModal";
import AttestorModal from "./form/AttestorModal";
import AuditReportModal from "./form/AuditReportModal";
import BudgetReallocationRequestModal from "./form/BudgetReallocationRequestModal";
import ConfirmationModal from "./ConfirmationModal";
import ConsumerModal from "./form/ConsumerModal";
import ConsumerTransferModal from './form/ConsumerTransferModal';
import ContactsModal from "./form/ContactsModal";
import EntityModal from "./form/EntityModal";
import EntityStateFilingFrequencyModal from "./form/EntityStateFilingFrequencyModal";
import EntityStateModal from "./form/EntityStateModal";
import EquipmentAttachmentsTableModal from "./form/EquipmentAttachmentsTableModal";
import EquipmentModal from "./form/EquipmentModal";
import EquipmentReturnModal from "./form/EquipmentReturnModal";
import FlagLineItemModal from "./form/FlagLineItemModal";
import InstallationAttachmentsTableModal from "./form/InstallationAttachmentsTableModal";
import InstallationModal from "./form/InstallationModal";
import LineItemAttachmentModal from "./form/LineItemAttachmentModal";
import MaintenanceRepairAttachmentsTableModal from "./form/MaintenanceRepairAttachmentsTableModal";
import MaintenanceRepairModal from "./form/MaintenanceRepairModal";
import MassCostAddAllModal from "./form/MassCostAddAllModal";
import MassCostEditModal from "./form/MassCostEditModal";
import NationalOutreachBudgetModal from "./form/NationalOutreachBudgetModal";
import NationalOutreachBudgetPaymentModal from "./form/NationalOutreachBudgetPaymentModal";
import NoteModal from "./form/NoteModal";
import NotificationModal from "./form/NotificationModal";
import OkayModal from './OkayModal'
import OutreachAttachmentsTableModal from "./form/OutreachAttachmentsTableModal";
import OutreachModal from "./form/OutreachModal";
import ProgramYearModal from "./form/ProgramYearModal";
import ReallocationApproveRejectModal from "./form/ReallocationApproveRejectModal";
import ReimbursementClaimLineItemModal from "./form/ReimbursementClaimLineItemModal";
import ReimbursementClaimModal from "./form/ReimbursementClaimModal";
import ReimbursementClaimPaymentModal from "./form/ReimbursementClaimPaymentModal";
import ReimbursementClaimRevertModal from "./form/ReimbursementClaimRevertModal";
import ReimbursementClaimSubmitModal from "./form/ReimbursementClaimSubmitModal";
import ReimbursementSpreadSheetModal from "./form/ReimbursementSpreadSheetModal";
import SemiAnnualReportSignerModal from "./form/SemiAnnualReportSignerModal";
import ShippingAndTaxAttachmentsTableModal from "./form/ShippingAndTaxAttachmentsTableModal";
import ShippingAndTaxModal from "./form/ShippingAndTaxModal";
import SubItemAttachmentsTableModal from "./form/SubItemAttachmentsTableModal";
import SubItemModal from "./form/SubItemModal";
import TechnicianModal from "./form/TechnicianModal";
import TrainingAttachmentsTableModal from "./form/TrainingAttachmentsTableModal";
import TrainingModal from "./form/TrainingModal";
import TravelAttachmentsTableModal from "./form/TravelAttachmentsTableModal";
import TravelModal from "./form/TravelModal";


NiceModal.register('AdministrativeAttachmentsTableModal', AdministrativeAttachmentsTableModal)
NiceModal.register('AdministrativeModal', AdministrativeModal)
NiceModal.register('AllocationModal', AllocationModal)
NiceModal.register('ApproveRejectClaimModal', ApproveRejectClaimModal)
NiceModal.register('AssessmentAttachmentsTableModal', AssessmentAttachmentsTableModal)
NiceModal.register('AssessmentModal', AssessmentModal)
NiceModal.register('AssignConsumerToEquipmentModal', AssignConsumerToEquipmentModal)
NiceModal.register('AssignShippingAndTaxModal', AssignShippingAndTaxModal)
NiceModal.register('AssignTechnicianToAdministrativeModal', AssignTechnicianToAdministrativeModal)
NiceModal.register('AssignTechnicianToAssessmentModal', AssignTechnicianToAssessmentModal)
NiceModal.register('AssignTechnicianToInstallationModal', AssignTechnicianToInstallationModal)
NiceModal.register('AssignTechnicianToMaintenanceRepairModal', AssignTechnicianToMaintenanceRepairModal)
NiceModal.register('AssignTechnicianToTrainingModal', AssignTechnicianToTrainingModal)
NiceModal.register('AssignTechnicianToTravelModal', AssignTechnicianToTravelModal)
NiceModal.register('AttachmentModal', AttachmentModal)
NiceModal.register('AttestorModal', AttestorModal)
NiceModal.register('AuditReportModal', AuditReportModal)
NiceModal.register('BudgetReallocationRequestModal', BudgetReallocationRequestModal)
NiceModal.register('ConfirmationModal', ConfirmationModal)
NiceModal.register('ConsumerModal', ConsumerModal)
NiceModal.register('ConsumerTransferModal', ConsumerTransferModal)
NiceModal.register('ContactsModal', ContactsModal)
NiceModal.register('EntityModal', EntityModal)
NiceModal.register('EntityStateFilingFrequencyModal', EntityStateFilingFrequencyModal)
NiceModal.register('EntityStateModal', EntityStateModal)
NiceModal.register('EquipmentAttachmentsTableModal', EquipmentAttachmentsTableModal)
NiceModal.register('EquipmentModal', EquipmentModal)
NiceModal.register('EquipmentReturnModal', EquipmentReturnModal)
NiceModal.register('FlagLineItemModal', FlagLineItemModal)
NiceModal.register('InstallationAttachmentsTableModal', InstallationAttachmentsTableModal)
NiceModal.register('InstallationModal', InstallationModal)
NiceModal.register('LineItemAttachmentModal', LineItemAttachmentModal)
NiceModal.register('MaintenanceRepairAttachmentsTableModal', MaintenanceRepairAttachmentsTableModal)
NiceModal.register('MaintenanceRepairModal', MaintenanceRepairModal)
NiceModal.register('MassCostAddAllModal', MassCostAddAllModal)
NiceModal.register('MassCostEditModal', MassCostEditModal)
NiceModal.register('NationalOutreachBudgetModal', NationalOutreachBudgetModal)
NiceModal.register('NationalOutreachBudgetPaymentModal', NationalOutreachBudgetPaymentModal)
NiceModal.register('NoteModal', NoteModal)
NiceModal.register('NotificationModal', NotificationModal)
NiceModal.register('OkayModal', OkayModal)
NiceModal.register('OutreachAttachmentsTableModal', OutreachAttachmentsTableModal)
NiceModal.register('OutreachModal', OutreachModal)
NiceModal.register('ProgramYearModal', ProgramYearModal)
NiceModal.register('ReallocationApproveRejectModal', ReallocationApproveRejectModal)
NiceModal.register('ReimbursementClaimLineItemModal', ReimbursementClaimLineItemModal)
NiceModal.register('ReimbursementClaimModal', ReimbursementClaimModal)
NiceModal.register('ReimbursementClaimPaymentModal', ReimbursementClaimPaymentModal)
NiceModal.register('ReimbursementClaimRevertModal', ReimbursementClaimRevertModal)
NiceModal.register('ReimbursementClaimSubmitModal', ReimbursementClaimSubmitModal)
NiceModal.register('ReimbursementSpreadSheetModal', ReimbursementSpreadSheetModal)
NiceModal.register('SemiAnnualReportSignerModal', SemiAnnualReportSignerModal)
NiceModal.register('ShippingAndTaxAttachmentsTableModal', ShippingAndTaxAttachmentsTableModal)
NiceModal.register('ShippingAndTaxModal', ShippingAndTaxModal)
NiceModal.register('SubItemAttachmentsTableModal', SubItemAttachmentsTableModal)
NiceModal.register('SubItemModal', SubItemModal)
NiceModal.register('TechnicianModal', TechnicianModal)
NiceModal.register('TrainingAttachmentsTableModal', TrainingAttachmentsTableModal)
NiceModal.register('TrainingModal', TrainingModal)
NiceModal.register('TravelAttachmentsTableModal', TravelAttachmentsTableModal)
NiceModal.register('TravelModal', TravelModal)