import {Envelope} from "./Envelope";

export interface IAttachmentEnvelope extends Envelope<IAttachment> {
}

export interface IAttachment {
    id?: string
    attachmentId?: string
    file: any
    description: string
    name: string
    createdByName?: string
    createdOn?: Date
    byteStream?: any
    mimeType?: string
    entityId?: string
    AssessmentIds?: string[]
    ReimbursementClaimIds?: string[]
    EquipmentIds?: string[]
    InstallationIds?: string[]
    MaintenanceRepairIds?: string[]
    OutreachIds?: string[]
    ShippingAndTaxIds?: string[]
    TrainingIds?: string[]
    TravelIds?: string[]
    ConsumerIds?: string[]
    SubItemIds?: string[]
    AdministrativeAttachmentIds?: string[]
    internalOnly: boolean
    submitterName?: string
    submitterTitle?: string

}

//empty form model
export class AttachmentFormValues implements IAttachment {
    id?: string
    file: any
    attachmentId?: string = ''
    description: string = ''
    name: string = ''
    createdByName?: string
    createdOn?: Date
    byteStream?: any
    mimeType?: string
    entityId?: string
    AssessmentIds?: string[]
    ReimbursementClaimIds?: string[]
    EquipmentIds?: string[]
    InstallationIds?: string[]
    MaintenanceRepairIds?: string[]
    OutreachIds?: string[]
    ShippingAndTaxIds?: string[]
    TrainingIds?: string[]
    TravelIds?: string[]
    ConsumerIds?: string[]
    SubItemIds?: string[]
    AdministrativeAttachmentIds?: string[]
    internalOnly: boolean = false
    submitterName?: string
    submitterTitle?: string

    constructor(attachment?: IAttachment) {
        if (!!attachment) {
            Object.assign(this, attachment);
        }
    }
}
