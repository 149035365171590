import {Envelope} from "../generic/Envelope";

export interface IConsumerEnvelope extends Envelope<IConsumer> {
}

export interface IConsumer {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    firstName: string
    middleName?: string
    lastName: string
    address1: string
    address2?: string
    city: string
    state: string
    zipCode: string
    dob: Date
    dateRegistered?: Date
    phone?: string
    email?: string
    customId?: string
    programStatus?: string
    currentStatus: string
    attestorId?: string
    attestorName: string
    entityStateId?: string
    entityState?: string
    isSanitized?: boolean
    hasNoPhone?: boolean
    // firstContactDate: string | Date
}


//interface extention for custom built items like time in a datetime
export interface IConsumerFormValues extends Partial<IConsumer> {
    //time?:Date
}

//empty form model
export class ConsumerFormValues implements IConsumerFormValues {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    firstName: string = ''
    middleName?: string
    lastName: string = ''
    address1: string = ''
    address2?: string
    city: string = ''
    state: string = ''
    zipCode: string = ''
    dob: Date = new Date()
    dateRegistered?: Date
    phone?: string
    email?: string
    customId?: string
    programStatus?: string
    currentStatus: string = ''
    attestorId?: string
    attestorName: string = ''
    entityStateId?: string
    entityState?: string
    isSanitized?: boolean
    hasNoPhone?: boolean
    // firstContactDate: string | Date = ''

    constructor(consumer?: IConsumer) {
        if (consumer) {
            Object.assign(this, consumer);
        }
    }
}