import {Envelope} from "../generic/Envelope";
import {ISubItem} from "../SubItems/SubItem";

export interface ITrainingEnvelope extends Envelope<ITraining> {
}

export interface ITraining {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string
    entityState?: string
    consumerId?: string
    consumerName?: string
    equipmentIds?: string[]
    technicianId?: string
    technicianName?: string
    dateRequested?: Date
    datePerformed: Date
    isTrainTheTrainer?: boolean
    trainingTime?: number
    trainingLaborCosts?: number
    instructionalMaterialCosts?: number
    auxiliaryAidCosts?: number
    otherExpenses?: number
    trainTrainerCosts?: number
    currentStatus?: string
    travelIds?: string[]
    isFlagged?: boolean
    subItems?: ISubItem[]
    attachmentCount?: number
}

export class TrainingFormValues implements ITraining {
    createdByName?: string
    createdOn?: Date
    updatedByName?: string
    updatedOn?: Date
    id?: string
    entityStateId: string = ''
    entityState?: string
    consumerId?: string
    consumerName?: string
    equipmentIds?: string[]
    technicianId?: string
    technicianName?: string
    dateRequested?: Date
    datePerformed: Date = new Date()
    isTrainTheTrainer?: boolean
    trainingTime?: number
    trainingLaborCosts?: number
    instructionalMaterialCosts?: number
    auxiliaryAidCosts?: number
    otherExpenses?: number
    trainTrainerCosts?: number
    currentStatus?: string
    travelIds?: string[]
    isFlagged?: boolean
    subItems?: ISubItem[]
    attachmentCount?: number

    constructor(training?: ITraining) {
        Object.assign(this, training);
    }
}