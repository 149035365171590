import React, {useEffect, useState} from "react";
import {Rows} from "../components/table/Pagination";
import {useLocation} from "react-router-dom";
import {useSessionStorage} from "./useSessionStorage";
import {useMapInSessionStorage} from "./useMapInSessionStorage";

export interface APIPagerReturn {
    items: any[]
    page: number
    limit: Rows
    count: number

    handleChangePage(newPage: number, newRow: number): void

    isLoading: boolean
    setPredicates: React.Dispatch<React.SetStateAction<Map<any, any>>>
    predicates: Map<any, any>
    fetchData: () => void
    sortFilter: ISortFilter

    setSort(sortField: string): void
    setLimit(limit: Rows): void
    setPage(page: number): void
    setCount(count: number): void
}

export interface ISortFilter {
    sortField?: string,
    sortOrder?: 'asc' | 'desc'
}

export const useAPIPager = (api: { list: Function }, defaultPredicates?: Map<any, any>, initPage: number = 1, initLimit: number = 5):
    APIPagerReturn => {
    // State and Refs
    const location = useLocation()
    const [predicates, setPredicates] = useMapInSessionStorage(`${location.key}Predicates`,defaultPredicates ?? new Map<any, any>());
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useSessionStorage(`${location.key}Page`, initPage);
    const [limit, setLimit] = useSessionStorage(`${location.key}Limit`, initLimit);
    const [count, setCount] = useSessionStorage(`${location.key}Count`, 0);
    const [items, setItems] = useState([] as any[]);
    const [sortFilter, setSortFilter] = useSessionStorage(`${location.key}SortFilter`, {sortOrder: undefined, sortField: undefined} as ISortFilter)


    // Functions
    const setSort = async (field: string) => {
        await setSortFilter((prev: ISortFilter) => {
            let order
            if (prev.sortOrder === undefined) order = 'asc'
            if (prev.sortOrder === 'asc') order = 'desc'
            if (prev.sortOrder === 'desc') order = undefined

            return {
                sortField: field,
                sortOrder: order
            }
        })
    }


    const handleChangePage = async (newPage: number, newRow?: number) => {
        await setPage(newPage)
        if (newRow) await setLimit(newRow)
    }

    const fetchData = async () => {
        try {
            if (isLoading) return

            setIsLoading(true)

            const params = new URLSearchParams()
            params.append('limit', `${limit}`)
            params.append('offset', `${(page - 1) * limit}`)
            if (predicates.size > 0) {
                predicates?.forEach((value, key) => {
                    if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object') {
                        params.append(key, value)
                    } else if (typeof value === 'object') {
                        value?.forEach(val => params.append(key, val))
                    }
                })
            }

            const {modelsDto, modelCount} = await api.list(params)
            setItems([...modelsDto])
            setCount(modelCount)
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setPredicates(prevPred => {
            return  new Map([...prevPred, ...Object.entries(sortFilter)])
        })
    }, [page, limit, sortFilter]);

    // Use Effects
    useEffect(() => {
      fetchData()
    }, [page, limit, predicates]);

    return {
        items,
        page,
        limit,
        count,
        handleChangePage,
        isLoading,
        setPredicates,
        fetchData,
        predicates,
        sortFilter,
        setSort,
        setPage,
        setLimit,
        setCount,
    }
}