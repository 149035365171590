import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IOutreachAttachment, OutreachAttachmentFormValues} from '../../models/Outreach/OutreachAttachment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class OutreachAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.outreachAttachmentRegistry.clear()
                this.loadOutreachAttachment()
            }
        )
        reaction(
            () => this.currentOutreachId,
            () => {
                this.page = 0
                this.outreachAttachmentRegistry.clear()
                !!this.currentOutreachId ? this.getOutreachAttachmentsByOutreachId() : this.loadOutreachAttachment()
            }
        )
    }

    outreachAttachmentRegistry = new Map()
    outreachAttachment: IOutreachAttachment | null = null
    editMode = false


    currentOutreachId = '';
    outreachAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentOutreachId = (outreachId: string) => {
        this.currentOutreachId = outreachId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!!predicate) {
            if (predicate !== 'all') {
                this.predicate.delete(predicate)
                if (!!value) this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
            } else {
                this.predicate.clear()
            }
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.outreachAttachmentCount / this.limit)
    }

    get outreachAttachmentsById() {
        return Array.from(this.outreachAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get outreachAttachmentsByDate() {
        return Array.from(this.outreachAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getOutreachAttachment = (id: string) => {
        return this.outreachAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.outreachAttachmentRegistry.clear()
        this.loadOutreachAttachment()
    }

    clearOutreachAttachment = () => {
        this.outreachAttachmentRegistry.clear()
    }

    loadOutreachAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const outreachAttachmentsEnvelope = await Agent.OutreachAttachments.list(this.axiosParams);
            const {modelsDto, modelCount} = outreachAttachmentsEnvelope;

            runInAction(() => {
                this.clearOutreachAttachment()
                modelsDto.forEach((outreachAttachment: IOutreachAttachment) => {
                    this.outreachAttachmentRegistry.set(outreachAttachment.id, outreachAttachment)
                })
                this.outreachAttachmentCount = modelCount
            })
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getOutreachAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let outreachAttachment = await Agent.OutreachAttachments.byId(id)
            runInAction(() => {
                this.outreachAttachment = outreachAttachment
                this.outreachAttachmentRegistry.set(outreachAttachment.id, outreachAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return outreachAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getOutreachAttachmentsByOutreachId = async (outreachId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let outreachAttachmentEnvelope = await Agent.OutreachAttachments.byOutreachId(outreachId || this.currentOutreachId, this.axiosParams)
            const {modelsDto, modelCount} = outreachAttachmentEnvelope
            runInAction(() => {
                modelsDto.forEach(outreachAttachment => {
                    this.outreachAttachmentRegistry.set(outreachAttachment.id, outreachAttachment)
                })

                this.outreachAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createOutreachAttachment = async (outreachAttachment: OutreachAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newOutreachAttachment = await Agent.OutreachAttachments.create(outreachAttachment)
            await (!!this.currentOutreachId ? this.getOutreachAttachmentsByOutreachId() : this.loadOutreachAttachment())

            runInAction(() => {
                this.outreachAttachmentRegistry.set(newOutreachAttachment.id, newOutreachAttachment)
                this.rootStore.modalStore.closeModal('AttachmentModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteOutreachAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.OutreachAttachments.delete(id)
            runInAction(() => {
                this.outreachAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('OutreachAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }
    }


}
