import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {IMaintenanceRepair, MaintenanceRepairFormValues} from '../../models/MaintenanceRepair/MaintenanceRepair'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class MaintenanceRepairStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.maintenanceRepairRegistry.clear()
                this.loadMaintenanceRepair()
            }
        )
    }

    maintenanceRepairRegistry = new Map()
    maintenanceRepair: IMaintenanceRepair | null = null
    editMode = false

    maintenanceRepairCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.maintenanceRepairRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.maintenanceRepairCount / this.limit)
    }

    get maintenanceRepairsList() {
        // @ts-ignore
        return Array.from(this.maintenanceRepairRegistry.values())//.sort((a,b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get maintenanceRepairsByDate() {
        return Array.from(this.maintenanceRepairRegistry.values())
        // .sort(
        //     (a, b) => b.dateCreated - a.dateCreated//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        // )
    }

    getMaintenanceRepair = (id: string) => {
        return this.maintenanceRepairRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.maintenanceRepairRegistry.clear()
        this.loadMaintenanceRepair()
    }

    clearMaintenanceRepair = () => {
        this.maintenanceRepairRegistry.clear()
    }

    loadMaintenanceRepair = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const maintenanceRepairsEnvelope = await Agent.MaintenanceRepair.list(this.axiosParams)
            const {modelsDto, modelCount} = maintenanceRepairsEnvelope

            runInAction(() => {
                this.clearMaintenanceRepair()
                modelsDto.forEach((maintenanceRepair: IMaintenanceRepair) => {
                    this.maintenanceRepairRegistry.set(maintenanceRepair.id, maintenanceRepair)
                })
                this.maintenanceRepairCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    loadNakedMaintenanceRepair = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)
            const maintenanceRepairsEnvelope = await Agent.MaintenanceRepair.list(params)
            const {modelsDto} = maintenanceRepairsEnvelope

            return modelsDto
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getMaintenanceRepairById = async (id: string) => {
            let loadingId = this.rootStore.commonStore.setIsLoading()
            try {
                let maintenanceRepair = await Agent.MaintenanceRepair.byId(id)
                runInAction(() => {
                    this.maintenanceRepair = maintenanceRepair
                    this.rootStore.commonStore.setIsLoading(loadingId)
                })
                return maintenanceRepair
            } catch (error) {
                this.rootStore.errorStore.addError(error)
            }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getMaintenanceRepairByIds = async (ids: string[]) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '1000000')
            params.append('offset', `0`)

            for (let id of ids) {
                params.append('ids', id)
            }

            let {modelsDto} = await Agent.MaintenanceRepair.list(params)

            return modelsDto
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


    createMaintenanceRepair = async (maintenanceRepair: IMaintenanceRepair) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newMaintenanceRepair = await Agent.MaintenanceRepair.create(maintenanceRepair)
            runInAction(() => {
                this.maintenanceRepairRegistry.set(newMaintenanceRepair.id, newMaintenanceRepair)
                this.rootStore.modalStore.closeModal('MaintenanceRepairModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newMaintenanceRepair
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editMaintenanceRepair = async (maintenanceRepair: MaintenanceRepairFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedMaintenanceRepair = await Agent.MaintenanceRepair.update(maintenanceRepair)
            runInAction(() => {
                this.maintenanceRepairRegistry.set(updatedMaintenanceRepair.id, updatedMaintenanceRepair)
                this.rootStore.modalStore.closeModal('MaintenanceRepairModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return updatedMaintenanceRepair
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteMaintenanceRepair = async (maintenanceRepair: IMaintenanceRepair) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            if (!maintenanceRepair.id) throw new Error('Maintenance/Repair Id is required to delete record')
            await Agent.MaintenanceRepair.delete(maintenanceRepair.id)
            runInAction(() => {
                this.maintenanceRepairRegistry.delete(maintenanceRepair.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }

    }


}