import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {ITravel, TravelFormValues} from '../../models/Travel/Travel'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'


export default class TravelStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.travelRegistry.clear()
                this.loadTravel()
            }
        )
    }

    travelRegistry = new Map()
    travel: ITravel | null = null
    editMode = false

    travelCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.travelRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.travelCount / this.limit)
    }

    get travelsList() {
        // @ts-ignore
        return Array.from(this.travelRegistry.values())
    }

    get travelsByDate() {
        return Array.from(this.travelRegistry.values())
    }

    getTravel = (id: string) => {
        return this.travelRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.travelRegistry.clear()
        this.loadTravel()
    }

    clearTravel = () => {
        this.travelRegistry.clear()
    }

    loadTravel = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const travelsEnvelope = await Agent.Travel.list(this.axiosParams)
            const {modelsDto, modelCount} = travelsEnvelope


            runInAction( () => {
                this.clearTravel()
                modelsDto.forEach((travel: ITravel) => {
                    this.travelRegistry.set(travel.id, travel)
                })
                this.travelCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getTravelById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let travel = await Agent.Travel.byId(id)
            runInAction( () => {
                this.travel = travel
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return travel
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    createTravel = async (travel: ITravel) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newTravel = await Agent.Travel.create(travel)
            await this.loadTravel()
            runInAction( () => {
                this.travelRegistry.set(newTravel.id, newTravel)
                this.rootStore.modalStore.closeModal('TravelModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newTravel
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction( () => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editTravel = async (travel: TravelFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedTravel = await Agent.Travel.update(travel)
            runInAction( () => {
                this.travelRegistry.set(updatedTravel.id, updatedTravel)
                this.rootStore.modalStore.closeModal('TravelModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return updatedTravel
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteTravel = async (travel: ITravel) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.Travel.delete(`${travel.id}`)
            runInAction( () => {
                this.travelRegistry.delete(travel.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('TravelModal')
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }


}
