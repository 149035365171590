import React, {FunctionComponent} from "react";
import {observer} from "mobx-react-lite";

interface OwnProps {
    name: string
    onChange: (e) => void
    checked: boolean
}

type Props = OwnProps;

const LegalAgreementApprovalInput: FunctionComponent<Props> = ({name, onChange, checked}) => {
    return (
      <p className={'inline-flex items-center flex-wrap text-justify min-w-0 gap-x-2 mb-3'}>
        <input type={'checkbox'} name={name} onChange={onChange} checked={checked} className={'m-0 mr-1 rounded inline'} />
        You are about to reject this Semi-Annual Report. Once rejected this can not easily be undone.
        Click save to reject and please download the review if you haven't already.
    </p>)
}
export default observer(LegalAgreementApprovalInput);