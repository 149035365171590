import React, {FunctionComponent} from 'react';
import {Disclosure} from "@headlessui/react";

import {Link, useLocation} from 'react-router-dom'

type Item = {content, link, icon?, visible}

interface OwnProps {
    current?: boolean
    link: string
    icon?: React.ReactNode | any
    content: string
    items: Item[]
}

type Props = OwnProps;

const SidebarDropDown: FunctionComponent<Props> = ({current = false, link, icon, content, items}) => {
    const location = useLocation();
    const urlParentName = location.pathname.split('/')[1];
    const parentLinkName = link.split('/')[1];

    const Icon = icon

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    return (<Disclosure defaultOpen={urlParentName === parentLinkName} as="div" className="space-y-1">
        {({open}) => (
            <>
                <Disclosure.Button
                    className={classNames(
                        current
                            ? 'bg-gray-900 text-gray-200'
                            : 'bg-gray-900 text-gray-300 hover:bg-gray-800 hover:text-gray-200',
                        'group w-full flex items-center pl-2 pr-1 py-2 text-left text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all'
                    )}
                >
                    {/*// @ts-ignore*/}
                    {!!icon && <Icon
                              className="mr-3  h-5 w-5 text-gray-300 group-hover:text-gray-200 !flex items-center"
                              aria-hidden="true"
                    />}
                    <span className="grow  hover:underline">{content}</span>
                    <svg
                        className={classNames(
                            open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                            'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                        )}
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                    >
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
                    </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="space-y-1 flex flex-col ">
                    {items.map((item) => {
                        const InnerIcon = item.icon
                        return item.visible && <Link
                            key={item.link}
                            className={classNames(
                                location.pathname === item.link ? "bg-gray-800" : '',
                                "group w-full pl-11 pr-2 py-2 text-base font-medium text-gray-100 rounded-md hover:text-gray-200 hover:bg-gray-800 flex gap-2 items-center"
                            )}
                            to={item.link}
                        >
                            {!!item.icon && <InnerIcon className={'h-5 w-5 mr-2'}/>}
                            {item.content}
                        </Link>
                    })}
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>);
};

export default SidebarDropDown;
