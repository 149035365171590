import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {inputOnChange} from "../../../../../lib/utilities";
import Button from "../../../../../components/generic/Button";
import {IReallocationRequestApproval} from "../../../../../models/Allocation/ReallocationRequest";
import JSONView from "../../../../../components/generic/JSONView";
import TextArea from "../../../../../components/form/TextArea";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface ReallocationApproveRejectFormProps {
    reallocationApproval?: IReallocationRequestApproval
}

type Props = ReallocationApproveRejectFormProps

const ReallocationApproveRejectForm: React.FC<Props> = ({reallocationApproval}) => {
    // store
    const rootStore = useContext(RootStoreContext);
    const {approveReallocationRequest} = rootStore.reallocationRequestStore
    const {closeModal} = rootStore.modalStore

    // state
    const [currentReallocation, setCurrentReallocation] = useState(reallocationApproval ?? {} as IReallocationRequestApproval);


    // functions
    const handleSubmit = (e) => {
        e.preventDefault()
        approveReallocationRequest(currentReallocation)
    }

    const handleChange = (e) => inputOnChange(e, setCurrentReallocation)

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full'}>

            <div className={'flex-1'}>
                <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">

                    <div className="py-2 w-full text-lg">
                        Once <span
                        className="font-bold uppercase">{currentReallocation.approve ? 'APPROVED' : 'REJECTED'}</span> this
                        action cannot
                        be undone.
                    </div>
                    <div className="py-2 w-full">
                        <TextArea name={'comment'} onChange={handleChange} value={currentReallocation.comment}
                                  label={`Comments`}
                                  placeholder={'Roads? Where we\'re going we don\'t need roads.'} rows={6}/>
                    </div>
                </div>

                <JSONView data={currentReallocation}/>
            </div>

            <div className={'flex justify-end gap-4'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}>Save</Button>
                <Button color="red" onClick={() => closeModal('ReallocationApproveRejectModal')}
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>
    );
};
export default observer(ReallocationApproveRejectForm)