import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../api/Agent'
import {AttachmentFormValues, IAttachment} from '../models/generic/Attachment'
import {toast} from 'react-toastify'
import {RootStore} from './RootStore'
import dayjs from 'dayjs'

export default class GenericAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.genericAttachmentRegistry.clear()
                this.loadGenericAttachment()
            }
        )
    }

    genericAttachmentRegistry = new Map()
    genericAttachment: IAttachment | null = null
    editMode = false


    genericAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.genericAttachmentCount / this.limit)
    }

    get genericAttachmentsById() {
        return Array.from(this.genericAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get genericAttachmentsByDate() {
        return Array.from(this.genericAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getGenericAttachment = (id: string) => {
        return this.genericAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.genericAttachmentRegistry.clear()
        this.loadGenericAttachment()
    }

    clearGenericAttachment = () => {
        this.genericAttachmentRegistry.clear()
    }

    loadGenericAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const genericAttachmentsEnvelope = await Agent.GenericAttachments.list(this.axiosParams);
            const {modelsDto, modelCount} = genericAttachmentsEnvelope;
            runInAction(() => {
                modelsDto.forEach((genericAttachment: IAttachment) => {
                    this.genericAttachmentRegistry.set(genericAttachment.id, genericAttachment)
                })
                this.genericAttachmentCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getGenericAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let genericAttachment = await Agent.GenericAttachments.byId(id)
            runInAction(() => {
                this.genericAttachment = genericAttachment
                this.genericAttachmentRegistry.set(genericAttachment.id, genericAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return genericAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }
    getGenericDirectAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let directDownloadResult = await Agent.Attachments.byIdDirectDownload(id)
            runInAction(() => {
                // this.subItemAttachment = subItemAttachment
                // this.subItemAttachmentRegistry.set(subItemAttachment.id, subItemAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return directDownloadResult
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
    }

    createGenericAttachment = async (genericAttachment: AttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newAttachment = await Agent.GenericAttachments.create(genericAttachment)
            await this.loadGenericAttachment()
            runInAction(() => {
                this.genericAttachmentRegistry.set(newAttachment.id, newAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('AttachmentModal')
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteGenericAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newAttachment = await Agent.GenericAttachments.delete(id ?? '')
            runInAction(() => {
                this.genericAttachmentRegistry.delete(newAttachment.id)
            })
            toast.success('Successfully deleted!')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

}