import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {IAllocation, IAllocationApproval, IAllocationEnvelope} from "../../models/Allocation/Allocation";

const Allocations = {
    list: (
        params: URLSearchParams
    ): Promise<IAllocationEnvelope> =>
        axios.get("/Allocation", {params: params})
            .then(responseBody),
    byId: (id: string) => api.get(`/Allocation/${id}`),
    update: (Allocation: IAllocation) =>
        api.put(`/Allocation/${Allocation.id}`, Allocation),
    approve: (AllocationApproval: IAllocationApproval) =>
        api.put(`/Allocation/Approve`, AllocationApproval),
};

export default Allocations