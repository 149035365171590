import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import {AssessmentFormValues, IAssessment} from "../../../../models/Assessment/Assessment";
import {inputOnChange, objectDateConverter, today} from "../../../../lib/utilities";
import ConsumerAutoCompleteInput from '../../../../components/form/ConsumerAutoCompleteInput';
import TechnicianAutoCompleteInput from '../../../../components/form/TechnicianAutoCompleteInput';
import Input from '../../../../components/Inputs/Input';
import Button from "../../../../components/generic/Button";
import dateInputOptions from "../../../../data/cleave/dateInputOptions";
import {IEntityState} from "../../../../models/Entity/EntityState";
import EntityStateAutoCompleteInput from "../../../../components/form/EntityStatesAutoCompleteInput";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import {isEmpty} from "lodash";
import JSONView from "../../../../components/generic/JSONView";
import Toggle from "../../../../components/generic/Toggle";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import Checkbox from "../../../../components/Inputs/Checkbox";

export interface AssessmentFormProps {
    assessment?: IAssessment
}

type Props = AssessmentFormProps

const AssessmentForm: React.FC<Props> = ({assessment}) => {
    // store
    const rootStore = useContext(RootStoreContext);
    const {closeModal, openModal} = rootStore.modalStore;
    const {createAssessment, editAssessment} = rootStore.assessmentStore
    const {getEntityStateByConsumerId} = rootStore.entityStateStore

    // state
    const [currentAssessment, setCurrentAssessment] = useState(new AssessmentFormValues(assessment));
    // const [stateProgram, setStateProgram] = useState({} as IEntityState);
    const [ineligible, setIneligible] = useState(false);
    const [addCost, setAddCost] = useState(true);

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);


    useEffect(() => {
        if (!currentAssessment.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentAssessment((prev: AssessmentFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates])

    useEffect(() => {
        if (!!assessment) {
            objectDateConverter(assessment, setCurrentAssessment)
        }
    }, [assessment])


    // Functions
    const handleConsumerAndState = (value) => {
        if (!!value) {
            getEntityStateByConsumerId(value)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    // setStateProgram(entityState)
                    setCurrentAssessment((prev: IAssessment) => {
                        return {
                            ...prev,
                            entityStateId: entityState?.id || '',
                            entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : '',
                            consumerId: value
                        }
                    })
                })
        } else {
            // setStateProgram({} as IEntityState)
            setCurrentAssessment((prev: IAssessment) => {
                return {
                    ...prev,
                    entityStateId: '',
                    entityState: '',
                    consumerId: ''
                }
            })
        }
    }

    const handleChange = (e) => {
        const {name, value} = e.currentTarget
        name === 'consumerId' ? handleConsumerAndState(value) : inputOnChange(e, setCurrentAssessment)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newAssessment = {...currentAssessment}
        if (!newAssessment.id || newAssessment.id === '' || newAssessment.id === 'undefined') {
            delete newAssessment.id
            return createAssessment(newAssessment).then(data => addSubItem(data))
        } else {
            return editAssessment(newAssessment)
        }
    }

    const addSubItem = async (data) => {
        if (!!data && addCost) openModal('SubItemModal', {
            header: 'New Assessment Cost',
            lineItemId: data.id,
            lineItemType: 'Assessment',
            entityStateId: data.entityStateId
        })
    }

    const handleIneligible = (e) => {
        e.stopPropagation()
        setIneligible((prev: boolean) => {
            if (!prev) {
                setCurrentAssessment(prevAss => {
                    return {
                        ...prevAss,
                        consumerName: '',
                        consumerId: '',
                        entityState: '',
                        entityStateId: ''
                    }
                })
            }

            return !prev
        })
    }

    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full'}>

            <div className={'flex-1'}>
                <Checkbox label={'Is ineligible consumer'}
                       checked={ineligible}
                       name={'ineligible'}
                       onChange={handleIneligible}
                       disabled={ineligible && !!Object.keys(assessment ?? {}).length}
                />

                {ineligible || !isEmpty(assessment) ?
                    // @ts-ignore
                    <Input label={'Consumer Name'} disabled value={assessment.consumerName ?? 'Ineligible'}/> :
                    <ConsumerAutoCompleteInput
                        onChange={({id}) => handleAutoComplete(id, 'consumerId')}
                        displayValue={currentAssessment?.consumerId ? currentAssessment?.consumerName : ''}
                        required
                    />}

                <EntityStateAutoCompleteInput
                    required
                    isLocked={!ineligible || (!!assessment && !isEmpty(assessment))}
                    onChange={({id}) => handleAutoComplete(id, 'entityStateId')}
                    name={'entityStateId'}
                    placeholder={'California'}
                    displayValue={(currentAssessment?.entityStateId) ? (currentAssessment?.entityState || 'State Program already selected') : ''}
                />

                <TechnicianAutoCompleteInput
                    onChange={({id}) => handleAutoComplete(id, 'technicianId')}
                    displayValue={currentAssessment?.technicianId ? currentAssessment?.technicianName : ''}
                    name={'technicianId'}
                />

                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                    <div className={'w-full'}>
                        <Input label={'Date Requested'}
                               value={`${currentAssessment.dateRequested ?? ''}`}
                               name={'dateRequested'}
                               onChange={handleChange}
                               options={{
                                   ...dateInputOptions,
                                   dateMax: currentAssessment.datePerformed ? currentAssessment.datePerformed.toString() : today(),
                               }}

                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                        />
                    </div>
                    <div className={'w-full'}>
                        <Input label={'Date Performed'}
                               onChange={handleChange}
                               value={`${currentAssessment.datePerformed ?? ''}`}
                               name={'datePerformed'}
                               options={dateInputOptions}
                               errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                               required
                        />
                    </div>
                </div>

                {!currentAssessment.id ? <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                    <div className="mt-4">
                        <Toggle label={'add cost after saving'} onChange={() => setAddCost(prev => !prev)}
                                checked={addCost} name={'addCostToggle'}/>
                    </div>
                </div> : ''}

                <JSONView data={currentAssessment}/>
            </div>

            <div className={'flex justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}>Save</Button>
                <span className={'m-2'}/>
                <Button name={'cancel'} color="red" onClick={() => closeModal('AssessmentModal')}
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>
    );
};
export default observer(AssessmentForm)