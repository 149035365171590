import React, {useContext, useEffect, useState} from 'react'

import {RootStoreContext} from "../../stores/RootStore";
import {observer} from "mobx-react-lite";
import {ComboBoxProps, OptionType} from "./ComboBox";
import Agent from "../../api/Agent";
import Autocomplete from "../Inputs/Autocomplete";
import Skeleton from "../generic/Skeleton";

interface OwnProps extends Omit<ComboBoxProps, 'options'> {
    entityStateId?: string
    canAddNew?: boolean
    additionalOptions?: OptionType[]

    onChange?(e?: any): any
}

type Props = OwnProps

const ConsumerAutoCompleteInput: React.FC<Props> = ({entityStateId, children, canAddNew = true, additionalOptions, ...rest}) => {
    // Mobx
    const rootStore = useContext(RootStoreContext);
    const {openModal} = rootStore.modalStore
    const {consumersList} = rootStore.consumerStore

    //Local State
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([] as OptionType[]);


    // Use Effect
    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams()
                params.append('limit', '10000000')
                params.append('offset', `0`)
                const {modelsDto} = await Agent.Consumers.list(params)

                setAutoCompleteOptions(() => {
                    const opts = modelsDto
                        // @ts-ignore
                        .filter(opt => opt.entityStateId === entityStateId || !entityStateId)
                        .map(opt => {
                            // @ts-ignore
                            return {
                                id: opt.id,
                                name: `${opt.firstName} ${opt.middleName ?? ''} ${opt.lastName}`.trim()
                            }
                        }).sort((optA, optB) => optA.name > optB.name ? 1 : -1)

                    if (additionalOptions && additionalOptions.length > 0)
                        opts.unshift(...additionalOptions)

                    if (canAddNew)
                        // @ts-ignore
                        opts.unshift({id: 0, name: '+ New Consumer'})


                    return opts
                })
            } catch (e) {

            }
        }

        fetchData()
    }, [consumersList, entityStateId]);

    // Functions
    const createNew = () => openModal('ConsumerModal', {header: 'New Consumer'})

    const handleChange = (e) => {
        e.id === 0 ? createNew() : !!rest.onChange && rest.onChange(e)
    }

    if (!autoCompleteOptions || autoCompleteOptions.length === 0)
        return <Skeleton/>

    return (
        <Autocomplete placeholder={'Marty McFly'} {...rest} options={autoCompleteOptions} label={'Consumer Name'}
                      onChange={handleChange}>
            {children}
        </Autocomplete>
    );
};
export default observer(ConsumerAutoCompleteInput);