import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import MaintenanceRepairForm, {
    MaintenanceRepairFormProps
} from "../../../pages/consumers/maintenanceRepair/components/MaintenanceRepair/MaintenanceRepairForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, MaintenanceRepairFormProps {
}

type Props = OwnProps;

// @ts-ignore
const MaintenanceRepairModal = NiceModal.create<Props>(({maintenanceRepair, header, onClose}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <MaintenanceRepairForm maintenanceRepair={maintenanceRepair}/>
        </SlideOver>
    )
})

export default MaintenanceRepairModal;
