import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {
    IReimbursementClaimAttachmentEnvelope,
    ReimbursementClaimAttachmentFormValues
} from "../../models/Reimbursement/ReimbursementClaimAttachment";

const ReimbursementClaimAttachments = {
    list: (params: URLSearchParams): Promise<IReimbursementClaimAttachmentEnvelope> =>
        axios
            .get("/ReimbursementClaim/Attachment", {params: params})
            .then(responseBody),
    create: (reimbursementClaimAttachment: ReimbursementClaimAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in reimbursementClaimAttachment) {
            formData.append(key, reimbursementClaimAttachment[key])
        }
        return api.post(`/ReimbursementClaim/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/ReimbursementClaim/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/ReimbursementClaim/Attachment/${id}`),
    byIdCombinePdf: (reimbursementClaimId: string) => api.get(`/ReimbursementClaim/${reimbursementClaimId}/Combine/`),
    byReimbursementClaimId: (reimbursementClaimId: string, params: URLSearchParams): Promise<IReimbursementClaimAttachmentEnvelope> =>
        axios.get(`/ReimbursementClaim/${reimbursementClaimId}/Attachment`, {params}).then(responseBody),
};

export default ReimbursementClaimAttachments