import axios from "axios";
import {ITravelAttachmentEnvelope, TravelAttachmentFormValues} from "../../models/Travel/TravelAttachment";
import {api, responseBody} from "./apiBase.service";

const TravelAttachments = {
    list: (params: URLSearchParams): Promise<ITravelAttachmentEnvelope> =>
        axios
            .get("/Travel/Attachment", {params: params})
            .then(responseBody),
    create: (travelAttachment: TravelAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in travelAttachment) {
            formData.append(key, travelAttachment[key])
        }
        return api.post(`/Travel/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/Travel/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/Travel/Attachment/${id}`),
    byTravelId: (travelId: string, params: URLSearchParams): Promise<ITravelAttachmentEnvelope> =>
        axios.get(`/Travel/${travelId}/Attachment`, {params}).then(responseBody)
};

export default TravelAttachments