import React, {FunctionComponent, useContext} from 'react';

import {IAttachment} from '../../models/generic/Attachment';
import {displayDate} from '../../lib/utilities';
import Title from '../generic/Title';
import AttachmentFilters from './AttachmentFilters';
import {observer} from 'mobx-react-lite';
import ClickableIcon from "../clickables/ClickableIcon";
import TableNewButton from "../table/TableNewButton";
import {RootStoreContext} from "../../stores/RootStore";
import ToolTip from "../generic/ToolTip";
import Table from '../table/Table';
import THead from '../table/THead';
import TR from '../table/TR';
import TBody from "../table/TBody";
import TD from "../table/TD";
import {EyeIcon, EyeSlashIcon, MagnifyingGlassIcon, TrashIcon} from "@heroicons/react/24/solid";
import Pagination from "../table/Pagination";
import {CloudArrowDownIcon} from "@heroicons/react/24/outline";
import {EnvelopeIcon} from "@heroicons/react/20/solid";


interface OwnProps {
    attachments: any[];
    attachmentCount: number;
    handleChangePage: (page: number, row: number) => void;
    openCreateModal: () => void;
    filterPredicate: (n, v) => void;
    getAttachmentById: (string) => Promise<any>;
    getDirectAttachmentById: (bool) => Promise<any>;
    disableCreateNew?: boolean;

    deleteAttachment?: undefined | ((id: string) => void);
}

type Props = OwnProps;

const AttachmentTable: FunctionComponent<Props> = ({
                                                       attachments,
                                                       attachmentCount,
                                                       handleChangePage,
                                                       openCreateModal,
                                                       filterPredicate,
                                                       getAttachmentById, getDirectAttachmentById,
                                                       deleteAttachment,
                                                       disableCreateNew
                                                   }) => {

    const rootStore = useContext(RootStoreContext);
    const {openModal} = rootStore.modalStore
    const {isFCC, isRLUser} = rootStore.oidcStore;

    const handleDownload = async (e) => {
        e.preventDefault()
        const {id} = e.currentTarget
        const {byteStream, name} = await getAttachmentById(id);
        let a = document.createElement("a");
        a.href = `data:application/octet-stream;base64,${byteStream}`;
        a.download = name;
        a.click();
    }
    const handleDirectDownload = async (e) => {
        e.preventDefault()
        const {id} = e.currentTarget
        const result = await getDirectAttachmentById(id);


        if(!!result){
            openModal('OkayModal', {
                body: 'Download is processing. You should receive an email with a direct download link shortly.',
            })
        }
        else{
            openModal('OkayModal', {
                body: 'Something went wrong.',
            })
        }


        //open modal
    }

    const handleDeleteAttachment = (e) => {
        e.preventDefault()
        const {id} = e.currentTarget
        if (!!deleteAttachment) {
            openModal('ConfirmationModal', {
                header: 'Delete Attachment?',
                body: 'Are your sure you want to delete this attachment?',
                onConfirm: () => deleteAttachment(id)
            })
        }
    }

    return (
        <div className={'overflow-x-auto'}>
            <Title subtitle size={'xl'} as={'h2'} className={'flex my-3'}><MagnifyingGlassIcon className={'h-6 w-6 mr-2'}/> Search
                Attachments</Title>
            <AttachmentFilters filterPredicate={filterPredicate}/>
            <Table>
                <THead>
                    <TR key={'header'}>
                        {(isRLUser || isFCC) && <TD/>}
                        <TD>File Name</TD>
                        <TD>Description</TD>
                        <TD>Created By</TD>
                        <TD>Date Created</TD>
                        <TD className={'text-right justify-end'}>
                            {!disableCreateNew &&
                            <TableNewButton name={'new'} onClick={openCreateModal} tooltip={'New Attachment'}/>}
                        </TD>
                    </TR>
                </THead>
                <TBody>
                    {attachments.map((attachment: IAttachment) => {
                        const {
                            id,
                            attachmentId,
                            name,
                            description,
                            createdByName,
                            createdOn,
                            internalOnly,
                        } = attachment;
                        return (
                            <TR key={id}>
                                {(isRLUser || isFCC) &&
                                <TD>
                                    <ToolTip direction={'right'}
                                             content={internalOnly ? 'internal only' : 'visible'}>
                                        {internalOnly ? <EyeSlashIcon className={'h-6 w-6 text-red-600'}/> :
                                            <EyeIcon className={'h-6 w-6 text-green-600'}/>}
                                    </ToolTip>
                                </TD>
                                }
                                <TD key={'name'}>{name}</TD>
                                <TD key={'description'}>{description}</TD>
                                <TD key={'updatedBY'}>{createdByName}</TD>
                                <TD key={'createdOn'}>{displayDate(createdOn, 'YYYY-MM-DD hh:mm A', false)}</TD>
                                <TD key={'btn'}><div className={'flex gap-4 justify-end'}>
                                    <ClickableIcon id={id} tooltip={'Download'}
                                                   onClick={handleDownload}><CloudArrowDownIcon
                                        className={'h-6 w-6'}/></ClickableIcon>
                                    {(isRLUser || isFCC) &&
                                        <ClickableIcon id={attachmentId} tooltip={'Direct Download'}
                                                       onClick={handleDirectDownload}><EnvelopeIcon
                                            className={'h-6 w-6'}/></ClickableIcon>
                                    }
                                    {!!deleteAttachment &&
                                    <ClickableIcon id={id} tooltip={'delete'} color={'red'}
                                                   onClick={handleDeleteAttachment}><TrashIcon
                                        className={'h-6 w-6'}/></ClickableIcon>}
                                </div></TD>
                            </TR>
                        )
                    })}
                </TBody>
            </Table>
            <Pagination totalRows={attachmentCount} onPageChange={handleChangePage} onRowChange={handleChangePage}/>
        </div>);
};

export default observer(AttachmentTable)
