import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import {
    IReimbursementClaimAttachment,
    ReimbursementClaimAttachmentFormValues
} from "../../models/Reimbursement/ReimbursementClaimAttachment";


export default class ReimbursementClaimAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.reimbursementClaimAttachmentRegistry.clear()
                this.loadReimbursementClaimAttachment()
            }
        )
        reaction(
            () => this.currentReimbursementClaimId,
            () => {
                this.page = 0
                this.reimbursementClaimAttachmentRegistry.clear()
                !!this.currentReimbursementClaimId ? this.getReimbursementClaimAttachmentsByReimbursementClaimId() : this.loadReimbursementClaimAttachment()
            }
        )
    }

    reimbursementClaimAttachmentRegistry = new Map()
    reimbursementClaimAttachment: IReimbursementClaimAttachment | null = null
    editMode = false


    currentReimbursementClaimId = '';
    reimbursementClaimAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentReimbursementClaimId = (reimbursementClaimId: string) => {
        this.currentReimbursementClaimId = reimbursementClaimId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.reimbursementClaimAttachmentCount / this.limit)
    }

    get reimbursementClaimAttachmentsList() {
        return Array.from(this.reimbursementClaimAttachmentRegistry.values())
    }

    getReimbursementClaimAttachment = (id: string) => {
        return this.reimbursementClaimAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.reimbursementClaimAttachmentRegistry.clear()
        !!this.currentReimbursementClaimId ? this.getReimbursementClaimAttachmentsByReimbursementClaimId() : this.loadReimbursementClaimAttachment()
    }

    clearReimbursementClaimAttachment = () => {
        this.reimbursementClaimAttachmentRegistry.clear()
    }

    loadReimbursementClaimAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            // if (!!this.currentReimbursementClaimId) {
                const {modelsDto, modelCount} = await Agent.ReimbursementClaimAttachments.list(this.axiosParams);

                runInAction(() => {
                    this.clearReimbursementClaimAttachment();
                    modelsDto.forEach((reimbursementClaimAttachment: IReimbursementClaimAttachment) => {
                        this.reimbursementClaimAttachmentRegistry.set(reimbursementClaimAttachment.id, reimbursementClaimAttachment)
                    })
                    this.reimbursementClaimAttachmentCount = modelCount
                })
            // }
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getReimbursementClaimAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let reimbursementClaimAttachment = await Agent.ReimbursementClaimAttachments.byId(id)
            runInAction(() => {
                this.reimbursementClaimAttachment = reimbursementClaimAttachment
                this.reimbursementClaimAttachmentRegistry.set(reimbursementClaimAttachment.id, reimbursementClaimAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return reimbursementClaimAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }
    getReimbursementClaimAttachmentByIdCombinePdf = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let reimbursementClaimAttachment = await Agent.ReimbursementClaimAttachments.byIdCombinePdf(id)
            runInAction(() => {
                this.reimbursementClaimAttachment = reimbursementClaimAttachment
                this.reimbursementClaimAttachmentRegistry.set(reimbursementClaimAttachment.id, reimbursementClaimAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return reimbursementClaimAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getReimbursementClaimAttachmentsByReimbursementClaimId = async (reimbursementClaimId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const {modelsDto, modelCount} = await Agent.ReimbursementClaimAttachments.byReimbursementClaimId(reimbursementClaimId || this.currentReimbursementClaimId, this.axiosParams)
            runInAction(() => {
                modelsDto.forEach(reimbursementClaimAttachment => {
                    this.reimbursementClaimAttachmentRegistry.set(reimbursementClaimAttachment.id, reimbursementClaimAttachment)
                })

                this.reimbursementClaimAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createReimbursementClaimAttachment = async (reimbursementClaimAttachment: ReimbursementClaimAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const newAttachment = await Agent.ReimbursementClaimAttachments.create(reimbursementClaimAttachment);
            await (!!this.currentReimbursementClaimId ? this.getReimbursementClaimAttachmentsByReimbursementClaimId() : this.loadReimbursementClaimAttachment())

            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('AttachmentModal')
            })

            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteReimbursementClaimAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.ReimbursementClaimAttachments.delete(id)
            runInAction(() => {
                this.reimbursementClaimAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('ReimbursementClaimAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }
    }

}