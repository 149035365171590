import React, {FunctionComponent} from 'react';
import Title from "../../components/generic/Title";

interface OwnProps {
}

type Props = OwnProps;

const termsOfService: FunctionComponent<Props> = (props) => {

    return (<div className={'max-w-5xl m-auto prose pt-8 pb-20'}>
        <Title as={'h1'} size={'3xl'}>Rolka Loube Terms of Use for https://ndbedp.rolkaloube.com</Title>

        <p className={'-mt-6'}>
            <time dateTime={'2023-08-08'}>Last updated August 8, 2023</time>
        </p>

        <Title as={'h2'} size={'2xl'}>AGREEMENT TO TERMS</Title>

        <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf
            of an entity (&quot;you&quot;) and Rolka Loube
            ("Administrator," &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), in our capacity of providing
            administrative support for the Federal Communications Commission’s (FCC) administration of the National Deaf
            Blind Equipment Distribution Program (&quot;NDBEDP&quot;), concerning your access to and use of the
            https://ndbedp.rolkaloube.com website as well as any other media form, media channel, mobile website or
            mobile application related, linked, or otherwise connected thereto (collectively, the &quot;Site&quot;). The
            Site is offered to entities in the United States who are certified by the FCC as a State’s sole authorized
            entity to participate in the NDBEDP (&quot;Entities&quot; or &quot;Entity&quot;) for the purpose of
            facilitating reimbursement for NDBEDP related expenses.</p>

        <p>You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms
            of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
            SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>

        <p>Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby
            expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
            modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by
            updating the &quot;Last updated&quot; date of these Terms of Use, and you waive any right to receive
            specific notice of each such change. It is your responsibility to periodically review these Terms of Use to
            stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have
            accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such
            revised Terms of Use are posted.</p>

        <p>The information provided on the Site is not intended for distribution to or use by any person or entity in
            any jurisdiction or country where such distribution or use would be contrary to law or regulation or which
            wouldx subject us to any registration requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on their own initiative and are solely
            responsible for compliance with local laws, if and to the extent local laws are applicable.</p>

        <Title as={'h2'} size={'2xl'}>INTELLECTUAL PROPERTY RIGHTS</Title>

        <p>Unless otherwise indicated, the Site is our proprietary property and all source code, databases,
            functionality, software, website designs, and other content on the Site (collectively,
            the &quot;Content&quot;) and the trademarks, service marks, and logos contained therein
            (the &quot;Marks&quot;) are owned or controlled by us or licensed to us, and are protected by copyright and
            trademark laws and various other intellectual property rights and unfair competition laws of the United
            States, foreign jurisdictions, and international conventions.</p>

        <p>The Site, its Content, and Marks are provided &quot;AS IS&quot; for your information and NDBEDP - related
            business use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or
            Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded,
            translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission.</p>

        <p>Provided that you are an Authorized User (see below), you are granted a limited license to access and use the
            Site and to download or print a copy of any portion of the Content to which you have properly gained access
            solely for your use in connection with your participation in the NDBEDP. We reserve all rights not expressly
            granted to you in and to the Site, the Content and the Marks.</p>

        <Title as={'h2'} size={'2xl'}>AUTHORIZED SITE USERS</Title>

        <p>Use of this Site is limited to authorized users. The &quot;Initial Authorized User&quot; for each Entity is
            the individual initially identified as the Entity administrator at launch date of the site. The Initial
            Authorized User may designate Additional Authorized Users and is solely responsible for any improper
            designations. The Initial Authorized User is responsible for any actions or omissions of his or her own and
            his or her designated Additional Authorized Users. Administrator has no responsibility for any harm or
            damages arising out of the use of this Site caused by any Authorized or unauthorized user.</p>

        <p>Authorized Users of the Site are provided emails inviting them to the Site and the need to log on and update
            the User’s first name, last name, email address, and password.</p>

        <Title as={'h2'} size={'2xl'}>USER REPRESENTATIONS</Title>

        <p>By using the Site, you represent and warrant that: (1) you are an Initial Authorized User or an Additional
            Authorized User; (2) all Site-User registration information; all information on the recipients of NDBEDP
            equipment (&quot;Recipient(s)&quot;); and information on professionals who attest to the eligibility of
            Recipients for the equipment (&quot;Attestor(s)&quot;) that you submit will be true; accurate; current; and
            complete; 3) you have obtained consent to submit Site-User registration; Recipient; and Attestor information
            to us and your submission does not violate the privacy rights or any other rights of any person; (4) you
            grant us the right and license to use and distribute the Site-User registration; Recipient; and Attestor
            information you submit to carry out our responsibilities in support of the FCC’s administration of the
            NDBEDP; (5) you will access the Site only from an IP address located in the United States or its territories;
            (6) you will maintain the accuracy of all submitted information; and promptly update such information as
            necessary; (7) you have the legal capacity and you agree to comply with these Terms of Use; (8) you are not
            a minor in the jurisdiction in which you reside; (9) you will not access the Site through automated or
            non-human means; whether through a bot; script; or otherwise unless designated and approved by the Site
            administrator; (10) you will not use the Site for any illegal or unauthorized purpose; and (11) your use of
            the Site will not violate any applicable law or regulation.</p>

        <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
            suspend or terminate your account and refuse any and all current or future use of the Site (or any portion
            thereof).</p>

        <Title as={'h2'} size={'2xl'}>USER REGISTRATION</Title>

        <p>Only Authorized Users may access the Site and may do so only from an IP address in the United States or its
            territories. Your username for the Site is the email for the individual initially identified as the Entity
            administrator at launch date of the site (for Initial Authorized Users) or one provided by an Initial
            Authorized User (for Additional Authorized Users). You will need to create a password to access the Site.
            You agree to keep your password confidential and will be responsible for all use of your account and
            password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our
            sole discretion, that such username is inappropriate, obscene, or otherwise objectionable. You should not
            share your Site access or password detail with anyone else. You are responsible for maintaining the
            confidentiality and security of your account and for all activities that occur on or through it. You agree
            to notify the Administrator if you become aware of a security incident or breach affecting your account,
            including where you believe your password may have been compromised. Administrator is not responsible for
            any lost, stolen, or compromised password for any activity on your account from unauthorized users or for
            any losses arising out of or in connection with the unauthorized use of the account were caused by you.</p>

        <Title as={'h2'} size={'2xl'}>PROHIBITED ACTIVITIES</Title>

        <p>You may not access or use the Site for any purpose other than that for which we make the Site available. The
            Site may not be used in connection with any commercial endeavors except those related to your obligations to
            the NDBEDP or those that are specifically endorsed or approved by us. As a user of the Site, you agree not
            to:</p>

        <ol className={'list-decimal ml-4'}>
            <li>Systematically retrieve data or other content from the Site to create or compile, directly or
                indirectly, a collection, compilation, database, or directory without written permission from us.
            </li>
            <li>Circumvent, disable, or otherwise interfere with security-related features of the Site, including
                features that prevent or restrict the use or copying of any Content or enforce limitations on the use of
                the Site and/or the Content contained therein.
            </li>
            <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
                information such as user passwords.
            </li>
            <li>Engage in unauthorized framing of or linking to the Site.</li>
            <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
            <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using
                any data mining, robots, or similar data gathering and extraction tools other than that which has been
                designated and approved by the Site administrator.
            </li>
            <li>Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to
                the
                Site.
            </li>
            <li>Attempt to impersonate another user or person or use the username of another user.</li>
            <li>Use any information obtained from the Site in order to harass, abuse, or harm another person.</li>
            <li>Use the Site as part of any effort to compete with us or other Entities or otherwise use the Site and/or
                the Content for any revenue-generating endeavor or commercial enterprise other than for receiving
                reimbursement from the NDBEDP.
            </li>
            <li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way
                making up a part of the Site.
            </li>
            <li>Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any
                portion of the Site.
            </li>
            <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion
                of the Site to you.
            </li>
            <li>Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other
                code.
            </li>
            <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
            <li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
            <li>Access or attempt to access the Site from an IP address outside of the United States or its
                territories.
            </li>
        </ol>

        <Title as={'h2'} size={'2xl'}>SUBMISSIONS</Title>

        <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information
            regarding the Site ("Submissions") provided by you to us are non-confidential and shall become our sole
            property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled
            to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or
            otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are original with you or that you have the
            right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual
            infringement or misappropriation of any proprietary right in your Submissions.</p>

        <Title as={'h2'} size={'2xl'}>THIRD-PARTY WEBSITES AND CONTENT</Title>

        <p>The Site may contain (or you may be sent via the Site) links to other websites ("Third-Party Websites") We
            are not responsible for any Third-Party Websites accessed through the Site. Inclusion of, linking to, or
            permitting the use or installation of any Third-Party Websites does not imply approval or endorsement
            thereof by us. If you decide to leave the Site and access the Third-Party Websites you should be aware these
            Terms of Use no longer govern. You should review the applicable terms and policies, including privacy and
            data gathering practices, of any website to which you navigate from the Site. Any transactions you make
            through Third-Party Websites will be through other websites and from other entities, and we take no
            responsibility whatsoever in relation to such transactions which are exclusively between you and the
            applicable third party. You agree to hold us harmless from any harm caused by your use of such Third Party
            Websites.</p>

        <Title as={'h2'} size={'2xl'}>SITE MANAGEMENT</Title>

        <p>We reserve the right, but have no obligation, to: <em>(1)</em> monitor the Site for violations of these Terms
            of Use; <em>(2)</em> take appropriate legal action against anyone who, in our sole discretion, violates the
            law or these Terms of Use, including without limitation, reporting such user to law enforcement
            authorities; <em>(3)</em> in our sole discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically feasible) any of your Site Activity,
            including making Contributions or any portion thereof; <em>(4)</em> in our sole discretion and without
            limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that
            are excessive in size or are in any way burdensome to our systems; <em>(5)</em> disable access to the Site
            from IP addresses located outside of the United States and its territories; and <em>(6)</em> otherwise
            manage the Site in a manner designed to protect our rights and property and to facilitate the proper
            functioning of the Site.</p>

        <Title as={'h2'} size={'2xl'}>PRIVACY POLICY</Title>

        <p>We care about data privacy and security. The Site does not collect any Personal Identifiable Information
            (PII) other than the email address of Authorized Users. The Site does collect or generate Business
            Information (as defined in our Privacy Policy) which includes Personal Identifiable Information (PII) of
            Recipients and the names and Business Information of Attestors . That information may be shared only with
            the Federal Communications Commission (FCC) or its designees. For further detail, please review our Privacy
            Policy: https//ndbedp.rolkaloube.com/privacy. By using the Site, you agree to be bound by our Privacy
            Policy, which is incorporated into these Terms of Use. Please be advised the Site is hosted in the United
            States. While accessing the Site from locations outside the United States or its territories is prohibited
            and we may disable your ability to do so, if, nevertheless, you obtain unauthorized access of the Site from
            any other locations then, through your continued use of the Site, you are transferring your data to the
            United States, and you expressly consent to have your data transferred to and processed in the United
            States.</p>

        <Title as={'h2'} size={'2xl'}>TERM AND TERMINATION</Title>

        <p className={'text-justify'}>These Terms of Use shall remain in full force and effect while you use the Site.
            WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION
            AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP
            ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
            REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
            INFORMATION CONTAINED THEREIN AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>

        <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a
            new account under your name, a fake or borrowed name, or the name of any third party, even if you may be
            acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the
            right to take appropriate legal action, including without limitation pursuing civil, criminal, and
            injunctive redress.</p>

        <Title as={'h2'} size={'2xl'}>MODIFICATIONS AND INTERRUPTIONS</Title>

        <p>We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at
            our sole discretion without notice. However, we have no obligation to update any information on our Site. We
            also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will
            not be liable to you or any third party for any modification, suspension, or discontinuance of the Site.</p>

        <p>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other
            problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors.
            We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any
            time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss,
            damage, or inconvenience caused by your inability to access or use the Site during any downtime or
            discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and
            support the Site or to supply any corrections, updates, or releases in connection therewith.</p>

        <Title as={'h2'} size={'2xl'}>GOVERNING LAW</Title>

        <p>These Terms of Use and your use of the Site are governed by and construed in accordance with the laws of the
            Commonwealth of Pennsylvania applicable to agreements made and to be entirely performed within the
            Commonwealth of Pennsylvania, without regard to its conflict of law principles.</p>

        <Title as={'h2'} size={'2xl'}>DISPUTE RESOLUTION</Title>

        <p>Binding Arbitration. If the Parties are unable to resolve a Dispute through informal negotiations, the
            Dispute (except those Disputes expressly excluded below) will be finally and exclusively resolved by binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A
            JURY TRIAL. The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the
            American Arbitration Association ("AAA") and, where appropriate, the AAA’s Supplementary Procedures for
            Consumer Related Disputes ("AAA Consumer Rules"), both of which are available at the AAA website
            www.adr.org. Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA
            Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. The arbitration may be conducted
            in person, through the submission of documents, by phone, or online. The arbitrator will make a decision in
            writing but need not provide a statement of reasons unless requested by either Party. The arbitrator must
            follow applicable law, and any award may be challenged if the arbitrator fails to do so. Except where
            otherwise required by the applicable AAA rules or applicable law, the arbitration will take place in Dauphin
            County, Pennsylvania. Except as otherwise provided herein, the Parties may litigate in court to compel
            arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the
            award entered by the arbitrator. If for any reason, a Dispute proceeds in court rather than arbitration, the
            Dispute shall be commenced or prosecuted in the state and federal courts located in Dauphin County,
            Pennsylvania, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction,
            and forum non conveniens with respect to venue and jurisdiction in such state and federal courts.
            Application of the Uniform Computer Information Transaction Act (UCITA) are excluded from these Terms of
            Use. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to be illegal or unenforceable and such
            Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction
            above, and the Parties agree to submit to the personal jurisdiction of that court.</p>

        <p>Restrictions. The Parties agree that any arbitration shall be limited to the Dispute between the Parties
            individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or
            to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in
            a purported representative capacity on behalf of the general public or any other persons.</p>

        <p>Exceptions to Arbitration. The Parties agree that the following Disputes are not subject to the above
            provisions concerning binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the
            validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising
            from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for
            injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect
            to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable
            and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.</p>

        <Title as={'h2'} size={'2xl'}>CORRECTIONS</Title>

        <p>There may be information on the Site that contains typographical errors, inaccuracies, or omissions,
            including descriptions, rates, availability, and various other information. We reserve the right to correct
            any errors, inaccuracies, or omissions and to change or update the information on the Site at any time,
            without prior notice.</p>

        <Title as={'h2'} size={'2xl'}>DISCLAIMER</Title>

        <p className={'text-justify'}>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE
            OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM
            ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT
            LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S
            CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY
            FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
            DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED
            ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS,
            VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
            INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED
            OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
            FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
            MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
            PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT
            AND EXERCISE CAUTION WHERE APPROPRIATE.</p>

        <Title as={'h2'} size={'2xl'}>LIMITATIONS OF LIABILITY</Title>

        <p className={'text-justify'}>IN NO EVENT WILL WE OR OUR OWNERS, MANAGER, MEMBERS, DIRECTORS, EMPLOYEES, OR
            AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
            SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING
            FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
            ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF
            THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN STATE
            LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
            THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU
            MAY HAVE ADDITIONAL RIGHTS.</p>

        <Title as={'h2'} size={'2xl'}>INDEMNIFICATION</Title>

        <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
            respective owners, managers, members, officers, agents, partners, and employees, from and against any loss,
            damage,
            liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due
            to or arising out of: (1) your use of the Site; (2) breach of these Terms of Use; (3) any breach of your
            representations and warranties set forth in these Terms of Use; (4) your violation of the rights of a third
            party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any
            other user of the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the
            right, at your expense, to assume the exclusive defense and control of any matter for which you are required
            to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use
            reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this
            indemnification upon becoming aware of it.</p>

        <Title as={'h2'} size={'2xl'}>USER DATA</Title>

        <p>We or third-party services that we use may maintain certain data that you transmit to the Site for the
            purpose of managing the performance of the Site, as well as data relating to your use of the Site. Although
            we perform regular routine backups of data, you are solely responsible for all data that you transmit or
            that relates to any activity you have undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby waive any right of action against us
            arising from any such loss or corruption of such data.</p>

        <Title as={'h2'} size={'2xl'}>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</Title>

        <p>Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You
            consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and
            other communications we provide to you electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
            CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
            TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under
            any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original
            signature or delivery or retention of non-electronic records, or to payments or the granting of credits by
            any means other than electronic means. You acknowledge that not all electronic communications are encrypted
            or otherwise secured. You agree that Administrator is not responsible for the unauthorized release of any
            communications you send that are not encrypted or otherwise secured.</p>

        <Title as={'h2'} size={'2xl'}>MISCELLANEOUS</Title>

        <p>These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site
            constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any
            right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and
            obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or
            failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of
            these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the
            provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership, employment or agency relationship created
            between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use
            will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you
            may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to
            execute these Terms of Use.</p>

        <Title as={'h2'} size={'2xl'}>CONTACT US</Title>

        <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the
            Site,
            please contact us at: Phone: <a href={'tel:+!7175856605'}>(717) 585-6605</a>
            <br/>
            Email: <a
                href={'mailto:ndbedp@rolkaloube.com'}>ndbedp@rolkaloube.com</a></p>

        <address>Rolka Loube <br/>4050 Crums Mill Road <br/>Suite 303 <br/>Harrisburg, PA 17112 United States</address>
    </div>);
};

export default termsOfService;
