import React, {FunctionComponent} from 'react';
import Title from "../../components/generic/Title";
import HomeLink from "../../components/generic/HomeLink";

interface OwnProps {
}

type Props = OwnProps;

const privacyPolicy: FunctionComponent<Props> = (props) => {

    return (<div className={'max-w-5xl m-auto prose pt-8 pb-20'}>
            <HomeLink/>
            <Title as={'h1'}>Rolka Loube Privacy Policy for ndbedp.rolkaloube.com</Title>

            <p className={'-mt-6'}>
                <time dateTime={'2023-08-02'}>Last updated August 2, 2023</time>
            </p>

            <Title as={'h2'} size={'2xl'}>GENERAL STATEMENT OF POLICY</Title>

            <p>Rolka Loube Saltzer LLC ("Rolka Loube", "Administrator", "we", "us", or "our") is a private-sector
                corporation that administers programs and services for government entities. This policy applies to
                our provision of administrative support for the Federal Communications Commission’s (FCC)
                administration of the National Deaf Blind Equipment Distribution Program (“NDBEDP”). This policy
                explains:</p>

            <ul className={'list-disc'}>
                <li>What information we might collect about you (the Site user)</li>
                <li>How we might use that information</li>
                <li>What information of yours we might share with others</li>
                <li>Your choices about the personal information you give us.</li>
            </ul>

            <Title as={'h2'} size={'2xl'}>What Information We Collect</Title>

            <p>The <a href={'https://ndbedp.rolkaloube.com'}>https://ndbedp.rolkaloube.com</a> site ("Site") is offered
                to entities in the United States who are
                certified by the FCC as a State’s sole authorized entity to participate in the NDBEDP (“Entities”
                or “Entity”) for the purpose of facilitating reimbursement for NDBEDP related expenses. Other than
                collecting and using your first and last name, our Site does not collect, use, or disclose
                Personal Data. Other than your name, this Site collects and uses only "Business Information" (see
                definition below). We may also collect "Usage Data" and use "Cookies." We use your first and last
                name, the Business Information, Usage Data, and Cookies to facilitate your Entity’s reimbursement
                for NDBEDP-related expenses. By using the Site, you agree to the collection and use of your first
                and last name, the Business Information, Usage Data, and Cookies in accordance with this policy.
                Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms of Use, accessible from <a
                    href={'https://ndbedp.rolkaloube.com'}>https://ndbedp.rolkaloube.com</a>.</p>

            <div className={'ml-6'}>
                <Title as={'h3'} size={'xl'}>Use of Cookies</Title>

                <p>We may use cookies and similar tracking technologies to track the activity on our Site and we
                    may hold
                    certain information. You can instruct your browser to refuse all Cookies or to indicate
                    when a
                    Cookie is being sent. However, if you do not accept Cookies, you may not be able to use
                    some
                    portions of our Service. Examples of Cookies we use include, but are not limited to:</p>

                <ul className={'list-disc'}>
                    <li>Session Cookies - used to operate our service</li>
                    <li>Preference Cookies - used to remember your preferences and various settings</li>
                    <li>Security Cookies - used for security purposes</li>
                </ul>

                <Title as={'h3'} size={'xl'}>Usage Data</Title>

                <p>Rolka Loube may employ Usage Data to provide analysis or valuable information to improve the
                    Site,
                    monitor usage of the Site, and detect, prevent, and address technical issues.</p>
            </div>

            <Title as={'h2'} size={'2xl'}>Who We Share Information With</Title>

            <p>Your Information may be shared with the FCC or their designees. It may also be shared with Service
                Providers but only for the purpose of performing Site-related tasks on our behalf. Service
                Providers are obligated not to disclose or use it for any other purpose. Aggregated,
                non-Entity-identifiable Information may be used in publicly available material. Otherwise, your
                information is not shared.</p>

            <p>If you access the Site through the general Rolka Loube corporate website, your information might also
                be shared with Google Analytics. Rolka Loube uses Google Analytics to track web visitors to our
                corporate website. Google Analytics uses JavaScript to send information back to Google's servers
                to track activity. As per Google Analytics' Terms of Service, Google and its wholly owned
                subsidiaries may access and make use of the aggregate usage information collected by Google
                Analytics. If you would like to opt-out of Google Analytics on our website, please see: Opt-out
                browser add-on <a
                    href={'https://tools.google.com/dlpage/gaoptout'}>https://tools.google.com/dlpage/gaoptout</a>.</p>

            <Title as={'h2'} size={'2xl'}>Use of Information</Title>

            <p>The Site uses your Information for several purposes, including but not limited to:</p>

            <ul className={'list-disc'}>
                <li>Facilitating reimbursements in accordance with the administration of the NDBEDP</li>
                <li>Providing and maintaining the Site</li>
                <li>Notifying you about changes to our Site</li>
                <li>Allowing you to participate in interactive features of our Site, should you choose</li>
                <li>Providing customer care and support</li>
            </ul>

            <Title as={'h2'} size={'2xl'}>Security of Information</Title>

            <p>The security of your information is important to us but remember that no method of transmission over
                the Internet or method of electronic storage is 100% secure. While we strive to use commercially
                acceptable means to protect your information, we cannot guarantee its absolute security.</p>

            <Title as={'h2'} size={'2xl'}>Links to Other Sites</Title>

            <p>Our Site may contain links to other sites that are not operated by us. If you click a third-party link,
                then you will be directed to that third party's site and subject to their Privacy Policy. We strongly
                advise you to review the Privacy Policy of every site you visit. Rolka Loube has no control over and
                assumes no responsibility for the content, privacy policies, or practices of any third-party sites or
                services.</p>

            <Title as={'h2'} size={'2xl'}>Changes to this Privacy Policy</Title>

            <p>Rolka Loube reserves the right to modify this Privacy Policy without written notice. You are advised to
                review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.</p>

            <Title as={'h2'} size={'2xl'}>Transfer of Data</Title>

            <p>Your Information is maintained on computers and servers located in the United States. And, as stated in
                the Terms of Use, accessing the Site from outside the United States or its territories is
                prohibited and may result in use of the Site being disabled. If, nevertheless, you obtain
                unauthorized access of the Site from any other locations then, through your continued use of the
                Site, you are transferring your data to the United States, and you expressly consent to have your
                data transferred to and processed in the United States.</p>

            <Title as={'h2'} size={'2xl'}>Extraordinary Circumstances That May Result in Disclosure of
                Information</Title>

            <p>Extraordinary circumstances may arise that would dictate that Rolka Loube disclose your information.
                Such circumstances include, but are not limited to, Rolka Loube's good faith belief that the
                disclosure is necessary to:</p>

            <ul className={'list-disc'}>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of Rolka Loube or the Site</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Site</li>
                <li>Protect the personal safety of users of the site or the public</li>
                <li>Protect against legal liability</li>
            </ul>

            <Title as={'h2'} size={'2xl'}>California, Colorado, Connecticut, Utah, and Virginia Residents</Title>

            <p>If you are a resident of California, Colorado, Connecticut, Utah, or Virginia your state has passed
                legislation that provides consumers with specific rights regarding their personal information.
                This section describes your rights and explains how you can exercise those rights.</p>

            <div className={'ml-6'}>
                <Title as={'h3'} size={'xl'}>Access to specific information and data portability rights, Data
                    Portability and Deletion
                    Rights</Title>

                <p>You have the right to request that Rolka Loube disclose certain information to you about our
                    collection and use of your personal information.. You have the right to request that Rolka
                    Loube
                    delete or correct data that has been collected. To exercise the access, data
                    portability, and deletion rights described above, please submit a verifiable consumer request by
                    either:</p>

                <ul className={'list-disc'}>
                    <li>Calling us at: <a href={'tel:+17175856605'}>(717) 585-6605</a></li>
                    <li>Emailing us at: <a href={'mailto:ndbedp@rolkaloube.com'}>ndbedp@rolkaloube.com</a></li>
                </ul>
            </div>

            <Title as={'h2'} size={'2xl'}>Definitions</Title>

            <p className={'inline-block -indent-8 pl-8'}>
                <dt className={'font-medium whitespace-nowrap inline'}>Business Information</dt>
                <span className="mx-2">&mdash;</span>
                <dd className={'inline'}>information collected from you or other
                    sources (such as the FCC, other Entities, or generated by us and includes such items as your
                    business
                    email address, information identifying the Entity(s) for which you are an Authorized User, Personal
                    Identifying Information about recipients of NDBEDP equipment (“Recipients”), information about
                    individuals
                    or organizations that attest to the eligibility of Recipients (“Attestors”), data and supporting
                    documentation for requested reimbursed costs, National Outreach costs, and audit reports.
                </dd>
            </p>

            <p className={'inline-block -indent-8 pl-8'}>
                <dt className={'font-medium whitespace-nowrap inline'}>Cookies</dt>
                <span className="mx-2">&mdash;</span>
                <dd className={'inline'}>small files, stored on your device, which may include
                    an anonymous unique identifier. Cookies are sent to your browser from the site and stored on your
                    device.
                    The term "Cookies," as used herein, includes other tracking technologies, which may also be used,
                    such
                    as
                    beacons, tags, and scripts, to collect and track information and to improve and analyze our Service.
                </dd>
            </p>

            <p className={'inline-block -indent-8 pl-8'}>
                <dt className={'font-medium whitespace-nowrap inline'}>Information</dt>
                <span className="mx-2">&mdash;</span>
                <dd className={'inline'}>the information we collect (first and last name,
                    and Business Information, Cookie, and Usage Data), which are collectively referred to herein as the
                    Information.
                </dd>
            </p>

            <p className={'inline-block -indent-8 pl-8'}>
                <dt className={'font-medium whitespace-nowrap inline'}>Personal Data</dt>
                <span className="mx-2">&mdash;</span>
                <dd className={'inline'}>any information which could be used to identify
                    a living person.
                </dd>
            </p>

            <p className={'inline-block -indent-8 pl-8'}>
                <dt className={'font-medium whitespace-nowrap inline'}>Service Provider</dt>
                <span className="mx-2">&mdash;</span>
                <dd className={'inline'}>third-party companies and individuals that we
                    may use to provide the Site on our behalf, to perform Site-related services, or to assist us in
                    analyzing
                    how our Site is used.
                </dd>
            </p>

            <p className={'inline-block -indent-8 pl-8'}>
                <dt className={'font-medium whitespace-nowrap inline'}>Usage Data</dt>
                <span className="mx-2">&mdash;</span>
                <dd className={'inline'}>data collected automatically generated from the use
                    of the Site itself. Examples of Usage Data we may collect are your computer's Internet Protocol
                    address
                    (IP address), browser type, browser version, the pages of our Site that you visit, the time and date
                    of
                    your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                </dd>
            </p>

            <Title as={'h2'} size={'2xl'}>CONTACT US</Title>

            <p>If you have any questions about this Privacy Policy, please contact us:</p>

            <address>RolkaLoube 4050 Crums Mill Rd. Suite 303 Harrisburg, PA 17112</address>

            <p>United States Phone: <br/><a href={'tel:+7175856605'}>(717) 585-6605</a><br/><a
                href={'mailto:ndbedp@rolkaloube.com'}>ndbedp@rolkaloube.com</a></p>

            <HomeLink/>

        </div>
    );
};

export default privacyPolicy;
