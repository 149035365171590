import React, {FunctionComponent, useContext} from 'react';
import SidebarItem from "./SidebarItem";
import {ReactComponent as Logo} from '../../assets/img/RL_Monogram.svg'
import {Link, useLocation} from 'react-router-dom'
import SidebarDropDown from "./SidebarDropDown";
import {RootStoreContext} from "../../stores/RootStore";
import {observer} from "mobx-react-lite";
import navigationItems from './navigation-items';

interface OwnProps {
}

type Props = OwnProps;

const SideBar: FunctionComponent<Props> = () => {
    const rootStore = useContext(RootStoreContext);
    const { hasRole, isTenantOf} = rootStore.oidcStore;
    const { pathname } = useLocation()

    return (
        <div id={'main-navigation'}
            className="flex flex-col flex-grow border-r border-gray-200 pt-2 pb-2 bg-gray-900 overflow-y-auto h-screen min-w-fit whitespace-nowrap w-80">
            <div className="flex items-center flex-shrink-0 px-4 py-2">
                <Link to={'/'} className={'text-white text-4xl flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-4 focus:ring-offset-gray-900 rounded-lg'}>
                    <Logo className={'h-6 w-6 mr-2'}/>
                    NDBEDP
                </Link>
            </div>
            <div className="mt-2 flex-grow flex flex-col">
                <nav className="grow px-2 space-y-1 bg-gray-900" aria-label="Sidebar">
                    {/* We need to inject the auth function here so that the items can compute visibility */}
                    {/*// @ts-ignore*/}
                    {navigationItems({hasRole, isTenantOf}).map((item) =>{
                        return !item.children ?
                            (item.visible &&
                                <SidebarItem key={item.link + item.icon} icon={item.icon} to={item.link}
                                             as={Link} current={pathname === item.link}>{item.content}</SidebarItem>) :
                            (item.visible &&
                                <SidebarDropDown key={item.link + item.icon} link={item.link} content={item.content}
                                                 icon={item.icon} items={item.children}/>)}
                    )}
                </nav>
            </div>
            <div className="flex-shrink-0 border-t border-gray-800 py-2 px-4 text-white text-center test-sm">
                &copy; Rolka Loube {new Date().getFullYear()}<br/>
                <span className={'text-xs'}>NDBEDP v{process.env.REACT_APP_VERSION}</span>
            </div>
        </div>
    )
};

export default observer(SideBar);
