import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import NationalOutreachBudgetForm, {
    NationalOutreachBudgetFormProps
} from "../../../pages/nationalOutreachBudget/components/NationalOutreachBudgetForm";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, NationalOutreachBudgetFormProps {
}

type Props = OwnProps;

// @ts-ignore
const NationalOutreachBudgetModal = NiceModal.create<Props>(({nationalOutreach, header, onClose, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <NationalOutreachBudgetForm nationalOutreach={nationalOutreach} {...rest}/>
        </SlideOver>
    )
})

export default NationalOutreachBudgetModal;
