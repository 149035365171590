import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import {ITechnician, TechnicianFormValues} from "../../../../models/Technician";
import EntityAutoCompleteInput from "../../../../components/form/EntityAutoCompleteInput";
import Input from "../../../../components/Inputs/Input";
import Button from "../../../../components/generic/Button";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface TechnicianFormProps {
    technician: ITechnician | undefined;
    edit?: boolean
}

type Props = TechnicianFormProps

const TechnicianForm: React.FC<Props> = ({technician, edit = false}) => {
    // Stores
    const rootStore = useContext(RootStoreContext);
    const {createTechnician, editTechnician} = rootStore.techniciansStore;
    const {closeModal} = rootStore.modalStore;
    const {isLoading} = rootStore.commonStore


    // Local State
    const [currentTechnician, setCurrentTechnician] = useState(new TechnicianFormValues(technician));


    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);


    // Use Effects
    useEffect(() => {
        if (!currentTechnician.id && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentTechnician((prev: TechnicianFormValues) => {
                return {
                    ...prev,
                    entityId: localEntityStates[0].entityId || '',
                    entityName: localEntityStates[0].entityId ? `${localEntityStates[0].entityName}` : ''
                }
            })
        }
    }, [localEntityStates])


    // Functions
    const handleSubmit = async (e) => {
        e.preventDefault()
        const {...Technician} = currentTechnician
        if (!Technician.id) {
            let newTechnician = {
                ...Technician
            };
            // @ts-ignore
            delete newTechnician.id
            await createTechnician(newTechnician);
        } else {
            await editTechnician(Technician);
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.currentTarget
        let tmpTechnician = {...currentTechnician}
        tmpTechnician[name] = value
        setCurrentTechnician(tmpTechnician)
    }

    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    return (
        <form onSubmit={handleSubmit} className={'flex flex-1 flex-col h-full'} autoComplete={'off'}>
            <div className={'overflow-y-auto overflow-x-hidden flex-1'}>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-1">
                    <Input
                        onChange={handleChange}
                        name='firstName'
                        label='First Name'
                        value={currentTechnician.firstName}
                        placeholder={'Emmett'}
                        required
                    />
                    <Input
                        onChange={handleChange}
                        name='middleName'
                        label='Middle Name'
                        value={currentTechnician.middleName}
                        placeholder={'Lathrop'}
                    />
                    <Input
                        onChange={handleChange}
                        name='lastName'
                        label='Last Name'
                        value={currentTechnician.lastName}
                        placeholder={'Brown'}
                        required
                    />
                </div>


                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                    <Input
                        onChange={handleChange}
                        name='company'
                        label='Company'
                        value={currentTechnician.company}
                        placeholder={'Delorean Time Travel Agency'}
                    />
                    <EntityAutoCompleteInput
                        required
                        name={'entityId'}
                        isLocked={!currentTechnician.id && !!localEntityStates && localEntityStates.length === 1}
                        onChange={({id}) => handleAutoComplete(id, 'entityId')}
                        displayValue={currentTechnician?.entityId ? (currentTechnician?.entityName || 'Entity already selected') : ''}
                        placeholder={'The Institute for Time Travel'}
                        disabled={edit}
                    />
                </div>
            </div>


            <div className="flex gap-4 justify-end">
                <Button disabled={isLoading || isLoading} type='submit' iconLeft={<CheckIcon className={'h-5 w-5'}/>}
                        color={'green'}>Save</Button>
                <Button name={'cancel'}
                        onClick={() => closeModal('TechnicianModal')}
                        disabled={isLoading || isLoading}
                        color="red" iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>
                    Cancel
                </Button>
            </div>
        </form>
    );
};
export default observer(TechnicianForm);