import {RootStore} from "../RootStore";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import agent from "../../api/Agent";
import Agent from "../../api/Agent";
import {toast} from "react-toastify";
import {INotification, NotificationFormValues} from "../../models/Notification/Notification";

export default class NotificationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.notificationRegistry.clear()
                this.loadNotifications()
            }
        )
    }

    page = 0;
    limit = 5;
    notificationCount = 0;

    predicate = new Map();
    notificationRegistry = new Map();

    setPredicate = (predicate?: string, value?: string | boolean | Date) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            if (this.predicate.get(predicate) === value) {
                return
            }
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    setPredicates = (predicates: {}) => {
        this.notificationRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object'){
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get notificationList() {
        return Array.from(this.notificationRegistry.values())
    }

    get pageCount() {
        return Math.ceil(this.notificationCount / this.limit)
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page doesn't load the prev records
        this.notificationRegistry.clear()
        this.loadNotifications()
    }

    clearNotifications = async () => {
        this.notificationRegistry.clear()
    }
    setPage = (page: number) => {
        this.page = page
    }
    setLimit = (limit: number) => {
        this.limit = limit
    }

    createNotification = async (notification: NotificationFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await agent.Notification.create(notification)
            await this.loadNotifications()
            toast.success('Saved')
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('NotificationModal')
            })
        } catch (error) {
            toast.error('Problem creating notification')
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteNotification = async (notification: INotification) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.Notification.delete(notification.id ?? '')
            runInAction(() => {
                this.notificationRegistry.delete(notification.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.error('Problem submitting data')
        }
    }

    loadNotifications = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const notificationEnvelope = await agent.Notification.list(this.axiosParams)
            let {modelsDto, modelCount} = notificationEnvelope;
            this.clearNotifications()
            runInAction(() => {
                modelsDto.forEach((notification) => {
                    this.notificationRegistry.set(notification.id, notification)
                })
                this.notificationCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error);
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId);
        }
    }
}