import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {
    IMaintenanceRepairAttachment,
    MaintenanceRepairAttachmentFormValues
} from '../../models/MaintenanceRepair/MaintenanceRepairAttachment'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import dayjs from 'dayjs'


export default class MaintenanceRepairAttachmentStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.maintenanceRepairAttachmentRegistry.clear()
                this.loadMaintenanceRepairAttachment()
            }
        )

        reaction(
            () => this.currentMaintenanceRepairId,
            () => {
                this.page = 0
                this.maintenanceRepairAttachmentRegistry.clear()
                !!this.currentMaintenanceRepairId ? this.getMaintenanceRepairAttachmentsByMaintenanceRepairId() : this.loadMaintenanceRepairAttachment()
            }
        )
    }

    maintenanceRepairAttachmentRegistry = new Map()
    maintenanceRepairAttachment: IMaintenanceRepairAttachment | null = null
    editMode = false


    currentMaintenanceRepairId = '';
    maintenanceRepairAttachmentCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setCurrentMaintenanceRepairId = (maintenanceRepairId: string) => {
        this.currentMaintenanceRepairId = maintenanceRepairId;
    }

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!!value)
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : value)
        }
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!!value) params.append(key, value)
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.maintenanceRepairAttachmentCount / this.limit)
    }

    get maintenanceRepairAttachmentsById() {
        return Array.from(this.maintenanceRepairAttachmentRegistry.values()).sort((a, b) => b.id - a.id)
    }

    get maintenanceRepairAttachmentsByDate() {
        return Array.from(this.maintenanceRepairAttachmentRegistry.values()).sort(
            (a, b) => dayjs(b.createdOn).isBefore(a.createdOn) ? -1 : 1//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getMaintenanceRepairAttachment = (id: string) => {
        return this.maintenanceRepairAttachmentRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.maintenanceRepairAttachmentRegistry.clear()
        this.loadMaintenanceRepairAttachment()
    }

    clearMaintenanceRepairAttachment = () => {
        this.maintenanceRepairAttachmentRegistry.clear()
    }

    loadMaintenanceRepairAttachment = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()

        try {
            const maintenanceRepairAttachmentsEnvelope = await Agent.MaintenanceRepairAttachments.list(this.axiosParams);
            const {modelsDto, modelCount} = maintenanceRepairAttachmentsEnvelope;
            runInAction(() => {
                modelsDto.forEach((maintenanceRepairAttachment: IMaintenanceRepairAttachment) => {
                    this.maintenanceRepairAttachmentRegistry.set(maintenanceRepairAttachment.id, maintenanceRepairAttachment)
                })
                this.maintenanceRepairAttachmentCount = modelCount
            })
        } catch (error) {
            throw error
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getMaintenanceRepairAttachmentById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let maintenanceRepairAttachment = await Agent.MaintenanceRepairAttachments.byId(id)
            runInAction(() => {
                this.maintenanceRepairAttachment = maintenanceRepairAttachment
                this.maintenanceRepairAttachmentRegistry.set(maintenanceRepairAttachment.id, maintenanceRepairAttachment)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return maintenanceRepairAttachment
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    getMaintenanceRepairAttachmentsByMaintenanceRepairId = async (maintenanceRepairId?: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let maintenanceRepairAttachmentEnvelope = await Agent.MaintenanceRepairAttachments.byMaintenanceRepairId(maintenanceRepairId || this.currentMaintenanceRepairId, this.axiosParams)
            const {modelsDto, modelCount} = maintenanceRepairAttachmentEnvelope
            runInAction(() => {
                modelsDto.forEach(maintenanceRepairAttachment => {
                    this.maintenanceRepairAttachmentRegistry.set(maintenanceRepairAttachment.id, maintenanceRepairAttachment)
                })

                this.maintenanceRepairAttachmentCount = modelCount
            })
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    createMaintenanceRepairAttachment = async (maintenanceRepairAttachment: MaintenanceRepairAttachmentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newMaintenanceRepairAttachment = await Agent.MaintenanceRepairAttachments.create(maintenanceRepairAttachment)
            await (!!this.currentMaintenanceRepairId ? this.getMaintenanceRepairAttachmentsByMaintenanceRepairId() : this.loadMaintenanceRepairAttachment())
            runInAction(() => {
                this.maintenanceRepairAttachmentRegistry.set(newMaintenanceRepairAttachment.id, newMaintenanceRepairAttachment)
                this.rootStore.modalStore.closeModal('AttachmentModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    deleteMaintenanceRepairAttachment = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.MaintenanceRepairAttachments.delete(id)
            runInAction(() => {
                this.maintenanceRepairAttachmentRegistry.delete(id)
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('MaintenanceRepairAttachmentStore')
            })
            toast.success('Deleted!')
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

}