import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import AttachmentTable from '../../../../../components/attachment/AttachmentTable';
import {TrainingAttachmentFormValues} from '../../../../../models/Training/TrainingAttachment';
import {IAttachment} from '../../../../../models/generic/Attachment';
import {RootStoreContext} from '../../../../../stores/RootStore';


export interface TrainingAttachmentTableProps {
    trainingId: string;
    disableCreateNew?: boolean
}

type Props = TrainingAttachmentTableProps;

const TrainingAttachmentTable: FunctionComponent<Props> = ({trainingId, disableCreateNew = false}) => {
    // STORES
    const rootStore = useContext(RootStoreContext)
    const {openModal} = rootStore.modalStore
    const {
        setPredicate,
        setCurrentTrainingId,
        trainingAttachmentsByDate,
        trainingAttachmentCount,
        handleChangePage,
        getTrainingAttachmentsByTrainingId,
        getTrainingAttachmentById,
        createTrainingAttachment,
        deleteTrainingAttachment
    } = rootStore.trainingAttachmentStore
    const {
        getGenericDirectAttachmentById
    }  = rootStore.genericAttachment

    // USE EFFECTS
    useEffect(() => {
        if (!!trainingId) {
            setCurrentTrainingId(trainingId);
            getTrainingAttachmentsByTrainingId()
        }
    }, [trainingId, getTrainingAttachmentsByTrainingId]);

    const openCreateModal = () => {
        openModal('AttachmentModal', {header: 'Create Training Attachment', createAttachment});
    }

    const createAttachment = (attachment: IAttachment) => {
        const newTrainingAttachment: TrainingAttachmentFormValues = {...attachment, trainingId}
        createTrainingAttachment(newTrainingAttachment);
    }


    return (<AttachmentTable
        attachments={trainingAttachmentsByDate}
        attachmentCount={trainingAttachmentCount}
        handleChangePage={handleChangePage}
        openCreateModal={openCreateModal}
        filterPredicate={setPredicate}
        getAttachmentById={getTrainingAttachmentById}
        getDirectAttachmentById={getGenericDirectAttachmentById}
        deleteAttachment={deleteTrainingAttachment}
        disableCreateNew={disableCreateNew}
    />)
};

export default observer(TrainingAttachmentTable)
