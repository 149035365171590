const modalClose = (e, modal?, onClose?) => {
    modal.hide()
    setTimeout(modal.remove, 300)
    !!onClose && onClose()
}

const modalConfirm = (onConfirm) => {
    try {
        onConfirm()
    } catch (e) {
        return console.error(e)
    }

    modalClose(null)
}

export {
    modalClose,
    modalConfirm
}