import React, {FunctionComponent} from 'react';
import {getClassName} from "../../lib/getClassName";


interface OwnProps {
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl'
    as?: any
    subtitle?: boolean
    className?: string
    children?: React.ReactNode;
}

type Props = OwnProps;

const Title: FunctionComponent<Props> = ({
                                             as = 'h1',
                                             size = '3xl',
                                             subtitle = false,
                                             className,
                                             children,
                                             ...rest
                                         }) => {
    const Tag = as

    const sizeClasses = getClassName({
        'text-xs': size === 'xs',
        'text-sm': size === 'sm',
        'text-base': size === 'base',
        'text-lg': size === 'lg',
        'text-xl': size === 'xl',
        'text-2xl': size === '2xl',
        'text-3xl': size === '3xl',
        'text-4xl': size === '4xl',
        'text-5xl': size === '5xl',
        'text-6xl': size === '6xl',
        'text-7xl': size === '7xl',
        'text-8xl': size === '8xl',
        'text-9xl': size === '9xl',
    })


    return (
        <Tag className={`${subtitle ? 'text-gray-700' : 'font-medium'} ${sizeClasses} ${className}`} {...rest}>{children}</Tag>
    );
};

export default Title;
