import React, {FunctionComponent, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useLocation, useParams} from "react-router-dom";
import {RouteParams} from "../models/RouteParams";
import Paper from "../components/generic/Paper";
import notificationblacklist from "../api/service/notificationblacklist.service";
import Button from "../components/generic/Button";
import {toast, ToastContainer} from "react-toastify";
import LoadingComponent from "../layout/LoadingComponent";
import {CheckIcon} from "@heroicons/react/24/solid";
import Checkbox from "../components/Inputs/Checkbox";

interface OwnProps {
}

type Props = OwnProps;

const NotificationOpt: FunctionComponent<Props> = () => {
    const location = useLocation();
    const {id} = useParams<RouteParams>();
    const query = location.pathname.split('/')[2];

    const [displayAction, setDisplayAction] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(true);
    const [optStatus, setOptStatus] = useState(false);


    useEffect(() => {
        if (query === 'OptOut')
            setDisplayAction('Unsubscribe')
        if (query === 'OptIn')
            setDisplayAction('Subscribe')
    }, [query])


    const handleOnChange = () => {
        setChecked(!checked)
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (checked) {
                await notificationblacklist.put(query, id);
                toast.success('Saved')
                setOptStatus(true);
            }
        } catch (e) {
            toast.error('Problem submitting data')
        }
        finally {
            setIsLoading(false);
        }
    }

    return (<>
        <div className={'flex items-center justify-center h-full'}>
            <div className={'max-w-md'}><Paper>
                {optStatus ?
                    <p className={"m-0"}>
                        <b>{`${displayAction} was successful.`}</b>
                        <span className={"block"}>{`You may now close this window.`}</span>
                    </p> :
                    <form autoComplete={'off'} onSubmit={handleOnSubmit} id={'main'}>
                        <p className={'font-bold text-xl '}>{`Would you like to ${displayAction?.toLowerCase()} 
                            ${displayAction === 'Unsubscribe' ? 'from' : ''}
                            ${displayAction === 'Subscribe' ? 'to' : ''} 
                        receiving this notification from the NDBEDP Centralized Database?`}</p>


                        <p className={"mt-3 text-sm"}>{`This will apply to all notifications from NDBEDP Centralized Database.`}</p>
                        <div className={'flex gap-x-4 justify-between mt-3'}>
                            <Checkbox className={"p-0"} id={"flexCheckDefault"}
                                     onChange={handleOnChange} checked={checked} required label={displayAction}/>
                            <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'}
                                    type={'submit'}>Save</Button></div>
                    </form>
                }
            </Paper></div>
        </div>
        <ToastContainer position='top-right'/>

        
        <LoadingComponent active={isLoading}/>
    </>);
};

export default observer(NotificationOpt)
