import {IConsumerMerge, IConsumerMergeEnvelope} from "../../models/Consumer/ConsumerMerge";
import axios from "axios";
import {api, responseBody} from './apiBase.service'

const ConsumerMerges = {
    list: (
        consumerId: string,
        params: URLSearchParams
    ): Promise<IConsumerMergeEnvelope> => {
        return axios
            .get(`/Consumer/${consumerId}/conflict`, {params: params})
            .then(responseBody)

    },
    release: (consumerIds: Array<string>) =>
        api.put(`/Consumer/Conflict/Release`, consumerIds),
    merge: (consumer: IConsumerMerge, consumerIds: Array<string>) =>
        api.put(`/Consumer/Conflict/Merge/${consumer.id}`, consumerIds),

};


export default ConsumerMerges