import axios from "axios";
import {IOutreachAttachmentEnvelope, OutreachAttachmentFormValues} from "../../models/Outreach/OutreachAttachment";
import {api, responseBody} from "./apiBase.service";

const OutreachAttachments = {
    list: (params: URLSearchParams): Promise<IOutreachAttachmentEnvelope> =>
        axios
            .get("/Outreach/Attachment", {params: params})
            .then(responseBody),
    create: (outreachAttachment: OutreachAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in outreachAttachment) {
            formData.append(key, outreachAttachment[key])
        }
        return api.post(`/Outreach/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/Outreach/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/Outreach/Attachment/${id}`),
    byOutreachId: (outreachId: string, params: URLSearchParams): Promise<IOutreachAttachmentEnvelope> =>
        axios.get(`/Outreach/${outreachId}/Attachment`, {params}).then(responseBody)
};

export default OutreachAttachments