import React, {FunctionComponent, useState} from 'react';


interface OwnProps extends React.HTMLProps<HTMLInputElement>{
    options: Array<{ label: string, value: any }>
    displayValue?: string
    onChange?: (e) => void
}

type Props = OwnProps;

const RadioGroup: FunctionComponent<Props> = ({options, onChange, displayValue, ...rest}) => {
    const [isChecked, setIscChecked] = useState(displayValue || '');

    const handleChange = (e) => {
        // e.preventDefault()
        setIscChecked(`${e.target.value}`)
        !!onChange && onChange(e.target.value)
    }
    return (<div className={'flex gap-4 flex-wrap'} onChange={handleChange}>
            {options.map((o, idx) => {
                return <div className="flex items-center whitespace-nowrap" key={idx}>
                    <input className="" type="radio" {...rest} id={o.label.replaceAll(/[^\w]/g, '')}
                           value={`${o.value}`} checked={isChecked === `${o.value}`}/>
                    <label className="ml-2 whitespace-nowrap" htmlFor={o.label.replaceAll(/[^\w]/g, '')}>{o.label}</label>
                </div>
            })}
        </div>
    );
};

export default RadioGroup;
