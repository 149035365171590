import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";
import EquipmentAttachmentsTable, {
    EquipmentAttachmentTableProps
} from "../../../pages/consumers/equipment/components/Equipment/EquipmentAttachmentsTable";


interface OwnProps extends SlideOverProps, EquipmentAttachmentTableProps {}

type Props = OwnProps;

// @ts-ignore
const EquipmentAttachmentsTableModal = NiceModal.create<Props>(({onClose, header, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <EquipmentAttachmentsTable {...rest}/>
        </SlideOver>
    )
})

export default EquipmentAttachmentsTableModal;
