import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import EquipmentForm, {EquipmentFormProps} from "../../../pages/consumers/equipment/components/Equipment/EquipmentForm";
import {modalClose} from "../genericFunctions";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, EquipmentFormProps {
}

type Props = OwnProps;

// @ts-ignore
const EquipmentModal = NiceModal.create<Props>(({equipment, onClose, header, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} title={header}>
            <EquipmentForm equipment={equipment} {...rest}/>
        </SlideOver>
    )
})

export default EquipmentModal;
