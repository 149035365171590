import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RootStoreContext} from "../../../stores/RootStore";

import {IAssessment} from "../../../models/Assessment/Assessment";
import {arraySum, displayDate, displayDollarAmount} from "../../../lib/utilities";
import {observer} from "mobx-react-lite";
import AssessmentAutoCompleteInput from "../AssessmentAutoCompleteInput";
import Table from '../../table/Table';
import THead from '../../table/THead';
import TR from '../../table/TR';
import TBody from '../../table/TBody';
import TD from '../../table/TD';
import {TrashIcon} from "@heroicons/react/24/solid";

interface OwnProps {
    onChange?: (e) => (void)
    name?: string
    selected?: string[]
    entityStateId?: string
}

type Props = OwnProps;

const AssessmentMultiselect: FunctionComponent<Props> = ({entityStateId, onChange, name, selected}) => {
    //Stores
    const rootStore = useContext(RootStoreContext);
    const {
        loadNakedAssessment,
        assessmentsByDate,
        getAssessmentsByIds,
    } = rootStore.assessmentStore
    const {newSubItemQueue} = rootStore.subItemStore


    // Local State
    const [selectedAssessments, setSelectedAssessments] = useState([] as Array<IAssessment>);
    const [availableAssessments, setAvailableAssessments] = useState([] as IAssessment[]);

    // Use Effects
    useEffect(() => {
        loadNakedAssessment().then(data => setAvailableAssessments([...data]))
        if (!!selected && selected?.length > 0) {
            getAssessmentsByIds(selected).then(data => {
                if (!!data) setSelectedAssessments(data)
            })
        }
    }, [assessmentsByDate, newSubItemQueue]);

    // Functions
    const handleRemove = (idx): void => {
        setSelectedAssessments((prev) => {
            prev.splice(idx, 1)
            !!onChange && onChange({
                currentTarget: {
                    name,
                    value: prev
                }
            })
            return prev
        })
    }

    const handleSelected = (data): void => {
        if (!!data) {
            let newAssessment = availableAssessments.find((assessment) => assessment.id === data.id)

            if (!newAssessment) return

            // @ts-ignore
            setSelectedAssessments(prev => {
                let tmp = [...prev, newAssessment]
                // let tmp = [...prev]
                !!onChange && onChange({
                    currentTarget: {
                        name,
                        value: tmp.map(ids => ids?.id)
                    }
                })
                return tmp
            })
        }
    }

    return (<>
        <div className={'grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1'}>
                <AssessmentAutoCompleteInput
                    onChange={handleSelected}
                    entityStateId={entityStateId}
                    // @ts-ignore
                    filterIds={selectedAssessments?.map((select: IAssessment) => {
                        return !!select ? select.id : ''
                    }) ?? []}/>
        </div>

        <Table>
            <THead>
                <TR>
                    <TD/>
                    <TD>Consumer</TD>
                    <TD>Technician</TD>
                    <TD>Date Performed</TD>
                    <TD>Assessment Time</TD>
                    <TD>Total Cost</TD>
                </TR>
            </THead>
            <TBody>
                {selectedAssessments.map((assessment: IAssessment, idx) => {
                    return (<TR key={idx}>
                        <TD><TrashIcon className={'text-red-600 h-6 w-6'} onClick={() => handleRemove(idx)}/></TD>
                        <TD>{assessment?.consumerName}</TD>
                        <TD>{assessment?.technicianName}</TD>
                        <TD>{displayDate(assessment?.datePerformed)}</TD>
                        <TD>{assessment?.assessorTime ?? 0} {!!assessment?.assessorTime && assessment?.assessorTime > 1 ? 'hours' : 'hour'}</TD>
                        <TD>{displayDollarAmount(arraySum([
                            assessment?.auxiliaryAidCosts,
                            assessment?.assessmentCosts,
                            assessment?.eligibilityCosts,
                            assessment?.otherCosts,
                        ]))}</TD>
                    </TR>)
                })}
            </TBody>
        </Table>
    </>);
};

export default observer(AssessmentMultiselect);
