import React from 'react';
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {modalClose} from "../genericFunctions";
import ProgramYearForm, {
    ProgramYearFormProps
} from "../../../pages/administration/program-year/components/ProgramYearForm";
import SlideOver, {SlideOverProps} from "../../generic/SlideOver";


interface OwnProps extends SlideOverProps, ProgramYearFormProps {
}

type Props = OwnProps;

// @ts-ignore
const ProgramYearModal = NiceModal.create<Props>(({header, programYear, onClose, ...rest}) => {
    const modal = useModal()

    const handleClose = (e) => {
        modalClose(e, modal, onClose)
    }

    return (
        <SlideOver onClose={handleClose} open={modal.visible} afterClose={modal.remove} title={header}>
            <ProgramYearForm programYear={programYear} {...rest}/>
        </SlideOver>
    )
})

export default ProgramYearModal;
