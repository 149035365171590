import React, {Fragment} from 'react';
import {observer} from "mobx-react-lite";

import Button from "../generic/Button";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {Dialog, Transition} from '@headlessui/react';
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

interface OwnProps {
    body: any
    confirmText?: string
    declineText?: string
    header?: string

    onConfirm(): any | void;

    onClose?(): any | void;
}

type Props = OwnProps;

//@ts-ignore
const ConfirmationModal = NiceModal.create<Props>(({
                                                       body,
                                                       confirmText = 'yes',
                                                       declineText = 'no',
                                                       onConfirm,
                                                       onClose,
                                                       header = 'Confirmation'
                                                   }) => {
        const modal = useModal()

        const handleClose = (e) => {
            modal.hide()
            setTimeout(modal.remove, 300)
            !!onClose && onClose()
        }

        const handleConfirm = (e) => {
            try {
                onConfirm()
            } catch (e) {
                return console.error(e)
            }

            handleClose(null)
        }

        return (<>
            <Transition.Root show={modal.visible} as={Fragment}>
                <Dialog as="div" className="relative z-1010" onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur       transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div
                            className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-4">
                                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                        <button
                                            type="button"
                                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={handleClose}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg leading-6 font-medium text-gray-900">
                                                {header}
                                            </Dialog.Title>
                                            <div className="mt-2 text-sm text-gray-700">
                                                {body}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 flex gap-3 justify-center sm:justify-end">

                                        <Button onClick={handleConfirm} iconLeft={<CheckIcon className={'h-5 w-5'}/>}
                                                color={'blue'}>{confirmText}</Button>

                                        <Button name={'cancel'} onClick={handleClose} iconLeft={<XMarkIcon className={'h-5 w-5'}/>}
                                                color={'red'}>{declineText}</Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>)
    }
)


export default observer(ConfirmationModal);
