export const dropDownOptions = {
    'Semi-Annual': [{
        key: '1',
        text: 'July - December',
        value: 1
    }, {
        key: '2',
        text: 'January - June',
        value: 2
    }],
    'Quarterly': [{
        key: '1',
        text: 'July - September',
        value: 1
    }, {
        key: '2',
        text: 'October - December',
        value: 2
    }, {
        key: '3',
        text: 'January - March',
        value: 3
    }, {
        key: '4',
        text: 'April - June',
        value: 4
    }],
    'Monthly': [{
        key: '7',
        text: 'July',
        value: 1
    }, {
        key: '8',
        text: 'August',
        value: 2
    }, {
        key: '9',
        text: 'September',
        value: 3
    }, {
        key: '10',
        text: 'October',
        value: 4
    }, {
        key: '11',
        text: 'November',
        value: 5
    }, {
        key: '12',
        text: 'December',
        value: 6
    }, {
        key: '1',
        text: 'January',
        value: 7
    }, {
        key: '2',
        text: 'February',
        value: 8
    }, {
        key: '3',
        text: 'March',
        value: 9
    }, {
        key: '4',
        text: 'April',
        value: 10
    }, {
        key: '5',
        text: 'May',
        value: 11
    }, {
        key: '6',
        text: 'June',
        value: 12
    }]
}