import {makeAutoObservable, reaction, runInAction} from 'mobx';
import {RootStore} from './RootStore';
import agent from "../api/Agent";
import {IState} from "../models/State";
import {dropdown} from '../components/types/Dropdown';

export default class StateStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                // this.page = 0;
                // this.StateRegistry.clear();
                // this.loadStates();
            }
        )
    }

    StateRegistry = new Map();
    State: IState | null = null;
    loadingInitial = false;
    submitting = false;
    target = "";
    page = 0;
    predicate = new Map();
    stateCount = 0
    limit = 5

    countryCode = 'US';
    // states = State.getStatesOfCountry(this.countryCode);
    states = ["AK",
        "AL",
        "AR",
        "AS",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "DE",
        "FL",
        "GA",
        "GU",
        "HI",
        "IA",
        "ID",
        "IL",
        "IN",
        "KS",
        "KY",
        "LA",
        "MA",
        "MD",
        "ME",
        "MH",
        "MI",
        "MN",
        "MO",
        "MP",
        "MS",
        "MT",
        "NC",
        "ND",
        "NE",
        "NH",
        "NJ",
        "NM",
        "NV",
        "NY",
        "OH",
        "OK",
        "OR",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VA",
        "VI",
        "VT",
        "WA",
        "WI",
        "WV",
        "WY",]

    get getStates() {
        var res: dropdown[] = [];
        this.states.forEach((state) => {
            res.push({
                key: state,
                text: state,
                value: state
            })

        })
        return res;
    }


    setPredicate = (predicate: string, value: string | Date) => {
        this.predicate.clear();
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(this.limit));
        params.append('offset', `${this.page ? this.page * this.limit : 0}`);
        this.predicate.forEach((value, key) => {
            params.append(key, value);
        })
        return params;
    }

    get statesById() {
        return Array.from(this.StateRegistry.values()).sort(
            (b: IState, a: IState) => (a.stateName > b.stateName ? -1 : 1)
        );
    };

    setLimit = (limit: number) => {
        this.limit = limit
    }

    loadStates = async () => {
        this.loadingInitial = true;
        try {
            const stateEnvelope = await agent.States.list(this.axiosParams);
            let {modelsDto, modelCount} = stateEnvelope;

            runInAction( () => {
                modelsDto.forEach((state) => {
                    this.StateRegistry.set(state.id, state);
                })
                this.stateCount = modelCount;
            });

        } catch (error) {
            this.rootStore.errorStore.addError(error);
        }
        runInAction( () => {
            this.loadingInitial = false;
        });
    };

    clearStates = async () => {
        this.StateRegistry.clear();
    }

}