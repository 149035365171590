import React, {FunctionComponent, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {IAuditReport} from "../../../../models/AuditReport/AuditReport";
import Button from "../../../../components/generic/Button";
import {CheckIcon} from "@heroicons/react/24/solid";
import {RootStoreContext} from "../../../../stores/RootStore";
import ProgramYearAutoCompleteInput from "../../../../components/form/ProgramYearAutoCompleteInput";
import {inputOnChange} from "../../../../lib/utilities";
import EntityStateAutoCompleteInput from "../../../../components/form/EntityStatesAutoCompleteInput";
import Toggle from "../../../../components/generic/Toggle";
import {IAttachment} from "../../../../models/generic/Attachment";
import {AuditReportAttachmentFormValues} from "../../../../models/AuditReport/AuditReportAttachment";
import JSONView from "../../../../components/generic/JSONView";

export interface AuditReportFormProps {
}

type Props = AuditReportFormProps;

const AuditReportTable: FunctionComponent<Props> = () => {
    const rootStore = useContext(RootStoreContext);
    const {openModal} = rootStore.modalStore;
    const {createAuditReport, loadAuditReports} = rootStore.auditReportStore;
    const {createAuditReportAttachment} = rootStore.auditReportAttachmentStore;

    const [currentAuditReport, setCurrentAuditReport] = useState({} as IAuditReport);
    const [addAttachments, setAddAttachments] = useState(true);

    const openCreateAttachmentModal = (auditReport) => {
        const auditReportId = auditReport?.id;
        const createAttachment = async (attachment: IAttachment) => {
            const newAuditReportAttachment: AuditReportAttachmentFormValues = {...attachment, auditReportId}
            await createAuditReportAttachment(newAuditReportAttachment).then(() => loadAuditReports());
        }

        openModal('AttachmentModal', {header: 'Create Audit Report Attachment', createAttachment}, 'lg');
    }

    const handleChange = (e) => {
        inputOnChange(e, setCurrentAuditReport)
    }

    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        createAuditReport(currentAuditReport).then(newAuditReport => addAttachmentsAfterSubmit(newAuditReport));
    }

    const addAttachmentsAfterSubmit = async (newAuditReport) => {
        if (addAttachments) openCreateAttachmentModal(newAuditReport)
    }


    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full overflow-x-hidden'}>
            <div className={'flex-1'}>
                <div className={'grid grid-cols-1 gap-4 mb-3'}>
                    <ProgramYearAutoCompleteInput
                        name='programYear'
                        onChange={({id}) => handleAutoComplete(id, 'programYearId')}
                        required
                    />
                </div>

                <div className={'grid grid-cols-1 gap-4 mb-3'}>
                    <EntityStateAutoCompleteInput
                        name={'entityStateId'}
                        onChange={({id}) => handleAutoComplete(id, 'entityStateId')}
                        placeholder={'California'}
                        displayValue={(currentAuditReport?.entityStateId) ? (currentAuditReport?.entityState || 'State Program already selected') : ''}
                        required
                    />
                </div>
                <div className={'grid grid-cols-1'}>
                    <Toggle label={'add attachments after submit'}
                        onChange={() => setAddAttachments(prev => !prev)} checked={addAttachments}
                        name={'addAttachments'}/>
                </div>

                <JSONView data={currentAuditReport}/>
            </div>


            <div className={'flex justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'submit'} color={'green'} type={'submit'}>
                    Submit
                </Button>
            </div>
        </form>
    )
}

export default observer(AuditReportTable);