import axios from "axios";
import {
    AdministrativeAttachmentFormValues,
    IAdministrativeAttachmentEnvelope
} from "../../models/Administrative/AdministrativeAttachment";
import {api, responseBody} from "./apiBase.service";

const AdministrativeAttachments = {
    list: (params: URLSearchParams): Promise<IAdministrativeAttachmentEnvelope> =>
        axios
            .get("/Administrative/Attachment", {params: params})
            .then(responseBody),
    create: (administrativeAttachment: AdministrativeAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in administrativeAttachment) {
            formData.append(key, administrativeAttachment[key])
        }
        return api.post(`/Administrative/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/Administrative/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/Administrative/Attachment/${id}`),
    byAdministrativeId: (administrativeId: string, params: URLSearchParams): Promise<IAdministrativeAttachmentEnvelope> =>
        axios.get(`/Administrative/${administrativeId}/Attachment`, {params}).then(responseBody)
};

export default AdministrativeAttachments