import axios from "axios";
import {
    IMaintenanceRepairNote,
    IMaintenanceRepairNoteEnvelope,
    MaintenanceRepairNoteFormValues
} from "../../models/MaintenanceRepair/MaintenanceRepairNote";
import {api, responseBody} from "./apiBase.service";

const MaintenanceRepairNotes = {
    list: (params: URLSearchParams): Promise<IMaintenanceRepairNoteEnvelope> =>
        axios
            .get("/MaintenanceRepair/Note", {params: params})
            .then(responseBody),
    create: (MaintenanceRepair: MaintenanceRepairNoteFormValues) =>
        api.post(`/MaintenanceRepair/Note`, MaintenanceRepair),
    update: (MaintenanceRepair: IMaintenanceRepairNote) =>
        api.put(`/MaintenanceRepair/Note/${MaintenanceRepair.id}`, MaintenanceRepair),
    delete: (id: string) =>
        api.del(`/MaintenanceRepair/Note/${id}`),
    byId: (id: string) => api.get(`/MaintenanceRepair/Note/${id}`),
    byMaintenanceRepairId: (maintenanceRepairId: string, params: URLSearchParams): Promise<IMaintenanceRepairNoteEnvelope> =>
        axios
            .get(`/MaintenanceRepair/${maintenanceRepairId}/Note`, {params: params})
            .then(responseBody)
    ,
};

export default MaintenanceRepairNotes