import axios from "axios";
import {
    IInstallationNote,
    IInstallationNoteEnvelope,
    InstallationNoteFormValues
} from "../../models/Installation/InstallationNote";
import {api, responseBody} from "./apiBase.service";

const InstallationNotes = {
    list: (params: URLSearchParams): Promise<IInstallationNoteEnvelope> =>
        axios
            .get("/Installation/Note", {params: params})
            .then(responseBody),
    create: (Installation: InstallationNoteFormValues) =>
        api.post(`/Installation/Note`, Installation),
    update: (Installation: IInstallationNote) =>
        api.put(`/Installation/Note/${Installation.id}`, Installation),
    delete: (id: string) =>
        api.del(`/Installation/Note/${id}`),
    byId: (id: string) => api.get(`/Installation/Note/${id}`),
    byInstallationId: (equipmentId: string, params: URLSearchParams): Promise<IInstallationNoteEnvelope> =>
        axios
            .get(`/Installation/${equipmentId}/Note`, {params: params})
            .then(responseBody)
    ,
};

export default InstallationNotes