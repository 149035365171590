import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";

import {EntityStateFormValues, IEntityState} from "../../../../models/Entity/EntityState";
import Button from "../../../../components/generic/Button";
import Select from "../../../../components/generic/Select";
import {inputOnChange, objectDateConverter} from "../../../../lib/utilities";
import Input from "../../../../components/Inputs/Input";
import dateInputOptions from "../../../../data/cleave/dateInputOptions";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import JSONView from "../../../../components/generic/JSONView";

export interface EntityStateFormProps {
    entityState: IEntityState | undefined;
    entityId: string;
}

type Props = EntityStateFormProps

const EntityStateForm: React.FC<Props> = ({entityState, entityId}) => {
    const rootStore = useContext(RootStoreContext);
    const {getStates} = rootStore.stateStore;

    const {createEntityState, editEntityState} = rootStore.entityStateStore;
    const [currentEntityState, setCurrentEntityState] = useState(new EntityStateFormValues(entityState));
    const {closeModal} = rootStore.modalStore;


    useEffect(() => {
        objectDateConverter(entityState, setCurrentEntityState);
    }, [entityState])


    const handleSubmit = (e) => {
        e.preventDefault()
        if (!currentEntityState.id) {
            currentEntityState.entityId = entityId
            let newEntityState = {
                ...currentEntityState
            };
            createEntityState(newEntityState);
        } else {
            editEntityState(currentEntityState);
        }
    };

    const handleChange = (e) => {
        inputOnChange(e, setCurrentEntityState)
    }

    return (
        <form onSubmit={handleSubmit} className={'flex flex-col justify-between flex-1 h-full'}>
            <div className={'h-full'}>
                <Select
                    name='abbreviation'
                    required
                    value={currentEntityState.abbreviation}
                    placeholder={'Select State'}
                    options={getStates}
                    label={'State'}
                    onChange={handleChange}
                />
                <Input
                    label={'Start Date'}
                    onChange={handleChange}
                    value={`${currentEntityState.startDate ?? ''}`}
                    name={'startDate'}
                    options={{
                        ...dateInputOptions,
                        dateMax: undefined,
                    }}
                    errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                    pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                    placeholder={'10/26/1985'}
                    required
                />
                <Input
                    label={'End Date'}
                    onChange={handleChange}
                    value={`${currentEntityState.endDate ?? ''}`}
                    name={'endDate'}
                    options={{
                        ...dateInputOptions,
                        dateMax: undefined,
                    }}
                    errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                    placeholder='10/26/1985'
                    pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                />
            <JSONView data={currentEntityState}/>
            </div>

            <div className={'text-right'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} color={'green'} type={'submit'}>Save</Button>
                <span className="mr-2"/>
                <Button name={'cancel'} iconLeft={<XMarkIcon className={'h-5 w-5'}/>}
                        onClick={() => closeModal('EntityStateModal')} color={'red'}
                >Cancel</Button>
            </div>
        </form>
    );
};
export default observer(EntityStateForm);