import React, {FunctionComponent} from 'react';
import {getClassName} from "../../lib/getClassName";

interface OwnProps {
    content: string
    children: any
    direction?: 'right' | 'left' | 'bottom' | 'top'
}

type Props = OwnProps;

const ToolTip: FunctionComponent<Props> = ({content, direction, children}) => {
    // const [UUID] = useState(uuid());
    let directionClasses = getClassName({
        'hidden group-hover:inline-block group-hover:opacity-100 absolute z-20 whitespace-nowrap rounded bg-black py-[6px] px-4 text-sm font-semibold text-white opacity-0  group-focus-within:opacity-100 group-focus-within:inline-block transition-opacity group-focus-within:outline-none': true,
        'bottom-full left-1/2 mb-2 -translate-x-1/2': direction === 'top',
        'left-full top-1/2 ml-2 -translate-y-1/2': direction === 'right',
        'top-full left-1/2 mt-2 -translate-x-1/2': direction === 'bottom',
        'right-full top-1/2 mr-2 -translate-y-1/2': direction === 'left',
    })
    return (
        <div className="group relative inline-block focus:outline-none">
            {children}
            <div
                className={`${directionClasses}`}>
                {content}
            </div>
        </div>
    );
};

export default ToolTip;