import React, {useContext, useEffect, useState} from 'react'

import {RootStoreContext} from "../../stores/RootStore";
import {observer} from "mobx-react-lite";
import {ComboBoxProps, OptionType} from "./ComboBox";
import Agent from "../../api/Agent";
import {classNames, displayDate} from "../../lib/utilities";
import {Combobox} from '@headlessui/react'
import {PlusIcon} from "@heroicons/react/24/solid";
import Autocomplete from "../Inputs/Autocomplete";

interface OwnProps extends Omit<ComboBoxProps, 'options'> {
    filterIds?: string[]
    entityStateId?: string
    canAddNew?: boolean
}

type Props = OwnProps

const AssessmentAutoCompleteInput: React.FC<Props> = ({entityStateId, filterIds, canAddNew = true, ...rest}) => {

    // Mobx
    const rootStore = useContext(RootStoreContext)
    const {assessmentsList} = rootStore.assessmentStore
    const {openModal} = rootStore.modalStore

    // local state
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([] as OptionType[]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams()
                params.append('limit', '10000000')
                params.append('offset', `0`)
                const {modelsDto} = await Agent.Assessments.list(params)
                setAutoCompleteOptions(() => {
                    const opts = modelsDto
                        .filter(opt => opt.entityStateId === entityStateId || !entityStateId)
                        .map(opt => {
                            return {
                                id: opt.id,
                                name: `${opt.consumerName ?? 'No Consumer'} - ${opt.technicianName ?? 'No Technician'} (${displayDate(opt.datePerformed) ?? 'No Date'})`.trim()
                            }
                        })
                        .sort((optA, optB) => optA.name > optB.name ? 1 : -1)
                    // @ts-ignore
                    if (canAddNew) opts.unshift({id: 0, name: '+ New Assessment'})
                    return opts
                })
            } catch (e) {

            }
        }

        fetchData()
    }, [assessmentsList, entityStateId]);


    const createNew = () => {
        openModal('AssessmentModal', {header: 'New Assessment'})
    }

    const handleChange = (e) => {
        return e.id === 0 ? createNew() : !!rest.onChange && rest?.onChange(e)
    }

    const optionsFilter = (option) => {
        if (filterIds) {
            return !filterIds.includes(option.id)
        } else {
            return true;
        }
    }

    return (
        <Autocomplete {...rest} options={autoCompleteOptions.filter(opt => optionsFilter(opt))} label={'Assessment'}
                      onChange={handleChange}>
        </Autocomplete>
    );
};
export default observer(AssessmentAutoCompleteInput);