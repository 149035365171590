import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {AttachmentFormValues} from "../../../../models/generic/Attachment";
import {RootStoreContext} from "../../../../stores/RootStore";
import Button from "../../../../components/generic/Button";
import {IReimbursementClaim} from "../../../../models/Reimbursement/ReimbursementClaim";
import FileDragAndDrop from "../../../../components/form/FileDragAndDrop";
import {displayDate, displayDollarAmount, inputOnChange, uuid} from "../../../../lib/utilities";
import JSONView from "../../../../components/generic/JSONView";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface ReimbursementClaimSubmitFormProps {
    reimbursementClaim: IReimbursementClaim
}

type Props = ReimbursementClaimSubmitFormProps

const AttachmentForm: React.FC<Props> = ({reimbursementClaim}) => {
    // store
    const rootStore = useContext(RootStoreContext);
    const {closeModal} = rootStore.modalStore;
    const {user, hasRole} = rootStore.oidcStore
    const {currentEntityState} = rootStore.commonStore
    const {submitReimbursementClaim} = rootStore.reimbursementClaimStore


    // state
    const [currentAttachment, setCurrentAttachment] = useState(new AttachmentFormValues());
    const [acknowledge, setAcknowledge] = useState({
        submitterName: user?.profile.name,
        accept: false,
        submitterTitle: ''
    });

    // Functions
    const handleSubmit = (e) => {
        e.preventDefault()
        let tmpAttachment = {...currentAttachment, entityId: currentEntityState.entityId, ...acknowledge}
        if (!acknowledge.accept) {
            tmpAttachment.name = tmpAttachment.file?.name;
            tmpAttachment.createdByName = user?.profile.email || '';
            // @ts-ignore
            tmpAttachment.fileName = tmpAttachment.file
        }

        submitReimbursementClaim(reimbursementClaim.id ?? '', tmpAttachment)
    }

    const handleFile = (files) => {
        setCurrentAttachment(prev => {
            return {
                ...prev,
                file: files[0]
            }
        })
    }

    const handleInput = (e) => inputOnChange(e, setAcknowledge)


    const canSubmit = () => {
        if (hasRole('signing') && acknowledge.accept && !!acknowledge.submitterTitle && !!acknowledge.submitterName)
            return true

        if (!hasRole('signing') && currentAttachment.file)
            return true

        return false
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit}
              className={'overflow-y-auto overflow-x-hidden flex-1 flex flex-col h-full'}>
            <div className={'flex-1'}>

                {hasRole('signing') && <>
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <p><b>State Program:</b> {reimbursementClaim.entityState ?? 'N/A'}</p>
                        <p><b>Filing
                            Period:</b> {`${displayDate(reimbursementClaim.expenseStartDate)} to ${displayDate(reimbursementClaim.expenseEndDate)}`}
                        </p>
                        <p><b>Program Year:</b> {reimbursementClaim.programYear ?? 'N/A'}</p>
                        <p><b>Claim Total:</b> {displayDollarAmount(reimbursementClaim.itemTotal ?? 0)}</p>
                        <hr/>
                        <p className={''} id={'acknowledgment'}>
                            <input type={'checkbox'} name={'accept'} checked={acknowledge.accept} className={'mr-2'}
                                   required onChange={handleInput}/>
                            I swear under penalty of perjury that I am
                            <input aria-label={'full name'} className={'mx-2 border-b-2 border-gray-400 max-w-fit'}
                                   name={'submitterName'} placeholder={'Full Name'} value={acknowledge.submitterName}
                                   onChange={handleInput}/>
                            <input className={'mx-2 border-b-2 border-gray-400 max-w-fit'} aria-label={'title'}
                                   name={'submitterTitle'} placeholder={'Title'} value={acknowledge.submitterTitle}
                                   onChange={handleInput}/>, an officer of the above-named reporting entity, and
                            that the entity has policies and procedures in place to ensure that recipients satisfy
                            the NDBEDP eligibility requirements, that the entity is in compliance with the
                            Commission's NDBEDP rules, that I have examined the foregoing reports and that all
                            requested information has been provided, and all statements of fact are true and an
                            accurate statement of the business activities conducted pursuant to the NDBEDP by the
                            above-named certified program.
                        </p>
                    </div>
                </>}
                {!hasRole('signing') && <>
                    <p>You are not signed in as an Authorized Signer. In order to submit your reimbursement claim,
                        you must upload a file with the attestation statement, the authorized signer's name, title,
                        signature and date.</p>


                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                        <FileDragAndDrop onChange={handleFile}
                                         label={'Supporting Document'}
                                         acceptedFileTypes={'.pdf,.xls,.xlsx,.jpg,.jpeg,.png'}
                                         maxNumberOfFiles={1}
                                         required/>
                    </div>
                </>}


                <JSONView data={acknowledge}/>
            </div>

            <div className={'flex justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>}
                        name={'save'} color={'green'} type={'submit'}
                        disabled={!canSubmit()}>
                    Save
                </Button>

                <span className={'m-2'}/>

                <Button name={'cancel'}
                        onClick={() => closeModal('ReimbursementClaimSubmitModal')}
                        color="red"
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>
                    Cancel
                </Button>
            </div>
        </form>

    );
};
export default observer(AttachmentForm)