import axios from "axios";
import {ConsumerAttachmentFormValues, IConsumerAttachmentEnvelope} from "../../models/Consumer/ConsumerAttachment";
import {api, responseBody} from "./apiBase.service";

const ConsumerAttachments = {
    list: (params: URLSearchParams): Promise<IConsumerAttachmentEnvelope> =>
        axios
            .get("/Consumer/Attachment", {params: params})
            .then(responseBody),
    create: (consumerAttachment: ConsumerAttachmentFormValues) => {
        let formData = new FormData();
        for (const key in consumerAttachment) {
            formData.append(key, consumerAttachment[key])
        }
        return api.post(`/Consumer/Attachment`, formData)
    },
    delete: (id: string) => {
        api.del(`/Consumer/Attachment/${id}`)
    },
    byId: (id: string) => api.get(`/Consumer/Attachment/${id}`),
    byConsumerId: (consumerId: string, params: URLSearchParams): Promise<IConsumerAttachmentEnvelope> =>
        axios.get(`/Consumer/${consumerId}/Attachment`, {params}).then(responseBody)
};

export default ConsumerAttachments