import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../stores/RootStore";
import Button from "../../../../components/generic/Button";
import {IReimbursementClaim} from "../../../../models/Reimbursement/ReimbursementClaim";
import AssessmentSelectTable from "../../../../components/form/SelectableTables/AssessmentSelectTable";
import AdministrativeSelectTable from "../../../../components/form/SelectableTables/AdministrativeSelectTable";
import EquipmentSelectTable from "../../../../components/form/SelectableTables/EquipmentSelectTable";
import InstallationSelectTable from "../../../../components/form/SelectableTables/InstallationSelectTable";
import OutreachSelectTable from "../../../../components/form/SelectableTables/OutreachSelectTable";
import MaintenanceRepairSelectTable from "../../../../components/form/SelectableTables/MaintenanceRepairSelectTable";
import ShippingAndTaxSelectTable from "../../../../components/form/SelectableTables/ShippingAndTaxSelectTable";
// import UpgradeSelectTable from "../../../../components/form/SelectableTables/UpgradeSelectTable";
import {IReimbursementClaimLineItem} from "../../../../models/Reimbursement/ReimbursementClaimLineItem";
import ProgressStepper from "../../../../components/generic/ProgressStepper";
import TrainingSelectTable from "../../../../components/form/SelectableTables/TrainingSelectTable";
import TravelSelectTable from "../../../../components/form/SelectableTables/TravelSelectTable";
import {CheckIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";

export interface ReimbursementClaimFormProps {
    reimbursementClaim?: IReimbursementClaim
    reimbursementClaimLineItem?: IReimbursementClaimLineItem
}

type Props = ReimbursementClaimFormProps

const ReimbursementLineItemsForm: React.FC<Props> = ({reimbursementClaim, reimbursementClaimLineItem}) => {

    // Mobx
    const rootStore = useContext(RootStoreContext);
    const {
        createReimbursementClaimLineItem,
    } = rootStore.reimbursementClaimLineItemStore;
    const {closeModal} = rootStore.modalStore;

    const [currentReimbursementLineItem, setCurrentReimbursementLineItem] = useState(reimbursementClaimLineItem ?? ({reimbursementClaimId: reimbursementClaim?.id} || {}) as IReimbursementClaimLineItem);
    const [step, setStep] = useState(0);
    const [animation, setAnimation] = useState('from-right');
    const [maxStep,] = useState(8);

    const handleSubmit = (e) => {
        e.preventDefault()
        let tmpReimbursementLineItems = {...currentReimbursementLineItem}
        createReimbursementClaimLineItem(tmpReimbursementLineItems)
    };


    const handleInput = (e) => setCurrentReimbursementLineItem(prev => {
        const {name, value} = e
        return {
            ...prev,
            [name]: value
        }
    })

    const handleNavigation = (newStep) => {
        setAnimation('to-left')
        setTimeout(() => {
            setStep(newStep)
            setAnimation('from-right')
        }, 600)
    }

    // @ts-ignore
    let predicates = new Map([
        ['submitted', false],
        ['entityStateId', reimbursementClaim?.entityStateId ?? '']
    ])

    return (
        <>
            <form onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full justify-between'}
                  autoComplete={'off'}>
                <div>
                    <ProgressStepper step={step} stepList={[
                        'Assessments',
                        'Equipment',
                        'Shipping & Tax',
                        'Installation',
                        'Training',
                        'Travel',
                        'Administration',
                        'Outreach',
                        'Maintenance/Repair'
                    ]} handleClick={(i) => setStep(i)}
                                     numbered
                    />
                    <div>
                        <div className={`${step === 0 ? animation : 'hide'}`}>
                            <AssessmentSelectTable onChange={(value) => handleInput({name: 'assessmentIds', value})}
                                                   selected={reimbursementClaimLineItem?.assessmentIds}
                                                   predicates={predicates}/>
                        </div>
                        <div className={`${step === 1 ? animation : 'hide'}`}>
                            <EquipmentSelectTable onChange={(value) => handleInput({name: 'equipmentIds', value})}
                                                  selected={reimbursementClaimLineItem?.equipmentIds}
                                                  predicates={predicates}/>
                        </div>
                        <div className={`${step === 2 ? animation : 'hide'}`}>
                            <ShippingAndTaxSelectTable
                                onChange={(value) => handleInput({name: 'shippingAndTaxIds', value})}
                                selected={reimbursementClaimLineItem?.shippingAndTaxIds} predicates={predicates}/>
                        </div>
                        <div className={`${step === 3 ? animation : 'hide'}`}>
                            <InstallationSelectTable
                                onChange={(value) => handleInput({name: 'installationIds', value})}
                                selected={reimbursementClaimLineItem?.installationIds}
                                predicates={predicates}/>
                        </div>
                        <div className={`${step === 4 ? animation : 'hide'}`}>
                            <TrainingSelectTable onChange={(value) => handleInput({name: 'trainingIds', value})}
                                                 selected={reimbursementClaimLineItem?.trainingIds}
                                                 predicates={predicates}/>
                        </div>
                        <div className={`${step === 5 ? animation : 'hide'}`}>
                            <TravelSelectTable onChange={(value) => handleInput({name: 'travelIds', value})}
                                               selected={reimbursementClaimLineItem?.travelIds}
                                               predicates={predicates}/>
                        </div>
                        <div className={`${step === 6 ? animation : 'hide'}`}>
                            <AdministrativeSelectTable
                                onChange={(value) => handleInput({name: 'administrativeIds', value})}
                                selected={reimbursementClaimLineItem?.administrativeIds} predicates={predicates}/>
                        </div>
                        <div className={`${step === 7 ? animation : 'hide'}`}>
                            <OutreachSelectTable onChange={(value) => handleInput({name: 'outreachIds', value})}
                                                 selected={reimbursementClaimLineItem?.outreachIds}
                                                 predicates={predicates}/>
                        </div>
                        <div className={`${step === 8 ? animation : 'hide'}`}>
                            <MaintenanceRepairSelectTable
                                onChange={(value) => handleInput({name: 'maintenanceRepairIds', value})}
                                selected={reimbursementClaimLineItem?.maintenanceRepairIds}
                                predicates={predicates}/>
                        </div>
                    </div>
                </div>

                <div className={'flex justify-between'}>
                    <div className={'flex gap-4'}>
                        <Button color='dark' onClick={() => handleNavigation(step - 1)}
                                id={'previous'}
                                disabled={step === 0} iconLeft={<ChevronLeftIcon className={'h-5 w-5'}/>}>Back</Button>
                        <span className={'m-2'}/>

                        <Button color='dark' onClick={() => handleNavigation(step + 1)}
                                id={'next'}
                                disabled={step === maxStep}
                                iconRight={<ChevronRightIcon className={'h-5 w-5'}/>}>Next</Button>
                    </div>
                    <div className="w-full">
                        {/*Do NOT delete me*/}
                    </div>
                    <div className={'flex gap-4'}>
                        <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>}
                                color={'green'}
                                type='submit'>Save</Button>
                        <Button onClick={() => closeModal('ReimbursementClaimLineItemModal')}
                                color="red"
                                iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>
                            Cancel
                        </Button>
                    </div>
                </div>


            </form>
        </>
    );
};
export default observer(ReimbursementLineItemsForm);