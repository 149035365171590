import {makeAutoObservable, reaction, runInAction} from 'mobx'
import Agent from '../../api/Agent'
import {toast} from 'react-toastify'
import {RootStore} from '../RootStore'
import {
    INationalOutreachBudget,
    NationalOutreachAllocationFormValues,
    NationalOutreachBudgetApprovalFormValues,
    NationalOutreachBudgetPaymentFormValues
} from "../../models/NationalOutreach/NationalOutreachBudget";


export default class NationalOutreachBudgetStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0
                this.nationalOutreachBudgetRegistry.clear()
                this.loadNationalOutreachBudget()
            }
        )
    }

    nationalOutreachBudgetRegistry = new Map()
    nationalOutreachBudget: INationalOutreachBudget | null = null

    nationalOutreachBudgetCount = 0
    page = 0
    limit = 5

    predicate = new Map()

    setPredicate = (predicate?: string, value?: any) => {
        if (!predicate || predicate === '') {
            this.predicate.clear()
        } else {
            this.predicate.delete(predicate)
            if (!(value === undefined && value === '' && value === 'undefined'))
                this.predicate.set(predicate, value instanceof Date ? new Date(value).toISOString() : `${value}`)
        }
    }

    setPredicates = (predicates: {}) => {
        this.nationalOutreachBudgetRegistry.clear()
        let tmpArr = Object.entries(predicates)
        this.predicate = new Map(tmpArr)
    }

    get axiosParams() {
        const params = new URLSearchParams()
        params.append('limit', String(this.limit))
        params.append('offset', `${this.page ? this.page * this.limit : 0}`)
        this.predicate.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === 'undefined') && typeof value !== 'object') {
                params.append(key, value)
            } else if (typeof value === 'object') {
                value?.forEach(val => params.append(key, val))
            }
        })
        return params
    }

    get totalPages() {
        return Math.ceil(this.nationalOutreachBudgetCount / this.limit)
    }

    get nationalOutreachBudgetsList() {
        // @ts-ignore
        return Array.from(this.nationalOutreachBudgetRegistry.values()).sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn))
    }

    get nationalOutreachBudgetsByDate() {
        return Array.from(this.nationalOutreachBudgetRegistry.values()).sort(
            (a, b) => b.dateCreated - a.dateCreated//   Date.parse(a.SubmittedOn)-Date.parse(b.SubmittedOn)
        )
    }

    getNationalOutreachBudget = (id: string) => {
        return this.nationalOutreachBudgetRegistry.get(id)
    }

    setPage = (page: number) => {
        this.page = page
    }

    setLimit = (limit: number) => {
        this.limit = limit
    }

    handleChangePage = (newPage: number, newRow: number) => {
        //sets page, we do -1 because the offset will be multiplied against 
        this.setPage(newPage - 1)
        //sets the row limit
        this.setLimit(newRow)
        //clears all records in the registry so that next page didnt load the prev records
        this.nationalOutreachBudgetRegistry.clear()
        this.loadNationalOutreachBudget()
    }

    clearNationalOutreachBudget = () => {
        this.nationalOutreachBudgetRegistry.clear()
    }

    loadNationalOutreachBudget = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const nationalOutreachBudgetsEnvelope = await Agent.NationalOutreachBudgets.list(this.axiosParams)
            const {modelsDto, modelCount} = nationalOutreachBudgetsEnvelope

            runInAction(() => {
                this.clearNationalOutreachBudget()
                modelsDto.forEach((nationalOutreachBudget: INationalOutreachBudget) => {
                    this.nationalOutreachBudgetRegistry.set(nationalOutreachBudget.id, nationalOutreachBudget)
                })
                this.nationalOutreachBudgetCount = modelCount
            })
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    loadNakedNationalOutreachBudget = async () => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const params = new URLSearchParams()
            params.append('limit', '10000000')
            params.append('offset', `0`)
            const nationalOutreachBudgetsEnvelope = await Agent.NationalOutreachBudgets.list(params)
            const {modelsDto} = nationalOutreachBudgetsEnvelope

            return modelsDto
        } catch (error) {
            throw error
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    getNationalOutreachBudgetById = async (id: string) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let nationalOutreachBudget = await Agent.NationalOutreachBudgets.byId(id)
            runInAction(() => {
                this.nationalOutreachBudget = nationalOutreachBudget
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            return nationalOutreachBudget
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        }
        runInAction(() => {
            this.rootStore.commonStore.setIsLoading(loadingId)
        })
    }

    createNationalOutreachBudget = async (nationalOutreachBudget: INationalOutreachBudget) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            const newNationalOutreachBudget = await Agent.NationalOutreachBudgets.create(nationalOutreachBudget)
            await this.loadNationalOutreachBudget()
            runInAction(() => {
                this.nationalOutreachBudgetRegistry.set(newNationalOutreachBudget.id, newNationalOutreachBudget)
                this.rootStore.modalStore.closeModal('NationalOutreachBudgetModal')
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')
            return newNationalOutreachBudget
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
        }
    }

    editNationalOutreachBudget = async (nationalOutreachBudget: NationalOutreachAllocationFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedNationalOutreachBudget = await Agent.NationalOutreachBudgets.update(nationalOutreachBudget)
            runInAction(() => {
                this.nationalOutreachBudgetRegistry.set(updatedNationalOutreachBudget.id, updatedNationalOutreachBudget)
                this.rootStore.modalStore.closeModal('NationalOutreachBudgetModal')
            })
            toast.success('Saved')
            return updatedNationalOutreachBudget
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    approveNationalOutreachBudget = async (nationalOutreachBudgetApproval: NationalOutreachBudgetApprovalFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedNationalOutreachBudget = await Agent.NationalOutreachBudgets.approve(nationalOutreachBudgetApproval)
            runInAction(() => {
                this.nationalOutreachBudgetRegistry.set(updatedNationalOutreachBudget.id, updatedNationalOutreachBudget)
            })
            toast.success('Saved')
            return updatedNationalOutreachBudget
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    reviewNationalOutreachBudget = async (nationalOutreachBudgetApproval: NationalOutreachBudgetApprovalFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let reviewedNationalOutreachBudget = await Agent.NationalOutreachBudgets.review(nationalOutreachBudgetApproval)
            runInAction(() => {
                this.nationalOutreachBudgetRegistry.set(reviewedNationalOutreachBudget.id, reviewedNationalOutreachBudget)
            })
            toast.success('Saved')
            return reviewedNationalOutreachBudget
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    submitNationalOutreachBudget = async (nationalOutreachBudget: NationalOutreachAllocationFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            let updatedNationalOutreachBudget = await Agent.NationalOutreachBudgets.submit(nationalOutreachBudget)
            runInAction(() => {
                this.nationalOutreachBudgetRegistry.set(updatedNationalOutreachBudget.id, updatedNationalOutreachBudget)
            })
            toast.success('Saved')
            return updatedNationalOutreachBudget
        } catch (error) {
            this.rootStore.errorStore.addError(error)
        } finally {
            this.rootStore.commonStore.setIsLoading(loadingId)
        }
    }

    deleteNationalOutreachBudget = async (nationalOutreachBudget: INationalOutreachBudget) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.NationalOutreachBudgets.delete(nationalOutreachBudget.id ?? '')
            runInAction(() => {
                this.nationalOutreachBudgetRegistry.delete(nationalOutreachBudget.id)
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            toast.success('Saved')

        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }

    }
    payNationalOutreachBudget = async (id:string, nationalOutreachBudget: NationalOutreachBudgetPaymentFormValues) => {
        let loadingId = this.rootStore.commonStore.setIsLoading()
        try {
            await Agent.NationalOutreachBudgets.payment(id, nationalOutreachBudget)
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
                this.rootStore.modalStore.closeModal('nationalOutreachBudgetPaymentModal')
            })
            toast.success('Saved')

        } catch (error) {
            runInAction(() => {
                this.rootStore.commonStore.setIsLoading(loadingId)
            })
            this.rootStore.errorStore.addError(error)
        }
    }


}