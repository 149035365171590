import {api, responseBody} from './apiBase.service'
import {ISubItemCategoryEnvelope} from "../../models/SubItems/SubItemCategory";
import {ISubItem} from '../../models/SubItems/SubItem'
import axios from "axios";
import {IMassCostUpdateFormValues} from "../../models/MassCost/MassCost";

const SubItem = {
    list: (
        params: URLSearchParams
    ): Promise<ISubItemCategoryEnvelope> => {
        return axios
            .get("/SubItem/Category", {params: params})
            .then(responseBody)
    },
    create: (subItem: ISubItem) =>
        api.post(`/SubItem`, subItem),
    update: (subItem: ISubItem) =>
        api.put(`/SubItem/${subItem.id}`, subItem),
    updates: (subItems: IMassCostUpdateFormValues) =>
        api.put(`/SubItem/MassUpdate`, subItems),
    delete: (subItem: ISubItem) =>
        api.del(`/SubItem/${subItem.id}`),
};


export default SubItem