import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {RootStoreContext} from "../../../../../stores/RootStore";
import {EquipmentFormValues, IEquipment} from "../../../../../models/Equipment/Equipment";
import ConsumerAutoCompleteInput from "../../../../../components/form/ConsumerAutoCompleteInput";
import Input from "../../../../../components/Inputs/Input";
import {functionOptions} from "../../../../../data/equiment/functionOptions";
import Button from "../../../../../components/generic/Button";
import dateInputOptions from "../../../../../data/cleave/dateInputOptions";
import {inputOnChange, objectDateConverter, today} from "../../../../../lib/utilities";
import {IEntityState} from "../../../../../models/Entity/EntityState";
import EntityStateAutoCompleteInput from '../../../../../components/form/EntityStatesAutoCompleteInput';
import Toggle from "../../../../../components/generic/Toggle";
import {useLocalStorage} from "../../../../../hooks/useLocalStorage";
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/solid";
import Checkbox from "../../../../../components/Inputs/Checkbox";

export interface EquipmentFormProps {
    equipment?: IEquipment
    consumerId?: string

}

type Props = EquipmentFormProps

const EquipmentForm: React.FC<Props> = ({equipment, consumerId}) => {
    // store
    const rootStore = useContext(RootStoreContext)
    const {currentEntityState} = rootStore.commonStore
    const {closeModal, openModal} = rootStore.modalStore
    const {createEquipment, editEquipment} = rootStore.equipmentStore
    const {getEntityStateByConsumerId, entityStatesByName} = rootStore.entityStateStore

    // state
    const [currentEquipment, setCurrentEquipment] = useState(equipment || {} as IEquipment);
    const [demo, setDemo] = useState(equipment?.demo ?? false);
    const [addCost, setAddCost] = useState(true);

    // Local Storage
    const [localEntityStates,] = useLocalStorage('entityStates', []);


    // Use Effects
    useEffect(() => {
        objectDateConverter(equipment, setCurrentEquipment)

        if (!!currentEquipment.consumerId) {
            getEntityStateByConsumerId(currentEquipment.consumerId)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    // setStateProgram(entityState)
                    // @ts-ignore
                    setCurrentEquipment((prev: IEquipment) => {
                        return {
                            ...prev,
                            entityStateId: entityState?.id || '',
                            entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : ''
                        }
                    })
                })
        }
    }, []);

    useEffect(() => {
        if (!currentEquipment.entityStateId && (!!entityStatesByName && entityStatesByName.length === 1)) {
            setCurrentEquipment((prev: EquipmentFormValues) => {
                return {
                    ...prev,
                    entityStateId: currentEntityState.id || '',
                    entityState: currentEntityState.id ? `${currentEntityState.stateName} (${currentEntityState.entityName})` : ''
                }
            })
        }
    }, [currentEntityState])

    useEffect(() => {
        if (!currentEquipment.consumerId && (!!localEntityStates && localEntityStates.length === 1)) {
            setCurrentEquipment((prev: EquipmentFormValues) => {
                return {
                    ...prev,
                    entityStateId: localEntityStates[0].id || '',
                    entityState: localEntityStates[0].id ? `${localEntityStates[0].stateName} (${localEntityStates[0].entityName})` : ''
                }
            })
        }
    }, [localEntityStates])

    useEffect(() => {
        setCurrentEquipment((prev: IEquipment) => {
            if (demo) {
                prev.consumerId = ''
                // prev.entityStateId = stateProgram.id ?? ''
            }
            return {
                ...prev
            }
        })
    }, [demo]);

    useEffect(() => {
        if (!!consumerId) getEntityStateByConsumerId(consumerId)
            // @ts-ignore
            .then((entityState: IEntityState) => {
                // setStateProgram(entityState)
                setCurrentEquipment((prev: IEquipment) => {
                    return {
                        ...prev,
                        entityStateId: entityState?.id || '',
                        entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : '',
                        consumerId
                    }
                })
            })
    }, [consumerId]);

    // Functions
    const handleConsumerAndState = (value) => {
        if (!!value) {
            getEntityStateByConsumerId(value)
                // @ts-ignore
                .then((entityState: IEntityState) => {
                    // setStateProgram(entityState)
                    setCurrentEquipment((prev: IEquipment) => {
                        return {
                            ...prev,
                            entityStateId: entityState?.id || '',
                            entityState: entityState?.id ? `${entityState?.stateName} (${entityState?.entityName})` : '',
                            consumerId: value
                        }
                    })
                })
        } else {
            // setStateProgram({} as IEntityState)
            setCurrentEquipment((prev: IEquipment) => {
                return {
                    ...prev,
                    entityStateId: '',
                    entityState: '',
                    consumerId: ''
                }
            })
        }

    }

    const handleChange = (e) => {
        const {name, value} = e.currentTarget;
        if (name === 'consumerId') {
            handleConsumerAndState(value)
        } else {
            inputOnChange(e, setCurrentEquipment)
        }
    }

    const handleSubmit = (e): Promise<any> => {
        e.preventDefault()
        let newEquipment = {...currentEquipment}
        if (!newEquipment.id || newEquipment.id === '') {
            delete newEquipment.id
            return createEquipment(newEquipment).then(data => addCostAfterSave(data))
        } else {
            return editEquipment(newEquipment)
        }
    }

    const addCostAfterSave = async (data) => {
        if (!!data && addCost) await openModal('SubItemModal', {
            lineItemId: data.id,
            lineItemType: 'Equipment',
            header: 'New Equipment Cost',
            entityStateId: data.entityStateId,
        })
    }

    const handleAutoComplete = (value, name) => {
        handleChange({
            currentTarget: {
                name, value
            }
        })
    }

    return (
        <form autoComplete={'off'} onSubmit={handleSubmit} className={'flex flex-col flex-1 h-full'}>
            <div className={'overflow-y-auto overflow-x-hidden flex-1'}>
                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                    <Input label={'Equipment Name'}
                           placeholder={'Flux Capacitor'}
                           value={currentEquipment.equipmentName}
                           name={'equipmentName'}
                           onChange={handleChange}
                           required
                    />
                    <Input label={'Serial Number'}
                           placeholder={'1&0n1y'}
                           value={currentEquipment.serialNumber}
                           name={'serialNumber'}
                           onChange={handleChange}
                    />
                </div>

                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                    <Input label={'Brand'}
                           placeholder={'Dolarian Time Parts'}
                           value={currentEquipment.brand}
                           name={'brand'}
                           onChange={handleChange}
                    />
                    <Input list="functionOptions" placeholder={'Energy Source'}
                           name="function" label={'Function'} onChange={handleChange}/>


                    <datalist id={'functionOptions'}>
                        {functionOptions.map(option => <option value={option}>{option}</option>)}
                    </datalist>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1 ">
                    {(equipment?.demo || !Object.keys(equipment ?? {}).length) && !consumerId &&
                    <Checkbox label={'Equipment is inventory'}
                           checked={demo}
                           name={'isDemo'}
                           onChange={(e) => {
                               e.stopPropagation()
                               setDemo(prev => !prev)
                           }}
                    />}
                    <Checkbox label={'Equipment is a device?'}
                           checked={currentEquipment.isDevice}
                           name={'isDevice'}
                           onChange={handleChange}
                    />
                </div>

                {!consumerId && <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1">
                    {!Object.keys(equipment ?? {}).length || equipment?.demo ?
                        <div className='w-full'>
                            {!demo ? <ConsumerAutoCompleteInput
                                displayValue={(currentEquipment.consumerId) ? (currentEquipment.consumerName ? currentEquipment.consumerName : 'Consumer Already Assigned') : ''}
                                onChange={({id}) => handleAutoComplete(id, 'consumerId')}
                                placeholder={'Marty McFly'}
                                disabled={demo}
                                required
                                name='consumer'
                            /> : <Input value={'Inventory Equipment'} disabled label={'Consumer'} name={'consumer'}/>}
                        </div> : ''}


                    <div className='w-full'>
                        <EntityStateAutoCompleteInput
                            required
                            isLocked={!demo || (!currentEquipment.id && !!localEntityStates && localEntityStates.length === 1)}
                            onChange={({id}) => handleAutoComplete(id, 'entityStateId')}
                            name={'entityStateId'}
                            placeholder={'California'}
                            displayValue={(currentEquipment?.entityStateId) ? (currentEquipment?.entityState || 'State Program already selected') : ''}
                        />
                    </div>
                </div>
                }
                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                    <div className={'w-full'}>
                        <Input label={'Date of Assessment'}
                               value={`${currentEquipment.dateRequested ?? ''}`}
                               name={'dateRequested'}
                               onChange={handleChange}
                               options={{
                                   ...dateInputOptions,
                                   dateMax: currentEquipment.dateReceived ? currentEquipment.dateReceived.toString() : today(),
                               }}

                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                               required={!demo}
                        />
                    </div>
                    <div className={'w-full'}>
                        <Input label={'Date Received '}
                               value={`${currentEquipment.dateReceived ?? ''}`}
                               name={'dateReceived'}
                               onChange={handleChange} required
                               options={dateInputOptions}
                               errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                        />
                    </div>
                </div>
                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-1'}>
                    <div className={'w-full'}>
                        <Input label={'Invoice Date'}
                               value={`${currentEquipment.invoiceDate ?? ''}`}
                               name={'invoiceDate'}
                               onChange={handleChange} required
                               options={dateInputOptions}
                               errMsg={'Please provide a valid Date e.g. "09/17/1972"'}
                               pattern="^(\d{2})\/(\d{2})\/(\d{4})$"
                               placeholder={'10/26/1985'}
                        />
                    </div>
                </div>

                {!currentEquipment.id ?
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-x-4 gap-y-1">
                            <Toggle label={'add cost after saving'} onChange={() => setAddCost(prev => !prev)}
                                    checked={addCost} name={'addCostToggle'}/>
                    </div> : ''}
            </div>

            <div className={'flex gap-4 justify-end'}>
                <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'}
                        type={'submit'}>Save</Button>

                <Button name={'cancel'} onClick={() => closeModal('EquipmentModal')} color="red"
                        iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
            </div>

        </form>
    )
}

export default observer(EquipmentForm)