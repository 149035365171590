import axios from "axios";
import {api, responseBody} from "./apiBase.service";
import {ISemiAnnualReportAttachmentEnvelope} from "../../models/SemiAnnualReport/SemiAnnualReportAttachment";

const SemiAnnualReportAttachments = {
    list: (params: URLSearchParams): Promise<ISemiAnnualReportAttachmentEnvelope> => {
        return axios
            .get("/SemiAnnualReport/Attachment", {params: params})
            .then(responseBody)
    },
    byId: (id: string) =>
        api.get(`/SemiAnnualReport/${id}/Attachment`),
    downloadById: (id: string) =>
        api.get(`/SemiAnnualReport/Attachment/${id}`),
};

export default SemiAnnualReportAttachments