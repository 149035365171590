import axios from "axios";
import {IStateEnvelope} from "../../models/State";
import {responseBody} from "./apiBase.service";

const States = {
    list: (
        params: URLSearchParams
    ): Promise<IStateEnvelope> =>
        axios
            .get("/State", {params: params})
            .then(responseBody),
};

export default States